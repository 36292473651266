var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"formProduct",model:{value:(_vm.validateProduct),callback:function ($$v) {_vm.validateProduct=$$v},expression:"validateProduct"}},[_c('v-dialog',{staticClass:"dialogo-compras",attrs:{"persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-list-item',[_c('v-row',{staticClass:"my-1"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"2"}},[_c('v-text-field',{attrs:{"label":"N° factura","rules":_vm.numeroFacturaRules,"required":""},model:{value:(_vm.purchase.factura_numero),callback:function ($$v) {_vm.$set(_vm.purchase, "factura_numero", $$v)},expression:"purchase.factura_numero"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"6"}},[_c('v-autocomplete',{attrs:{"rules":_vm.proveedorRules,"items":_vm.suppliers,"search-input":_vm.searchLocalSupplier,"loading":_vm.loading,"item-text":"nombre","item-value":"id","cache-items":"","label":"Proveedor *","clearable":""},on:{"update:searchInput":function($event){_vm.searchLocalSupplier=$event},"update:search-input":function($event){_vm.searchLocalSupplier=$event},"click":function($event){_vm.purchase.supplier_id = null}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-sheet',{staticClass:"d-flex justify-center ma-2"},[_c('h5',[_vm._v("Proveedor inexistente")])]),_vm._v(" "),_c('v-sheet',{staticClass:"d-flex justify-center"},[_c('v-btn',{on:{"click":function($event){_vm.setDialogSupplier();
                                                _vm.setIsSupplierForOther();
                                                _vm.autoInputNewSuppleir(
                                                    _vm.searchLocalSupplier
                                                );}}},[_vm._v("Crear Proveedor")])],1)]},proxy:true}]),model:{value:(_vm.purchase.supplier_id),callback:function ($$v) {_vm.$set(_vm.purchase, "supplier_id", $$v)},expression:"purchase.supplier_id"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-checkbox',{attrs:{"label":"Crédito *"},model:{value:(_vm.purchase.credito),callback:function ($$v) {_vm.$set(_vm.purchase, "credito", $$v)},expression:"purchase.credito"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"rules":_vm.methodPlazo,"disabled":!_vm.purchase.credito,"label":"Plazo *","suffix":"Días","required":""},model:{value:(_vm.purchase.plazo),callback:function ($$v) {_vm.$set(_vm.purchase, "plazo", $$v)},expression:"purchase.plazo"}})],1)],1),_vm._v(" "),_c('v-form',{ref:"formInputProduct",model:{value:(_vm.validateInputProduct),callback:function ($$v) {_vm.validateInputProduct=$$v},expression:"validateInputProduct"}},[_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.products,"loading":_vm.loadingSearchProduct,"search-input":_vm.searchProduct,"label":"Elija un producto","clearable":"","no-filter":""},on:{"update:searchInput":function($event){_vm.searchProduct=$event},"update:search-input":function($event){_vm.searchProduct=$event},"input":(event) =>
                                            _vm.agregarDatoProducto(event)},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('h6',[_vm._v("Producto inexistente")]),_vm._v(" "),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.setDialogProduct()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")]),_vm._v("\n                                                Agregar\n                                            ")],1)],1)]},proxy:true},{key:"item",fn:function({ item }){return [_c('v-list',[_c('v-list-item-title',[_vm._v("\n                                                "+_vm._s(item.nombre)+"\n                                            ")]),_vm._v(" "),_c('v-list-item-subtitle',[_vm._v("\n                                                "+_vm._s(item.codigo)+"\n                                            ")])],1)]}},{key:"selection",fn:function({ item }){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("\n                                                "+_vm._s(` ${item.nombre} - ${item.codigo}`)+"\n                                            ")])],1)]}},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(
                                                _vm.endIntersectProduct
                                            ),expression:"\n                                                endIntersectProduct\n                                            "}]})]},proxy:true}]),model:{value:(_vm.localProduct.select),callback:function ($$v) {_vm.$set(_vm.localProduct, "select", $$v)},expression:"localProduct.select"}})],1),_vm._v(" "),_c('v-col',{staticClass:"py-0",attrs:{"md":"1"}},[_c('v-text-field',{attrs:{"type":"number","placeholder":"cantidad producto","label":"Cantidad"},on:{"input":function($event){return _vm.calcularTotal()}},model:{value:(_vm.localProduct.cantidad),callback:function ($$v) {_vm.$set(_vm.localProduct, "cantidad", $$v)},expression:"localProduct.cantidad"}})],1),_vm._v(" "),_c('v-col',{staticClass:"py-0",attrs:{"md":"2"}},[_c('vuetify-money',{attrs:{"label":"Precio Compra","options":_vm.vmOptions},on:{"input":function($event){_vm.calcularTotal();
                                        _vm.calcularPrecioVenta();}},model:{value:(_vm.localProduct.precio_unitario),callback:function ($$v) {_vm.$set(_vm.localProduct, "precio_unitario", $$v)},expression:"localProduct.precio_unitario"}})],1),_vm._v(" "),_c('v-col',{attrs:{"md":"2"}},[_c('v-select',{staticClass:"mt-n2",attrs:{"label":"Deposito","items":_vm.stores,"placeholder":"Seleccionar Deposito","item-text":"nombre","item-value":"id","rules":_vm.storeRules},on:{"change":function($event){return _vm.changeStore(_vm.selectStorage)}},model:{value:(_vm.selectStorage),callback:function ($$v) {_vm.selectStorage=$$v},expression:"selectStorage"}})],1),_vm._v(" "),_c('v-col',{attrs:{"md":"3","align-self":"center"}},[_c('span',{staticClass:"text-success h5 ml-10"},[_vm._v("\n                                    Sub\n                                    "+_vm._s(_vm.convertMoney(
                                            _vm.localProduct.total,
                                            _vm.getCurrencyCode
                                        ))+"\n                                ")])]),_vm._v(" "),_c('v-col',{attrs:{"md":"3","align-self":"center"}},[_c('v-select',{attrs:{"items":_vm.quotes,"item-value":"quotation_id","item-text":"nombre","label":"Moneda","rules":_vm.quoteRules},on:{"change":_vm.selectQuotation},model:{value:(_vm.purchase.quotation_id),callback:function ($$v) {_vm.$set(_vm.purchase, "quotation_id", $$v)},expression:"purchase.quotation_id"}})],1)],1),_vm._v(" "),_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"md":"3"}},[_c('v-row',{attrs:{"no-gutters":"","align-end":""}},[_c('v-col',{attrs:{"md":"8"}},[_c('vuetify-money',{attrs:{"type":"number","options":_vm.vmOptions,"label":"Precio Venta"},on:{"input":function($event){return _vm.calcularPorcentaje(0)}},model:{value:(
                                                _vm.localProduct.precio_venta
                                            ),callback:function ($$v) {_vm.$set(_vm.localProduct, "precio_venta", _vm._n($$v))},expression:"\n                                                localProduct.precio_venta\n                                            "}})],1),_vm._v(" "),_c('v-col',{staticClass:"ml-1",attrs:{"md":"3"}},[_c('v-text-field',{staticClass:"centered-input",attrs:{"suffix":"%"},on:{"input":function($event){return _vm.calcularPrecioVenta(0)}},model:{value:(_vm.porcentaje),callback:function ($$v) {_vm.porcentaje=$$v},expression:"porcentaje"}})],1)],1)],1),_vm._v(" "),_c('v-col',{staticClass:"py-0",attrs:{"md":"3"}},[_c('v-row',{attrs:{"no-gutters":"","align-end":""}},[_c('v-col',{attrs:{"md":"8"}},[_c('vuetify-money',{attrs:{"label":"Precio Descuento","options":_vm.vmOptions},on:{"input":function($event){return _vm.calcularPorcentaje(1)}},model:{value:(
                                                _vm.localProduct.precio_descuento
                                            ),callback:function ($$v) {_vm.$set(_vm.localProduct, "precio_descuento", _vm._n($$v))},expression:"\n                                                localProduct.precio_descuento\n                                            "}})],1),_vm._v(" "),_c('v-col',{staticClass:"ml-1",attrs:{"md":"3"}},[_c('v-text-field',{staticClass:"centered-input",attrs:{"suffix":"%"},on:{"input":function($event){return _vm.calcularPrecioVenta(1)}},model:{value:(_vm.porcentajeDescuento),callback:function ($$v) {_vm.porcentajeDescuento=$$v},expression:"porcentajeDescuento"}})],1)],1)],1),_vm._v(" "),_c('v-col',{staticClass:"py-0",attrs:{"md":"3"}},[_c('v-row',{attrs:{"no-gutters":"","align-end":""}},[_c('v-col',{attrs:{"md":"8"}},[_c('vuetify-money',{attrs:{"min":"0","label":"Precio Mayorista","options":_vm.vmOptions},on:{"input":function($event){return _vm.calcularPorcentaje(2)}},model:{value:(
                                                _vm.localProduct.precio_mayorista
                                            ),callback:function ($$v) {_vm.$set(_vm.localProduct, "precio_mayorista", _vm._n($$v))},expression:"\n                                                localProduct.precio_mayorista\n                                            "}})],1),_vm._v(" "),_c('v-col',{staticClass:"ml-1",attrs:{"md":"3"}},[_c('v-text-field',{staticClass:"centered-input",attrs:{"suffix":"%"},on:{"input":function($event){return _vm.calcularPrecioVenta(2)}},model:{value:(_vm.porcentajeMayorista),callback:function ($$v) {_vm.porcentajeMayorista=$$v},expression:"porcentajeMayorista"}})],1)],1)],1),_vm._v(" "),_c('v-col',{staticClass:"pl-14 pt-6 py-0 mt-n2",attrs:{"md":"3"}},[_c('v-btn',{attrs:{"outlined":"","color":_vm.formColorEditProduct},on:{"click":_vm.agregarProducto}},[_vm._v("\n                                    "+_vm._s(_vm.formTitleEditProduct)+"\n                                ")]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"red"},on:{"click":_vm.btnEditProduct}},'v-icon',attrs,false),on),[_vm._v("\n                                            mdi-close\n                                        ")])]}}])},[_vm._v(" "),_c('span',[_vm._v("Vaciar Campos")])])],1)],1)],1),_vm._v(" "),_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.returnHeader,"items":_vm.purchaseProducts,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){_vm.setIsEditProduct(true);
                                        _vm.addEditProduct(item);}}},[_vm._v("\n                                    mdi-pencil\n                                ")]),_vm._v(" "),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deletePurchaseProduct(item)}}},[_vm._v("\n                                    mdi-delete\n                                ")])]}},{key:`item.sub_total`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.convertMoney(
                                        item.cantidad *
                                            item.precio_unitario,
                                        _vm.getCurrencyCode
                                    )))])]}},{key:`item.precio_unitario`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.convertMoney(
                                        item.precio_unitario,
                                        _vm.getCurrencyCode
                                    )))])]}},{key:`item.precio_venta`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.convertMoney(
                                        item.precio_venta,
                                        _vm.getCurrencyCode
                                    )))])]}},{key:`item.precio_mayorista`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.convertMoney(
                                        item.precio_mayorista,
                                        _vm.getCurrencyCode
                                    )))])]}},{key:`item.precio_descuento`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.convertMoney(
                                        item.precio_descuento,
                                        _vm.getCurrencyCode
                                    )))])]}},(_vm.edit)?{key:`item.store_id`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.returnNameStore(item.store_id)))])]}}:{key:`item.dep`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.returnNameStore(item.dep)))])]}}],null,true)}),_vm._v(" "),_c('h4',{staticClass:"text-success text-right mb-0 mt-2 mr-4"},[_vm._v("\n                            Total:\n                            "+_vm._s(_vm.convertMoney(_vm.getTotalPrice, _vm.getCurrencyCode))+"\n                        ")]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',{staticClass:"ma-0 pa-0"},[_c('v-spacer'),_vm._v(" "),(_vm.edit)?_c('v-btn',{staticClass:"ma-3",attrs:{"color":"red lighten-2","text":"","outlined":""},on:{"click":function($event){_vm.setDialog();
                                    _vm.setEdit();
                                    _vm.resetValidate();
                                    _vm.clearPurchase();}}},[_vm._v("\n                                Cerrar\n                            ")]):_c('v-btn',{staticClass:"ma-3",attrs:{"color":"red lighten-2","text":"","outlined":""},on:{"click":function($event){_vm.setDialog();
                                    _vm.resetValidate();}}},[_vm._v("\n                                Cerrar\n                            ")]),_vm._v(" "),(_vm.edit)?_c('v-btn',{staticClass:"ma-3",attrs:{"disabled":!_vm.valid,"loading":_vm.loadingEditPurchase,"color":"primary","outlined":"","text":""},on:{"click":function($event){return _vm.validateSubmit(_vm.edit, _vm.purchase)}}},[_vm._v("\n                                Editar\n                            ")]):_c('v-btn',{attrs:{"disabled":!_vm.valid,"loading":_vm.loadingCreatePurchase,"color":"primary","outlined":"","text":""},on:{"click":function($event){return _vm.validateSubmit(_vm.edit, _vm.purchase)}}},[_vm._v("\n                                Guardar\n                            ")])],1)],1)],1)],1)],1),_vm._v(" "),_c('FormSupplies'),_vm._v(" "),_c('FormProductComponent')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }