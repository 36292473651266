<template>
    <div class="mx-10">
        <v-data-table
            :headers="headers"
            :items="clients"
            :loading="loadingClient"
            hide-default-footer
        >
            <template v-slot:top>
                <!-- Encabezado Lista Tabla -->
                <!-- Buscar y Button Agregar -->

                <v-row align="center" justify="space-between">
                    <v-col cols="12" lg="6" md="6" sm="6">
                        <v-text-field
                            v-model="search"
                            label="Buscar"
                            append-icon="mdi-magnify"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="auto"
                        md="auto"
                        sm="auto"
                        v-if="canCreate"
                    >
                        <v-btn color="primary" @click="setDialog">
                            <v-icon> mdi-plus </v-icon>
                            Agregar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!-- Iconos Editar Y Eliminar  -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    v-if="canEdit"
                    small
                    class="mr-2"
                    color="blue"
                    @click="
                        setDialog();
                        setEdit();
                        setClient(item);
                    "
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    v-if="canDelete"
                    small
                    color="red"
                    @click="
                        localClient = item;
                        setDeleteDialog();
                    "
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>

        <!-- Seccion para paginacion -->
        <v-pagination
            v-model="clientsPage.current"
            class="py-4"
            :length="clientsPage.lastPage"
        ></v-pagination>
        <DeleteDialog
            :dialog="deleteDialog"
            :setDialog="setDeleteDialog"
            v-on:deleted="
                deleteClient(localClient);
                getClients();
            "
        />
    </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import DeleteDialog from "../DeleteDialog.Component.vue";

export default {
    data: () => ({
        search: "",
        localClient: {},
    }),
    watch: {
        "clientsPage.current"(newVal, oldVal) {
            if (this.search) {
                this.clientsPage.current = newVal;
                this.searchClient(this.search);
                return;
            }
            if (newVal !== oldVal) {
                this.clientsPage.current = newVal;
                this.getClients();
            }
        },

        search(val) {
            this.clientsPage.current = 1;
            this.searchClient(val);
        },
    },
    components: {
        DeleteDialog,
    },
    mounted() {
        this.getClients();
        this.getPermissions(this.$route.path);
    },
    computed: {
        ...mapState("client", [
            "clients",
            "dialog",
            "headers",
            "edit",
            "client",
            "deleteDialog",
            "loadingClient",
            "clientsPage",
        ]),
        ...mapState("app", ["canCreate", "canEdit", "canDelete"]),
    },
    methods: {
        ...mapActions("client", ["getClients", "deleteClient", "searchClient"]),
        ...mapMutations("client", [
            "setDialog",
            "setEdit",
            "setClient",
            "setDeleteDialog",
        ]),
        ...mapActions("app", ["getPermissions"]),
    },
};
</script>
