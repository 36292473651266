<template>
    <v-dialog 
        v-model="dialogReportAllSeller" 
        width="500"
        @click:outside="cerrarDialog()"
        @keydown.esc="cerrarDialog()"
    >
        <v-card>
            <v-card  style="overflow-x: hidden; overflow-y: hidden;">
                <v-card-title>{{title}}</v-card-title>
            </v-card>
            <template>
                <v-row class="ma-0 pa-0">
                    
                    <v-col md="12"> 
                        <v-card-subtitle class="text-center">Rango de fecha</v-card-subtitle>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-menu
                                    v-model="abrirDatePickerDesde"
                                    :close-on-content-click="true"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y 
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                    <v-text-field
                                        label="Desde"
                                        prepend-icon="event"
                                        readonly
                                        :value="desdeDateDisp"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        locale="es-es"
                                        v-model="fechaDesde"
                                        no-title
                                        :min="minDate"
                                        :max="new Date().toJSON().slice(0, 10)"
                                        @input="abrirDatePickerHasta = true"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-menu
                                    v-model="abrirDatePickerHasta"
                                    :close-on-content-click="true"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y 
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            label="Hasta"
                                            prepend-icon="event"
                                            readonly
                                            :value="hastaDateDisp"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        locale="es-es"
                                        v-model="fechaHasta"
                                        no-title
                                        :min="minDate"
                                        :max="getTomorrow"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        
                    </v-col>
                </v-row>
            </template>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="cerrarDialog() "
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="generar()"
                    :disabled = "fechaDesde == null || fechaHasta == null "
                >
                    Generar informe
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog> 
</template>
<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";


export default {
    data: () => ({
        selectedSeller: undefined,
        abrirDatePickerDesde: false,
        abrirDatePickerHasta: false,
        fechaDesde: null,
        fechaHasta: null,
        minDate: "2022-01-01",
    }), 
   
    computed: {
        ...mapState("report", ["sellerUsers", "dialogReportAllSeller", "isFacturaInforme"]),
        ...mapGetters("report", ["getTomorrow"]),
        desdeDateDisp() {
            return this.returnDate(this.fechaDesde);
        },
        hastaDateDisp() {
            return this.returnDate(this.fechaHasta);
        },
        title(){
            return this.isFacturaInforme ? 'Informe de facturas emitidas' : 'Informe general de ventas'
        }
    },
    methods: {
        ...mapMutations("report", ["setDialogReportAllSeller",]),
        ...mapActions("report", ["getReportAllSeller", "getReportByFactura"]),
        generar(){
            const data= {
                fechaDesde: this.fechaDesde,
                fechaHasta: this.fechaHasta,
            }
            this.isFacturaInforme ? this.getReportByFactura(data) : this.getReportAllSeller(data);
            this.cerrarDialog();
        },
        cerrarDialog(){
            this.abrirDatePickerDesde= false;
            this.abrirDatePickerHasta= false;
            this.fechaDesde= null;
            this.fechaHasta= null;
            this.setDialogReportAllSeller(false);
        },
        returnDate(date) {
            if(date){
                return date.split("-").reverse().join("-");
            }
        },
    },
};
</script>
