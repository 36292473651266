import axios from "axios";
//Variables Brand
const state = {
    dialog: false,
    edit: false,
    deleteDialog: false,
    isDeleted: false,
    debtors: [],
    debtorData: {},
    debtorDetails: [
        {codigo: "PYG"},
    ],
    headers: [
        {
            text: "ID",
            value: "client_id",
        },
        {
            text: "Nombre",
            align: "start",
            value: "nombre_y_apellido",
        },
        { text: "Monto", value: "total", sortable: false },
        { text: "Opciones", value: "actions", sortable: false, width: "150px" },
    ],
};

//Acciones Brand
const actions = {
    getDebtors({ commit }) {
        axios
            .get("/debtors")
            .then((response) => {
                commit("SET_DEBTORS", response.data);
            })
            .catch((err) => {
                this.commit("app/setSnackbarAlert", err.response.data.message);
            });
    },
    getOneDebtor({ commit }, payload) {
        axios
            .get(`/debtors/${payload.id}/currency/${payload.currency_id}`)
            .then((response) => {
                commit("SET_ONE_DEBTOR", response.data);
                this.commit("app/setVmSuffix", response.data.simbolo); // cambia el simbolo de la moneda para hacer pagos desde debtor.
            })
            .catch((err) => {
                console.log(err.response.data);
                this.commit("app/setSnackbarAlert", err.response.data.message);
            });
    },
};

//Metodos Brand
const mutations = {
    setDialog(state) {
        state.dialog = !state.dialog;
    },
    setEdit(state) {
        state.edit = !state.edit;
    },
    setDeleteDialog(state) {
        state.deleteDialog = !state.deleteDialog;
    },

    setcreate(state, access) {
        state.canCreate = access;
    },
    setedit(state, access) {
        state.canEdit = access;
    },
    setdelete(state, access) {
        state.canDelete = access;
    },
    SET_DEBTORS(state, debtors) {
        state.debtors = debtors;
    },
    SET_ONE_DEBTOR(state, details) {
        state.debtorDetails = details.facturas;

        state.debtorData = {
            nombre_y_apellido: details.nombre_y_apellido,
            total: details.total,
            client_id: details.client_id,
            telefono: details.telefono,
            ruc: details.ruc,
            direccion: details.direccion,
            email: details.email,
            codigo: details.codigo,
            simbolo: details.simbolo,
        };
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
