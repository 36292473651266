<template v-slot:top>
    <div>
        <v-card d-flex flex-row class="mb-6">
            <v-list-item class="mt-10">
                <v-card
                    class="mt-n7"
                    color="primary"
                    elevation="10"
                    min-height="80"
                    min-width="80"
                >
                    <v-icon class="ma-5" large dark>store</v-icon>
                </v-card>
                <v-list-item-title>
                    <v-row align="center" justify="center" class="ml-4">
                        <v-col>
                            <div class="overline">
                                <h4>Deposito</h4>
                            </div>
                        </v-col>
                        <v-col class="d-flex wrap align-center">
                            <v-select
                                v-model="store"
                                :items="stores"
                                placeholder="Seleccionar Deposito"
                                item-text="nombre"
                                item-value="id"
                                @change="(item) => handleChangeStore(item)"
                            />
                            <v-btn
                                outlined
                                small
                                color="primary"
                                class="ml-4"
                                @click="setDialogFormStore(true)"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-list-item-title>
            </v-list-item>

            <v-tabs
                v-model="tab"
                background-color="transparent"
                color="primary"
                grow
            >
                <v-tab v-for="item in tabItems" :key="item" :href="`#${item}`">
                    {{ item }}
                </v-tab>

                <v-tab-item value="deposito">
                    <ListStoreComponent />
                </v-tab-item>
                <v-tab-item value="ingreso">
                    <ListIncomeComponent />
                </v-tab-item>

                <v-tab-item value="baja">
                    <DropComponent />
                </v-tab-item>
                <v-tab-item value="">
                    <DropComponent />
                </v-tab-item>
            </v-tabs>
        </v-card>
        <FormStoreComponent />
        <FormTransfStoreComponent />
        <FormIngresoStoreComponent />
        <FormRemissionComponent />
        <ConvertProductPerBoxFormComponent />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import ListIncomeComponent from "./ListIncome.Component.vue";
import ListStoreComponent from "./ListStore.Component.vue";
import FormStoreComponent from "./FormStore.Component.vue";
import FormIngresoStoreComponent from "./Ingreso/FormIngresoStore.Component.vue";
import FormTransfStoreComponent from "./Transfrencia/FormTransfStore.Component.vue";
import FormRemissionComponent from "./Remission/FormRemission.Component.vue";
import DropComponent from "../DropProduct/Drop.Component.vue";
import ConvertProductPerBoxFormComponent from "./ProdPerBox/ConvertProductPerBoxForm.Component.vue";

export default {
    data: () => ({
        tab: 1,
        tabItems: ["deposito", "ingreso", "baja"],
        store: null,
    }),
    components: {
        ListStoreComponent,
        ListIncomeComponent,
        FormStoreComponent,
        FormIngresoStoreComponent,
        FormTransfStoreComponent,
        FormRemissionComponent,
        DropComponent,
        ConvertProductPerBoxFormComponent,
    },
    async mounted() {
        await this.getAllStores();
        this.store = parseInt(this.getLocaleStorageStore)
            ? parseInt(this.getLocaleStorageStore)
            : this.stores[0];
        this.setCurrencyStore(this.store);
        await this.getIncomeProducts();

        this.setProductPage({
            current: 1,
            total: 0,
            perPage: 0,
            lastPage: 0,
            firstPage: 0,
        });
    },
    computed: {
        ...mapState("deposito", ["stores", "currencyStore"]),
        ...mapGetters("deposito", ["getLocaleStorageStore"]),
    },
    methods: {
        ...mapActions("deposito", [
            "getAllStores",
            "getProductsStores",
            "getIncomeProducts",
        ]),
        ...mapMutations("product", ["setProductPage"]),
        ...mapMutations("deposito", [
            "setDialogFormStore",
            "setLocaleStorageStore",
            "setCurrencyStore",
        ]),

        handleChangeStore(item) {
            this.setLocaleStorageStore(item);
            this.getProductsStores({ dep: item });
            this.setCurrencyStore(item);
        },
    },
};
</script>
