<template>
    <div class="mx-10">
        <!-- Si es posible devolver nombre del cliente -->
        <v-data-table :headers="headers" :items="budgets" :search="search">
            <template v-slot:top>
                <!-- Encabezado Lista Tabla -->
                <!-- Buscar y Button Agregar -->

                <v-row align="center" justify="space-between">
                    <v-col cols="12" lg="6" md="6" sm="6">
                        <v-text-field
                            v-model="search"
                            label="Buscar"
                            append-icon="mdi-magnify"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="auto"
                        md="auto"
                        sm="auto"
                        v-if="canCreate"
                    >
                        <router-link to="/budget/form">
                            <v-btn
                                color="primary"
                                @click="clearSaleProducts(), updateTotal()"
                            >
                                <v-icon> mdi-plus </v-icon>
                                Agregar
                            </v-btn>
                        </router-link>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:[`item.estado`]="{ item }">
                <v-chip
                    style="width: 150px; justify-content: center"
                    :color="returnEstadoColor(item.estado)"
                    label
                    text-color="white"
                >
                    {{ item.estado }}
                </v-chip>
            </template>

            <!-- columna fecha creacion -->
            <template v-slot:[`item.credito`]="{ item }">
                <v-chip
                    class="ma-2"
                    label
                    color="white"
                    :text-color="
                        item.credito == 0
                            ? 'primary lighten-1'
                            : 'deep-orange darken-1'
                    "
                >
                    <v-icon left>{{
                        item.credito == 0
                            ? "mdi-cash-multiple"
                            : "mdi-credit-card-multiple"
                    }}</v-icon>

                    {{ isCredito(item.credito) }}
                </v-chip>
            </template>
            <!-- columna fecha creacion -->
            <template v-slot:[`item.created_at`]="{ item }">
                <span>{{ returnDate(item.created_at) }}</span>
            </template>

            <!-- Acciones para eliminar y editar -->

            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    color="blue"
                    @click="imprimirPresupuesto(item)"
                >
                    mdi-printer
                </v-icon>

                <v-icon
                    small
                    class=""
                    color="green"
                    @click="verPresupuesto(item)"
                >
                    mdi-eye
                </v-icon>

                <v-btn
                    icon
                    plain
                    :disabled="canEdit && item.estado === 'Vendido'"
                    to="/budget/form"
                    style="text-decoration: none"
                >
                    <!-- <router-link v-if="canEdit && item.estado !== 'Vendido'"> -->
                    <v-icon small color="primary" @click="editarDeposito(item)">
                        mdi-pencil
                    </v-icon>
                </v-btn>

                <v-menu bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon icon v-bind="attrs" v-on="on"
                            >mdi-dots-vertical</v-icon
                        >
                    </template>

                    <v-list dense>
                        <v-list-item
                            v-if="canDelete && item.estado !== 'Vendido'"
                            @click="
                                setDeleteDialogBudget(true),
                                    (localBudget = item)
                            "
                        >
                            <v-icon small class="mr-2" color="red">
                                mdi-delete
                            </v-icon>
                            Eliminar
                        </v-list-item>
                        <v-list-item @click="abrirOpcionPresupuesto(item)">
                            <v-icon small class="mr-2" color="primary">
                                mdi-download
                            </v-icon>
                            Descargar
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>

        <DeleteDialogComponent
            :dialog="deleteDialogBudget"
            :setDialog="setDeleteDialogBudget"
            v-on:deleted="eliminarDeposito(localBudget)"
        />
    </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import DeleteDialogComponent from "../DeleteDialog.Component.vue";

export default {
    data: () => ({
        search: "",
        localBudget: null,
    }),
    mounted() {
        this.getBudgets();
        this.getPermissions(this.$route.path);
        this.setEditBudget(false);
        this.getAllStores();
        this.getQuoteCurrency();
    },
    components: {
        DeleteDialogComponent,
    },
    computed: {
        ...mapState("app", ["canCreate", "canEdit", "canDelete"]),
        ...mapState("budget", [
            "headers",
            "budget",
            "budgets",
            "deleteDialogBudget",
        ]),
        ...mapState("sale", ["budgetDialog"]),
    },
    methods: {
        ...mapMutations("budget", [
            "setDeleteDialogBudget",
            "setEditBudget",
            "setShowDialogBudget",
        ]),
        ...mapMutations("infosoft", ["impresionPresupuesto"]),
        ...mapMutations("sale", [
            "clearSaleProducts",
            "updateTotal",
            "setBudgetDialog",
        ]),
        ...mapActions("deposito", ["getAllStores"]),
        ...mapActions("budget", ["getBudgets", "deleteBudget", "getOneBudget"]),
        ...mapActions("app", ["getPermissions"]),
        ...mapActions("quote", ["getQuoteCurrency"]),

        returnDate(date) {
            return new Date(date).toLocaleDateString("es-ES");
        },
        isCredito(credito) {
            return !credito ? "Contado" : "Credito";
        },
        eliminarDeposito(item) {
            this.deleteBudget(item);
        },
        editarDeposito(item) {
            this.setEditBudget(true);
            this.getOneBudget(item);
        },

        returnEstadoColor(estado) {
            if (estado === "Vendido" || estado == "Pedido-vendido") {
                return "green";
            } else if (estado === "Faltantes") {
                return "red";
            } else {
                return "orange";
            }
        },
        async abrirOpcionPresupuesto(item) {
            await this.getOneBudget(item);
            this.setBudgetDialog(true);
        },
        async verPresupuesto(item) {
            await this.getOneBudget(item);
            this.setShowDialogBudget(true);
        },
        async imprimirPresupuesto(item) {
            await this.getOneBudget(item);
            this.impresionPresupuesto(this.budget);
        },
    },
};
</script>
