var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dialogImpresion)?_c('div',{staticClass:"dialog"},[_c('div',{attrs:{"id":"seleccion"}},_vm._l((_vm.getTenProductsArray),function(arrayProducts,index){return _c('div',{key:index},[_c('div',{staticClass:"parte"},[_c('div',{staticClass:"fecha"},[_c('p',{staticClass:"fecha-content"},[_vm._v("\n                        "+_vm._s(_vm.date[0])+"\n                        "+_vm._s(_vm.date[1])+",\n                        "+_vm._s(_vm.date[2])+"\n                    ")]),_vm._v(" "),_c('span',{staticClass:"ruc"},[_vm._v(_vm._s(_vm.sale?.client?.ruc))])]),_vm._v(" "),_c('div',{staticClass:"datos"},[_c('span',{staticClass:"nombre"},[_vm._v("\n                        "+_vm._s(_vm.sale.client?.nombre_y_apellido)+"\n                    ")]),_vm._v(" "),(_vm.sale?.credito)?_c('span',{staticClass:"credito"},[_vm._v("X")]):_c('span',{staticClass:"contado"},[_vm._v("X")])]),_vm._v(" "),_c('div',{staticClass:"direcciones"},[_c('span',{staticClass:"direc",domProps:{"innerHTML":_vm._s(_vm.returnDireccionValue)}})]),_vm._v(" "),_c('div',{staticClass:"tabla"},_vm._l((arrayProducts),function(product){return _c('div',{key:product.product_id,staticClass:"pro"},[_c('span',{staticClass:"cantidad"},[_vm._v(_vm._s(product.cantidad))]),_vm._v(" "),_c('span',{staticClass:"codigo"},[_vm._v(_vm._s(product.producto))]),_vm._v(" "),_c('span',{staticClass:"precio"},[_vm._v(_vm._s(product.precio_unitario))]),_vm._v(" "),_c('span',{staticClass:"subTotalExenta"},[_vm._v("\n                            "+_vm._s(product.iva === "exenta"
                                    ? product.subtotal
                                    : "")+"\n                        ")]),_vm._v(" "),_c('span',{staticClass:"subTotalIva5"},[_vm._v("\n                            "+_vm._s(product.iva === "5%" ? product.subtotal : "")+"\n                        ")]),_vm._v(" "),_c('span',{staticClass:"subTotalIva10"},[_vm._v("\n                            "+_vm._s(product.iva === "10%"
                                    ? product.subtotal
                                    : "")+"\n                        ")])])}),0),_vm._v(" "),_c('div',{staticClass:"detalle-precio"},[_c('div',{staticClass:"precio-text"},[_c('p',{staticClass:"precio-text-p"},[_vm._v("\n                            "+_vm._s(_vm.returnTextTotal(
                                    _vm.returnTotalVenta(arrayProducts)
                                ))+"\n                        ")])]),_vm._v(" "),_c('div',{staticClass:"total"},[_vm._v("\n                        "+_vm._s(_vm.returnTotalVenta(arrayProducts))+"\n                    ")])]),_vm._v(" "),_c('div',{staticClass:"iva"},[_c('span',{staticClass:"iva5"},[_vm._v(_vm._s(_vm.returnIva5(arrayProducts)))]),_vm._v(" "),_c('span',{staticClass:"iva10"},[_vm._v(_vm._s(_vm.returnIva10(arrayProducts)))]),_vm._v(" "),_c('span',{staticClass:"ivaTotal"},[_vm._v(_vm._s(_vm.returnIva10(arrayProducts) +
                        _vm.returnIva5(arrayProducts)))])]),_vm._v(" "),_c('div',{staticClass:"fecha2"},[_c('p',{staticClass:"fecha-content2"},[_vm._v("\n                        "+_vm._s(_vm.date[0])+"\n                        "+_vm._s(_vm.date[1])+",\n                        "+_vm._s(_vm.date[2])+"\n                    ")]),_vm._v(" "),_c('span',{staticClass:"ruc"},[_vm._v(_vm._s(_vm.sale?.client?.ruc))])]),_vm._v(" "),_c('div',{staticClass:"datos2"},[_c('span',{staticClass:"nombre"},[_vm._v("\n                        "+_vm._s(_vm.sale.client?.nombre_y_apellido)+"\n                    ")]),_vm._v(" "),(_vm.sale?.credito)?_c('span',{staticClass:"credito"},[_vm._v("x")]):_c('span',{staticClass:"contado"},[_vm._v("x")])]),_vm._v(" "),_c('div',{staticClass:"direcciones2"},[_c('span',{staticClass:"direc",domProps:{"innerHTML":_vm._s(_vm.returnDireccionValue)}})]),_vm._v(" "),_c('div',{staticClass:"tabla2"},_vm._l((arrayProducts),function(product){return _c('div',{key:product.product_id,staticClass:"pro2"},[_c('span',{staticClass:"cantidad"},[_vm._v(_vm._s(product.cantidad))]),_vm._v(" "),_c('span',{staticClass:"codigo"},[_vm._v(_vm._s(product.producto))]),_vm._v(" "),_c('span',{staticClass:"precio"},[_vm._v(_vm._s(product.precio_unitario))]),_vm._v(" "),_c('span',{staticClass:"subTotalExenta"},[_vm._v("\n                            "+_vm._s(product.iva === "exenta"
                                    ? product.subtotal
                                    : "")+"\n                        ")]),_vm._v(" "),_c('span',{staticClass:"subTotalIva5"},[_vm._v("\n                            "+_vm._s(product.iva === "5%" ? product.subtotal : "")+"\n                        ")]),_vm._v(" "),_c('span',{staticClass:"subTotalIva10"},[_vm._v("\n                            "+_vm._s(product.iva === "10%"
                                    ? product.subtotal
                                    : "")+"\n                        ")])])}),0),_vm._v(" "),_c('div',{staticClass:"detalle-precio2"},[_c('div',{staticClass:"precio-text2"},[_c('p',{staticClass:"precio-text-p2"},[_vm._v("\n                            "+_vm._s(_vm.returnTextTotal(
                                    _vm.returnTotalVenta(arrayProducts)
                                ))+"\n                        ")])]),_vm._v(" "),_c('div',{staticClass:"total2"},[_vm._v("\n                        "+_vm._s(_vm.returnTotalVenta(arrayProducts))+"\n                    ")])]),_vm._v(" "),_c('div',{staticClass:"iva2"},[_c('span',{staticClass:"iva5"},[_vm._v(_vm._s(_vm.returnIva5(arrayProducts)))]),_vm._v(" "),_c('span',{staticClass:"iva10"},[_vm._v(_vm._s(_vm.returnIva10(arrayProducts)))]),_vm._v(" "),_c('span',{staticClass:"ivaTotal"},[_vm._v(_vm._s(_vm.returnIva10(arrayProducts) +
                        _vm.returnIva5(arrayProducts)))])])])])}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }