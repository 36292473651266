<template>
    <v-app app class="pa-6" id="inspire">
        <Drawer />
        <AppBar />

        <Content />
        <Footer />
    </v-app>
</template>
<script>
import AppBar from "./AppBar.vue";
import Drawer from "./Drawer.vue";
import Content from "./Content.vue";
import Footer from "./Footer.vue";

export default {
    components: {
        AppBar,
        Drawer,
        Content,
        Footer,
    },

    computed: {},
    methods: {},
};
</script>
