<template>
  	<div>
      <h1>Roles</h1>

      <!--Formulario para crear o editar -->
      	<template>
    		<v-form v-on:submit.prevent="guardar">
				<v-container>
					<v-row>
						<v-col cols="12" md="3">
							<v-text-field
							label="Nombre"
							v-model="role.name"
							required
							></v-text-field>
						</v-col>


						<v-col cols="12" md="5">
						
							<v-autocomplete
								v-model="role.permissions"
								:items="permissions"
								chips
								label="Seleccionar permisos"
								item-text="name"
								item-value="id"
								multiple
							>
								<template v-slot:selection="data">
									<v-chip
										v-bind="data.attrs"
										:input-value="data.selected"
										close
										@click="data.select"
										@click:close="remove(data.item)"
									>
										{{ data.item.name }}
									</v-chip>
								</template>
								<template v-slot:item="data">
									<v-list-item-title v-html="data.item.name"></v-list-item-title>
								</template>
							</v-autocomplete>
						</v-col>
						<v-btn color="success" float-right type="submit">
							guardar
						</v-btn>
					</v-row>
				</v-container>
        	</v-form>
      	</template>

		<template>
            <ul v-for="(item, index) in roles">
                <li >
                    <span>{{item.name}} </span>
						<ul v-for="(per, i) in item.permissions">
							<li>
								{{per.name}}
							</li>
						</ul>
                    	<v-icon small class="mr-2" @click="selectItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon @click="eliminar(item)">
                        remove_circle_outline
                    </v-icon>
                </li>
            </ul>
        </template>

    </div>
</template>

<script>
export default {
	data() {
		return {
			permissions: [],
			roles:[],
			role:{
				id:'',
				name:'',
				permissions:[],
			},
		};
	},
	mounted() {
		axios.get("/permissions").then(response => {
			this.permissions = response.data;
		});
		axios.get("/roles").then(response => {
			this.roles = response.data;
		});
	},
	methods: {
		guardar() {
			if (this.role.id) {
				axios
				.put(`roles/${this.role.id}`, this.role)
				.then(response => {
					const index = this.roles.map(function(x) {return x.id; }).indexOf(this.role.id);
					this.roles[index].name=this.role.name;
					this.roles[index].permissions=response.data.permissions;
					this.role={
						id:'',
						name:'',
						permissions:[],
					}        
					
				});
			} else {
				axios.post("roles", this.role).then(response => {
				this.roles.push(response.data);
				this.role={
					id:'',
					name:'',
					permissions:[],
				}          
				});
			}
		},
		eliminar(item) {
			axios.delete(`/roles/${item.id}`).then(response => {
				const index = this.roles.indexOf(item);
			    if (index >= 0) this.roles.splice(index, 1)
			});
		},
		remove (item) {
			const index = this.role.permissions.indexOf(item.id);
			if (index >= 0) this.role.permissions.splice(index, 1)
		},
		selectItem(item){
			let permissions =[];
			item.permissions.forEach(e => {
				permissions.push(e.id);
			});

			this.role={
				id:item.id,
				name:item.name,
				permissions:permissions,
			};
		}
	}
};
</script>
