// Vue & vuex
import Vue from "vue";
import Vuex from "vuex";

// Modules

import app from "./modules/app";
import brand from "./modules/brand";
import client from "./modules/client";
import supplier from "./modules/supplier";
import product from "./modules/product";
import category from "./modules/category";
import image from "./modules/image";
import purchase from "./modules/purchase";
import sale from "./modules/sale";
import debtor from "./modules/debtor";
import payment from "./modules/payment";
import drop_product from "./modules/drop_product";
import box from "./modules/box";
import cash_count from "./modules/cash_count";
import stock from "./modules/stock";
import report from "./modules/report";
import budget from "./modules/budget";
import deposito from "./modules/deposito";
import income from "./modules/income";
import transfer from "./modules/transfer";
import vehicle from "./modules/vehicle";
import driver from "./modules/driver";
import recipient from "./modules/recipient";
import transfer_data from "./modules/transfer_data";
import remission from "./modules/remission";
import seller from "./modules/seller";
import order from "./modules/order";
import money from "./modules/money";
import quote from "./modules/quote";
import bill from "./modules/bill";
import config from "./modules/config";
import prod_box from "./modules/prod_box";
import invoice from "./modules/invoice";
import infosoft from "./modules/infosoft";
import devolution from "./modules/devolution";
import credit_note from "./modules/credit_note";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        app,
        brand,
        client,
        supplier,
        product,
        category,
        image,
        purchase,
        sale,
        debtor,
        drop_product,
        payment,
        box,
        cash_count,
        stock,
        report,
        budget,
        deposito,
        income,
        transfer,
        vehicle,
        driver,
        recipient,
        transfer_data,
        remission,
        seller,
        order,
        money,
        quote,
        bill,
        config,
        prod_box,
        invoice,
        infosoft,
        devolution,
        credit_note,
    },
});

export default store;
