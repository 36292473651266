<template>
    <v-form ref="form" lazy-validation>
        <v-card height="80%">
            <v-toolbar dark color="primary">
                <router-link to="/order">
                    <v-icon class="text-info" @click="resetFormOrder()"
                        >mdi-arrow-left</v-icon
                    >
                    <v-spacer></v-spacer>
                </router-link>
                <v-toolbar-title class="ml-4"
                    >{{ isEdit }} Pedido</v-toolbar-title
                >
            </v-toolbar>
            <v-row class="ma-2">
                <v-col md="8" xl="8">
                    <!-- row para cliente y btn crear -->
                    <v-row justify="center" align-content="center">
                        <v-col md="6">
                            <AutocompleteClient v-model="selectedClient" />
                        </v-col>
                        <v-col auto>
                            <v-select
                                v-model="order.currency_id"
                                :items="quotes"
                                item-value="currency_id"
                                item-text="nombre"
                                label="Moneda"
                                :rules="quoteRules"
                            ></v-select>
                        </v-col>
                        <v-col auto>
                            <v-select
                                v-model="order.seller_id"
                                :items="sellers"
                                item-value="user_id"
                                item-text="username"
                                label="Vendedor"
                            ></v-select>
                        </v-col>
                        <v-col auto>
                            <!-- Agregar presupuesto  -->

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        class="mt-3 btn-crear"
                                        outlined
                                        :color="
                                            isEditOrder ? 'green' : 'primary'
                                        "
                                        :disabled="orderProduct.length < 1"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="handleSave()"
                                    >
                                        {{ isEdit }}
                                    </v-btn>
                                </template>
                                <span>{{ isEdit }} presupuesto</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>

                    <!-- row para agregar los productos -->
                    <v-row>
                        <v-col cols="12" md="12" xl="5">
                            <AutocompleteProduct
                                v-model="selectedProduct"
                                :deposito="selectDeposito"
                            />
                        </v-col>
                        <v-col md="4" xl="2">
                            <v-text-field
                                type="number"
                                v-model="cantidad"
                                label="Cantidad"
                                append-outer-icon="mdi-send"
                                @click:append-outer="agregaProducto()"
                                @keydown.enter="agregaProducto()"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col md="4" xl="3">
                            <v-text-field
                                v-model="productCode"
                                label="Codigo"
                                required
                                @keydown.enter="obtenerPorCodigo"
                                ref="obtenerPorCodigo"
                            ></v-text-field>
                        </v-col>

                        <!-- seleccionar deposito -->
                        <v-col md="4" xl="2">
                            <v-select
                                v-model="selectDeposito"
                                :items="stores"
                                label="Deposito"
                                item-text="nombre"
                                item-value="id"
                            />
                        </v-col>
                    </v-row>
                    <!-- Table de productos -->
                    <v-row class="mb-4">
                        <v-col md="12">
                            <v-data-table
                                :headers="headerOrderProducts"
                                :items="orderProduct"
                                hide-default-footer
                                :items-per-page="-1"
                                no-data-text="Producto no seleccionado"
                            >
                                <template v-slot:[`item.store_id`]="props">
                                    <span>{{
                                        returnNameStore(props?.item?.store_id)
                                    }}</span>
                                </template>

                                <!-- columna precio unitario-->
                                <template
                                    v-slot:[`item.precio_unitario`]="props"
                                >
                                    {{
                                        convertMoney(
                                            props.item.precio_unitario,
                                            selectCurrency.codigo
                                        )
                                    }}
                                </template>

                                <template
                                    v-if="isDescuentoArbitrario"
                                    v-slot:[`item.precio_unitario`]="{ item }"
                                >
                                    <v-edit-dialog
                                        :return-value.sync="
                                            item.precio_unitario
                                        "
                                        @save="updateTotal()"
                                    >
                                        {{
                                            convertMoney(
                                                item.precio_unitario,
                                                selectCurrency.codigo
                                            )
                                        }}
                                        <template v-slot:input>
                                            <v-text-field
                                                label="Precio"
                                                v-model="item.precio_unitario"
                                            ></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                </template>

                                <!-- columna precio subtotal-->
                                <template v-slot:[`item.subtotal`]="props">
                                    {{
                                        convertMoney(
                                            props.item.subtotal,
                                            selectCurrency.codigo
                                        )
                                    }}
                                </template>

                                <!-- Icono Eliminar  -->
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon
                                        small
                                        color="red"
                                        @click="abrirDialogEliminar(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- Detalle de productos -->
                <v-col md="4" xl="4">
                    <v-row class="justify-center">
                        <v-col md="12">
                            <v-card class="">
                                <v-img
                                    class="ma-1 rounded"
                                    max-height="100"
                                    max-width="100"
                                    :src="selectedProduct?.img_url"
                                    :lazy-src="selectedProduct?.img_url"
                                >
                                </v-img>
                                <v-card-title>{{
                                    selectedProduct?.producto
                                }}</v-card-title>

                                <v-card-text>
                                    <div>
                                        {{ selectedProduct?.descripcion }}
                                    </div>
                                </v-card-text>

                                <v-card-text>
                                    <h3>
                                        Total:
                                        {{
                                            convertMoney(
                                                total,
                                                selectCurrency.codigo
                                            )
                                        }}
                                    </h3>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5"
                    >¿Retirar este producto de la venta?</v-card-title
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="setDialogDelete(false)"
                        >Cancelar</v-btn
                    >
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="eliminarProducto()"
                        >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <template>
            <v-dialog v-model="dialogDescuentos" max-width="1000">
                <v-card>
                    <v-card-text class="mt-3">
                        <v-row align="center" justify="center">
                            <v-col cols="3">
                                <v-btn @click="descuento(0)" class=""
                                    >Precio Descuento</v-btn
                                >
                            </v-col>
                            <v-col cols="3">
                                <v-btn @click="descuento(1)" class=""
                                    >Precio Mayorista</v-btn
                                >
                            </v-col>
                            <v-col cols="3">
                                <v-btn @click="descuento(2)" class=""
                                    >Precio Arbitrario</v-btn
                                >
                            </v-col>
                            <v-col cols="3">
                                <v-btn @click="descuento(3)" class=""
                                    >Precio Normal</v-btn
                                >
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </template>
    </v-form>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { convertMoney } from "../../formatos";
import AutocompleteProduct from "../AutocompleteProduct.vue";
import AutocompleteClient from "../AutocompleteClient.Component.vue";

export default {
    data: () => ({
        productCode: "",
        selectedProduct: {},
        selectedClient: null,
        selectDeposito: 0,
        dialogDelete: false,
        cantidad: 1,
        dialogDescuentos: false,
        isDescuentoArbitrario: false,
        deleteItem: {},
        quoteRules: [
            (v) => !!v || "Es necesario seleccionar moneda",
            (v) => v !== 0 || "Es necesario seleccionar moneda",
        ],
        isEditFirstTime: false,
    }),
    beforeDestroy() {
        this.isEditFirstTime = false;
        this.setIsEditOrder(false);
        this.clearOrder();
    },
    watch: {
        selectDeposito(val) {
            this.setLocaleStorageStore(val);
        },
        "order.currency_id"(newVal, oldVal) {
            if (oldVal !== 0) {
                this.restoreCurrencyConversion(oldVal); // se llama para revertir la conversion. y volver a la moneda principal
                this.currencyConversion(newVal); // se llama para hacer la conversion a la moneda seleccionada
                this.setVmSuffix(this.currencySymbol);
                this.setVmPrecision(this.currencyDecimal);
                this.updateTotal();
            }
        },
        "order.client"(val) {
            this.selectedClient = val;
        },
        selectedClient(val) {
            if (val != null) {
                this.order.client_id = val.id;
            }
        },
    },
    mounted() {
        // this.clearOrder();
        document.addEventListener("keydown", this.keyListener);

        if (this.order.currency_id === 0) {
            this.order.currency_id = 1;
        }
        this.getAllStores();
        this.getSellers();
        this.selectDeposito = parseInt(this.getLocaleStorageStore);
        if (this.editOrder) {
            //TODO: ESTÁ LLAMANDO A UNA FUNCION Y NO A UNA VARIABLE
            this.selectedClient = parseInt(this.order.client_id);
            this.isEditFirstTime = true;
        }
    },

    beforeDestroy() {
        document.removeEventListener("keydown", this.keyListener);
    },
    components: {
        AutocompleteProduct,
        AutocompleteClient,
    },
    computed: {
        ...mapState("order", [
            "order",
            "isEdit",
            "orderProduct",
            "headerOrderProducts",
            "isEditOrder",
            "total",
        ]),
        ...mapState("deposito", ["stores"]),
        ...mapState("quote", ["quotes"]),
        ...mapState("seller", ["sellers"]),
        ...mapState("app", ["user"]),

        ...mapGetters("sale", ["getSaleProduct", "getCantidad"]),
        ...mapGetters("deposito", ["getLocaleStorageStore", "returnNameStore"]),
        ...mapGetters("quote", ["getQuote", "getQuotes"]),

        isEdit() {
            return !this.isEditOrder ? "Crear" : "Editar";
        },
        isEditBtnFunc() {
            return !this.isEditOrder ? "Crear" : "Editar";
        },
        selectCurrency() {
            return (
                this.getQuotes.find(
                    (o) => o.currency_id === this.order.currency_id
                ) ?? this.getQuote
            );
        },
    },
    methods: {
        ...mapActions("sale", ["searchProductsByCode"]),
        ...mapActions("deposito", ["getAllStores"]),
        ...mapActions("order", ["createOrder", "editOrder"]),
        ...mapActions("seller", ["getSellers"]),

        ...mapMutations("app", [
            "setSnackbarAlert",
            "setSnackbarInfo",
            "setVmSuffix",
            "setVmPrecision",
        ]),
        ...mapMutations("deposito", ["setLocaleStorageStore"]),

        ...mapMutations("payment", ["setDialogPayment"]),
        ...mapMutations("order", [
            "addOrderProduct",
            "editOrderProduct",
            "deleteOrderProduct",
            "clearOrder",
            "restoreCurrencyConversion",
            "currencyConversion",
            "updateTotal",
            "setIsEditOrder",
            "descuentoMayorista",
            "descuentoNormal",
            "revertirDescuento",
            "resetFormOrder",
        ]),

        abrirDialogEliminar(item) {
            this.deleteItem = item;
            this.setDialogDelete(true);
        },
        setDialogDelete(payload) {
            this.dialogDelete = payload;
        },

        agregaProducto() {
            if (Object.keys(this.selectedProduct).length === 0) {
                this.setSnackbarInfo("Seleccione producto para agregar");
                return;
            }
            this.cantidad ??= 0;
            if (this.selectedProduct?.stock < this.cantidad) {
                this.setSnackbarInfo("Stock Insuficiente");
                return;
            }
            if (this.cantidad <= 0) {
                this.setSnackbarInfo("Ingrese numero positivo");
                return;
            }

            const precio_unitario = Number(
                this.selectedProduct?.precio_venta /
                    this.selectCurrency?.cotizacion
            ).toFixed(2);
            const precio_venta = Number(
                this.selectedProduct?.precio_venta /
                    this.selectCurrency?.cotizacion
            ).toFixed(2);
            const precio_descuento = Number(
                this.selectedProduct?.precio_descuento /
                    this.selectCurrency?.cotizacion
            ).toFixed(2);
            const precio_mayorista = Number(
                this.selectedProduct?.precio_mayorista /
                    this.selectCurrency?.cotizacion
            ).toFixed(2);

            const subtotal = precio_unitario * this.cantidad;

            this.cantidad = parseFloat(this.cantidad).toFixed(2);

            const newProduct = {
                cantidad: this.cantidad,
                product: {
                    nombre: this.selectedProduct?.producto,
                    codigo: this.selectedProduct?.codigo,
                },
                product_id: this.selectedProduct?.product_id,
                store_id: this.selectedProduct?.store_id,
                precio_unitario: precio_unitario,
                precio_venta: precio_venta,
                precio_descuento: precio_descuento,
                precio_mayorista: precio_mayorista,
                subtotal: subtotal,
            };
            this.addOrderProduct(newProduct);
            this.updateTotal();

            this.selectedProduct = null;
            this.cantidad = 1;
        },

        eliminarProducto() {
            this.deleteOrderProduct(this.deleteItem);
            this.deleteItem = {};
            this.setDialogDelete(false);
        },

        handleSave() {
            this.order.seller_id ??= this.user.id;
            this.order.order_detail = this.orderProduct;
            this.order.currency_id = this.selectCurrency.currency_id;
            this.isEditOrder
                ? this.editOrder(this.order)
                : this.createOrder(this.order);

            this.$router.push("/order");
        },
        keyListener(e) {
            if ((e.key === "d" || e.key === "D") && e.ctrlKey) {
                e.preventDefault();

                if (this.orderProduct.length > 0) {
                    this.dialogDescuentos = true;
                } else {
                    this.setSnackbarInfo("No hay productos en el carrito");
                }
            }
        },
        descuento(tipo) {
            switch (tipo) {
                case 0:
                    this.descuentoNormal();
                    this.dialogDescuentos = false;
                    break;
                case 1:
                    this.descuentoMayorista();
                    this.dialogDescuentos = false;
                    break;
                case 2:
                    this.isDescuentoArbitrario = true;
                    this.dialogDescuentos = false;
                    break;
                case 3:
                    this.revertirDescuento();
                    this.dialogDescuentos = false;
                    break;
            }
        },
        reset() {},
        convertMoney,
    },
};
</script>
<style scoped>
.btn-crear {
    min-width: 120px;
}
</style>
