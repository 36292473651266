<template>
    <v-row justify="center">
        <v-dialog v-model="dialogFormVehicle" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ returnTitleForm }} Vehiculo</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="vehicle.marca_vehiculo"
                                        label="Marca Vehiculo"
                                        :rules="marcaRules"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="vehicle.rua"
                                        label="Rua"
                                        placeholder="aaaa-111"
                                        :rules="ruaRules"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="handlerClose()">
                        Cerrar
                    </v-btn>
                    <v-btn
                        :color="returnColorBtn"
                        text
                        @click="crearVehiculo()"
                    >
                        {{ returnBtnForm }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
    data: () => ({
        valid: true,
        dialog: false,
        marcaRules: [(v) => !!v || "Marca es obligatorio"],
        ruaRules: [(v) => !!v || "Chapa es obligatorio"],
    }),
    computed: {
        ...mapState("vehicle", [
            "vehicle",
            "isEditVehicle",
            "dialogFormVehicle",
        ]),

        returnTitleForm() {
            return this.isEditVehicle ? "Editar" : "Agregar";
        },
        returnBtnForm() {
            return this.isEditVehicle ? "Editar" : "Guardar";
        },
        returnColorBtn() {
            return this.isEditVehicle ? "green darken-1" : "blue darken-1";
        },
    },
    methods: {
        ...mapMutations("vehicle", ["setDialogFormVehicle", "handlerClose"]),
        ...mapActions("vehicle", ["createVehicle", "editVehicle"]),

        crearVehiculo() {
            if (this.$refs.form.validate()) {
                if (this.isEditVehicle) {
                    this.editVehicle(this.vehicle);
                } else {
                    this.createVehicle(this.vehicle);
                }
            }
        },
    },
    mounted() {},
};
</script>

<style></style>
