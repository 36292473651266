<template>
    <div class="mx-0 my-5">
        <v-data-table
            :items="boxes"
            :headers="getHeaders"
            item-key="id"
            id="my-table"
            class="px-5"
        >
            <template v-slot:top>
                <v-row justify="end" class="mr-2">
                    <v-col cols="auto">
                        <v-btn
                            color="primary"
                            @click="dialogApertura = true"
                            v-if="!isApertura && canCreate"
                            class="mb-2"
                        >
                            <v-icon> mdi-plus </v-icon>
                            Apertura
                        </v-btn>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    color="green"
                    @click="setDialogMoneyIn(true)"
                    v-if="!item.fecha_cierre && checkPermiso('money_in_create')"
                >
                    mdi-cash-plus
                </v-icon>
                <v-icon
                    color="red"
                    @click="setDialogMoneyOut(true)"
                    v-if="
                        !item.fecha_cierre && checkPermiso('money_out_create')
                    "
                >
                    mdi-cash-minus
                </v-icon>
                <v-icon color="blue" @click="detalles(item.box_id)">
                    mdi-eye
                </v-icon>
            </template>

            <template v-slot:[`item.fecha_cierre`]="{ item }">
                <v-icon
                    @click="cerrarCaja(item.box_id)"
                    color="red"
                    v-if="!item.fecha_cierre"
                >
                    mdi-exit-to-app
                </v-icon>
                <span v-else>
                    {{ item.fecha_cierre }}
                </span>
            </template>
            <template v-slot:[`item.saldo_inicial`]="{ item }">
                    <span
                        v-for="(element, i) in item.saldo_inicial"
                        :key="element.created_at"
                        >
                            {{ element.quotation.currency.codigo }}: {{ element.monto.toLocaleString("Es") }}
                            {{ i !== item.saldo_inicial.length - 1 && item.saldo_inicial.length > 0 ? ', ' : '' }}
                    </span>
            </template>
        </v-data-table>

        <template>
            <v-dialog v-model="dialogArqueo" persistent max-width="600px">
                <!-- Form de arqueo -->
                <!-- boxid envia el id de caja a cerrar -->
                <FormArqueoComponent
                    :setDialogArqueo="setDialogArqueo"
                    :dialogArqueo="dialogArqueo"
                    :boxId="box_id"
                />
                <!-- <v-card>
                    <v-card-title>
                        <span class="text-h5">Arqueo de caja</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container> </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="cerrarDialogArqueo"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="crearArqueo()"
                        >
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card> -->
            </v-dialog>
        </template>
        <template>
            <v-dialog v-model="dialogApertura" persistent max-width="500px">
                <FormAperturaComponent :setDialogApertura="setDialogApertura" />
                <!-- <v-card>
                    <v-card-title>
                        <span class="text-h5">Apertura de caja</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        label="Saldo Inicial"
                                        v-model="box.saldo_inicial"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialogApertura = false"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn
                            :disabled="isLoading"
                            :loading="isLoading"
                            color="blue darken-1"
                            text
                            @click="cerrarDialogCaja()"
                        >
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card> -->
            </v-dialog>
            <AddMoneyComponent />
            <SubMoneyComponent />
        </template>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import AddMoneyComponent from "./AddMoney.Component.vue";
import SubMoneyComponent from "./SubMoneyComponent.vue";

import FormAperturaComponent from "./FormApertura.Component.vue";
import FormArqueoComponent from "./FormArqueo.Component.vue";

export default {
    data: () => ({
        box_id: 0,
        dialogArqueo: false,
        dialogApertura: false,
        headers: [
            {
                text: "Usuario",
                value: "usuario",
                align: "center",
            },
            {
                text: "Apertura",
                value: "fecha_apertura",
            },
            {
                text: "Saldo inicial",
                value: "saldo_inicial",
            },
            {
                text: "Cierre",
                value: "fecha_cierre",
                align: "center",
            },
            {
                text: "Opciones",
                value: "actions",
                align: "center",
            },
        ],
    }),
    components: {
        AddMoneyComponent,
        SubMoneyComponent,
        FormAperturaComponent,
        FormArqueoComponent,
    },
    mounted() {
        this.getPermissions(this.$route.path);
        this.getBoxes();
        this.comprobarApertura();
        this.getAllBills();

        this.getBillsCashCount();
    },
    computed: {
        ...mapState("box", ["boxes", "box", "isApertura"]),
        ...mapState("app", [
            "boxCanDetail",
            "canCreate",
            "isLoading",
            "permisos",
        ]),
        ...mapState("cash_count", ["arqueo"]),
        getHeaders() {
            return this.boxCanDetail
                ? this.headers
                : this.headers.slice(0, this.headers.length - 1);
        },
    },
    methods: {
        ...mapActions("box", ["getBoxes", "createBox", "comprobarApertura"]),
        ...mapActions("cash_count", ["createCashCount", "getBillsCashCount"]),
        ...mapActions("app", ["getPermissions"]),
        ...mapActions("bill", ["getAllBills"]),
        ...mapMutations("box", [
            "CLEAR_ARQUEO",
            "setDialogMoneyIn",
            "setDialogMoneyOut",
        ]),

        cerrarCaja(box_id) {
            this.dialogArqueo = true;
            this.box_id = box_id;
        },
        crearArqueo() {
            this.createCashCount(this.arqueo);
            this.cerrarDialogArqueo();
            this.comprobarApertura();
        },
        cerrarDialogArqueo() {
            this.dialogArqueo = false;
            this.CLEAR_ARQUEO();
        },
        detalles(id) {
            this.$router.push(`/box/${id}`);
        },

        setDialogApertura(payload) {
            this.dialogApertura = payload;
        },
        setDialogArqueo(payload) {
            this.dialogArqueo = payload;
        },
        checkPermiso(permiso) {
            const isPermiso = this.permisos.find((e) => {
                return e === permiso;
            });
            let hasAccess = Boolean(isPermiso);
            return hasAccess;
        },
    },
};
</script>
