<template>
    <div class="mx-10">
        <v-data-table
            :headers="headerDevolution"
            :items="devolutions"
            :search.sync="searchDevolution"
        >
            <template v-slot:top>
                <!-- Encabezado Lista Tabla -->
                <!-- Buscar y Button Agregar -->

                <v-row align="center" justify="space-between">
                    <v-col cols="12" lg="6" md="6" sm="6">
                        <v-text-field
                            v-model="searchDevolution"
                            label="Buscar"
                            append-icon="mdi-magnify"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="auto"
                        md="auto"
                        sm="auto"
                        v-if="canCreate"
                    >
                        <v-btn
                            style="text-decoration: none"
                            color="primary"
                            to="/devolution/sale_list_filter"
                        >
                            Agregar
                            <v-icon> mdi-plus </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:[`item.numero`]="{ item }">
                {{ item.numero }}
                <v-chip
                    v-if="item.anulado"
                    color="red"
                    class="ml-2"
                    outlined
                    label
                    small
                >
                    Anulado
                </v-chip>
            </template>
            <template v-slot:[`item.client`]="{ item }">
                <v-list-item two-line class="ma-0 pa-0">
                    <v-list-item-content>
                        <v-list-item-title>{{
                            item.nombre_y_apellido
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.ruc
                        }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-slot:[`item.total`]="{ item }">
                {{ convertMoney(item.total, item.codigo) }}
            </template>
            <template v-slot:[`item.fecha`]="{ item }">
                {{ convertDate(item.fecha) }}
            </template>
            <!-- Iconos Editar Y Eliminar  -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    color="green"
                    @click="verDevolucion(item)"
                >
                    mdi-eye
                </v-icon>
                <!-- <v-icon v-if="canEdit" small class="mr-2" color="blue">
                    mdi-pencil
                </v-icon> -->
                <v-icon
                    v-if="canDelete"
                    small
                    color="red"
                    class="mr-2"
                    @click="eliminarDevolucion(item)"
                >
                    mdi-delete
                </v-icon>

                <v-icon
                    v-if="!item.anulado"
                    small
                    color="red"
                    @click="anularDevolucion(item)"
                >
                    mdi-cancel
                </v-icon>
            </template>
        </v-data-table>

        <v-dialog
            v-model="dialogAnular"
            max-width="500"
            class="ma-0 pa-0"
            content-class="elevation-0"
        >
            <v-alert
                class="bg-white"
                outlined
                color="deep-orange"
                type="warning"
                elevation="2"
            >
                ¿Desea anular esta venta?

                <v-card-actions class="mt-4">
                    <v-spacer></v-spacer>

                    <v-btn
                        class="mr-3"
                        outlined
                        text
                        @click="dialogAnular = false"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        text
                        outlined
                        color="deep-orange"
                        @click="
                            cancelDevolution(anularDevolucionId);
                            onAnularDevolucion();
                        "
                        >Anular</v-btn
                    >
                </v-card-actions>

                <v-spacer />
            </v-alert>
        </v-dialog>

        <DeleteDialog
            :dialog="dialogDeleteDevolution"
            :setDialog="setDialogDeleteDevolution"
            v-on:deleted="deleteDevolution(deleteItemDevolution)"
        />
        <ShowDevolutionComponent />
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { convertMoney, convertDate } from "../../formatos";
import DeleteDialog from "../DeleteDialog.Component.vue";
import ShowDevolutionComponent from "./ShowDevolution.Component.vue";
export default {
    data: () => ({
        searchDevolution: "",
        deleteItemDevolution: null,
        dialogAnular: false,
        anularDevolucionId: null,
    }),
    watch: {},
    components: {
        DeleteDialog,
        ShowDevolutionComponent,
    },
    mounted() {
        this.getAllDevolutions();
        this.getPermissions(this.$route.path);
    },
    computed: {
        ...mapState("app", ["canEdit", "canDelete", "canCreate"]),
        ...mapState("devolution", [
            "devolutions",
            "headerDevolution",
            "dialogDeleteDevolution",
        ]),
    },
    methods: {
        ...mapMutations("devolution", [
            "setDialogShowDevolution",
            "setDialogDeleteDevolution",
        ]),
        ...mapActions("devolution", [
            "getAllDevolutions",
            "getOneDevolution",
            "deleteDevolution",
            "cancelDevolution",
        ]),
        ...mapActions("app", ["getPermissions"]),

        verDevolucion(item) {
            this.setDialogShowDevolution(true);
            this.getOneDevolution(item.id);
        },
        eliminarDevolucion(item) {
            this.setDialogDeleteDevolution(true);
            this.deleteItemDevolution = item;
        },
        anularDevolucion(item) {
            this.dialogAnular = true;
            this.anularDevolucionId = item.id;
        },
        onAnularDevolucion() {
            this.dialogAnular = false;
            this.anularDevolucionId = null;
        },
        convertMoney,
        convertDate,
    },
};
</script>
