export function convertMoney(numero, codigo) {
    return parseFloat(numero).toLocaleString("es-PY", {
        style: "currency",
        currency: codigo,
        maximumFractionDigits: 2,
    });
}

export function random(min, max){
    return Math.floor((Math.random() * (max - min + 1)) + min);
}

export function convertDate(fecha) {
    const date = new Date(fecha);

    const dia = date.getDate().toString().padStart(2, "0");
    const mes = (date.getMonth() + 1).toString().padStart(2, "0"); // Los meses son base 0
    const año = date.getFullYear();
    const hora = date.getHours().toString().padStart(2, "0");
    const minutos = date.getMinutes().toString().padStart(2, "0");

    return `${dia}-${mes}-${año} ${hora}:${minutos}`;
}
