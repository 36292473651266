<template>
    <v-sheet>
        <v-form v-model="valid" ref="form" lazy-validation>
            <v-container>
                <v-card-title>Apertura Caja</v-card-title>

                <v-card-text>
                    <v-row
                        v-for="(valueQuote, i) in getListQuotes"
                        :key="i"
                        class="text-fields-row mt-4"
                        align="center"
                    >
                        <v-col cols="6">
                            <v-text-field
                                v-model="valueQuote.monto"
                                :label="textField.labelSaldo"
                                type="number"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="valueQuote.quotation_id"
                                :label="textField.labelMoneda"
                                :items="quotes"
                                item-value="quotation_id"
                                item-text="nombre"
                                readonly
                                outlined
                                dense
                            ></v-select>
                        </v-col>
                        <v-divider />
                    </v-row>
                </v-card-text>

                <v-card-actions class="justify-end my-2">
                    <v-btn
                        outlined
                        color="error"
                        @click="setDialogApertura(false)"
                    >
                        cancelar
                    </v-btn>
                    <v-btn
                        outlined
                        color="primary"
                        @click="guardarCaja()"
                    >
                        crear
                    </v-btn>
                </v-card-actions>
            </v-container>
        </v-form>
    </v-sheet>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { required } from "../../utils/validate";

export default {
    props: ["setDialogApertura"],
    data: () => ({
        required,
        textField: {
            labelSaldo: "Monto Inicial",
            textField: "Moneda",
        },
        valid: false,
    }),
    watch: {},
    mounted() {
        this.getQuoteCurrency();
    },
    computed: {
        ...mapState("quote", ["quotes"]),
        ...mapGetters("quote", ["getListQuotes"]),
    },
    methods: {
        ...mapMutations("app", ["setSnackbarInfo"]),
        ...mapActions("quote", ["getQuoteCurrency"]),
        ...mapActions("box", ["createBox"]),

        guardarCaja() {
            if (!this.$refs.form.validate()) {
                this.setSnackbarInfo("Debes ingresar moneda");
                return;
            }

            this.createBox(this.getListQuotes);
            this.setDialogApertura(false);
        },
    },
};
</script>

<style scoped>
.text-fields-row {
    display: flex;
}
</style>
