var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-10"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products,"hide-default-footer":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"align-self-center"},[_c('v-col',{attrs:{"lg":"5","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Buscar","append-icon":"mdi-magnify"},model:{value:(_vm.searchProduct.search),callback:function ($$v) {_vm.$set(_vm.searchProduct, "search", $$v)},expression:"searchProduct.search"}})],1),_vm._v(" "),_c('v-col',{attrs:{"lg":"2","md":"4","sm":"12"}},[_c('AutocompleteBrandComponent',{attrs:{"disabled":_vm.isFromService},model:{value:(_vm.localBrand),callback:function ($$v) {_vm.localBrand=$$v},expression:"localBrand"}})],1),_vm._v(" "),_c('v-col',{attrs:{"lg":"3","md":"4","sm":"12"}},[_c('autocomplete-category-component',{attrs:{"disabled":_vm.isFromService},model:{value:(_vm.localCategory),callback:function ($$v) {_vm.localCategory=$$v},expression:"localCategory"}})],1),_vm._v(" "),(_vm.canCreate)?_c('v-col',{staticClass:"mb-4",attrs:{"lg":"2","md":"4","sm":"12"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.setDialog}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" Agregar\n                    ")],1)],1):_vm._e()],1)]},proxy:true},{key:`item.category`,fn:function({ item }){return _vm._l((item.category),function(categoria,index){return _c('span',{key:categoria.id},[_vm._v("\n                "+_vm._s(categoria.nombre +
                    (item.category.length > 1 &&
                    index + 1 != item.category.length
                        ? ", "
                        : ""))+"\n            ")])})}},{key:`item.img_url`,fn:function({ item }){return [_c('span',[_c('v-img',{staticClass:"ma-1 rounded",attrs:{"max-height":"80","max-width":"80","src":item.img_url}})],1)]}},{key:`item.brand.nombre`,fn:function({ item }){return [(item.brand)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"indigo","text-color":"white"}},[_vm._v(_vm._s(item.brand.nombre)+"\n            ")]):_vm._e()]}},{key:`item.actions`,fn:function({ item }){return [(_vm.canEdit)?_c('v-icon',{staticClass:"mr-2",attrs:{"dense":"","color":"blue"},on:{"click":function($event){return _vm.editar(item)}}},[_vm._v("\n                mdi-pencil\n            ")]):_vm._e(),_vm._v(" "),(_vm.canDelete)?_c('v-icon',{staticClass:"mr-2",attrs:{"dense":"","color":"red"},on:{"click":function($event){_vm.localProduct = item;
                    _vm.setDeleteDialog();}}},[_vm._v("\n                mdi-delete\n            ")]):_vm._e(),_vm._v(" "),(_vm.canDelete)?_c('v-icon',{attrs:{"dense":"","color":"red accent-3"},on:{"click":function($event){_vm.localProduct = item;
                    _vm.dialogDiscontinuar = true;}}},[_vm._v("\n                mdi-file-cancel-outline\n            ")]):_vm._e()]}}],null,true)}),_vm._v(" "),_c('v-pagination',{staticClass:"py-4",attrs:{"length":_vm.productsPage.lastPage},model:{value:(_vm.productsPage.current),callback:function ($$v) {_vm.$set(_vm.productsPage, "current", $$v)},expression:"productsPage.current"}}),_vm._v(" "),_c('DeleteDialog',{attrs:{"dialog":_vm.deleteDialog,"setDialog":_vm.setDeleteDialog},on:{"deleted":function($event){return _vm.deleteProduct(_vm.localProduct)}}}),_vm._v(" "),_c('DeleteDialog',{attrs:{"dialog":_vm.dialogDiscontinuar,"setDialog":_vm.setDialogDiscontinuar},on:{"deleted":function($event){return _vm.discontinuarProducto(_vm.localProduct)}}},[_c('section',[_c('p',{staticClass:"font-weight-medium"},[_vm._v("\n                El producto seguirá apareciendo en los registros pero ya no\n                estará disponible en movimiento!!\n            ")])]),_vm._v(" "),_c('v-divider')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }