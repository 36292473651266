import axios from "axios";

const state = {
    dialogRemission: false,
    dialogDeleteRemission: false,
    loadingSearchProductRemission: false,
    isEdit: false,
    isIngreso: false,
    remissions: [],
    remission: {
        vehicle_id: null,
        driver_id: null,
        recipient_id: null,
        transfer_data_id: null,
        tipo: "entrante",
        numero_remision: "",
    },
    remissionProducts: [],
    productsRemissionStore: [],
    headersRemission: [
        { text: "Producto", value: "product.nombre" },
        { text: "Cantidad", value: "cantidad" },
        { text: "Deposito", value: "store_id" },
        { text: "Opciones", value: "actions", align: "center" },
    ],
    headersRemissionList: [
        { text: "Remision", value: "numero_remision" },
        { text: "Estado", value: "tipo" },
        { text: "Vehiculo", value: "vehicle.marca_vehiculo" },
        { text: "Conductor", value: "driver.nombre" },
        { text: "Destinatario", value: "recipient.nombre" },
        { text: "Responsable", value: "transfer_data.responsable" },
        { text: "Motivo", value: "transfer_data.motivo" },
        { text: "Opciones", value: "actions", align: "center" },
    ],
};
const actions = {
    getRemissions({ commit, dispatch }) {
        commit("clearRemission");
        axios
            .get("/remissions")
            .then((res) => {
                commit("GET_REMISSIONS", res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    },
    getOneRemission({ commit, dispatch }, payload) {
        commit("clearRemission");
        axios
            .get(`/remissions/${payload.id}`)
            .then((res) => {
                commit("GET_ONE_REMISSION", res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    },

    createRemission({ commit, dispatch }, payload) {
        commit("clearRemission");
        axios
            .post("/remissions", payload)
            .then(() => {
                commit("CREATE_REMISSION");
                dispatch(
                    "deposito/getProductsStores",
                    { dep: 1 },
                    { root: true }
                );
            })
            .catch((err) => {
                console.log(err);
            });
    },
    editRemission({ commit, dispatch }, payload) {
        commit("clearRemission");
        axios
            .put(`/remissions/${payload.id}`, payload)
            .then(() => {
                commit("EDIT_REMISSION");
                dispatch("getRemissions");
            })
            .catch((err) => {
                console.log(err);
            });
    },
    deleteRemission({ commit, dispatch }, payload) {
        axios
            .delete(`/remissions/${payload.id}`)
            .then(() => {
                commit("DELETE_REMISSION");
                dispatch("getRemissions");
            })
            .catch((err) => {
                console.log(err);
            });
    },
    async getRemissionProducts({ commit }, payload) {
        state.loadingSearchProductRemission = true;
        await axios
            .post("/get_stock", { dep: payload })
            .then((response) => {
                commit("GET_PRODUCTS_REMISSION", response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    },
};
const mutations = {
    setDialogRemission(state, payload) {
        state.dialogRemission = payload;
    },
    setRemissionVehicle(state, payload) {
        state.remission.idVehicle = payload;
    },
    setRemissionDriver(state, payload) {
        state.remission.idDriver = payload;
    },
    setRemissionRecipient(state, payload) {
        state.remission.idRecipient = payload;
    },
    setRemissionDataTransfer(state, payload) {
        state.remission.idDataTransfer = payload;
    },
    setIsEdit(state, paylaod) {
        state.isEdit = paylaod;
    },
    setDialogDeleteRemission(state, paylaod) {
        state.dialogDeleteRemission = paylaod;
    },
    addRemissionProducts(state, product) {
        const index = state.remissionProducts.find(
            (p) => p.product_id === product.product_id
        );
        if (!index) {
            state.remissionProducts.push(product);
        } else {
            this.commit(
                "app/setSnackbarInfo",
                "Se ha repetido el producto, verifique la tabla"
            );
        }
    },

    editRemissionProducts(state, product) {
        state.remissionProducts.splice(
            state.remissionProducts.indexOf(
                (p) => p.product_id === product.product_id
            ),
            1,
            product
        );
    },

    deleteRemissionProducts(state, product) {
        const { product_id } = product;
        const index = state.remissionProducts.findIndex(
            (p) => product_id === product_id
        );
        state.remissionProducts.splice(index, index >= 0 ? 1 : 0);
    },
    clearRemission(state) {
        state.remission = {
            vehicle_id: null,
            driver_id: null,
            recipient_id: null,
            transfer_data_id: null,
        };
        state.remissionProducts = [];
        state.productsRemissionStore = [];
    },

    CREATE_REMISSION(state) {
        state.remission = {
            vehicle_id: null,
            driver_id: null,
            recipient_id: null,
            transfer_data_id: null,
        };
        state.dialogRemission = false;
        state.remissionProducts = [];
        state.productsRemissionStore = [];
    },

    EDIT_REMISSION(state) {
        state.remission = {
            vehicle_id: null,
            driver_id: null,
            recipient_id: null,
            transfer_data_id: null,
        };
        state.dialogRemission = false;
        state.isEdit = false;
        state.remissionProducts = [];
        state.productsRemissionStore = [];
    },
    GET_PRODUCTS_REMISSION(state, paylaod) {
        state.loadingSearchProductRemission = false;
        state.productsRemissionStore = paylaod;
    },
    GET_REMISSIONS(state, payload) {
        state.remissions = payload;
    },
    GET_ONE_REMISSION(state, payload) {
        state.remission = Object.assign({}, payload);
        //mandar los datos necesarios para tabla de productos (nombre, product_id, cantidad, store_id)

        payload.remission_detail.forEach((product) => {
            const newProduct = {
                product_id: product.product.id,
                cantidad: product.cantidad,
                nombre: product.product.nombre,
                store_id: product.store_id,
            };
            state.remissionProducts.push(newProduct);
        });
    },
    DELETE_REMISSION(state) {
        state.remission = {
            vehicle_id: null,
            driver_id: null,
            recipient_id: null,
            transfer_data_id: null,
        };
        state.dialogRemission = false;
        state.isEdit = false;
        state.remissionProducts = [];
        state.productsRemissionStore = [];
    },
};
const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
