<template>
    <div>
        <h1>User</h1>
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogUser"
                persistent
                max-width="600px"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    >
                    Nuevo Usuario
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title>
                    <span class="text-h5">Datos de Usuario</span>
                    </v-card-title>
                    <v-card-text>
                    <v-container>
                        <v-row>
                        <v-col cols="12">
                            <v-text-field
                            label="Nombre y Apellido"
                            required
                            v-model="user.name"
                            ></v-text-field>
                        </v-col>


                        <v-col cols="12">
                            <v-text-field
                            label="Nombre de Usuario"
                            required
                            v-model="user.username"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                        >
                           <!-- <v-select
                            :items="roles"
                            label="Rol"
                            item-text="name"
							item-value="id"
                            v-model="user.roles"
                            required
                            ></v-select> -->

                            <v-select
                            v-model="user.roles"
                            :items="roles"
                            item-text="name"
							item-value="id"
                            label="Seleccione el rol"
                            multiple
                            chips
                            persistent-hint
                            ></v-select>
                        </v-col>
                        
                        <v-col cols="12">
                            <v-text-field
                            label="Email*"
                            v-model="user.email"
                            required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                            label="Password*"
                            type="password"
                            v-model="user.password"
                            required
                            ></v-text-field>
                        </v-col>
    
                        </v-row>
                    </v-container>
                    <small>*indicates required field</small>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="cerrarDialog"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="guardar"
                    >
                        Guardar
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>
        <v-row>
        <div v-for="(item, index) in users"
            v-bind:data="index"
            v-bind:key="item.id">
            <template >
            <v-card 
                class="mx-3 my-2"
                width="344"
                outlined
            >
                <v-list-item three-line>
                <v-list-item-content>
                    <div class="text-overline mb-4">
                    {{item.roles.name}}
                    </div>
                    <v-list-item-title class="text-h5 mb-2">
                    {{item.name}}
                    
                    </v-list-item-title>
                    
                    <div v-for="(role, indice) in item.roles"
                     v-bind:data="role"
                     v-bind:key="indice"
                    > 
                        {{role.name}}

                        <v-list-item-subtitle v-for="(permiso,indic) in role.permissions"
                        v-bind:data="permiso"
                        v-bind:key="indic">{{permiso.name}}</v-list-item-subtitle>
                    </div>
                </v-list-item-content>
                </v-list-item>

                <v-card-actions>
                <v-btn
                    outlined
                    rounded
                    text
                    @click="selectItem(item)"
                >
                    Editar
                </v-btn>

                <v-btn
                    outlined
                    rounded
                    text
                    color="error"
                    @click="eliminar(item)"
                >
                    Eliminar
                </v-btn>
                </v-card-actions>
            </v-card>  
            </template>
          
          <!--  <ul v-for="(item, index) in users">
                <li >
                    <span>{{item.name}} </span> 
                    <v-icon small class="mr-2" @click="selectItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon @click="eliminar(item)">
                        remove_circle_outline
                    </v-icon>
                </li>
            </ul> -->
        </div>
        </v-row>
        
    </div>
</template>

<script>
export default {
    data() {
        return {
            users: [],
            roles:[],
            user:{
                id:'',
                name:'',
                email:'',
                password:'',
                roles:'',
            },
            dialogUser:false,
        };
    },
    mounted() {
        axios.get("/users").then(response => {
            this.users = response.data;
        });
        axios.get("/roles").then(response => {
            this.roles = response.data;
        });
    },
    methods: {
        guardar() {
            if (this.user.id) {
                axios
                    .put(`users/${this.user.id}`, this.user)
                    .then(response => {
                        const index = this.users.map(function(x) {return x.id; }).indexOf(this.user.id);
                        this.users[index]=response.data;
                        this.user = {
                            id:'',
                            name:'',
                            email:'',
                            password:'',
                            roles:'',
                        };
                        this.dialogUser=false;
                    });
            } else {
                axios.post("users", this.user).then(response => {
                    this.users.push(response.data);
                    this.user.name = {
                        id:'',
                        name:'',
                        email:'',
                        password:'',
                        roles:'',
                    };        
                    this.dialogUser=false;        
                });
            }
        },
        eliminar(item) {
            axios.delete(`/users/${item.id}`).then(response => {
                const index = this.users.map(function(x) {return x.id; }).indexOf(item.id);
                if (index >= 0) this.users.splice(index, 1)
            });
        },
        selectItem(item){
            this.user.id=item.id;
            this.user.name=item.name;
            this.user.email=item.email;
            if (item.roles){
                let roles=[];
                item.roles.forEach(e => {
                    roles.push(e.id);
                });
                this.user.roles=roles;
            } 
            this.dialogUser=true;
        },
        cerrarDialog(){
            this.user = {
                id:'',
                name:'',
                email:'',
                password:'',
                roles:'',
            };
            this.dialogUser=false;
        }
    }
};
</script>
