<template>
    <div>
        <v-card d-flex flex-row mb-6>
            <v-list-item class="my-10">
                <v-card
                    class="mt-n7"
                    color="primary"
                    elevation="10"
                    min-height="80"
                    min-width="80"
                >
                    <v-icon class="ma-5" large dark>store</v-icon>
                </v-card>
                <v-list-item-title>
                    <div class="overline mx-10 mt-5">
                        <h4>Transferencia Datos</h4>
                    </div>
                </v-list-item-title>
            </v-list-item>
            <FormTransferDataComponent />
            <router-view />
        </v-card>
    </div>
</template>

<script>
import FormTransferDataComponent from "./FormTransferData.Component.vue";

export default {
    data: () => ({}),
    components: { FormTransferDataComponent },
};
</script>

<style></style>
