const state = {
    dialog: false,
    edit: false,
    deleteDialog: false,
    isDeleted: false,
    paymentReceipt: {
        //ESTA VARIABLE SE ENVIA PARA REGISTRAR UN PAGO
        recibo_numero: "",
        details: [
            //array de los pagos, puede ser en partes efetivo y otra parte deposito por ejemplo.
            /*{
                metodo_pago: '', // opciones disponibles: 'efectivo','tarjeta_credito','tarjeta_debito','cheque','transferencia','billetera_electronica'
                codigo:'',
                entidad_metodo_pago: '',  //puede ser null
                monto: '',
            }*/
        ],
        saleHeaders: [], //array de id de la cabecera de facturas que se va a pagar.
        partialPayment: {
            sale_header_id: "",
            pagado: "",
        },
        currency_id: 0,
    },
    details: [],
    metodoPago: [
        { method: "efectivo", name: "Efectivo" },
        { method: "tarjeta_credito", name: "Tarjeta de crédito" },
        { method: "tarjeta_debito", name: "Tarjeta débito" },
        { method: "cheque", name: "Cheque" },
        { method: "transferencia", name: "Transferencia" },
        { method: "billetera_electronica", name: "Billetera electrónica" },
        { method: "gift_card", name: "Gift Card" },
    ],
    headersPaymentDetail: [
        { text: "Metodo", value: "metodo_pago" },
        { text: "Código", value: "codigo" },
        { text: "Entidad", value: "entidad_metodo_pago" },
        { text: "Monto", value: "monto" },
        { text: "Opciones", value: "actions", align: "center" },
    ],
    selectedSaleDetail: [], // ESTA VARIABLE SE USA EN V-MODEL DE LAS FACTURAS SELECCIONADAS
    dialogSelectTypePayment: false,
    dialogListSale: false,
    paymentDialog: false,
    dialogAddMount: false,
    nuevoDetalle: {
        metodo_pago: "efectivo",
        codigo: "0",
        entidad_metodo_pago: "0",
        monto: 0,
    },
    totalSelect: 0,
    sumPaymentDetails: 0,
    isPayAmount: false,
    maxSelect: 1, // VARIABLE DE LA CANTIDAD DE FACTURAS QUE SE PUEDE SELECCIONAR
};

const actions = {
    getNumberReceipt({ commit }) {
        //ESTE METODO OBTIENE EL NUMERO DE RECIBO +1
        axios
            .get("/receiptnumber")
            .then((response) => {
                commit("GET_NUMBER_RECEIPT", response.data);
            })
            .catch((error) => {
                this.commit("app/setSnackbarAlert", "Error en Servidor");
            });
    },
    createPayment({ commit, dispatch }) {
        axios
            .post("/payments", state.paymentReceipt)
            .then((response) => {
                commit("CREATE_PAYMENT", response.data);
                dispatch("debtor/getDebtors", {}, { root: true });
                dispatch("getNumberReceipt");
            })
            .catch(function (error) {
                if (error.response) {
                    this.commit("app/setSnackbarAlert", "Error en Servidor");
                    this.commit(
                        "app/setSnackbarAlert",
                        error.response.data.message
                    );
                }
            });
    },
    eliminarItemDetail({ state }, index) {
        state.paymentReceipt.details.splice(index, 1);
        state.sumPaymentDetails = 0;
        state.paymentReceipt.details.forEach((e) => {
            //SUMA LA CANTIDAD DE DINERO DE LOS DETALLES
            state.sumPaymentDetails += e.monto;
        });
    },
};

const mutations = {
    setDialog(state) {
        state.dialog = !state.dialog;
    },
    setEdit(state) {
        state.edit = !state.edit;
    },
    setDeleteDialog(state) {
        state.deleteDialog = !state.deleteDialog;
    },
    setDialogListSale(state) {
        state.dialogListSale = !state.dialogListSale;
        if (state.isPayAmount) state.totalSelect = state.sumPaymentDetails;
    },
    setDialogPayment(state) {
        state.paymentDialog = !state.paymentDialog;
    },
    setDialogAddMount(state) {
        state.dialogAddMount = !state.dialogAddMount;
    },
    setDialogSelectTypePayment(state) {
        state.dialogSelectTypePayment = !state.dialogSelectTypePayment;
    },
    setNewDetail(state, payload) {
        if (payload.monto > 0) {
            state.paymentReceipt.details.push(payload);
            state.dialogAddMount = false;
            state.nuevoDetalle = {
                metodo_pago: "efectivo",
                codigo: "0",
                entidad_metodo_pago: "0",
                monto: 0,
            };
            state.sumPaymentDetails = 0;
            state.paymentReceipt.details.forEach((e) => {
                //SUMA LA CANTIDAD DE DINERO DE LOS DETALLES
                state.sumPaymentDetails += e.monto;
            });
        } else {
            this.commit("app/setSnackbarAlert", `Monto no válido`);
        }
    },

    setTotal(state, { debtorDetails, selectedSaleDetail }) {
        //ESTE METODO SE LLAMA CADA VEZ QUE SE SELECCIONA UNA FACTURA
        state.totalSelect = 0;
        state.paymentReceipt.saleHeaders = [];
        selectedSaleDetail.forEach((index) => {
            //EN ESTE FOR SE SUMA EL TOTAL DE LAS FACTURAS SELECCIONADAS Y SE AGREGA EL SALE_HEADER_ID A paymentReceipt
            state.totalSelect +=
                debtorDetails[index].sale_detail[0].monto_factura;
            state.paymentReceipt.saleHeaders.push(debtorDetails[index].id);
        });
        state.maxSelect = state.paymentReceipt.saleHeaders.length + 1; // se amplia +1 la seleccion maxima de facturas
        if (state.isPayAmount) {
            // pregunta si se llama desde pagar monto
            state.totalSelect = state.sumPaymentDetails - state.totalSelect;
            if (state.totalSelect < 1) {
                // si la resta del monto de pago y el total de facturas da negativo se muestra el resultado como pendiente
                state.totalSelect = state.totalSelect * -1;
                state.maxSelect = selectedSaleDetail.length; // se limita el maximo de facturas que se puede seleccionar
            }
            // a partir de aca se carga la variable receipt cuando hay pago parcial
            var tempRest = state.sumPaymentDetails;
            var tempArray = [];
            state.paymentReceipt.partialPayment = {};
            selectedSaleDetail.forEach((index) => {
                if (
                    tempRest -
                        debtorDetails[index].sale_detail[0].monto_factura >=
                    0
                ) {
                    // si la resta entre el monto de pago y la factura hasta este punto es positivo, se realiza la resta
                    tempRest -=
                        debtorDetails[index].sale_detail[0].monto_factura;
                    tempArray.push(debtorDetails[index].id); //y se registra la ID del la factura pagada
                } else {
                    //acá se registra el pago parcial
                    state.paymentReceipt.partialPayment = {
                        sale_header_id: debtorDetails[index].id,
                        pagado: tempRest,
                    };
                }
                state.paymentReceipt.saleHeaders = tempArray;
            });
        }
    },
    setIsPayAmount(state) {
        state.isPayAmount = !state.isPayAmount;
    },
    setSelectedSaleDetail(state, payload) {
        state.selectedSaleDetail = payload;
    },
    limpiarCampos(state) {
        (state.paymentReceipt = {
            recibo_numero: "",
            details: [],
            saleHeaders: [],
            partialPayment: {
                sale_header_id: "",
                pagado: "",
            },
        }),
            // state.paymentReceiptDetail=[];
            (state.selectedSaleDetail = []);
        state.nuevoDetalle = {
            metodo_pago: "efectivo",
            codigo: "0",
            entidad_metodo_pago: "0",
            monto: 0,
        };
        state.totalSelect = 0;
        state.sumPaymentDetails = 0;
        state.isPayAmount = false;
        state.maxSelect = 1;
        state.paymentDialog = false;
        state.dialogAddMount = false;
        state.dialogListSale = false;
        state.dialogSelectTypePayment = false;
    },
    btnCancelarDialogPaymentSale(state) {
        state.paymentDialog = false;
        (state.paymentReceipt.details = []), (state.sumPaymentDetails = 0);
        if (state.isPayAmount) state.isPayAmount = false;
    },
    btnCancelarDialogListSale() {
        state.dialogListSale = false;
        state.totalSelect = 0;
        state.selectedSaleDetail = [];
    },
    GET_NUMBER_RECEIPT(state, number) {
        state.paymentReceipt.recibo_numero = number;
    },
    CREATE_PAYMENT(state) {
        (state.paymentReceipt = {
            recibo_numero: "",
            details: [],
            saleHeaders: [],
            partialPayment: {
                sale_header_id: "",
                pagado: "",
            },
        }),
            (state.selectedSaleDetail = []);
        state.nuevoDetalle = {
            metodo_pago: "efectivo",
            codigo: "0",
            entidad_metodo_pago: "0",
            monto: 0,
        };
        state.totalSelect = 0;
        state.sumPaymentDetails = 0;
        state.isPayAmount = false;
        state.maxSelect = 1;
        state.paymentDialog = false;
        state.dialogAddMount = false;
        state.dialogListSale = false;
        state.dialogSelectTypePayment = false;
    },
    setCurrencyId(state, payload) {
        state.paymentReceipt.currency_id = payload;
    },
};

const getters = {
    getSelectedSaleDetail: (state) => {
        let selected = state.selectedSaleDetail;
        return selected;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
