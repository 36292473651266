<template v-slot:activator="{ on, attrs }">
    <!-- Dialog ver Venta -->
    <v-dialog
        v-model="dialogShowSale"
        max-width="1000px"
        @click:outside="closeDialog()"
        @keydown.esc="closeDialog()"
    >
        <v-card :loading="loadingShowSale">
            <template slot="progress">
                <LoadingComponent />
            </template>
            <v-card-title>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="text-h7 pb-1"
                            >Factura # {{ returnNumInvoice }}
                        </v-list-item-title>
                        <v-list-item-title>
                            Venta # {{ sale.numero }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-card-title>
            <v-row class="mx-2" justify="space-between">
                <v-col cols="12" md="4">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-subtitle class="text-h7 pb-1"
                                >Cliente
                            </v-list-item-subtitle>
                            <v-list-item-title>
                                {{ returnClientName }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="4">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-subtitle class="text-h7 pb-1"
                                >Ruc
                            </v-list-item-subtitle>
                            <v-list-item-title>
                                {{ returnClientRuc }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="4">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-subtitle class="text-h7 pb-1"
                                >Fecha
                            </v-list-item-subtitle>
                            <v-list-item-title>
                                {{ sale.fecha }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
            <v-row class="mx-2" justify="space-between" align="start">
                <v-col md="6">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-subtitle class="text-body-1 mb-2">
                                Cantidad
                            </v-list-item-subtitle>
                            <v-list-item-title class="text-h6 mb-2">
                                {{ sale.cantidad }} items
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col md="6">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title
                                class="text-body-1 text-end mb-2"
                            >
                                Total
                            </v-list-item-title>
                            <v-list-item-title class="text-h6 text-end mb-2">
                                {{ convertMoney(sale.total, currencyCode) }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
            <v-row justify="center" class="pa-0 ma-0">
                <v-col class="pa-0" md="10"
                    ><v-divider class="info"></v-divider
                ></v-col>
            </v-row>

            <!-- Tabla mostrar compra-->
            <v-row class="p-0 m-0">
                <v-col cols="12" md="12">
                    <v-data-table
                        class="mx-1"
                        :headers="filteredHeaders"
                        :items="sale.sale_detail"
                        hide-default-footer
                        :items-per-page="-1"
                    >
                        <!-- Columna precio_unitario  -->
                        <template v-slot:[`item.precio_unitario`]="{ item }">
                            {{
                                convertMoney(item.precio_unitario, currencyCode)
                            }}
                        </template>

                        <!-- Columna subtotal  -->
                        <template v-slot:[`item.subtotal`]="{ item }">
                            {{ convertMoney(item.subtotal, currencyCode) }}
                        </template>

                        <template v-slot:[`item.estado`]="{ item }">
                            <v-chip
                                label
                                outlined
                                :color="returnStateColor(item.estado)"
                                class="font-weight-medium"
                                >{{ item.estado }}</v-chip
                            >
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red lighten-2"
                    text
                    class="ma-3"
                    @click="closeDialog()"
                >
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { convertMoney } from "../../formatos";
import LoadingComponent from "../Loading.Component.vue";

export default {
    data: () => ({
        dialog: true,
    }),
    components: {
        LoadingComponent,
    },
    computed: {
        ...mapState("sale", [
            "sale",
            "headersProduct",
            "showSale",
            "dialogShowSale",
            "loadingShowSale",
        ]),
        filteredHeaders() {
            return this.headersProduct.filter(
                (header) => header.text !== "Opciones"
            );
        },
        currencyCode() {
            return this.sale.moneda ? this.sale.moneda.codigo : "PYG";
        },
        returnClientName() {
            return this.sale.client
                ? this.sale.client.nombre_y_apellido
                : "Sin Cliente";
        },
        returnClientRuc() {
            return this.sale.client ? this.sale.client.ruc : "Sin Cliente";
        },
        returnNumInvoice() {
            return this.sale.factura_numero
                ? this.sale.factura_numero
                : "Sin Factura";
        },
    },
    methods: {
        ...mapMutations("sale", ["setDialogShowSale", "limpiarPOS"]),

        closeDialog() {
            this.setDialogShowSale(false);
            const quote = JSON.parse(localStorage.getItem("quote"));
            this.limpiarPOS(quote.currency_id);
        },

        returnStateColor(estado) {
            if (estado === "intercambio") {
                return "orange accent-4";
            }
            if (estado === "devolucion") {
                return "yellow darken-3";
            }
            if (estado === "activo") {
                return "green";
            }
            if (estado === ("inactivo" || "eliminado" || "anulado")) {
                return "red";
            }
        },

        convertMoney,
    },
};
</script>
