<template>
    <div>
        <v-card class="ma-5 overflow-y-auto" height="100vh">
            <v-list-item>
                <v-list-item-title>
                    <div class="overline mt-5">
                        <h4>Lista Stock</h4>
                    </div>
                </v-list-item-title>
            </v-list-item>

            <!-- Lista de Stock -->
            <v-data-table
                :headers="headers"
                :items="productsStore"
                :search="search"
                sort-by="product.nombre"
                class="elevation-1 ma-5"
            >
                <template v-slot:top>
                    <v-row align="center" justify="space-between">
                        <v-col cols="12" md="6">
                            <v-text-field
                                class="ma-5"
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Busqueda general"
                                placeholder="Busqueda general"
                                single-line
                                hide-details
                            ></v-text-field>
                        </v-col> </v-row
                ></template>

                <template v-slot:[`item.product.nombre`]="{ item }">
                    {{ item.product.nombre }}
                </template>
                <template v-slot:[`item.cantidad`]="{ item }">
                    {{ item.product.stock_ilimitado ? "∞" : item.cantidad }}
                </template>
                <template v-slot:[`item.product.codigo`]="{ item }">
                    {{ item.product.codigo }}
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    data: () => ({
        search: "",
    }),
    mounted() {
        this.getProductsStores({ dep: 1 });
    },
    computed: {
        ...mapState("app", ["canCreate", "canEdit", "canDelete"]),
        ...mapState("stock", ["stock", "headers"]),
        ...mapState("deposito", ["stores", "productsStore"]),
    },
    methods: {
        ...mapActions("stock", ["getStock"]),
        ...mapActions("deposito", ["getProductsStores"]),
    },
};
</script>
