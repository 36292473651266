const { default: axios } = require("axios");

const state = {
    dialogShowBill: false,
    dialogFormBill: false,
    dialogDeleteBill: false,
    bills: [],
    bill: {},
    loading: false,
    pageBill: {},
    isEditBill: false,
    boxBills: [],
    billsPage: {
        current: 0,
        total: 0,
        perPage: 0,
        lastPage: 0,
        firstPage: 0,
    },
    headers: [
        { text: "Billete", value: "nombre" },
        { text: "Valor", value: "valor" },
        { text: "Moneda", value: "currency_id" },
        { text: "Opciones", value: "actions", align: "center" },
    ],
};
const actions = {
    getBills({ state, commit }) {
        state.loading = true;
        const { perPage, current } = state.billsPage;
        axios
            .get(`/bills?page=${current}&limit=${perPage}`)
            .then((res) => {
                commit("GET_BILLS", res.data);
            })
            .catch((err) => {
                this.commit("app/setSnackbarAlert", err.message);
            })
            .finally((res) => {
                state.loading = false;
            });
    },
    createBill({ commit, dispatch }, payload) {
        axios
            .post("/bills", payload)
            .then(() => {
                commit("CREATE_BILL");
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                dispatch("getBills");
            })
            .catch((err) => {
                this.commit("app/setSnackbarAlert", err.message);
            });
    },
    editBill({ commit, dispatch }, payload) {
        axios
            .put(`/bills/${payload.id}`, payload)
            .then((res) => {
                commit("EDIT_BILL", res.data);
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                dispatch("getBills");
            })
            .catch((err) => {
                this.commit("app/setSnackbarAlert", err.message);
            });
    },
    deleteBill({ commit, dispatch }, payload) {
        axios
            .delete(`/bills/${payload.id}`)
            .then(() => {
                commit("DELETE_BILL");
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                dispatch("getBills");
            })
            .catch((err) => {
                this.commit("app/setSnackbarAlert", err.message);
            });
    },
    getAllBills({ commit, dispatch }) {
        axios.get("currency_bills").then((res) => {
            commit("GET_ALL_BILLS", res.data);
        });
    },
};
const mutations = {
    GET_BILLS(state, payload) {
        state.bills = payload.data;
        state.billsPage = {
            current: payload.current_page,
            perPage: payload.per_page,
            total: payload.total,
            lastPage: payload.last_page,
        };
    },
    CREATE_BILL(state) {
        state.bill = {};
        state.dialogFormBill = false;
    },
    EDIT_BILL(state) {
        state.bill = {};
        state.dialogFormBill = false;
        state.isEditBill = false;
    },
    DELETE_BILL(state) {
        state.bill = {};
    },
    GET_ALL_BILLS(state, payload) {
        state.boxBills = payload;
    },
    setDialogShowBill(state, payload) {
        state.dialogShowBill = payload;
    },
    setDialogFormBill(state, payload) {
        state.dialogFormBill = payload;
    },
    setDialogDeleteBill(state, paylaod) {
        state.dialogDeleteBill = paylaod;
    },
    setIsEditBill(state, payload) {
        state.isEditBill = payload;
    },
    setBill(state, payload) {
        state.bill = payload;
    },
    resetBill(state) {
        state.bill = {};
    },
};
const getters = {};

module.exports = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
