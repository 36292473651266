<template v-slot:top>
    <div>
        <v-card class="my-10">
            <v-list-item class="">
                <v-card
                    class="mt-n7"
                    color="primary"
                    elevation="10"
                    min-height="80"
                    min-width="80"
                >
                    <v-icon class="ma-5" large dark>payments</v-icon>
                </v-card>

                <v-list-item-title>
                    <div class="overline mx-10 mt-5">
                        <h4>Lista Ventas</h4>
                    </div>
                </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-card-text>
                        <v-card-actions> </v-card-actions>
                    </v-card-text>
                </v-list-item-content>
            </v-list-item>

            <!-- Lista de Marcas -->
            <ListSales></ListSales>
        </v-card>

        <!-- Para Dialogo Editar y Nuevo  -->
        <template>
            <FormSales />
            <FormClient />
        </template>
        <template v-if="dialogImpresion">
            <PreimpresoDobleComponentVue />
        </template>
    </div>
</template>
<script>
import ListSales from "./ListSales.Component.vue";
import FormSales from "./FormSales.Component.vue";
import FormClient from "../Clients/FormClient.Component.vue";
import { mapState, mapMutations } from "vuex";
import PreimpresoDobleComponentVue from "../PointOfSale/PreimpresoDoble.Component.vue";

export default {
    data: () => ({}),
    components: {
        ListSales,
        FormSales,
        FormClient,
        PreimpresoDobleComponentVue,
    },
    mounted() {
        this.setRuta('/sales');
    },
    computed: {
        ...mapState("app", ["snackbar"]),
        ...mapState("sale", ["dialogImpresion"]),
    },
    methods: {
        ...mapMutations("sale", ["setRuta"]),
    },
    created() {},
};
</script>
