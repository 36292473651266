<template>
    <v-row justify="center">
        <v-dialog v-model="printDialog" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    Impresion de factura
                </v-card-title>
                <v-card-text> Desea imprimir la factura? </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="cancel()">
                        Cancelar
                    </v-btn>
                    <v-btn color="green darken-1" text @click="imprimir()">
                        Imprimir
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
    data: () => ({
        ticket: JSON.parse(localStorage.getItem("ticket")),
    }),
    mounted() {
        this.ticket = JSON.parse(localStorage.getItem("ticket"));
    },
    methods: {
        ...mapActions("sale", ["printSale", "pagoRapido"]),
        ...mapMutations("sale", [
            "setPrintDialog",
            "setDialogImpresion",
            "setIsPagando",
        ]),

        imprimir() {
            this.printSale(); //hacer la impresion
           // this.pagoRapido(); //hacer la compra
            this.setPrintDialog(false); //cerrar dialogo
        },
        cancel() {
            this.setIsPagando(false);
            this.setPrintDialog(false);
           // this.pagoRapido();
        },
    },
    computed: {
        ...mapState("sale", ["print", "printDialog"]),
    },
};
</script>
