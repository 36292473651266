import axios from "axios";
//Variables Category
const state = {
    dialog: false,
    edit: false,
    deleteDialog: false,
    isDeleted: false,
    categories: [],
    categoryForOther: {},
    isCategoryForOther: false,
    category: {},
    loading: false,
    lazy: false,
    categoryPage: {
        current: 1,
        total: 0,
        perPage: 10,
        lastPage: 0,
        firstPage: 0,
    },
    loading: false,
    img: null,
    headers: [
        {
            text: "Nombre",
            align: "start",
            value: "nombre",
        },
        { text: "Imagen", value: "img_url" },
        { text: "Opciones", value: "actions", sortable: false, width: "150px" },
    ],
};

//Acciones Categoria
const actions = {
    getCategories({ state, commit }) {
        state.loading = true;
        axios
            .get(
                `/categories?page=${state.categoryPage.current}&limit=${state.categoryPage.perPage}`
            )
            .then((response) => {
                commit("GET_CATEGORIES", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            })
            .finally(() => {
                state.loading = false;
            });
    },

    async createCategory({ commit, dispatch, state }, params) {
        this.commit("app/setIsLoading", true);
        //si existe imagen crea esa imagen sino pasa linea
        if (params.img_url) {
            await this.dispatch("image/createImage", params.img_url).then(
                (res) => {
                    params.img_url = res.data;
                }
            );
        }

        axios
            .post("/categories", params)
            .then((response) => {
                if (state.isCategoryForOther) {
                    state.categoryForOther = response.data;
                }
                commit("CREATE_CATEGORY", params);
            })
            .then(() => {
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                dispatch("getCategories");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
        this.commit("app/setIsLoading", false);
    },

    //editar categoria
    //dispath: llama al mismo action para ejecutar
    //getters: llama a getter para retornar obj
    async editCategory({ commit, getters, dispatch }, params) {
        //busca categoria anterior luego para borrar la imagen anterior
        let item = getters.getCategoryById(params.id);

        //creamos nueva imagen si hay imagen

        if (params.img_url !== item.img_url) {
            await this.dispatch("image/createImage", params.img_url).then(
                async (response) => {
                    params.img_url = response.data;
                    await this.dispatch("image/editImage", item);
                }
            );
        }

        //editar agregando la imagen nueva
        axios
            .put(`/categories/${params.id}`, params)
            .then((response) => {
                commit("EDIT_CATEGORY", response);
            })
            .then(() => {
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                dispatch("getCategories");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },

    deleteCategory({ commit, dispatch }, params) {
        axios
            .delete(`/categories/${params.id}`)
            .then(() => {
                commit("DELETE_CATEGORY", params);
            })
            .then(() => {
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                dispatch("getCategories");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    async searchCategories({ state, commit }, payload) {
        state.loading = true;
        await axios
            .post(`/search_categories?page=${state.categoryPage.current}`, {
                buscador: payload,
            })
            .then((res) => {
                commit("SEARCH_CATEGORY", res.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            })
            .finally(() => {
                state.loading = false;
            });
    },
};

//Metodos Category
const mutations = {
    setDialog(state) {
        state.dialog = !state.dialog;
    },
    setEdit(state) {
        state.edit = !state.edit;
    },
    setDeleteDialog(state) {
        state.deleteDialog = !state.deleteDialog;
    },
    setCategory(state, category) {
        state.category = category;
    },
    setImage(state, img) {
        state.img = img;
    },
    clearCategoryForOther(state) {
        state.categoryForOther = {};
        state.isCategoryForOther = false;
    },
    setIsCategoryForOther(state) {
        state.isCategoryForOther = true;
    },
    clearCategory(state) {
        state.category = {};
    },
    getEdit(state, item) {
        state.category.id = item.id;
        state.category.nombre = item.nombre;
        state.category.img_url = item.img_url;
    },
    GET_CATEGORIES(state, categories) {
        state.categoryPage = {
            total: categories.total,
            perPage: categories.per_page,
            current: categories.current_page,
            lastPage: categories.last_page,
            firstPage: categories.first_page,
        };
        state.categories = categories.data;
    },
    CREATE_CATEGORY(state) {
        state.dialog = false;
        state.img = "";
    },
    CREATE_IMAGE(state, item) {
        state.img = item;
    },
    EDIT_CATEGORY(state) {
        state.dialog = false;
        state.edit = false;
        state.img = "";
    },
    DELETE_CATEGORY(state) {
        state.delete = false;
        state.isDeleted = false;
    },
    SEARCH_CATEGORY(state, categories) {
        state.categoryPage = {
            total: categories.total,
            perPage: categories.per_page,
            current: categories.current_page,
            lastPage: categories.last_page,
            firstPage: categories.first_page,
        };
        if (state.lazy) {
            state.categories = [...state.categories, ...categories.data];
            state.lazy = false;
        } else {
            state.categories = categories.data;
        }
        state.loading = false;
    },
    setLazy(state, payload) {
        state.lazy = payload;
    },
    addListCategories(state, categories) {
        state.categories = [...state.categories, ...categories];
    },
};

const getters = {
    //busca categoria por id
    getCategoryById: (state) => (id) => {
        return state.categories.find((obj) => obj.id === id);
    },
    returnCategories: (state) => {
        return state.categories;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
