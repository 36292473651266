<template>
    <v-dialog
        v-model="dialogConvertProductBox"
        @click:outside="closeDialog()"
        @keydown.esc="closeDialog()"
    >
        <v-card>
            <v-card-title>Convertir </v-card-title>
            <v-card-text>
                <section class="d-flex">
                    <v-autocomplete v-model="convertProductBox" />
                    <v-text-field label="Cantidad" />
                </section>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn outlined color="red" @click="closeDialog()"
                    >Cerrar</v-btn
                >
                <v-btn outlined color="primary">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
    data: () => ({}),
    watch: {},
    computed: {
        ...mapState("prod_box", [
            "dialogConvertProductBox",
            "convertProductBox",
        ]),
    },
    methods: {
        ...mapMutations("prod_box", ["setDialogConverProductBox"]),
        closeDialog() {
            this.setDialogConverProductBox(false);
        },
    },
};
</script>

<style></style>
