const state = {
    recipients: [],
    recipient: {
        nombre: "",
        ruc: "",
        direccion: "",
    },
    isEditRecipient: false,
    dialogFormRecipient: false,
    dialogDeleteRecipient: false,
    headers: [
        {
            text: "Nombre",
            align: "start",
            value: "nombre",
        },
        { text: "Ruc", value: "ruc" },
        { text: "Direccion", value: "direccion" },
        { text: "Opciones", value: "actions", sortable: false, width: "150px" },
    ],
};
const actions = {
    getRecipients({ commit }) {
        axios
            .get("recipients")
            .then((res) => {
                commit("GET_RECIPIENTS", res.data);
            })
            .catch((err) => {
                throw err;
            });
    },
    createRecipient({ commit, dispatch }, payload) {
        axios
            .post("recipients", payload)
            .then((res) => {
                commit("CLEAR_RECIPIENT");
                commit("CREATE_RECIPIENT");
                dispatch("getRecipients");
            })
            .catch((err) => {
                throw err;
            });
    },
    editRecipient({ commit, dispatch }, payload) {
        axios
            .put(`recipients/${payload.id}`, payload)
            .then((res) => {
                commit("CLEAR_RECIPIENT");
                commit("EDIT_RECIPIENT");
                dispatch("getRecipients");
            })
            .catch((err) => {
                throw err;
            });
    },
    deleteRecipient({ commit, dispatch }, payload) {
        axios
            .delete(`recipients/${payload.id}`)
            .then((res) => {
                dispatch("getRecipients");
            })
            .catch((err) => {
                throw err;
            });
    },
};

const mutations = {
    setDialogFormRecipient(state, payload) {
        state.dialogFormRecipient = payload;
    },
    setDialogDeleteRecipient(state, payload) {
        state.dialogDeleteRecipient = payload;
    },
    setRecipient(state, payload) {
        state.recipient = payload;
    },
    handlerEditRecipient(state, recipient) {
        state.isEditRecipient = true;
        state.dialogFormRecipient = true;
        state.recipient = Object.assign({}, recipient);
    },
    handlerClose(state) {
        state.recipient = {
            nombre: "",
            ruc: "",
            direccion: "",
        };
        state.dialogFormRecipient = false;
        state.isEditRecipient = false;
    },
    GET_RECIPIENTS(state, payload) {
        state.recipients = payload;
    },
    CREATE_RECIPIENT(state) {
        state.isEditRecipient = false;
        state.dialogFormRecipient = false;
    },
    EDIT_RECIPIENT(state) {
        state.isEditRecipient = false;
        state.dialogFormRecipient = false;
    },
    CLEAR_RECIPIENT(state) {
        state.recipient = {
            nombre: "",
            ruc: "",
            direccion: "",
        };
    },
};
const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
