var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},on:{"click:outside":function($event){_vm.setDialog();
                _vm.clearSupplier();
                _vm.resetValidate();},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.setDialog();
                _vm.clearSupplier();
                _vm.resetValidate();}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_vm._v(" "),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Nombre Apellido","rules":_vm.nameRules,"required":""},model:{value:(_vm.supplier.nombre),callback:function ($$v) {_vm.$set(_vm.supplier, "nombre", $$v)},expression:"supplier.nombre"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Ruc","rules":_vm.rucRules,"required":""},model:{value:(_vm.supplier.ruc),callback:function ($$v) {_vm.$set(_vm.supplier, "ruc", $$v)},expression:"supplier.ruc"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Telefono"},model:{value:(_vm.supplier.telefono),callback:function ($$v) {_vm.$set(_vm.supplier, "telefono", $$v)},expression:"supplier.telefono"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"email"},model:{value:(_vm.supplier.correo),callback:function ($$v) {_vm.$set(_vm.supplier, "correo", $$v)},expression:"supplier.correo"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Direccion","rows":"2"},model:{value:(_vm.supplier.direccion),callback:function ($$v) {_vm.$set(_vm.supplier, "direccion", $$v)},expression:"supplier.direccion"}})],1)],1)],1),_vm._v(" "),_c('small',[_vm._v("*indicates required field")])],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),(_vm.edit)?_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":function($event){_vm.setDialog();
                            _vm.setEdit();
                            _vm.clearSupplier();
                            _vm.resetValidate();}}},[_vm._v("\n                        Cerrar\n                    ")]):_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":function($event){_vm.setDialog();
                            _vm.clearSupplier();
                            _vm.resetValidate();}}},[_vm._v("\n                        Cerrar\n                    ")]),_vm._v(" "),(_vm.edit)?_c('v-btn',{attrs:{"disabled":!_vm.valid,"color":"blue darken-1","outlined":""},on:{"click":function($event){return _vm.validateSubmit(_vm.edit, _vm.supplier)}}},[_vm._v("\n                        Editar\n                    ")]):_c('v-btn',{attrs:{"disabled":!_vm.valid || _vm.isLoading,"loading":_vm.isLoading,"color":"blue darken-1","outlined":""},on:{"click":function($event){return _vm.validateSubmit(_vm.edit, _vm.supplier)}}},[_vm._v("\n                        Guardar\n                    ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }