<template>
    <div class="ma-5">
        <v-data-table
            :headers="headersDropProduct"
            :items="allDropProducts"
            :search="search"
        >
            <!-- Iconos Editar Y Eliminar  -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    color="blue"
                    @click="
                        setDialog();
                        setEdit();
                        setDropProduct(item);
                    "
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    color="red"
                    @click="
                        setDeleteDialog();
                        localItem = item;
                    "
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
        <DeleteDialog
            :dialog="deleteDialog"
            :setDialog="setDeleteDialog"
            v-on:deleted="deleteDropProduct(localItem)"
        />
    </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import DeleteDialog from "../DeleteDialog.Component.vue";
export default {
    data: () => ({
        localItem: {},
    }),
    mounted() {
        this.getPermissions(this.$route.path);
        this.getAllDropProduct();
    },
    components: {
        DeleteDialog,
    },
    computed: {
        ...mapState("drop_product", [
            "search",
            "allDropProducts",
            "headersDropProduct",
            "deleteDialog",
        ]),
        ...mapState("app", ["canCreate", "canEdit", "canDelete"]),
    },
    methods: {
        ...mapMutations("drop_product", [
            "setDialog",
            "setDeleteDialog",
            "setEdit",
            "setDropProduct",
        ]),
        ...mapActions("app", ["getPermissions"]),
        ...mapActions("drop_product", [
            "getAllDropProduct",
            "getDropProduct",
            "deleteDropProduct",
            "getOneDropProduct",
        ]),
    },
};
</script>
