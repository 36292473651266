var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-10"},[_c('v-data-table',{attrs:{"headers":_vm.headersSale,"items":_vm.sales,"options":_vm.options,"loading":_vm.loading,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-pagination',{staticClass:"py-4",attrs:{"length":_vm.salesPage.lastPage},on:{"input":function($event){return _vm.inputPage(_vm.salesPage)}},model:{value:(_vm.salesPage.current),callback:function ($$v) {_vm.$set(_vm.salesPage, "current", $$v)},expression:"salesPage.current"}})]},proxy:true},{key:"top",fn:function(){return [_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"mdi-magnify","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('v-col',{attrs:{"md":"4","sm":"4"}},[_c('v-select',{attrs:{"items":_vm.filtros,"label":"Filtro","outlined":"","dense":""},model:{value:(_vm.selectedFiltro),callback:function ($$v) {_vm.selectedFiltro=$$v},expression:"selectedFiltro"}})],1)],1)]},proxy:true},{key:`item.ruc`,fn:function({ item }){return [(item.ruc)?_c('v-sheet',{staticClass:"pa-2 ma-md-2 d-flex flex-column rounded"},[_c('div',{staticClass:"subtitle-2"},[_vm._v("\n                    "+_vm._s(item.nombre_y_apellido)+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"body-2"},[_vm._v("\n                    "+_vm._s(item.ruc)+"\n                ")])]):_vm._e()]}},{key:`item.credito`,fn:function({ item }){return [_c('span',[_vm._v("\n                "+_vm._s(_vm.getCreditText(
                        item.credito,
                        item.pagado,
                        item.plazo,
                        item.created_at
                    ))+"\n            ")])]}},{key:`item.factura_numero`,fn:function({ item }){return [_vm._v("\n            "+_vm._s(item.factura_numero === null
                    ? "Sin Factura"
                    : item.factura_numero)+"\n            "),(item.anulado)?_c('span',{staticClass:"text-danger"},[_vm._v("anulado")]):_vm._e()]}},{key:`item.total`,fn:function({ item }){return [_vm._v("\n            "+_vm._s(_vm.convertMoney(item.total, item?.codigo || "PYG"))+"\n        ")]}},{key:`item.created_at`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.fecha))])]}},{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.imprimirVenta(item.id)}}},[_vm._v("\n                mdi-printer\n            ")]),_vm._v(" "),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.showSaleDetail(item.id)}}},[_vm._v("\n                mdi-eye\n            ")]),_vm._v(" "),_c('v-icon',{staticClass:"mr-2",attrs:{"disabled":_vm.isCanCancel && !!item.anulado,"small":"","color":"red"},on:{"click":function($event){return _vm.setAnularVenta(item.id)}}},[_vm._v("\n                mdi-cancel\n            ")]),_vm._v(" "),_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"icon":""}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])]}}],null,true)},[_vm._v(" "),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"font-weight-medium",on:{"click":function($event){_vm.setDialog();
                            _vm.setEdit();
                            _vm.getEditSale(item);}}},[(_vm.canEdit)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"blue"}},[_vm._v("\n                            mdi-pencil\n                        ")]):_vm._e(),_vm._v("\n                        Editar\n                    ")],1),_vm._v(" "),_c('v-list-item',{staticClass:"font-weight-medium",on:{"click":function($event){_vm.localSale = item;
                            _vm.setDeleteDialog();}}},[(_vm.canDelete)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"red"}},[_vm._v("\n                            mdi-delete Eliminar\n                        ")]):_vm._e(),_vm._v("\n                        Eliminar\n                    ")],1)],1)],1)]}}],null,true)}),_vm._v(" "),_c('DeleteDialog',{attrs:{"dialog":_vm.deleteDialog,"setDialog":_vm.setDeleteDialog},on:{"deleted":function($event){return _vm.setDeleteSale(_vm.localSale)}}}),_vm._v(" "),_c('AvisoAperturaDialog'),_vm._v(" "),_c('v-dialog',{staticClass:"ma-0 pa-0",attrs:{"max-width":"500","content-class":"elevation-0"},model:{value:(_vm.dialogAnular),callback:function ($$v) {_vm.dialogAnular=$$v},expression:"dialogAnular"}},[_c('v-alert',{staticClass:"bg-white",attrs:{"outlined":"","color":"deep-orange","type":"warning"}},[_vm._v("\n            ¿Desea anular esta venta?\n            "),_c('v-card-actions',{staticClass:"mt-4"},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"mr-3",attrs:{"outlined":"","text":""},on:{"click":function($event){_vm.dialogAnular = false}}},[_vm._v("\n                    Cancelar\n                ")]),_vm._v(" "),_c('v-btn',{attrs:{"outlined":"","text":"","color":"deep-orange"},on:{"click":function($event){_vm.anularFactura(_vm.idAnular);
                        _vm.dialogAnular = false;}}},[_vm._v("Anular")])],1)],1)],1),_vm._v(" "),_c('DialogShowSale')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }