<template>
    <v-dialog 
        v-model="dialogReportByDep" 
        width="800"
        @click:outside="cerrarDialog()"
        @keydown.esc="cerrarDialog()"
    >
        <v-card>
            <v-card  style="overflow-x: hidden; overflow-y: hidden;">
                <v-card-title>Informe por depósito</v-card-title>
            </v-card>
            <template>
                <v-row class="ma-0 pa-0 justify-center">
                    <v-col md="6">
                        <v-card-subtitle class="text-center">Lista de depósitos</v-card-subtitle>
                        <v-item-group 
                            v-model="selectedDep"
                        >
                            <v-row
                                v-for="(details, i) in stores"
                                :key="i"
                                class="mt-2 mx-4"
                            >
                                <v-col >
                                    <v-item v-slot="{ active, toggle }">
                                        <v-card
                                        :color="active ? 'primary' : ''"
                                        class="d-flex align-center text-center"
                                        height="50"
                                        @click="toggle"
                                        >
                                            <v-card-text :class="active ? 'white--text' : ''">
                                                <h6>{{ details.nombre }}</h6>
                                            </v-card-text>
                                        </v-card>
                                    </v-item>
                                </v-col>
                            </v-row>
                            
                        </v-item-group>
                    </v-col>
                   
                </v-row>
            </template>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="cerrarDialog() "
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="generar()"
                    :disabled = "selectedDep == undefined"
                >
                    Generar informe
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog> 
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";


export default {
    data: () => ({
        selectedDep: undefined,
    }), 
    mounted(){
        this.getAllStores();
    },
    computed: {
        ...mapState("report", ["dialogReportByDep"]),
        ...mapState("deposito", ["stores"]),
    },
    methods: {
        ...mapMutations("report", ["setDialogReportByDep"]),
        ...mapActions("stock",["generarInformeStock"]),
        ...mapActions("deposito", ["getAllStores"]),
        generar(){
            const data= {
                store_id: this.stores[this.selectedDep].id,
            }
            this.generarInformeStock(data);
            this.cerrarDialog();
        },
        cerrarDialog(){
            this.selectedDep = undefined;
            this.setDialogReportByDep(false);
        },
    },
};
</script>
