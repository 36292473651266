<template>
    <v-container>
        <v-row>
            <!-- Listar todos los billetes de una moneda -->
            <v-col v-for="(bill, i) in bills" :key="i" cols="3">
                <v-text-field
                    v-model.number="inputBills[i].cantidad"
                    :label="bill.nombre"
                    dense
                    type="number"
                    @change="calculoTotal()"
                ></v-text-field>
            </v-col>
            <v-col cols="12">
                <h6>Total: {{ this.total.toLocaleString("Es") }}</h6>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    props: ["bills", "inputBills"],
    data: () => ({
        valuesBills: [],
        total: 0,
    }),
    watch: {},
    computed: {},
    methods: {
        calculoTotal() {
            this.total = 0;
            Object.values(this.inputBills).forEach((el) => {
                this.total += el.cantidad * el.valor;
            });
        },
    },
};
</script>

<style></style>
