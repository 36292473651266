<template>
    <v-snackbar
        v-model="snackbar.show"
        :timeout="snackbar.timeout"
        class="v-snackbar--material d-flex flex-row"
        v-bind="$attrs"
        :color="snackbar.type"
    >
        <v-icon dark class="mr-2">
            {{ this.snackbar.icon }}
        </v-icon>

        {{ this.snackbar.text }}

        <template v-slot:action="{ attrs }">
            <v-btn color icon small v-bind="attrs" @click="close">
                <v-icon> mdi-close </v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
    name: "MaterialSnackbar",

    computed: {
        ...mapState("app", ["snackbar"]),
    },

    methods: {
        ...mapMutations("app", ["setSnackbar"]),
        close() {
            this.setSnackbar({ show: false });
        },
        showSnackbar() {
            setTimeout(() => {
                this.setSnackbar({ show: false });
            }, this.snackbar.timeout);
        },
    },
};
</script>

<style lang="sass">
.v-snackbar--material
    margin-top: 32px
    margin-bottom: 32px
</style>
