import axios from "axios";
//Variables Brand
const state = {
    dialog: false,
    edit: false,
    deleteDialog: false,
    isDeleted: false,
    brands: [],
    brandForOther: {},
    isBrandForOther: false,
    canCreate: false,
    canEdit: false,
    canDelete: false,
    img: "",
    loading: false,
    loadingFormBrand: false,
    lazy: false,
    brandsPage: {
        current: 0,
        total: 0,
        perPage: 0,
        lastPage: 0,
        firstPage: 0,
    },
    brand: {
        id: "",
        nombre: "",
        img_url: "",
        user_id: "",
    },
    headers: [
        {
            text: "Nombre",
            align: "start",
            value: "nombre",
        },
        { text: "Imagen", value: "img_url", sortable: false },
        { text: "Opciones", value: "actions", sortable: false, width: "150px" },
    ],
};

//Acciones Brand
const actions = {
    getBrands({ state, commit }) {
        state.loading = true;
        axios
            .get(`/brands?page=${state.brandsPage.current}`)
            .then((response) => {
                commit("SET_BRANDS", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            })
            .finally(() => {
                state.loading = false;
            });
    },
    async createBrand({ commit, state, dispatch }, params) {
        state.loadingFormBrand = true;
        this.commit("app/setIsLoading", true);
        if (params.img_url) {
            await this.dispatch("image/createImage", params.img_url).then(
                (res) => {
                    params.img_url = res.data;
                }
            );
        }

        axios
            .post("/brands", params)
            .then((response) => {
                if (state.isBrandForOther) state.brandForOther = response.data;
                commit("CREATE_BRAND", params);
            })
            .then(() => {
                this.commit(
                    "app/setSnackbarSuccess",
                    "La marca guardo correctamente"
                );

                dispatch("getBrands");
                commit("clearBrand");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
        this.commit("app/setIsLoading", false);
    },
    async editBrand({ commit, dispatch, getters }, params) {
        //busca categoria anterior luego para borrar la imagen anterior

        let item = getters.getBrandById(params.id);

        //creamos nueva imagen
        if (params.img_url !== item.img_url) {
            await this.dispatch("image/createImage", params.img_url).then(
                async (response) => {
                    params.img_url = response.data;
                    await this.dispatch("image/editImage", item);
                }
            );
        }
        state.loadingFormBrand = true;
        axios
            .put(`/brands/${params.id}`, params)
            .then(() => {
                commit("EDIT_BRAND", params);
            })
            .then(() => {
                this.commit(
                    "app/setSnackbarSuccess",
                    "La marca editado correctamente"
                );
                dispatch("getBrands");
                commit("clearBrand");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    deleteBrand({ commit, dispatch }, params) {
        axios
            .delete(`/brands/${params.id}`)
            .then((response) => {
                commit("DELETE_BRAND", params);
                this.commit(
                    "app/setSnackbarSuccess",
                    "La marca eliminado correctamente"
                );
                dispatch("getBrands");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    getPermissions({ commit }) {
        let permission = ["create", "edit", "delete"]; //lista de permisos
        let index = permission.length - 1;
        while (index >= 0) {
            //recorre el array de permisos
            let hasAccess = false;
            let url = "/brand_" + permission[index];
            axios
                .get(`/permission${url}`)
                .then((response) => {
                    hasAccess = true;
                })
                .catch(() => {
                    hasAccess = false;
                });
            commit("set" + permission[index], hasAccess);
            index -= 1;
        }
        /* esta parte quita opciones del header */
        if (!state.canDelete && !state.canEdit) {
            state.headers.splice(2, 1);
        }
    },
    searchBrands({ commit, state }, payload) {
        state.loading = true;
        axios
            .post(`search_brands?page=${state.brandsPage.current}`, {
                buscador: payload,
            })
            .then((res) => {
                commit("SEARCH_BRANDS", res.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            })
            .finally(() => {
                state.loading = false;
            });
    },
};

//Metodos Brand
const mutations = {
    setDialog(state) {
        state.dialog = !state.dialog;
    },
    setEdit(state) {
        state.edit = !state.edit;
    },
    setDeleteDialog(state) {
        state.deleteDialog = !state.deleteDialog;
    },
    setBrand(state, brand) {
        state.brand = brand;
    },
    clearBrandForOther(state) {
        state.brandForOther = {};
        state.isBrandForOther = false;
    },
    setIsBrandForOther(state) {
        state.isBrandForOther = true;
    },
    clearBrand(state) {
        state.brand = {};
    },

    setImage(state, img) {
        state.img = img;
    },
    getEdit(state, item) {
        state.brand.nombre = item.nombre;
        state.brand.img_url = item.img_url;
        state.brand.id = item.id;
        state.brand.user_id = item.user_id;
    },
    SET_BRANDS(state, brands) {
        state.brandsPage = {
            total: brands.total,
            perPage: brands.per_page,
            current: brands.current_page,
            lastPage: brands.last_page,
            firstPage: brands.first_page,
        };

        state.brands = brands.data;
    },
    CREATE_BRAND(state) {
        state.loadingFormBrand = false;
        state.dialog = false;
    },
    EDIT_BRAND(state) {
        state.loadingFormBrand = false;
        state.dialog = false;
        state.edit = false;
    },
    DELETE_BRAND(state) {
        state.delete = false;
        state.isDeleted = false;
    },
    SEARCH_BRANDS(state, brands) {
        state.brandsPage = {
            total: brands.total,
            perPage: brands.per_page,
            current: brands.current_page,
            lastPage: brands.last_page,
            firstPage: brands.first_page,
        };
        if (state.lazy) {
            state.brands = [...state.brands, ...brands.data];
            state.lazy = false;
        } else {
            state.brands = brands.data;
        }
    },
    setcreate(state, access) {
        state.canCreate = access;
    },
    setedit(state, access) {
        state.canEdit = access;
    },
    setdelete(state, access) {
        state.canDelete = access;
    },
    setLazy(state, payload) {
        state.lazy = payload;
    },
    addListBrands(state, brands) {
        state.brands = [...state.brands, ...brands];
    },
};

const getters = {
    allBrands(state) {
        return state.brands;
    },
    getBrandById: (state) => (id) => {
        return state.brands.find((obj) => obj.id === id);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
