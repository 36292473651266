<template>
    <!-- Dialog ver Venta -->
    <v-dialog
        v-model="showDialogBudget"
        max-width="1000px"
        @click:outside="closeDialog()"
        @keydown.esc="closeDialog()"
    >
        <v-card>
            <v-row class="mx-1" justify="space-between">
                <v-col auto align-self="start">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="text-h6 pb-2">
                                <v-icon> mdi-account </v-icon>
                                {{
                                    this.budget.client?.nombre_y_apellido || ""
                                }}
                            </v-list-item-title>
                            <v-list-item-title class="text-h6 pb-2"
                                ><v-icon dense>mdi-domain</v-icon>
                                {{ this.budget.client?.ruc || "" }}
                            </v-list-item-title>
                            <v-list-item-title class="text-h6"
                                ><v-icon dense>mdi-calendar</v-icon>
                                {{ returnDate }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col auto align-self="start">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="text-h6 mb-2 text-end">
                                Presupuesto #{{ this.budget.numero }}
                            </v-list-item-title>
                            <v-list-item-title class="text-h6 text-end">
                                Total:
                                {{
                                    convertMoney(
                                        returnTotalPrices,
                                        budget.cotizacion.codigo || "PYG"
                                    )
                                }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
            <v-row justify="center" class="pa-0 ma-0">
                <v-col class="pa-0" md="10"
                    ><v-divider class="info"></v-divider
                ></v-col>
            </v-row>
            <v-col>
                <v-alert
                    v-if="
                        this.budget.estado !== 'Pedido-pendiente' &&
                        this.budget.estado !== 'Vendido'
                    "
                    color="red lighten-2"
                    v-model="alert"
                    close-text="Close Alert"
                    type="warning"
                    outlined
                    dismissible
                >
                    Hay productos que no tiene stock suficiente
                </v-alert>
            </v-col>
            <!-- Tabla mostrar compra-->
            <v-row class="p-0 m-0">
                <v-col cols="12" md="12">
                    <v-data-table
                        :headers="localShowHeaders"
                        :items="budget.budget_detail"
                        class="mx-1"
                        :item-class="itemRowBackground"
                        :items-per-page="-1"
                        hide-default-footer
                    >
                        <template v-slot:[`item.codigo`]="props">
                            <span>{{ props.item.product?.codigo }}</span>
                        </template>
                        <template v-slot:[`item.producto`]="props">
                            <span>{{ props.item.product?.nombre }}</span>
                        </template>
                        <template v-slot:[`item.precio_unitario`]="props">
                            <span>{{
                                convertMoney(
                                    props.item.precio_unitario,
                                    budget.cotizacion.codigo || "PYG"
                                )
                            }}</span>
                        </template>
                        <template v-slot:[`item.subtotal`]="props">
                            <span>{{
                                convertMoney(
                                    props.item.cantidad *
                                        props.item.precio_unitario,
                                    budget.cotizacion.codigo || "PYG"
                                )
                            }}</span>
                        </template>
                        <template v-slot:[`item.store_id`]="props">
                            <span>{{
                                returnNameStore(props.item.store_id)
                            }}</span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="
                        (budget.estado !== 'Pedido-pendiente' ? false : true) ||
                        (budget.estado !== 'Vendido' ? false : true)
                    "
                    color="primary lighten-2"
                    text
                    class="ma-3"
                    outlined
                    @click="goToPointOfSale"
                >
                    Vender
                </v-btn>
                <v-btn
                    :disabled="
                        (budget.estado !== 'Pedido-pendiente' ? false : true) ||
                        (budget.estado !== 'Vendido' ? false : true)
                    "
                    color="green lighten-2"
                    text
                    class="ma-3"
                    outlined
                    @click="handleBudgetToOrder()"
                >
                    Pedir
                </v-btn>
                <v-btn
                    color="red lighten-2"
                    text
                    class="ma-3"
                    outlined
                    @click="closeDialog()"
                >
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { convertMoney } from "../../formatos";

export default {
    data: () => ({
        localClient: {
            nombre: "",
            ruc: "",
        },
        alert: false,
    }),

    beforeUpdate() {
        const isMissingProduct = this.budget?.budget_detail?.find(
            (product) => product.faltante === true
        );
        isMissingProduct ? (this.alert = true) : (this.alert = false);
    },

    computed: {
        ...mapState("budget", [
            "showDialogBudget",
            "budget",
            "headersProduct",
            "productsBugetToPos",
            "productsBudget",
        ]),
        ...mapGetters("deposito", ["returnNameStore", "getLocaleStorageStore"]),
        ...mapGetters("quote", ["getQuotes"]),
        ...mapState("sale", ["saleProducts"]),

        localShowHeaders() {
            return this.headersProduct.slice(0, 6);
        },
        returnDate() {
            let date = this.budget.created_at;
            return new Date(date).toLocaleDateString("es-ES");
        },
        returnTotalPrices() {
            let suma = 0;
            this.saleProducts.forEach((element) => {
                suma += element.subtotal;
            });
            return suma;
        },
    },
    methods: {
        ...mapMutations("budget", [
            "setShowDialogBudget",
            "clearProductBudgetToPos",
        ]),
        ...mapMutations("sale", [
            "clearSaleProducts",
            "setSaleProducts",
            "setOrderPos",
        ]),
        ...mapActions("budget", ["verifyProductAmount"]),
        ...mapActions("order", ["createOrderToBudget"]),
        ...mapActions("client", ["searchClient"]),

        goToPointOfSale() {
            this.verifyProductAmount(this.saleProducts);
            this.searchClient(this.budget.client?.ruc);

            const sale = {
                budget_header_id: this.budget?.id,
                seller_id: this.budget?.seller_id,
                saleProducts: this.budget?.budget_detail,
                client: this.budget?.client || {},
                currency_id: this.budget.currency_id,
            };
            this.setOrderPos(sale);
            this.setShowDialogBudget(false);
            this.$router.push("/pos");
        },

        closeDialog() {
            this.setShowDialogBudget(false),
                this.clearSaleProducts(),
                this.clearProductBudgetToPos();
        },
        handleBudgetToOrder() {
            this.createOrderToBudget(this.budget);
            this.closeDialog();
        },
        returnTableColor: (item) => {
            return item.faltante ? "blue" : "red";
        },
        itemRowBackground: function (item) {
            return item.faltante &&
                this.budget.estado !== "Pedido-pendiente" &&
                this.budget.estado !== "Vendido"
                ? "red lighten-4"
                : "";
        },
        convertMoney,
    },
    watch: {
        "budget.client"(val) {
            this.localClient.nombre = val?.nombre_y_apellido || "";
            this.localClient.ruc = val?.ruc || "";
        },
    },
};
</script>

<style scoped></style>
