<template>
    <div>
        <v-tabs v-model="tab" color="primary" grow>
            <v-tab href="#tab-1"> Stock </v-tab>
            <v-tab href="#tab-2"> Baja Producto </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1"> <stock /> </v-tab-item>
            <v-tab-item value="tab-2"> <drop-product /> </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import Stock from "./Stock/Stock.Component.vue";
import DropProduct from "./DropProduct/Drop.Component.vue";
export default {
    data() {
        return {
            tab: null,
        };
    },
    components: {
        Stock,
        DropProduct,
    },
};
</script>
