<template v-slot:activator="{ on, attrs }">
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-row justify="center">
            <!-- Dialog (Modal Form Client) -->
            <v-dialog
                v-model="dialog"
                persistent
                max-width="600px"
                @click:outside="
                    setDialog();
                    clearSupplier();
                    resetValidate();
                "
                @keydown.esc="
                    setDialog();
                    clearSupplier();
                    resetValidate();
                "
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        label="Nombre Apellido"
                                        v-model="supplier.nombre"
                                        :rules="nameRules"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        label="Ruc"
                                        v-model="supplier.ruc"
                                        :rules="rucRules"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        label="Telefono"
                                        v-model="supplier.telefono"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        label="email"
                                        v-model="supplier.correo"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea
                                        label="Direccion"
                                        v-model="supplier.direccion"
                                        rows="2"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                        <small>*indicates required field</small>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="edit"
                            color="red darken-1"
                            outlined
                            @click="
                                setDialog();
                                setEdit();
                                clearSupplier();
                                resetValidate();
                            "
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            v-else
                            color="red darken-1"
                            outlined
                            @click="
                                setDialog();
                                clearSupplier();
                                resetValidate();
                            "
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            v-if="edit"
                            :disabled="!valid"
                            color="blue darken-1"
                            outlined
                            @click="validateSubmit(edit, supplier)"
                        >
                            Editar
                        </v-btn>
                        <v-btn
                            v-else
                            :disabled="!valid || isLoading"
                            :loading="isLoading"
                            color="blue darken-1"
                            outlined
                            @click="validateSubmit(edit, supplier)"
                        >
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-form>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";

export default {
    data: () => ({
        valid: true,
        nameRules: [(v) => !!v || "Nombre es obligatorio"],
        rucRules: [(v) => !!v || "Ruc es obligatorio"],
        localItem: {},
    }),
    computed: {
        //llamada de variables del store
        ...mapState("supplier", ["dialog", "edit", "supplier"]),
        ...mapState("app", ["isLoading"]),
        //Dependiendo del edit (boolean) cambia el titulo del form
        formTitle() {
            return this.edit === false ? "Nuevo Proveedor" : "Editar Proveedor";
        },
    },

    methods: {
        //llamada de metodos del store
        ...mapMutations("supplier", ["setDialog", "setEdit", "clearSupplier"]),
        //llamada de peticiones del store
        ...mapActions("supplier", [
            "createSupplier",
            "editSupplier",
            "getSuppliers",
        ]),

        //validar campo requerido
        //Dependiendo del edit (boolean), crea o edita la marca
        //resetea validacion, obtiene las listas marca, borra los campos nombre url
        validateSubmit(edit, item) {
            if (this.$refs.form.validate()) {
                if (edit) {
                    this.editSupplier(item);
                } else {
                    this.createSupplier(item);
                }
                this.clearSupplier();
                this.resetValidate();
            }
        },
        //resetea las validaciones activadas
        resetValidate() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>
