<template>
    <article>
        <v-form ref="form" v-model="valid" lazy-validation>
            <h6>Numero Remision</h6>
            <v-row>
                <v-col cols="12" lg="8" md="8" sm="12">
                    <v-text-field
                        v-model="remission.numero_remision"
                        type="Number"
                        label="Numero de Remision"
                        :disabled="validRemissionNumber"
                    />
                </v-col>

                <v-col cols="12" lg="2" md="2" sm="12">
                    <v-radio-group
                        v-model="remission.tipo"
                        :rules="[required('Ingreso Salida')]"
                        required
                    >
                        <v-radio label="Ingreso" value="entrante" />
                        <v-radio label="Salida" value="saliente" />
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-divider />
            <h6>Vehiculo y Conductor</h6>
            <v-row>
                <v-col cols="12" lg="6" md="6" sm="12">
                    <v-autocomplete
                        v-model="remission.vehicle_id"
                        :filter="filterObjectVehicle"
                        :items="vehicles"
                        :search-input.sync="searchVehicle"
                        label="Elija un vehicle"
                        item-value="id"
                        cache-items
                        clearable
                        :rules="vehicleRules"
                    >
                        <!-- En caso que no encuentra -->
                        <template v-slot:no-data>
                            <div
                                class="d-flex flex-column justify-center align-center"
                            >
                                <h6>Vehiculo inexistente</h6>
                                <v-btn
                                    outlined
                                    @click="setDialogFormVehicle(true)"
                                    >Agregar</v-btn
                                >
                            </div>
                        </template>
                        <template slot="item" slot-scope="data">
                            <v-list-item-content>
                                <v-list-item-title
                                    >Marca: {{ data.item.marca_vehiculo }}
                                </v-list-item-title>
                                <v-list-item-subtitle
                                    >RUA:
                                    {{ data.item.rua }}</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </template>

                        <template slot="selection" slot-scope="data">
                            <v-list-item-content>
                                <v-list-item-title
                                    >Marca: {{ data.item.marca_vehiculo }}
                                </v-list-item-title>
                                <v-list-item-subtitle
                                    >RUA:
                                    {{ data.item.rua }}</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                    <v-autocomplete
                        v-model="remission.driver_id"
                        :filter="filterObjectDriver"
                        :items="drivers"
                        :search-input.sync="searchDriver"
                        label="Elija un Conductor"
                        item-value="id"
                        cache-items
                        clearable
                        :rules="driverRules"
                    >
                        <!-- En caso que no encuentra -->
                        <template v-slot:no-data>
                            <div
                                class="d-flex flex-column justify-center align-center"
                            >
                                <h5>Conductor inexistente</h5>
                                <v-btn @click="setDialogFormDriver(true)"
                                    >Agregar</v-btn
                                >
                            </div>
                        </template>
                        <template slot="item" slot-scope="data">
                            <v-list-item-content>
                                <v-list-item-title
                                    >Nombre: {{ data.item.nombre }}
                                </v-list-item-title>
                                <v-list-item-subtitle
                                    >CI:
                                    {{ data.item.ci }}</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </template>

                        <template slot="selection" slot-scope="data">
                            <v-list-item-content>
                                <v-list-item-title
                                    >Nombre: {{ data.item.nombre }}
                                </v-list-item-title>
                                <v-list-item-subtitle
                                    >CI:
                                    {{ data.item.ci }}</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
        </v-form>
    </article>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "../../../utils/validate";
export default {
    data: () => ({
        searchDriver: "",
        searchVehicle: "",
        vehicleRules: [(v) => !!v || "Vehiculo Obligatorio"],
        driverRules: [(v) => !!v || "Conductor Obligatorio"],
        valid: false,
        radioGroup: null,
        validRemissionNumber: false,
        required,
    }),
    computed: {
        ...mapState("driver", ["drivers"]),
        ...mapState("vehicle", ["vehicles"]),
        ...mapState("remission", ["remission"]),
    },
    watch: {
        "remission.tipo"(val) {
            this.validRemissionNumber = true;
            if (val === "entrante") {
                this.validRemissionNumber = false;
                this.remission.numero_remision = 0;
                return;
            }
        },
    },
    methods: {
        ...mapMutations("driver", ["setDialogFormDriver"]),
        ...mapMutations("vehicle", ["setDialogFormVehicle"]),

        filterObjectDriver(item, queryText, itemText) {
            return (
                item.nombre
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.ci
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },
        filterObjectVehicle(item, queryText, itemText) {
            return (
                item.marca_vehiculo
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.rua
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },
    },
};
</script>

<style scoped>
.textbox {
    width: 100px;
}
</style>
