<template>
    <v-card class="mx-4 my-6">
        <v-toolbar flat color="white">
            <v-toolbar-title>Lista Productos</v-toolbar-title>
        </v-toolbar>
        <v-data-table
            :items="productsStore"
            :headers="getHeaderAdmin"
            :search="searchProduct"
            show-expand
            :single-expand="singleExpand"
            :expanded="expanded"
            :loading="loading"
            sort-by="product"
            class="mx-4"
        >
            <template v-slot:[`item.precio_venta`]="{ item }">
                {{ item.precio_venta.toLocaleString("es-ES") }}
            </template>

            <template v-slot:[`item.latest_purchase_detail.precio_unitario`]="{ item }">
                {{ convertMoney(item.latest_purchase_detail?.precio_unitario || 0, getCurrencyCode) }}
            </template>

            <template v-slot:[`item.latest_purchase_detail.created_at`]="{ item }">
                {{ item.latest_purchase_detail ? convertDate(item.latest_purchase_detail.created_at) : '--' }}
            </template>

            <template v-slot:[`item.precio_mayorista`]="{ item }">
                {{ item.precio_mayorista.toLocaleString("es-ES") }}
            </template>

            <template v-slot:[`item.precio_descuento`]="{ item }">
                {{ item.precio_descuento.toLocaleString("es-ES") }}
            </template>

            <template v-slot:top>
                <!-- Encabezado Lista Tabla -->
                <!-- Buscar y Button Agregar -->

                <v-row align="center" justify="center">
                    <v-col>
                        <v-text-field
                            v-model="searchProduct"
                            label="Buscar Producto"
                            append-icon="mdi-magnify"
                            :clearable="true"
                        ></v-text-field>
                    </v-col>

                    <v-col>
                        <v-select
                            v-model="currency"
                            :items="quotes"
                            item-text="nombre"
                            item-value="quotation_id"
                            label="Moneda"
                        />
                    </v-col>

                    <v-col cols="12" lg="6" md="12" sm="12" class="text-right">
                        <v-btn
                            solo
                            small
                            color="primary"
                            @click="setDialogTransfer(true)"
                        >
                            Transferencia
                        </v-btn>

                        <v-btn
                            solo
                            small
                            color="primary"
                            @click="setDialogRemission(true)"
                        >
                            <v-icon left> mdi-archive-arrow-up-outline </v-icon>
                            Remision
                        </v-btn>

                        <v-btn
                            solo
                            small
                            color="primary"
                            @click="setDialogIncome(true)"
                        >
                            <v-icon left> mdi-plus </v-icon>
                            Ingreso
                        </v-btn>
                        <!--select de las monedas-->
                    </v-col>
                </v-row>
            </template>

            <template
                v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }"
            >
                <v-icon
                    v-if="!isExpanded && returnIsProductPerBox(item.product)"
                    class="mr-2"
                    :color="!isExpanded ? 'primary' : 'red'"
                    @click="handleClickConvert(), expand(!isExpanded)"
                >
                    {{ isExpanded ? "mdi-close" : icons.mdiArchiveSyncOutline }}
                </v-icon>
                <v-icon
                    v-if="!isExpanded"
                    :color="!isExpanded ? 'green' : 'red'"
                    @click="leerPrecioProducto(item), expand(!isExpanded)"
                    >{{ isExpanded ? "mdi-close" : "mdi-pencil" }}</v-icon
                >
                <v-icon v-else color="red" @click="expand(!isExpanded)"
                    >mdi-close</v-icon
                >
            </template>

            <!-- Columnas de precios -->
            <!-- <template v-slot:item.precio_compra_promedio="{ item }"> -->
            <template v-slot:[`item.precio_compra_promedio`]="{ item }">
                {{ convertMoney(item.precio_compra_promedio, getCurrencyCode) }}
            </template>

            <template v-slot:item.precio_venta="{ item }">
                {{ convertMoney(item.precio_venta, getCurrencyCode) }}
            </template>

            <template v-slot:item.precio_mayorista="{ item }">
                {{ convertMoney(item.precio_mayorista, getCurrencyCode) }}
            </template>

            <template v-slot:item.precio_descuento="{ item }">
                {{ convertMoney(item.precio_descuento, getCurrencyCode) }}
            </template>

            <template v-slot:expanded-item="{ headers, item }">
                <!-- Editar precio de productos -->
                <td
                    v-if="!convertProd"
                    :colspan="headers.length"
                    class="pa-0 pb-1"
                >
                    <v-card class="pa-2" tile elevation="8">
                        <v-row align="center">
                            <v-col cols="2">
                                <h5>Editar precio</h5>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    label="Precio venta"
                                    type="number"
                                    v-model="localProduct.precio_venta"
                                />
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    label="Precio Mayorista"
                                    type="number"
                                    v-model="localProduct.precio_mayorista"
                                />
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    label="Precio Descuento"
                                    type="number"
                                    v-model="localProduct.precio_descuento"
                                />
                            </v-col>
                            <v-spacer />
                            <v-col cols="2" class="text-center">
                                <v-btn
                                    color="blue"
                                    outlined
                                    @click="editarPrecioProducto(item)"
                                >
                                    Editar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </td>

                <!-- Convertir producto a paquete -->

                <td v-else :colspan="headers.length" class="pa-0 pb-1">
                    <v-card class="pa-2" tile elevation="8">
                        <v-row align="center">
                            <v-col cols="3">
                                <v-card elevation="1" class="pa-2">
                                    <div class="text-overline ml-4">
                                        {{
                                            !!item.product.caja_product_id
                                                ? "Caja"
                                                : "Producto"
                                        }}
                                    </div>
                                    <v-card-text>
                                        {{ item.product.nombre }}
                                    </v-card-text>
                                    <v-card-text>
                                        {{ item.product.codigo }}
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col
                                cols="3"
                                class="d-flex justify-center flex-column align-items-center"
                            >
                                <v-icon
                                    class="mb-2"
                                    size="36"
                                    color="primary"
                                    >{{
                                        icons.mdiArrowRightBoldBoxOutline
                                    }}</v-icon
                                >
                                <v-text-field
                                    type="number"
                                    solo
                                    placeholder="Cantidad"
                                    v-model="localProductBox.cantidad"
                                />
                            </v-col>

                            <v-col cols="3">
                                <v-card elevation="1" class="pa-2">
                                    <div class="text-overline ml-4">
                                        {{
                                            !item.product.caja_product_id
                                                ? "Caja"
                                                : "Producto"
                                        }}
                                    </div>

                                    <v-card-text>
                                        {{
                                            item.product?.can_convert_to_product
                                                ?.nombre
                                                ? item.product
                                                      ?.can_convert_to_product
                                                      ?.nombre
                                                : item.product
                                                      ?.can_convert_to_box
                                                      ?.nombre
                                        }}
                                    </v-card-text>
                                    <v-card-text>
                                        {{
                                            item.product?.can_convert_to_product
                                                ?.codigo
                                                ? item.product
                                                      ?.can_convert_to_product
                                                      ?.codigo
                                                : item.product
                                                      ?.can_convert_to_box
                                                      ?.codigo
                                        }}
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-spacer />
                            <v-col cols="2" class="text-center">
                                <v-btn
                                    color="blue"
                                    outlined
                                    @click="convertirProducto(item)"
                                >
                                    Convertir
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </td>
            </template>
        </v-data-table>
    </v-card>
</template>
<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import { convertMoney, convertDate } from "../../formatos";
import { mdiArchiveSyncOutline, mdiArrowRightBoldBoxOutline } from "@mdi/js";

export default {
    data: () => ({
        searchProduct: "",
        store: null,
        singleExpand: true,
        expanded: [],
        localProduct: {
            precio_venta: null,
            precio_descuento: null,
            precio_mayorista: null,
        },
        localProductBox: {
            cantidad: 0,
        },
        currency: 1,
        convertProd: false,
        icons: {
            mdiArchiveSyncOutline,
            mdiArrowRightBoldBoxOutline,
        },
    }),
    mounted() {
        this.getAllStores();
        this.getQuoteCurrency();
        this.currency = this.getQuote.quotation_id;
        this.store = parseInt(this.getLocaleStorageStore)
            ? parseInt(this.getLocaleStorageStore)
            : this.stores[0];
        this.getProductsStores({ dep: this.store });
    },

    computed: {
        ...mapState("deposito", [
            "productsStore",
            "headers",
            "headersAdmin",
            "stores",
            "dialogFormPriceEdit",
            "loading",
        ]),
        ...mapState("app", ["permisos"]),
        ...mapState("income", ["dialogIncome"]),
        ...mapGetters("deposito", ["getLocaleStorageStore"]),
        ...mapState("quote", ["quotes"]),
        ...mapGetters("quote", ["getQuote"]),
        getCurrencyCode() {
            return this.quotes.find((co) => co.currency_id === this.currency)
                .codigo;
        },

        getHeaderAdmin() {
            return this.findInArray(this.permisos, "stock_edit")
                ? this.headers
                : this.headersAdmin;
        },
    },

    watch: {
        currency(newVal, oldVal) {
            this.setLoading(true);
            this.restoreCurrencyConversion(oldVal); // se llama para revertir la conversion. y volver a la moneda principal
            this.currencyConversion(newVal); // se llama para hacer la conversion a la moneda seleccionada
        },
    },

    methods: {
        ...mapMutations("prod_box", [
            "setDialogConverProductBox",
            "setConvertProductBox",
        ]),
        ...mapActions("quote", ["getQuoteCurrency"]),
        ...mapActions("prod_box", ["convertProductPerBox"]),
        ...mapActions("deposito", [
            "getAllStores",
            "getProductsStores",
            "getProductPrice",
            "editProductPrice",
        ]),
        ...mapMutations("deposito", [
            "setDialogFormPriceEdit",
            "setDialogFormStore",
            "setLocaleStorageStore",
            "currencyConversion",
            "restoreCurrencyConversion",
            "setLoading",
        ]),
        ...mapMutations("income", ["setDialogIncome"]),
        ...mapMutations("transfer", ["setDialogTransfer"]),
        ...mapMutations("remission", ["setDialogRemission"]),

        editarPrecioProducto(item) {
            if (
                item.precio_mayorista === 0 &&
                this.localProduct.precio_mayorista === 0
            ) {
                this.localProduct.precio_mayorista =
                    this.localProduct.precio_venta;
            }
            if (
                item.precio_descuento === 0 &&
                this.localProduct.precio_descuento === 0
            ) {
                this.localProduct.precio_descuento =
                    this.localProduct.precio_venta;
            }

            this.localProduct.id = item.id;
            this.localProduct.dep = this.store;
            this.editProductPrice(this.localProduct);
            this.expanded = [];
        },
        leerPrecioProducto(item) {
            this.localProduct = {
                id: item.id,
                precio_venta: item.precio_venta.toFixed(3),
                precio_descuento: item.precio_descuento.toFixed(3),
                precio_mayorista: item.precio_mayorista.toFixed(3),
            };
            console.log(item);
            this.convertProd = false;
        },
        handleChangeStore(item) {
            this.setLocaleStorageStore(item);
            this.getProductsStores({ dep: item });
        },

        handleClickConvert() {
            this.convertProd = true;
        },

        convertirProducto(item) {
            const obj = {
                stock_id: item.id,
                cantidad: this.localProductBox.cantidad,
                is_box_to_product: !!item.product.caja_product_id
                    ? true
                    : false,
            };

            this.convertProductPerBox(obj);
            this.localProductBox = {
                cantidad: 0,
            };
            this.searchProduct = "";
            this.expanded = [];
        },
        returnIsProductPerBox(payload) {
            return (
                _.isObject(payload.can_convert_to_box) ||
                _.isObject(payload.can_convert_to_product)
            );
        },
        findInArray(myArray, item) {
            return !!myArray.find((el) => el == item);
        },

        convertMoney,
        convertDate,
    },
};
</script>
