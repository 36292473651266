var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"70%"},on:{"click:outside":function($event){return _vm.close()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.close()}},model:{value:(_vm.dialogShowDevolution),callback:function ($$v) {_vm.dialogShowDevolution=$$v},expression:"dialogShowDevolution"}},[_c('v-card',{attrs:{"loading":_vm.loadingShowDevolution}},[_c('template',{slot:"progress"},[_c('loading-component')],1),_vm._v(" "),_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Nota Credito # "+_vm._s(_vm.devolution.numero))])])],1),_vm._v(" "),_c('v-row',{attrs:{"justify":"start","align":"start","no-gutters":""}},[_c('v-col',[_c('v-list-item',[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('p',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v("\n                                Cliente\n                            ")]),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.devolution.client?.nombre_y_apellido))]),_vm._v(" "),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.devolution.client?.ruc))])],1)],1)],1)],1),_vm._v(" "),_c('v-col',[_c('v-list-item',[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('p',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v("\n                                Metodo\n                            ")]),_vm._v(" "),_c('v-list-item-title')],1)],1)],1)],1),_vm._v(" "),_c('v-col',[_c('v-list-item',[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('p',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v("\n                                Fecha\n                            ")]),_vm._v(" "),_c('v-list-item-title',[_vm._v("\n                                "+_vm._s(new Date(
                                        _vm.devolution.created_at
                                    ).toLocaleDateString("es-es", {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                    }))+"\n                            ")])],1)],1)],1)],1)],1),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",staticStyle:{"background-color":"#1976d2"}}),_vm._v(" "),_c('v-data-table',{staticClass:"mx-2",attrs:{"items":_vm.devolution.devolution_detail,"headers":_vm.showHeaderDevolution,"items-per-page":-1,"single-expand":false,"expanded":_vm.expanded,"item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:`item.precio_unitario`,fn:function({ item }){return [_vm._v("\n                "+_vm._s(_vm.convertMoney(
                        item.precio_unitario,
                        item.quotation?.currency?.codigo
                    ))+"\n            ")]}},{key:`item.total`,fn:function({ item }){return [_vm._v("\n                "+_vm._s(_vm.convertMoney(
                        item.precio_unitario * item.cantidad,
                        item.quotation?.currency?.codigo
                    ))+"\n            ")]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v("\n                    "+_vm._s(item.observacion)+"\n                ")])]}}],null,true)}),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"outlined":"","color":"red"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Cerrar ")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }