<template>
    <v-row justify="center">
        <v-dialog v-model="paymentDialog" persistent max-width="800px">
            <v-card style="overflow-x: hidden">
                <v-row class="mb-1 mx-2">
                    <v-col cols="12" md="4">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="text-h5 mb-2">
                                    <v-text-field
                                        max-width="100px"
                                        v-model="paymentReceipt.recibo_numero"
                                        label="Recibo #: "
                                    ></v-text-field>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col
                        cols="12"
                        md="8"
                        class="d-flex flex-column justify-end"
                    >
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-subtitle class="text-end"
                                    >Cliente:
                                    {{
                                        debtorData.nombre_y_apellido
                                    }}</v-list-item-subtitle
                                >
                                <v-list-item-subtitle class="text-end"
                                    >Ruc:
                                    {{ debtorData.ruc }}</v-list-item-subtitle
                                >
                                <v-list-item-subtitle class="text-end"
                                    >Dirección:
                                    {{
                                        debtorData.direccion
                                    }}</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>

                <template class="mx-7">
                    <v-data-table
                        :headers="headersPaymentDetail"
                        :items="paymentReceipt.details"
                        hide-default-footer
                        class="mx-5"
                    >
                        <!-- monto -->
                        <template v-slot:[`item.monto`]="{ item }">
                            {{
                                convertMoney(
                                    item.monto,
                                    debtorDetails[0].codigo
                                )
                            }}
                        </template>

                        <!-- Icono Eliminar  -->
                        <template v-slot:[`item.actions`]="{ index }">
                            <v-icon
                                small
                                color="red"
                                @click="showDialogEliminar(index)"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                    <template>
                        <div class="text-center">
                            <v-dialog
                                v-model="dialogAddMount"
                                width="550"
                                @click:outside="setDialogAddMount()"
                                @keydown.esc="setDialogAddMount()"
                            >
                                <v-card>
                                    <v-row class="mx-2 mt-2">
                                        <v-col>
                                            <v-select
                                                v-model="
                                                    nuevoDetalle.metodo_pago
                                                "
                                                label="Metodo de pago"
                                                :items="metodoPago"
                                                item-value="method"
                                                item-text="name"
                                            ></v-select>
                                        </v-col>
                                        <v-col>
                                            <vuetify-money
                                                v-model.number="
                                                    nuevoDetalle.monto
                                                "
                                                label="Monto"
                                                :options="vmOptions"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        class="mx-2"
                                        v-if="
                                            nuevoDetalle.metodo_pago !=
                                            'efectivo'
                                        "
                                    >
                                        <v-col>
                                            <v-text-field
                                                v-model="
                                                    nuevoDetalle.entidad_metodo_pago
                                                "
                                                label="Entidad de pago"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                                v-model="nuevoDetalle.codigo"
                                                label="Código"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="crearDetalle()"
                                        >
                                            Agregar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </div>
                    </template>
                </template>
                <template>
                    <v-btn
                        color="green lighten-2 text-white"
                        @click="setDialogAddMount"
                        class="mt-2 mx-2"
                    >
                        + monto
                    </v-btn>
                </template>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title
                            class="text-end text-h8 mb-2"
                            :class="
                                sumPaymentDetails < totalSelect
                                    ? 'red--text'
                                    : 'green--text'
                            "
                        >
                            Total
                            {{
                                convertMoney(
                                    sumPaymentDetails,
                                    debtorDetails[0].codigo
                                )
                            }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                            class="text-end text-h8 mb-2"
                            v-if="!isPayAmount"
                        >
                            Facturas
                            {{
                                convertMoney(
                                    totalSelect,
                                    debtorDetails[0].codigo
                                )
                            }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="btnCancelarDialogPaymentSale"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        v-if="isPayAmount"
                        color="blue darken-1"
                        text
                        :disabled="!(sumPaymentDetails > 0)"
                        @click="setDialogListSale"
                    >
                        Siguiente
                    </v-btn>
                    <v-btn
                        v-else
                        color="blue darken-1"
                        text
                        :disabled="!(sumPaymentDetails >= totalSelect)"
                        @click="createPayment()"
                    >
                        Pagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogEliminar" max-width="500" class="ma-0 pa-0">
            <v-alert
                class="bg-white"
                text
                outlined
                color="deep-orange"
                type="warning"
            >
                ¿Desea eliminar este ítem?
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="
                            eliminarItemDetail(index);
                            dialogEliminar = false;
                        "
                    >
                        Eliminar
                    </v-btn>
                </v-card-actions>
            </v-alert>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { convertMoney } from "../../formatos";
export default {
    data: () => ({
        dialog: false,
        dialogEliminar: false,
        index: 0,
    }),
    mounted() {
        this.getNumberReceipt();
    },
    computed: {
        ...mapState("payment", [
            "paymentDialog",
            "paymentReceipt",
            "metodoPago",
            "dialogAddMount",
            "nuevoDetalle",
            "totalSelect",
            "sumPaymentDetails",
            "isPayAmount",
            "headersPaymentDetail",
            "details",
        ]),
        ...mapState("debtor", ["debtorDetails", "debtorData"]),
        ...mapState("app", ["vmOptions"]),
    },
    methods: {
        ...mapMutations("payment", [
            "setNewDetail",
            "setDialogAddMount",
            "setDialogPayment",
            "setDialogListSale",
            "setIsPayAmount",
            "btnCancelarDialogPaymentSale",
            "setCurrencyId",
        ]),
        ...mapActions("payment", [
            "getNumberReceipt",
            "createPayment",
            "eliminarItemDetail",
        ]),
        convertMoney,
        showDialogEliminar(index) {
            this.dialogEliminar = true;
            this.index = index;
        },
        crearDetalle() {
            this.setCurrencyId(this.debtorDetails[0].currency_id);
            this.setNewDetail(this.nuevoDetalle);
        },
    },
};
</script>
