<template>
    <div class="mx-10">
        <template>
            <v-data-table
                :headers="creditorHeaders"
                :items="creditors"
                :item-key="random(1, 1000).toString()"
            >
                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                small
                                class="mr-2"
                                color="green"
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                    abrirDetalles(item.id, item.currency_id)
                                "
                            >
                                mdi-eye
                            </v-icon>
                        </template>
                        <span>Ver facturas</span>
                    </v-tooltip>
                </template>

                <template v-slot:[`item.total`]="{ item }">
                    {{ convertMoney(item.total, item.codigo) }}
                </template>
            </v-data-table>
        </template>
        <!-- <CreditorDetail /> -->
        <AvisoAperturaDialog/>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { convertMoney, random } from "../../formatos";
import AvisoAperturaDialog from "../AvisoAperturaDialog.vue";

export default {
    data: () => ({}),
    components: {
        // CreditorDetail,
        AvisoAperturaDialog,
    },
    mounted() {
        this.getCreditors();
    },
    computed: {
        ...mapState("purchase", ["creditors", "creditorHeaders"]),
    },
    methods: {
        ...mapActions("purchase", ["getCreditors"]),
        //...mapMutations("purchase", [""]),
        abrirDetalles(id, currency_id) {
            this.$router.push(`/creditor/${id}/currency/${currency_id}`);
        },
        random,
        convertMoney,
    },
};
</script>
