<template>
    <div>
        <v-row>
            <v-col cols="12" md="3">
                <v-card class="pa-5 ml-4">
                    <v-card-title>Filtros</v-card-title>
                    <v-divider class="mx-2"></v-divider>
                    <div class="ml-4 mt-n2 text-subtitle-1">
                        <v-text-field
                            label="N° de venta"
                            :loading="loading"
                            ref="input_number"
                            v-model="search.number"
                            @input="buscarPorNumero"
                        >
                        </v-text-field>
                    </div>
                    <div class="ml-4 mt-n2 text-subtitle-1">
                        <v-text-field
                            label="Cliente"
                            v-model="search.client"
                            @input="buscarPorClienteYProducto"
                        >
                        </v-text-field>
                    </div>
                    <div class="ml-4 mt-n2 text-subtitle-1">
                        <v-text-field
                            label="Producto"
                            v-model="search.product"
                            @input="buscarPorClienteYProducto"
                        >
                        </v-text-field>
                    </div>
                    <div class="ml-4 mt-2 text-center">
                        <v-btn @click="buscar" class="primary">Buscar</v-btn>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" md="9">
                <v-card class="pa-5 mr-4">
                    <v-row>
                        <v-col cols="12" md="8">
                            <v-card-title>Facturas</v-card-title>
                        </v-col>
                        <v-col
                            cols="12"
                            md="4"
                            class="d-flex flex-column justify-end"
                        >
                        </v-col>
                    </v-row>

                    <v-divider class="mx-2"></v-divider>
                    <router-view />
                </v-card>
            </v-col>
        </v-row>
        <template>
            <DialogShowSale />
        </template>
    </div>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import DialogShowSale from "../Sales/DialogShowSale.Component.vue";

export default {
    data: () => ({
        search: {
            number: null,
            client: null,
            product: null,
        },
        setTimeoutBuscador: null,
        loading: false,
    }),
    components: {
        DialogShowSale,
    },
    mounted() {
        setTimeout(() => {
            this.$refs.input_number.$refs.input.focus();
        });
    },
    computed: {},
    methods: {
        ...mapActions("devolution", ["searchSales"]),
        ...mapMutations("devolution", ["setSearch"]),
        buscarPorNumero() {
            if (this.search.client != null || this.search.product != null) {
                this.search.client = null;
                this.search.product = null;
            }
            this.setSearch(this.search);
            this.buscar();
        },
        buscarPorClienteYProducto() {
            if (this.search.number != null) this.search.number = null;
            this.setSearch(this.search);
            this.buscar();
        },
        buscar() {
            this.loading = true;
            if (this.setTimeoutBuscador) clearTimeout(this.setTimeoutBuscador);
            this.setTimeoutBuscador = setTimeout(() => {
                this.searchSales(this.search);
                this.loading = false;
            }, 300);
        },
    },
};
</script>

<style>
.container {
    height: 100%;
}
</style>
