<template>
    <v-card class="mx-4 my-6">
        <v-data-table
            :items="productsIncome"
            :headers="headersIncome"
            :search="searchProduct"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
            class="mx-4"
        >
            <template v-slot:top>
                <v-toolbar flat color="white" class="mt-6">
                    <v-toolbar-title>Lista de Ingresos </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-switch
                        v-model="singleExpand"
                        label="Expancion Unico"
                        class="mt-2"
                        @change="changeSingleExpand"
                    ></v-switch>
                </v-toolbar>
            </template>

            <template v-slot:[`item.store_input_detail`]="{ item }">
                {{ item.store_input_detail.length }}
            </template>

            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-card class="pa-4 ma-4">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>Producto</th>
                                        <th>Codigo</th>
                                        <th>Cantidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="product in item.store_input_detail"
                                        :key="product.id"
                                    >
                                        <td>
                                            {{ product.product.nombre }}
                                        </td>
                                        <td>
                                            {{ product.product.codigo }}
                                        </td>
                                        <td>{{ product.cantidad }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </td>
            </template>
        </v-data-table>
    </v-card>
</template>
<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
    data: () => ({
        searchProduct: "",
        expanded: [],
        singleExpand: false,
    }),

    computed: {
        ...mapState("deposito", ["productsIncome", "headersIncome"]),
    },

    methods: {
        ...mapActions("deposito", ["getIncomeProducts"]),

        changeSingleExpand() {
            this.expanded = [];
        },
    },
};
</script>

<style scoped>
.custom-highlight-row {
    background-color: cian;
}
</style>
