<template>
    <v-autocomplete
        v-model="localSelectedProduct"
        :items="productsRemissionStore"
        label="Buscar producto"
        return-object
        :loading="loadingSearchProductRemission"
        :search-input.sync="searchProduct"
        :filter="filterObjectProduct"
        @change="handleInput()"
        @keydown.enter="agregar()"
        @click="getRemissionProducts(deposito)"
        clearable
    >
        <!-- No Encuentra el producto -->
        <template v-slot:no-data>
            <div class="d-flex flex-column align-items-center pt-4">
                <h6>Producto inexistente</h6>
            </div>
        </template>

        <!--   Lista categoria de productos -->
        <template slot="item" slot-scope="data">
            <v-list-item-avatar tile>
                <img :src="data.item.img_url" />
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>{{
                    data.item.product.nombre
                }}</v-list-item-title>
                <v-list-item-subtitle>{{
                    data.item.product.codigo
                }}</v-list-item-subtitle>
                <v-list-item-subtitle
                    >Disponible:
                    {{ data.item.disponible }}</v-list-item-subtitle
                >
            </v-list-item-content>
        </template>

        <!-- Producto seleccionado  -->
        <template slot="selection" slot-scope="data">
            <v-list-item-content>
                <v-list-item-title>
                    {{
                        `${data.item.product.nombre} - ${data.item.product.codigo}`
                    }}
                </v-list-item-title>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    props: ["deposito", "select", "agregar"],
    model: {
        prop: "select",
        event: "blur",
    },
    data: () => ({
        searchProduct: "",
        localSelectedProduct: {},
    }),
    mounted() {
        this.localSelectedProduct = JSON.parse(JSON.stringify(this.select));
    },
    watch: {
        searchProduct(val) {
            this.getRemissionProducts(this.deposito);
        },
        localSelectedProduct(val) {
            if (val) {
                this.handleInput(val);
            }
        },
        select(val) {
            if (!val) {
                this.localSelectedProduct = null;
            } else {
                this.localSelectedProduct = this.select;
            }
        },
        deposito(val) {
            this.getRemissionProducts(val);
        },
    },
    computed: {
        ...mapState("remission", [
            "productsRemissionStore",
            "loadingSearchProductRemission",
        ]),
    },

    methods: {
        ...mapActions("product", ["buscarProductos", "getProducts"]),
        ...mapActions("remission", ["getRemissionProducts"]),

        handleInput() {
            this.$emit("blur", this.localSelectedProduct);
        },

        filterObjectProduct(item, queryText, itemText) {
            return (
                (item.producto &&
                    item.producto
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.codigo &&
                    item.codigo
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.descripcion &&
                    item.descripcion
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.marca &&
                    item.marca
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.categoria &&
                    item.categoria
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1)
            );
        },
    },
};
</script>

<style scoped></style>
