<template>
    <div class="mx-10">
        <v-data-table :items="drivers" :headers="headers" :search="search">
            <template v-slot:top>
                <!-- Encabezado Lista Tabla -->
                <!-- Buscar y Button Agregar -->

                <v-row align="center" justify="space-between">
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="search"
                            label="Buscar"
                            append-icon="mdi-magnify"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            color="primary"
                            @click="setDialogFormDriver(true)"
                        >
                            <v-icon> mdi-plus </v-icon>
                            Agregar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!-- Iconos Editar Y Eliminar  -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon @click="handlerEditDriver(item)">
                    <v-icon small color="blue"> mdi-pencil </v-icon>
                </v-btn>
                <v-btn icon @click="eliminarDriver(item)">
                    <v-icon small color="red"> mdi-delete </v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <DeleteDialog
            :dialog="dialogDeleteDriver"
            :setDialog="setDialogDeleteDriver"
            v-on:deleted="deleteDriver(driver)"
        />
    </div>
</template>

<script>
import DeleteDialog from "../DeleteDialog.Component.vue";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
    data: () => ({
        search: "",
    }),
    components: {
        DeleteDialog,
    },
    computed: {
        ...mapState("driver", [
            "drivers",
            "driver",
            "headers",
            "dialogDeleteDriver",
        ]),
    },
    methods: {
        ...mapMutations("driver", [
            "setDialogFormDriver",
            "setDialogDeleteDriver",
            "setDriver",
            "handlerEditDriver",
        ]),
        ...mapActions("driver", ["getDrivers", "deleteDriver"]),

        eliminarDriver(payload) {
            this.setDriver(payload);
            this.setDialogDeleteDriver(true);
        },
    },
    mounted() {
        this.getDrivers();
    },
};
</script>

<style></style>
