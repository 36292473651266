const state = {
    dialogIncome: false,
    income: {
        store_id: 0,
        details: [],
    },
    incomeProducts: [],
    headersIncome: [
        { text: "Producto", value: "nombre" },
        { text: "Cantidad", value: "cantidad" },
        { text: "Opciones", value: "actions", align: "center" },
    ],
};

const actions = {
    createIncomeStore({ commit, dispatch }, payload) {
        axios
            .post("/stock_income", payload)
            .then(() => {
                commit("CREATE_INCOME");
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
            })
            .then(() => {
                const dep = localStorage.getItem("deposito");
                dispatch(
                    "deposito/getProductsStores",
                    { dep: dep },
                    { root: true }
                );
                dispatch("deposito/getIncomeProducts", null, { root: true });
            })
            .catch((error) => {
                console.log(error.response.data);
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
};
const mutations = {
    setDialogIncome(state, payload) {
        state.dialogIncome = payload;
    },
    addIncomeProducts(state, product) {
        const index = state.incomeProducts.find(
            (p) => p.product_id === product.product_id
        );
        if (!index) {
            state.incomeProducts.push(product);
        } else {
            this.commit(
                "app/setSnackbarInfo",
                "Se ha repetido el producto, verifique la tabla"
            );
        }
    },

    editIncomeProducts(state, product) {
        const index = state.incomeProducts.findIndex(
            (p) => p.product_id === product.product_id
        );
        state.incomeProducts.splice(index, 1, product);
    },

    deleteIncomeProducts(state, product) {
        const { product_id } = product;
        const index = state.incomeProducts.findIndex(
            (p) => p.product_id === product_id
        );
        state.incomeProducts.splice(index, index >= 0 ? 1 : 0);
    },
    clearIncome(state, payload) {
        state.income = {
            store_id: 0,
            details: [],
        };
        state.incomeProducts = [];
    },
    CREATE_INCOME(state) {
        state.income = {
            store_id: 0,
            details: [
                {
                    product_id: 0,
                    cantidad: 0,
                },
            ],
        };
        state.incomeProducts = [];
        state.dialogIncome = false;
    },
};
const getters = {};

module.exports = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
