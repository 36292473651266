export const required = (propertyType) => {
    return (v) => !!v || `El campo ${propertyType} es obligatorio`;
};

export const positiveNumber = (propertyType) => {
    return (v) => v > 0 || `El campo ${propertyType} debe ser positivo`;
};
export const minNumber = (propertyType, minLength) => {
    return (v) =>
        !!(v && v >= minLength) ||
        `El campo ${propertyType} no debe ser memor a ${minLength}`;
};
export const maxNumber = (propertyType, maxLength) => {
    return (v) =>
        !!(v && v <= maxLength) ||
        `El campo ${propertyType} no debe ser mayor a ${maxLength}`;
};

export const requiredSelection = (propertyType) => {
    return (v) =>
        !!(v && v.length) || `El campo ${propertyType} debe ser obligatorio`;
};

export const requiredObject = (propertyType) => {
    return (v) =>
        _.isEmpty(v) || `El campo ${propertyType} debe ser obligatorio`;
};

export const minLength = ({ propertyType, minLength }) => {
    return (v) =>
        (v && v.length >= minLength) ||
        `El campo ${propertyType} no debe ser memor a ${minLength}`;
};

export const maxLength = (propertyType, maxLength) => {
    return (v) =>
        (v && v.length <= maxLength) ||
        `El campo ${propertyType} no debe ser mayor a ${maxLength}`;
};

export const validEmail = (propertyType) => {
    return (v) => /.+@.+\..+/.test(v) || "Ingrese E-mail valida";
};

export const positiveNumberAndZero = (propertyType) => {
    return (v) => v > -1 || `El campo ${propertyType} debe ser mayor a -1`;
};
