<template>
    <v-dialog
        v-model="dialogShowDevolution"
        @click:outside="close()"
        @keydown.esc="close()"
        width="70%"
    >
        <v-card :loading="loadingShowDevolution">
            <template slot="progress">
                <loading-component />
            </template>

            <v-toolbar color="primary" dark>
                <v-card-title>
                    <span class="text-h5"
                        >Nota Credito # {{ devolution.numero }}</span
                    >
                </v-card-title>
            </v-toolbar>

            <v-row justify="start" align="start" no-gutters>
                <v-col>
                    <v-list-item>
                        <v-list-item two-line>
                            <v-list-item-content>
                                <p class="text-subtitle-1 font-weight-medium">
                                    Cliente
                                </p>
                                <v-list-item-title>{{
                                    devolution.client?.nombre_y_apellido
                                }}</v-list-item-title>
                                <v-list-item-subtitle>{{
                                    devolution.client?.ruc
                                }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item>
                </v-col>
                <v-col>
                    <v-list-item>
                        <v-list-item two-line>
                            <v-list-item-content>
                                <p class="text-subtitle-1 font-weight-medium">
                                    Metodo
                                </p>
                                <v-list-item-title> </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item>
                </v-col>
                <v-col>
                    <v-list-item>
                        <v-list-item two-line>
                            <v-list-item-content>
                                <p class="text-subtitle-1 font-weight-medium">
                                    Fecha
                                </p>
                                <v-list-item-title>
                                    {{
                                        new Date(
                                            devolution.created_at
                                        ).toLocaleDateString("es-es", {
                                            year: "numeric",
                                            month: "short",
                                            day: "numeric",
                                        })
                                    }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item>
                </v-col>
            </v-row>

            <v-divider
                class="mx-4"
                style="background-color: #1976d2"
            ></v-divider>

            <v-data-table
                class="mx-2"
                :items="devolution.devolution_detail"
                :headers="showHeaderDevolution"
                :items-per-page="-1"
                :single-expand="false"
                :expanded.sync="expanded"
                item-key="id"
                show-expand
            >
                <template v-slot:[`item.precio_unitario`]="{ item }">
                    {{
                        convertMoney(
                            item.precio_unitario,
                            item.quotation?.currency?.codigo
                        )
                    }}
                </template>
                <template v-slot:[`item.total`]="{ item }">
                    {{
                        convertMoney(
                            item.precio_unitario * item.cantidad,
                            item.quotation?.currency?.codigo
                        )
                    }}
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        {{ item.observacion }}
                    </td>
                </template>
            </v-data-table>

            <v-card-actions>
                <v-spacer />
                <v-btn outlined color="red" @click="close()"> Cerrar </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { convertMoney } from "../../formatos";
import LoadingComponent from "../Loading.Component.vue";
export default {
    data() {
        return {
            convertMoney,
            expanded: [],
        };
    },
    components: {
        LoadingComponent,
    },

    mounted() {},

    computed: {
        ...mapState("devolution", [
            "devolution",
            "dialogShowDevolution",
            "showHeaderDevolution",
            "loadingShowDevolution",
        ]),
    },

    methods: {
        ...mapMutations("devolution", [
            "setDialogShowDevolution",
            "setDevolution",
        ]),

        close() {
            this.setDevolution({});
            this.setDialogShowDevolution(false);
        },
    },
};
</script>
