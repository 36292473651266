<template>
    <!-- Dialog ver Venta -->
    <v-dialog
        v-model="dialogShowOrder"
        max-width="1000px"
        @click:outside="closeDialog()"
        @keydown.esc="closeDialog()"
    >
        <v-card>
            <v-row class="mx-1" justify="space-between">
                <v-col cols="auto" md="auto">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="text-h5 pb-2">
                                <v-icon> mdi-account </v-icon>
                                {{ this.order.client?.nombre_y_apellido }}
                            </v-list-item-title>
                            <v-list-item-title class="text-h7 pb-2"
                                ><v-icon dense>mdi-domain</v-icon>
                                {{ this.order.client?.ruc }}
                            </v-list-item-title>
                            <v-list-item-title class="text-h7"
                                ><v-icon dense>mdi-calendar</v-icon>
                                {{ returnDate }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="auto" md="auto" align-self="end">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="text-h6 text-end mb-2">
                                Total:
                                {{
                                    convertMoney(
                                        returnTotalPrices,
                                        order.order_detail[0]?.codigo
                                    )
                                }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
            <v-row justify="center" class="pa-0 ma-0">
                <v-col class="pa-0" md="10"
                    ><v-divider class="info"></v-divider
                ></v-col>
            </v-row>

            <!-- Tabla mostrar compra-->
            <v-row class="p-0 m-0">
                <v-col cols="12" md="12">
                    <v-data-table
                        class="mx-1"
                        hide-default-footer
                        :headers="headerOrderShow"
                        :items="order?.order_detail"
                        :items-per-page="-1"
                    >
                        <!-- columna precio unitario-->
                        <template v-slot:[`item.precio_unitario`]="props">
                            {{
                                convertMoney(
                                    props.item?.precio_unitario,
                                    order.order_detail[0]?.codigo
                                )
                            }}
                        </template>

                        <!-- columna precio subtotal-->
                        <template v-slot:[`item.subtotal`]="props">
                            {{
                                convertMoney(
                                    props.item?.subtotal,
                                    order.order_detail[0]?.codigo
                                )
                            }}
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    color="red lighten-2"
                    text
                    class="ma-3"
                    outlined
                    @click="closeDialog()"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    :disabled="order.estado === 'Pendiente' ? false : true"
                    color="primary lighten-2"
                    text
                    class="ma-3"
                    outlined
                    @click="pedidoVenta()"
                >
                    Vender
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import { convertMoney } from "../../formatos";

export default {
    data: () => ({}),
    computed: {
        ...mapState("order", [
            "dialogShowOrder",
            "order",
            "headersProduct",
            "headerOrderShow",
            "orderProduct",
        ]),
        ...mapGetters("deposito", ["returnNameStore", "getLocaleStorageStore"]),

        localShowHeaders() {
            return this.headersProduct.slice(0, 6);
        },
        returnDate() {
            let date = this.order?.created_at;
            return new Date(date).toLocaleDateString("es-ES");
        },
        returnTotalPrices() {
            let suma = 0;
            if (this.orderProduct.length !== 0) {
                this.orderProduct?.forEach((product) => {
                    suma += parseFloat(product?.subtotal);
                });
            }
            return suma;
        },
    },
    methods: {
        ...mapMutations("order", ["setDialogShowOrder", "clearTableOrder"]),
        ...mapMutations("sale", ["setOrderPos"]),
        ...mapActions("client", ["searchClient"]),

        closeDialog() {
            this.clearTableOrder();
            this.setDialogShowOrder(false);
        },
        pedidoVenta() {
            if (this.order.client) {
                this.searchClient(this.order?.client.ruc);
            }

            const sale = {
                client: this.order?.client || null,
                saleProducts: this.order?.order_detail,
                order_header_id: this.order?.id,
                seller_id: this.order?.seller_id,
                currency_id: this.order?.order_detail[0].currency_id,
            };
            this.setDialogShowOrder(false);
            this.$router.push("/pos");
            this.setOrderPos(sale);
        },
        convertMoney,
    },
};
</script>
