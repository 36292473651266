<template>
    <v-card class="pa-5">
        <v-row>
            <v-col cols="10">
                <v-icon class="text-info" @click="goBack">mdi-arrow-left</v-icon>
            </v-col>
            <v-col cols="2">
                <v-switch
                    label="Expandir todos"
                    @change="panelExpandido"
                    v-model="expandirTodos"
                ></v-switch>
            </v-col>
        </v-row>
        
        
        <v-row justify="center">
            <v-col md="3" class="text-center">
                <v-card-subtitle class="h5 ma-0 pa-0">
                
                </v-card-subtitle>
            </v-col>
            
        </v-row>
        <v-row>
            <template>
                <v-container>

                    <!-- Paneles de expansión -->
                    <v-expansion-panels
                        v-model="panel"
                        multiple
                    >
                        <v-expansion-panel
                            v-for="(recibo, index) in paymentReceipt" 
                            :key="index"
                            :value="index"
                        >
                            <v-expansion-panel-header>
                                Pago: {{ convertMoney(sumarTotal(recibo.payment_receipt_detail), getCurrencyCode ) }}
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <v-card class="mb-10 elevation-5" >
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <strong>Nombre: {{ recibo.sale_header[0].client ? recibo.sale_header[0].client.nombre_y_apellido : '--' }}</strong>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <strong>RUC: {{  recibo.sale_header[0].client ? recibo.sale_header[0].client.ruc : '--' }}</strong>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="6" class="text-right">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <strong>Fecha: {{ convertDate(recibo.created_at) }}</strong>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <strong v-if="recibo.recibo_numero">Recibo N°: {{ recibo.recibo_numero }}</strong>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                        <v-row>
                                            <v-col class="d-flex justify-center" cols="12">
                                                <strong class="">Detalles del pago</strong> 
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col class="text-center" cols="3">
                                                <strong>Método de Pago</strong>
                                            </v-col>
                                            <v-col class="text-center" cols="3">
                                                <strong>Entidad</strong>
                                            </v-col>
                                            <v-col class="text-center" cols="3">
                                                <strong>Código transacción</strong>
                                            </v-col>
                                            <v-col class="text-center" cols="3">
                                                <strong>Monto</strong>
                                            </v-col>
                                        </v-row>
                                        <v-row v-for="detalle in recibo.payment_receipt_detail" :key="detalle.id">
                                            <v-col class="text-center" cols="3">{{ detalle.metodo_pago }}</v-col>
                                            <v-col class="text-center" cols="3">{{ detalle.entidad_metodo_pago }}</v-col>
                                            <v-col class="text-center" cols="3">{{ detalle.codigo }}</v-col>
                                            <v-col class="text-center" cols="3">{{ convertMoney(detalle.monto, getCurrencyCode ) }}</v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                        <v-row class="mt-3">
                                            <v-col class="d-flex justify-center" cols="12">
                                                <strong class="">Facturas afectadas</strong> 
                                            </v-col>
                                        </v-row> 
                                        <v-row>
                                            <v-col class="text-center" cols="3">
                                                <strong>Factura</strong>
                                            </v-col>
                                            <v-col class="text-center" cols="3">
                                                <strong>Fecha</strong>
                                            </v-col>
                                            <v-col class="text-center" cols="3">
                                                <strong>Condición</strong>
                                            </v-col>
                                            <v-col class="text-center" cols="3">
                                                <strong>Monto</strong>
                                            </v-col>
                                        </v-row>
                                        <v-row v-for="factura in recibo.sale_header" :key="factura.id">
                                            <v-col class="text-center" cols="3">{{ factura.factura_numero ? factura.factura_numero : '--' }}</v-col>
                                            <v-col class="text-center" cols="3">{{ convertDate(factura.created_at) }}.</v-col>
                                            <v-col class="text-center" cols="3">{{ factura.credito ? 'Crédito' : 'Contado'}}</v-col>
                                            <v-col class="text-center" cols="3">{{ convertMoney(factura.sale_detail[0].total, getCurrencyCode ) }}</v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-container>
            </template>
        </v-row>
    </v-card>
</template>


<script>
import {convertMoney, convertDate} from '../../../formatos.js'
import {mapActions, mapState} from 'vuex';
import ListPurchasesComponent from '../../Purchases/ListPurchases.Component.vue';
export default {
    data:() =>({
        expandirTodos: false, 
        estadosPaneles: [],
        panel:[]
    }),
    mounted(){
        this.getPaymentReceipt();
    },
    components:{
       ListPurchasesComponent
    },
    computed:{
        ...mapState("box", ["paymentReceipt"]),
        getCurrencyCode(){
            return this.paymentReceipt[0].payment_receipt_detail[0].quotation ? this.paymentReceipt[0].payment_receipt_detail[0].quotation.currency.codigo : 'PYG';
        }
    },
    methods: {
        ...mapActions("box", ["getPaymentReceipt"]),
        convertMoney,
        convertDate,
        goBack(){
            history.go(-1); 
            return false;
        },
        panelExpandido() {
            if(this.expandirTodos){
                this.paymentReceipt.forEach( (e, index) =>{
                    this.panel.push(index);
                });
            }else{
                this.panel=[];
            }
            
        },
        fechaFormateada(fecha) {
            const date = new Date(fecha);
            
            const dia = date.getDate().toString().padStart(2, '0');
            const mes = (date.getMonth() + 1).toString().padStart(2, '0'); // Los meses son base 0
            const año = date.getFullYear();
            const hora = date.getHours().toString().padStart(2, '0');
            const minutos = date.getMinutes().toString().padStart(2, '0');
            
            return `${dia}-${mes}-${año} ${hora}:${minutos}`;
        },
        sumarTotal(detalles){
            let sum = 0;
            detalles.forEach(detalle => {
                const monto = parseFloat(detalle.monto);
                sum += monto;
            });
            return sum;
        }
    },
};
</script>
