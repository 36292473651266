<template v-slot:activator="{ on, attrs }">
    <!-- Dialog ver Venta -->
    <v-dialog
        v-model="dialogCreditorDetail"
        max-width="1000px"
        @click:outside="setDialogCreditorDetail(false)"
        @keydown.esc="setDialogCreditorDetail(false)"
    >
        <v-card>
            <v-row class="mx-1" justify="space-between">
                <v-col cols="12" md="6" class="">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-subtitle class="font-weight-normal"
                                >Proveedor:
                                {{
                                    purchase.supplier.nombre === null
                                        ? ""
                                        : purchase.supplier.nombre
                                }}</v-list-item-subtitle
                            >
                            <v-list-item-subtitle class="font-weight-normal"
                                >Ruc:
                                {{
                                    purchase.supplier.ruc
                                }}</v-list-item-subtitle
                            >
                            <v-list-item-subtitle class="font-weight-normal"
                                >Fecha:
                                {{ purchase.fecha }}</v-list-item-subtitle
                            >
                            <v-list-item-subtitle class="font-weight-normal"
                                >Plazo:
                                {{ purchase.plazo }}</v-list-item-subtitle
                            >
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="6">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="text-h7 text-end mb-2">
                                Factura: {{ purchase.factura_numero }}
                            </v-list-item-title>
                            <v-list-item-title class="text-h6 text-end mb-2">
                                Total:
                                {{
                                    convertMoney(
                                        purchase.total,
                                        purchase.moneda?.codigo || "PYG"
                                    )
                                }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>

            <!-- Tabla mostrar compra-->
            <v-row class="p-0 m-0">
                <v-col cols="12" md="12">
                    <v-data-table
                        :headers="headersProductCreditorDetail"
                        :items="purchase.purchase_detail"
                        :items-per-page="-1"
                        hide-default-footer
                    >
                        <template v-slot:[`item.store_id`]="{ item }">
                            <span>{{ returnNameStore(item.store_id) }}</span>
                        </template>

                        <template v-slot:[`item.precio_unitario`]="{ item }">
                            <span>{{
                                convertMoney(
                                    item.precio_unitario,
                                    purchase.moneda?.codigo
                                )
                            }}</span>
                        </template>

                        <template v-slot:[`item.precio_descuento`]="{ item }">
                            <span>{{
                                convertMoney(
                                    item.precio_descuento,
                                    purchase.moneda?.codigo
                                )
                            }}</span>
                        </template>

                        <template v-slot:[`item.precio_venta`]="{ item }">
                            <span>{{
                                convertMoney(
                                    item.precio_venta,
                                    purchase.moneda?.codigo
                                )
                            }}</span>
                        </template>

                        <template v-slot:[`item.precio_mayorista`]="{ item }">
                            <span>{{
                                convertMoney(
                                    item.precio_mayorista,
                                    purchase.moneda?.codigo
                                )
                            }}</span>
                        </template>

                        <template v-slot:[`item.subtotal`]="{ item }">
                            <span>{{
                                convertMoney(
                                    item.subtotal,
                                    purchase.moneda?.codigo
                                )
                            }}</span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red lighten-2"
                    text
                    class="ma-3"
                    @click="setDialogCreditorDetail(false)"
                >
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import { convertMoney } from "../../formatos";

export default {
    data: () => ({
        dialog: true,
    }),
    watch: {
        dialogCreditorDetail(val) {
            if (!val) {
                this.clearPurchase();
            }
        },
    },
    computed: {
        ...mapState("purchase", [
            "headersProductCreditorDetail",
            "purchase",
            "dialogCreditorDetail",
            "edit",
        ]),
        ...mapState("deposito", ["stores"]),
        ...mapGetters("deposito", ["returnNameStore"]),
    },
    methods: {
        ...mapMutations("purchase", [
            "setDialogCreditorDetail",
            "clearPurchase",
        ]),
        ...mapActions("deposito", ["getAllStores"]),
        convertMoney,
    },
    mounted() {
        this.getAllStores();
    },
};
</script>
