<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <article>
            <v-autocomplete
                v-model="remission.recipient_id"
                :filter="filterObject"
                :items="recipients"
                :search-input.sync="searchRecipient"
                label="Elija un Destinatario"
                item-value="id"
                cache-items
                clearable
                :rules="recipientRules"
            >
                <!-- En caso que no encuentra -->
                <template v-slot:no-data>
                    <div class="d-flex flex-column justify-center align-center">
                        <h6>Destinatario inexistente</h6>
                        <v-btn outlined @click="setDialogFormRecipient(true)"
                            >Agregar</v-btn
                        >
                    </div>
                </template>
                <template slot="item" slot-scope="data">
                    <v-list-item-content>
                        <v-list-item-title
                            >Destinatario: {{ data.item.nombre }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                            >Ruc: {{ data.item.ruc }}</v-list-item-subtitle
                        >
                    </v-list-item-content>
                </template>
                <template slot="selection" slot-scope="data">
                    <v-list-item-content>
                        <v-list-item-title
                            >Destinatario: {{ data.item.nombre }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                            >Ruc: {{ data.item.ruc }}</v-list-item-subtitle
                        >
                    </v-list-item-content>
                </template>
            </v-autocomplete>
        </article>
    </v-form>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
    data: () => ({
        searchRecipient: "",
        valid: false,
        recipientRules: [(v) => !!v || "Destinatario obligatorio"],
    }),
    computed: {
        ...mapState("recipient", ["recipients"]),
        ...mapState("remission", ["remission"]),
    },
    methods: {
        ...mapMutations("recipient", ["setDialogFormRecipient"]),

        filterObject(item, queryText, itemText) {
            return (
                item.nombre
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.ruc
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },
    },
};
</script>

<style></style>
