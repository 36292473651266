<template>
    <v-dialog v-model="dialogListSale" persistent width="800">
        <v-card>
            <v-card  style="overflow-x: hidden; overflow-y: hidden;">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-card-title>Seleccione las ventas</v-card-title>
                    </v-col>
                    <v-col
                        cols="12"
                        md="6"
                        class="d-flex flex-column justify-end"
                    >
                        <v-card-title class="justify-end "
                            :class="maxSelect == selectedSaleDetail.length ? 'red--text' : ''"
                            >{{
                                maxSelect == selectedSaleDetail.length ? 'Pendiente ' + convertMoney(totalSelect, debtorDetails[0].codigo) : convertMoney(totalSelect, debtorDetails[0].codigo)
                            }}
                        </v-card-title
                        >
                        <v-card-subtitle class="text-end"
                            >Monto Total</v-card-subtitle
                        >
                    </v-col>
                </v-row>
            </v-card>
            <template>
            <v-item-group 
                multiple
                :max="maxSelect"
                v-model="selectedSaleDetail"
                item-value="method"
            >
                <v-container>
                <v-row
                    v-for="(details, i) in debtorDetails"
                    :key="i"
                    class="mt-2 mx-4"
                >
                    <v-col
                    >
                    <v-item v-slot="{ active, toggle }">
                        <v-card
                        :color="active ? 'primary' : ''"
                        class="d-flex align-center"
                        height="50"
                        @click="toggle"
                        v-on:click="setTotal({debtorDetails, selectedSaleDetail})"
                        >
                            <v-card-text :class="active ? 'white--text' : ''">
                                <div >Nº venta</div>
                                <h6>
                                    {{ details.numero }}
                                </h6>
                            </v-card-text>
                            <v-card-text :class="active ? 'white--text' : ''">
                                <div >Nº factura</div>
                                <h6>
                                    {{ details.factura_numero }}
                                </h6>
                            </v-card-text>
                            <v-card-text :class="active ? 'white--text' : ''">
                                <div class="subtitle">Monto</div>
                                <h6>
                                    {{
                                        convertMoney(details.sale_detail[0].monto_factura, details.codigo)
                                    }}
                                    Gs
                                </h6>
                            </v-card-text>
                            <v-card-text :class="active ? 'white--text' : ''">
                            <div class="subtitle">Fecha</div>
                                <h6>
                                    {{
                                        new Date(details.created_at).toLocaleDateString(
                                            "es-PY"
                                        )
                                    }}
                                </h6>
                            </v-card-text>
                        </v-card>
                    </v-item>
                    </v-col>
                </v-row>
                </v-container>
            </v-item-group>
            </template>

            <v-divider></v-divider>

            <v-card-actions>
            <v-spacer></v-spacer>
             <v-btn
                text
                @click="btnCancelarDialogListSale "
            >
                Cancelar
            </v-btn>
            <v-btn
                v-if="isPayAmount"
                color="primary"
                text
                :disabled="!(selectedSaleDetail.length > 0)"
                @click="btnPressed"
            >
                Pagar
            </v-btn>
            <v-btn
                v-else
                color="primary"
                text
                :disabled="!(selectedSaleDetail.length > 0)"
                @click="btnPressed"
            >
                Siguiente
            </v-btn>
            </v-card-actions>
        </v-card>
        
    </v-dialog> 
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { convertMoney } from "../../formatos";

export default {
    data: () => ({
    }),
    computed: {
        ...mapState("sale", ["sale", "headersProduct", "showSale",  "debtorDetails"]),
        ...mapState("debtor", ["debtorDetails"]),
        ...mapState("payment", ["paymentDialog", "paymentReceipt","totalSelect","dialogListSale", "maxSelect", "isPayAmount", "selectedSaleDetail"]),
        ...mapGetters("payment", ["getSelectedSaleDetail"]),
        ...mapGetters("sale", [
            "getTotalPrice",
            "getSaleProductCount",
            "getCountSale",
        ]),
        filteredHeaders() {
            return this.headersProduct.filter(
                (header) => header.text !== "Opciones"
            );
        },
        selectedSaleDetail:{
            get(){return this.getSelectedSaleDetail},
            set(newVal){
                this.setSelectedSaleDetail(newVal);
            }
        }
    },
    methods: {
        ...mapMutations("sale", ["setDialog"]),
        ...mapMutations("payment",["setDialogPayment","setDialogListSale","setTotal","setSelectedSaleDetail", "btnCancelarDialogListSale"]),
        ...mapActions("payment", ["createPayment",]),
        isCredito(cred) {
            return cred === 1 ? "Credito" : "Contado";
        },
        returnDate(date) {
            return new Date(date).toLocaleDateString("es-ES");
        },
        btnPressed(){
            if(this.isPayAmount){
                this.createPayment();
            }
            this.setDialogPayment();
            //aca cerrar el dialog de este component
        },
        convertMoney
    },
};
</script>
