import axios from "axios";

const state = {
    impresion: null,
    parameter: {},
    parameters: [],
    isEditParams: false,
    dialogFormParams: false,
    dialogViewParams: false,
    dialogDeleteParams: false,
    beforeEditParams: {},
    headerParameter: [
        {
            text: "Clave",
            value: "clave",
        },
        { text: "Valor", value: "valor" },
        { text: "Opciones", value: "actions", sortable: false },
    ],
    logoUrl: "",
};
const actions = {
    // LLAMADA AL API DE PARAMETROS //
    async getParameters({ commit, state }) {
        await axios
            .get("/parameters")
            .then((res) => {
                commit("GET_PARAMS", res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    },
    async getOneParameter({ commit, state }, payload) {
        await axios
            .get(`/parameters/${payload}`)
            .then((res) => {
                commit("GET_ONE_PARAMS", res.data);
                commit("setBeforeEdit", res.data);
            })
            .catch((err) => {
                commit("ERR_ONE_PARAM");
                return err;
            });
    },
    async getOneParameterObj({ commit, state }, payload) {
        await axios
            .get(`/parameters/${payload}/object`)
            .then((res) => {
                commit("GET_ONE_PARAMS_OBJ", res.data);
            })
            .catch((err) => {
                commit("ERR_ONE_PARAM");
                return err;
            });
    },
    createParameters({ commit, state, dispatch }, payload) {
        const { clave, valor } = payload;

        axios
            .post(`/parameters/${clave}`, JSON.parse(valor.trim()))
            .then((res) => {
                commit("CREATE_PARAMS");
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                localStorage.setItem(clave, valor);
            })
            .catch((err) => {
                return err;
            })
            .finally(() => {
                dispatch("getParameters");
            });
    },
    editParameters({ commit, state, dispatch }, payload) {
        const { valor } = payload;
        // TODO hay que hacer los cambios con la ruta anterior

        let url = "",
            newVal;
        if (state.isEditParams) {
            url = state.beforeEditParams.clave;
            newVal = JSON.parse(valor);
        } else {
            url = payload.clave;
            newVal = JSON.stringify(valor);
            newVal = JSON.parse(newVal);
        }

        return axios
            .put(`parameters/${url}`, newVal)
            .then((res) => {
                commit("EDIT_PARAMS");
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
            })
            .catch((err) => {
                return err;
            })
            .finally(() => {
                dispatch("getParameters");
            });
    },
    deleteParameters({ commit, state, dispatch }, payload) {
        return axios
            .delete(`parameters/${payload}`)
            .then((res) => {
                commit("DELETE_PARAMS");
                dispatch("getParameters");
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
            })
            .catch((err) => {
                return err;
            });
    },

    // ----------------- //

    createPrintParams({ commit, state }, paylaod) {
        return axios
            .post(`parameters/print`, paylaod)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                return err;
            });
    },
    
    getLogoUrl({commit}){
        axios.get('parameters/logo').then((res) => {
            commit('SET_LOGO_URL', res.data);
        }).catch((err) => {
            console.log(err);
        });
    }
};

const mutations = {
    setDialogFormParams(state, payload) {
        state.dialogFormParams = payload;
    },
    setDialogDeleteParams(state, payload) {
        state.dialogDeleteParams = payload;
    },
    setBeforeEdit(state, payload) {
        state.beforeEdit = payload;
    },
    GET_PRINT_PARAMS(state, payload) {
        state.impresion = payload;
    },
    GET_PARAMS(state, payload) {
        state.parameters = payload;
    },
    DELETE_PARAMS(state) {
        state.parameter = {};
        state.dialogDeleteParams = false;
    },
    CREATE_PARAMS(state) {
        state.parameter = {};
        state.dialogFormParams = false;
    },
    EDIT_PARAMS(state) {
        state.parameter = {};
        state.dialogFormParams = false;
        state.isEditParams = false;
    },
    GET_ONE_PARAMS(state, payload) {
        state.parameter = { ...payload };
        state.beforeEditParams = { ...payload };
    },
    GET_ONE_PARAMS_OBJ(state, paylaod) {
        state.parameter = { ...paylaod };
    },
    ERR_ONE_PARAM(state) {
        state.parameter = null;
        state.parameters = [];
    },
    handlerClose(state) {
        state.parameter = {};
        state.dialogFormParams = false;
        state.isEditParams = false;
        state.beforeEditParams = {};
    },
    handlerEdit(state) {
        state.dialogFormParams = true;
        state.isEditParams = true;
        state.beforeEditParams = {};
    },
    handlerView(state) {
        state.dialogViewParams = true;
    },
    handlerDelete(state, payload) {
        state.dialogDeleteParams = true;
        state.parameter = payload;
    },
    SET_LOGO_URL(state, payload) {
        const json = JSON.parse(payload.valor);
        state.logoUrl = json.ruta;
    },
};
const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
