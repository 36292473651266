<template>
    <v-card class="mx-4 my-6">
        <v-toolbar flat color="white">
            <v-toolbar-title>Baja de Productos </v-toolbar-title>
        </v-toolbar>
        <v-row class="px-4" align="center" justify="space-between">
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="localSearch"
                    label="Busqueda general"
                    append-icon="mdi-magnify"
                    @input="localSetSearch()"
                ></v-text-field>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" @click="setDialog" small>
                    <v-icon> mdi-plus </v-icon>
                    Agregar
                </v-btn>
            </v-col>
        </v-row>

        <!-- Lista de Marcas -->
        <ListDrop />

        <template>
            <FormDrop />
        </template>
    </v-card>

    <!-- Para Dialogo Editar y Nuevo  -->
    <!-- Dialogo Para Form Marca llamado de Form Product -->
</template>
<script>
import FormDrop from "./FormDrop.Component.vue";
import ListDrop from "./ListDrop.Component.vue";

import { mapState, mapMutations, mapActions } from "vuex";

export default {
    data: () => ({
        localSearch: "",
    }),
    components: {
        FormDrop,
        ListDrop,
    },
    computed: {
        ...mapState("app", ["canCreate"]),
    },
    methods: {
        ...mapMutations("drop_product", ["setSearch", "setDialog"]),

        localSetSearch() {
            this.setSearch(this.localSearch);
        },
    },
};
</script>
