var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.paymentDialog),callback:function ($$v) {_vm.paymentDialog=$$v},expression:"paymentDialog"}},[_c('v-card',{staticStyle:{"overflow-x":"hidden"}},[_c('v-row',{staticClass:"mb-1 mx-2"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5 mb-2"},[_c('v-text-field',{attrs:{"max-width":"100px","label":"Recibo #: "},model:{value:(_vm.paymentReceipt.recibo_numero),callback:function ($$v) {_vm.$set(_vm.paymentReceipt, "recibo_numero", $$v)},expression:"paymentReceipt.recibo_numero"}})],1)],1)],1)],1),_vm._v(" "),_c('v-col',{staticClass:"d-flex flex-column justify-end",attrs:{"cols":"12","md":"8"}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"text-end"},[_vm._v("Cliente:\n                                "+_vm._s(_vm.debtorData.nombre_y_apellido))]),_vm._v(" "),_c('v-list-item-subtitle',{staticClass:"text-end"},[_vm._v("Ruc:\n                                "+_vm._s(_vm.debtorData.ruc))]),_vm._v(" "),_c('v-list-item-subtitle',{staticClass:"text-end"},[_vm._v("Dirección:\n                                "+_vm._s(_vm.debtorData.direccion))])],1)],1)],1)],1),_vm._v(" "),[_c('v-data-table',{staticClass:"mx-5",attrs:{"headers":_vm.headersPaymentDetail,"items":_vm.paymentReceipt.details,"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.monto`,fn:function({ item }){return [_vm._v("\n                        "+_vm._s(_vm.convertMoney(
                                item.monto,
                                _vm.debtorDetails[0].codigo
                            ))+"\n                    ")]}},{key:`item.actions`,fn:function({ index }){return [_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.showDialogEliminar(index)}}},[_vm._v("\n                            mdi-delete\n                        ")])]}}],null,true)}),_vm._v(" "),[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"550"},on:{"click:outside":function($event){return _vm.setDialogAddMount()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.setDialogAddMount()}},model:{value:(_vm.dialogAddMount),callback:function ($$v) {_vm.dialogAddMount=$$v},expression:"dialogAddMount"}},[_c('v-card',[_c('v-row',{staticClass:"mx-2 mt-2"},[_c('v-col',[_c('v-select',{attrs:{"label":"Metodo de pago","items":_vm.metodoPago,"item-value":"method","item-text":"name"},model:{value:(
                                                _vm.nuevoDetalle.metodo_pago
                                            ),callback:function ($$v) {_vm.$set(_vm.nuevoDetalle, "metodo_pago", $$v)},expression:"\n                                                nuevoDetalle.metodo_pago\n                                            "}})],1),_vm._v(" "),_c('v-col',[_c('vuetify-money',{attrs:{"label":"Monto","options":_vm.vmOptions},model:{value:(
                                                _vm.nuevoDetalle.monto
                                            ),callback:function ($$v) {_vm.$set(_vm.nuevoDetalle, "monto", _vm._n($$v))},expression:"\n                                                nuevoDetalle.monto\n                                            "}})],1)],1),_vm._v(" "),(
                                        _vm.nuevoDetalle.metodo_pago !=
                                        'efectivo'
                                    )?_c('v-row',{staticClass:"mx-2"},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Entidad de pago"},model:{value:(
                                                _vm.nuevoDetalle.entidad_metodo_pago
                                            ),callback:function ($$v) {_vm.$set(_vm.nuevoDetalle, "entidad_metodo_pago", $$v)},expression:"\n                                                nuevoDetalle.entidad_metodo_pago\n                                            "}})],1),_vm._v(" "),_c('v-col',[_c('v-text-field',{attrs:{"label":"Código"},model:{value:(_vm.nuevoDetalle.codigo),callback:function ($$v) {_vm.$set(_vm.nuevoDetalle, "codigo", $$v)},expression:"nuevoDetalle.codigo"}})],1)],1):_vm._e(),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.crearDetalle()}}},[_vm._v("\n                                        Agregar\n                                    ")])],1)],1)],1)],1)]],_vm._v(" "),[_c('v-btn',{staticClass:"mt-2 mx-2",attrs:{"color":"green lighten-2 text-white"},on:{"click":_vm.setDialogAddMount}},[_vm._v("\n                    + monto\n                ")])],_vm._v(" "),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-end text-h8 mb-2",class:_vm.sumPaymentDetails < _vm.totalSelect
                                ? 'red--text'
                                : 'green--text'},[_vm._v("\n                        Total\n                        "+_vm._s(_vm.convertMoney(
                                _vm.sumPaymentDetails,
                                _vm.debtorDetails[0].codigo
                            ))+"\n                    ")]),_vm._v(" "),(!_vm.isPayAmount)?_c('v-list-item-subtitle',{staticClass:"text-end text-h8 mb-2"},[_vm._v("\n                        Facturas\n                        "+_vm._s(_vm.convertMoney(
                                _vm.totalSelect,
                                _vm.debtorDetails[0].codigo
                            ))+"\n                    ")]):_vm._e()],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.btnCancelarDialogPaymentSale}},[_vm._v("\n                    Cancelar\n                ")]),_vm._v(" "),(_vm.isPayAmount)?_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":!(_vm.sumPaymentDetails > 0)},on:{"click":_vm.setDialogListSale}},[_vm._v("\n                    Siguiente\n                ")]):_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":!(_vm.sumPaymentDetails >= _vm.totalSelect)},on:{"click":function($event){return _vm.createPayment()}}},[_vm._v("\n                    Pagar\n                ")])],1)],2)],1),_vm._v(" "),_c('v-dialog',{staticClass:"ma-0 pa-0",attrs:{"max-width":"500"},model:{value:(_vm.dialogEliminar),callback:function ($$v) {_vm.dialogEliminar=$$v},expression:"dialogEliminar"}},[_c('v-alert',{staticClass:"bg-white",attrs:{"text":"","outlined":"","color":"deep-orange","type":"warning"}},[_vm._v("\n            ¿Desea eliminar este ítem?\n            "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.eliminarItemDetail(_vm.index);
                        _vm.dialogEliminar = false;}}},[_vm._v("\n                    Eliminar\n                ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }