<template>
    <v-container>
        <v-card>
            <v-card-title>Configuración General</v-card-title>
            <v-divider></v-divider>

            <v-card-title>Facturacion</v-card-title>
            <v-list-item style="justify-content: center">
                <template>
                    <v-list-item-action>
                        <v-switch v-model="ticket" />
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title
                            >Realizar facturacion mediante
                            ticket</v-list-item-title
                        >
                    </v-list-item-content>
                </template>
            </v-list-item>
            <v-list-item style="justify-content: center">
                <template>
                    <v-list-item-action>
                        <v-switch v-model="isInvoiceDoble" />
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title
                            >Realizar facturacion doble</v-list-item-title
                        >
                        <v-list-item-subtitle
                            >En caso que este desactivada realiza facturacion
                            triple</v-list-item-subtitle
                        >
                    </v-list-item-content>
                </template>
            </v-list-item>
            <v-list-item style="justify-content: center">
                <template>
                    <v-list-item-action>
                        <v-select v-model="quote" :items="quotes" return-object>
                            <template slot="item" slot-scope="data">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ data.item.nombre }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </template>

                            <template slot="selection" slot-scope="data">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ data.item.nombre }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-select>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title
                            >Selecionar moneda por defecto</v-list-item-title
                        >
                    </v-list-item-content>
                </template>
            </v-list-item>
            <v-divider></v-divider>
            <v-card-title>Impresion</v-card-title>
            <v-card-text>
                <ConfigImpresionComponent />
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ConfigImpresionComponent from "./ConfigImpresion.Component.vue";
export default {
    data: () => ({
        ticket: null,
        isInvoiceDoble: null,
        quote: null,
    }),
    components: {
        ConfigImpresionComponent,
    },
    mounted() {
        this.getQuoteCurrency();
        this.ticket = JSON.parse(localStorage.getItem("ticket")).data;
        this.isInvoiceDoble = JSON.parse(localStorage.getItem("invoice"));
        this.quote = JSON.parse(localStorage.getItem("quote"));

        if (this.ticket === null) {
            localStorage.setItem("ticket", JSON.stringify({ data: true }));
        }
        if (this.isInvoiceDoble === null) {
            this.isInvoiceDoble = true;
            localStorage.setItem("invoice", true);
        }
    },
    watch: {
        ticket(val) {
            localStorage.setItem("ticket", JSON.stringify({ data: val }));
        },
        isInvoiceDoble(val) {
            localStorage.setItem("invoice", val);
        },
        quote(val) {
            localStorage.setItem("quote", JSON.stringify(val));
        },
    },
    computed: {
        ...mapState("quote", ["quotes"]),
    },
    methods: {
        ...mapActions("quote", ["getQuoteCurrency"]),
        ...mapActions("config", ["getOneParameterObj"]),
    },
};
</script>
