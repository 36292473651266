<template>
    <v-row justify="center">
        <v-dialog v-model="dialogFormDriver" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ returnTitleForm }} Conductor</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="driver.nombre"
                                        label="Nombre Conductor"
                                        :rules="nombreRules"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="driver.ci"
                                        label="Cedula de Identidad"
                                        placeholder="xxxxxxx"
                                        :rules="ciRules"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="handlerClose()">
                        Cerrar
                    </v-btn>
                    <v-btn :color="returnColorBtn" text @click="crearDriver()">
                        {{ returnBtnForm }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
    data: () => ({
        valid: true,
        nombreRules: [(v) => !!v || "Marca es obligatorio"],
        ciRules: [(v) => !!v || "Chapa es obligatorio"],
    }),
    computed: {
        ...mapState("driver", ["driver", "isEditDriver", "dialogFormDriver"]),

        returnTitleForm() {
            return this.isEditDriver ? "Editar" : "Agregar";
        },
        returnBtnForm() {
            return this.isEditDriver ? "Editar" : "Guardar";
        },
        returnColorBtn() {
            return this.isEditDriver ? "green darken-1" : "blue darken-1";
        },
    },
    methods: {
        ...mapMutations("driver", ["setDialogFormDriver", "handlerClose"]),
        ...mapActions("driver", ["createDriver", "editDriver"]),

        crearDriver() {
            if (this.$refs.form.validate()) {
                if (this.isEditDriver) {
                    this.editDriver(this.driver);
                } else {
                    this.createDriver(this.driver);
                }
            }
        },
    },
    mounted() {},
};
</script>

<style></style>
