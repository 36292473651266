<template v-slot:top>
    <div>
        <v-card class="my-10">
            <v-list-item class="">
                <v-card
                    class="mt-n7"
                    color="primary"
                    elevation="10"
                    min-height="80"
                    min-width="80"
                >
                    <v-icon class="ma-5" large dark>mdi-book</v-icon>
                </v-card>

                <v-list-item-title>
                    <div class="overline mx-10 mt-5">
                        <h4>Lista Pedido</h4>
                    </div>
                </v-list-item-title>
            </v-list-item>

            <v-list-item>
                <v-list-item-content>
                    <v-card-text>
                        <v-card-actions> </v-card-actions>
                    </v-card-text>
                </v-list-item-content>
            </v-list-item>
            <!-- Lista de Marcas -->
            <ListOrder />

            <ShowOrder v-if="dialogShowOrder" />
        </v-card>
    </div>
</template>
<script>
import { mapState } from "vuex";
import ListOrder from "./ListOrder.Component.vue";
import ShowOrder from "./ShowOrder.Component.vue";

export default {
    data: () => ({}),

    components: {
        ListOrder,
        ShowOrder,
    },
    computed: {
        ...mapState("order", ["dialogShowOrder"]),
    },
    methods: {},
};
</script>
