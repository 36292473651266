<template>
    <v-fade-transition mode="out-in">
        <router-view />
    </v-fade-transition>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    destroyed() {
        clearInterval(this.sessionCheckInterval);
    },

    async mounted() {
        clearInterval(this.sessionCheckInterval);
        this.sessionCheckInterval = setInterval(
            this.checkSession,
            120 * 60 * 1000
        ); //comprobar sesion

        await this.getParameters();

        this.parameters.forEach((param) => {
            localStorage.setItem(param.clave, param.valor);
        });
    },
    computed: {
        ...mapState("config", ["parameters"]),
    },

    methods: {
        ...mapActions("app", ["checkSession"]),

        ...mapActions("config", ["getParameters"]),
    },
};
</script>
