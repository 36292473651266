<!-- Dialog General para eliminar -->
<template>
    
    <v-dialog v-model="dialog" max-width="500" >
        <v-alert class="bg-white ma-0 pa-3"
            text
            outlined
            color="deep-orange"
            type="warning"
        >
            No se ha hecho la apertura de caja. No puede realizar acciones, solo ver.
           
            <v-card-actions >
                <v-spacer></v-spacer>
                <v-btn to="/box" text @click="dialog=false"> Ir a caja. </v-btn>
            </v-card-actions>
          
       </v-alert>
    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
    data:()=>({
        dialog: false,
    }),
    watch:{
        isApertura(){
            this.dialog = !this.isApertura;
        }
    },
    computed:{
        ...mapState("box",["isApertura"]),
    },
    mounted(){
        this.comprobarApertura();
        this.dialog = !this.isApertura;
    },
    methods: {
       ...mapActions("box", ["comprobarApertura"]),
    },
};
</script>
