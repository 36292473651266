<template v-slot:activator="{ on, attrs }">
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-row justify="center">
            <!-- Dialog (Modal Form Client) -->
            <v-dialog v-model="dialog" persistent max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="edit"
                            color="blue darken-1"
                            text
                            @click="
                                setDialog();
                                setEdit();
                            "
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            v-else
                            color="blue darken-1"
                            text
                            @click="setDialog()"
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            v-if="edit"
                            :disabled="!valid"
                            color="blue darken-1"
                            text
                            @click="validateSubmit(edit, product)"
                        >
                            Editar
                        </v-btn>
                        <v-btn
                            v-else
                            :disabled="!valid"
                            color="blue darken-1"
                            text
                            @click="validateSubmit(edit, product)"
                        >
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-form>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
export default {
    data: () => ({
        valid: false,
    }),
    mounted() {
        this.getPermissions(this.$route.path);
    },

    computed: {
        ...mapState("app", ["canCreate", "canEdit", "canDelete"]),
        ...mapState("debtor", ["dialog", "edit"]),
        formTitle() {
            return this.edit === false ? "Nueva Deudor" : "Editar Duedor";
        },
    },
    methods: {
        ...mapMutations("debt", ["setDialog", "setDeleteDialog"]),
        ...mapActions("app", ["getPermissions"]),
    },
};
</script>
