var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-sheet',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.valuesColors),function(field,index){return _c('div',{key:index},[_c('v-card',{attrs:{"width":"300"}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('h6',[_vm._v("Color Nombre")]),_vm._v(" "),_c('v-text-field',{attrs:{"dense":"","outlined":"","placeholder":"NEGRO"},model:{value:(field.color),callback:function ($$v) {_vm.$set(field, "color", $$v)},expression:"field.color"}})],1),_vm._v(" "),_c('v-col',[_c('h6',[_vm._v("Color Codigo")]),_vm._v(" "),_c('v-text-field',{attrs:{"dense":"","outlined":"","placeholder":"N"},model:{value:(field.codigo),callback:function ($$v) {_vm.$set(field, "codigo", $$v)},expression:"field.codigo"}})],1)],1),_vm._v(" "),_c('h6',[_vm._v("Tamaño")]),_vm._v(" "),_c('v-text-field',{attrs:{"dense":"","outlined":"","placeholder":"","append-icon":"add"},on:{"click:append":function($event){_vm.addExtensionSize({
                                colorIdx: index,
                                size: field.size,
                            }),
                                (field.size = '')}},model:{value:(field.size),callback:function ($$v) {_vm.$set(field, "size", $$v)},expression:"field.size"}}),_vm._v(" "),_c('v-chip-group',{attrs:{"active-class":"primary--text","column":""}},_vm._l((field.sizes),function(tag,indexSize){return _c('v-chip',{key:indexSize,attrs:{"close":"","close-icon":"mdi-delete"},on:{"click:close":function($event){return _vm.removeExtensionSize({
                                    colorIdx: index,
                                    sizeIdx: indexSize,
                                })}}},[_vm._v("\n                            "+_vm._s(tag)+"\n                        ")])}),1)],1)],1)],1)}),0),_vm._v(" "),(!_vm.edit)?_c('v-btn',{staticClass:"d-flex justify-center mt-3",attrs:{"color":"primary","rounded":""},on:{"click":function($event){return _vm.addExtensionColor()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }