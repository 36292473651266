<template>
    <section>
        <v-card
            elevation="0"
            :loading="loadingListSale"
            min-height="200"
            v-if="getSalesData.length > 0 || loadingListSale"
        >
            <template slot="progress">
                <LoadingComponent />
            </template>

            <v-list class="d-flex flex-wrap">
                <v-list-item v-for="(sale, i) in getSalesData" :key="i">
                    <v-card
                        class="mt-2 d-flex"
                        elevation="4"
                        :style="'border: 0.25px solid #5accff; width: 100%'"
                    >
                        <v-card-text>
                            <div class="subtitle">Venta</div>
                            <h6>
                                {{ sale.numero }}
                            </h6>
                        </v-card-text>
                        <v-card-text>
                            <div class="subtitle">Factura</div>
                            <h6>
                                {{ sale.factura_numero || "--" }}
                            </h6>
                        </v-card-text>
                        <v-card-text>
                            <div class="subtitle">Cliente</div>
                            <h6>
                                {{ sale.nombre_y_apellido || "--" }}
                            </h6>
                            <h6>
                                {{ sale.ruc || "--" }}
                            </h6>
                        </v-card-text>
                        <v-card-text>
                            <div class="subtitle">Monto</div>
                            <h6>
                                {{
                                    convertMoney(
                                        sale.total,
                                        sale.codigo || "PYG"
                                    )
                                }}
                            </h6>
                        </v-card-text>
                        <v-card-text>
                            <div class="subtitle">Fecha</div>
                            <h6>
                                {{
                                    new Date(
                                        sale.created_at
                                    ).toLocaleDateString("es-es", {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                    })
                                }}
                            </h6>
                        </v-card-text>
                        <v-card-actions class="ml-auto">
                            <v-btn
                                small
                                icon
                                color="white"
                                @click="
                                    dialog = true;
                                    getOneSaleDevolution(sale.id);
                                    saleId = sale.id;
                                "
                            >
                                <v-icon color="blue">
                                    {{ icons.mdiInvoiceTextEditOutline }}
                                </v-icon>
                            </v-btn>
                            <v-btn
                                small
                                icon
                                color="white"
                                @click="
                                    getShowSale(sale.id);
                                    setDialogShowSale(true);
                                "
                            >
                                <v-icon color="green">mdi-eye </v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-list-item>
                <v-skeleton-loader v-intersect="endIntersectSale" />
            </v-list>
        </v-card>
        <v-card v-else elevation="0" class="pt-4 d-flex justify-center">
            <h4>No hay datos</h4>
        </v-card>

        <v-dialog
            v-model="dialog"
            @click:outside="dialog = false"
            max-width="400"
        >
            <v-card class="pa-4">
                <v-container fill-height fluid>
                    <v-row align="center" justify="center">
                        <v-col>
                            <v-btn
                                style="text-decoration: none"
                                color="primary"
                                outlined
                                :to="`form/devolution/${saleId}`"
                                >Devolucion</v-btn
                            >
                        </v-col>
                        <v-col>
                            <v-btn
                                style="text-decoration: none"
                                color="primary"
                                outlined
                                :to="`form/exchange/${saleId}`"
                                >Intercambio</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </section>
</template>
<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { convertMoney } from "../../formatos";
import { mdiInvoiceTextEditOutline } from "@mdi/js";
import LoadingComponent from "../Loading.Component.vue";

export default {
    data: () => ({
        icons: {
            mdiInvoiceTextEditOutline,
        },
        saleId: 0,
        dialog: false,
    }),
    components: {
        LoadingComponent,
    },
    computed: {
        ...mapState("devolution", [
            "sales",
            "loadingListSale",
            "page",
            "search",
        ]),
        ...mapGetters("devolution", ["getSalesData"]),
    },
    methods: {
        ...mapActions("sale", ["getShowSale"]),
        ...mapActions("devolution", [
            "getOneDevolution",
            "getOneSaleDevolution",
            "searchSales",
        ]),
        ...mapMutations("sale", ["setDialogShowSale"]),
        ...mapMutations("devolution", [
            "setDialogFormDevolution",
            "setDialogFormExchange",
            "setDevolution",
        ]),

        async endIntersectSale(entries, observer, isIntersecting) {
            if (isIntersecting) {
                if (this.page.lastPage > this.page.current) {
                    this.page.current += 1;
                    await this.searchSales(this.search);
                }
            }
        },
        convertMoney,
    },
};
</script>
