import axios from "axios";

const state = {
    dialogFormProdBox: false,
    dialogDeleteProductBox: false,
    dialogShowProductBox: false,
    dialogConvertProductBox: false,
    loadingCreateProductBox: false,
    loadingGetProductBox: false,
    loadingShowProductBox: false,
    editFormProductBox: false,
    productBoxs: [],
    productBox: {},
    convertProductBox: {},
    productBoxHeader: [
        { text: "Nombre", align: "start", value: "nombre" },
        { text: "Código", value: "codigo" },
        {
            text: "Cantidad por caja",
            value: "cantidad_por_caja",
            align: "center",
        },
        {
            text: "Opciones",
            value: "actions",
            align: "center",
            sortable: false,
            width: "150px",
        },
    ],
};
const actions = {
    async getProductBox({ state, commit }) {
        state.loadingGetProductBox = true;
        await axios
            .get("/products_per_box")
            .then((res) => {
                commit("GET_PRODUCT_BOX", res.data);
            })
            .catch((err) => {
                commit("ERROR_PRODUCT_BOX");
                console.log(err);
            });
    },
    async getOneProductBox({ state, commit }, payload) {
        state.loadingShowProductBox = true;
        await axios
            .get(`/products_per_box/${payload}`)
            .then((res) => {
                commit("GET_ONE_PRODUCT_BOX", res.data);
            })
            .catch((err) => {
                commit("ERROR_PRODUCT_BOX");
                console.log(err);
            });
    },
    async createProductBox({ state, commit, dispatch }, payload) {
        state.loadingCreateProductBox = true;
        axios
            .post("/products_per_box", payload)
            .then((res) => {
                commit("CREATE_PRODUCT_BOX");
            })
            .catch((err) => {
                commit("ERROR_PRODUCT_BOX");
                this.commit("app/setSnackbarAlert", err.response.data.message);
                console.log(err);
            })
            .finally(() => {
                dispatch("getProductBox");
            });
    },
    async editProductBox({ state, commit, dispatch }, payload) {
        axios
            .put("/products_per_box", payload)
            .then((res) => {
                commit("EDIT_PRODUCT_BOX");
            })
            .catch((err) => {
                commit("ERROR_PRODUCT_BOX");
                this.commit("app/setSnackbarAlert", err.response.data.message);
                console.log(err);
            })
            .finally(() => {
                dispatch("getProductBox");
            });
    },
    deleteProductBox({ state, commit, dispatch }, payload) {
        axios
            .delete(`/products/${payload}`)
            .then((res) => {
                commit("DELETE_PRODUCT_BOX");
            })
            .catch((err) => {
                console.log(err);
                commit("ERROR_PRODUCT_BOX");
                this.commit("app/setSnackbarAlert", err.response.data.message);
            })
            .finally(() => {
                dispatch("getProductBox");
            });
    },
    convertProductPerBox({ state, commit }, payload) {
        axios
            .post("/convert_stock", payload)
            .then((res) => {
                commit("CONVERT_PRODUCT_PER_BOX");
                this.commit("app/setSnackbarSuccess", "Conversión exitosa");
                this.dispatch("deposito/getProductsStores", { dep: 1 });
            })
            .catch((err) => {
                console.log(err);
            });
    },
};
const mutations = {
    GET_PRODUCT_BOX(state, payload) {
        state.loadingGetProductBox = false;
        state.productBoxs = [...payload];
    },
    GET_ONE_PRODUCT_BOX(state, payload) {
        state.loadingShowProductBox = false;
        state.productBox = payload;
    },
    CREATE_PRODUCT_BOX(state, payload) {
        state.loadingCreateProductBox = false;
        state.dialogFormProdBox = false;
        state.productBox = {};
    },
    EDIT_PRODUCT_BOX(state) {
        state.loadingCreateProductBox = false;
        state.dialogFormProdBox = false;
        state.editFormProductBox = false;
        state.productBox = {};
    },
    DELETE_PRODUCT_BOX(state, payload) {},
    ERROR_PRODUCT_BOX(state) {
        //state.dialogFormProdBox = false;
        state.loadingCreateProductBox = false;
        state.loadingGetProductBox = false;
    },
    CONVERT_PRODUCT_PER_BOX(state, payload) {},
    setDialogProdBox(state, paylaod) {
        state.dialogFormProdBox = paylaod;
    },
    setDialogDeleteProductBox(state, paylaod) {
        state.dialogDeleteProductBox = paylaod;
    },
    setDialogShowProdBox(state, paylaod) {
        state.dialogShowProductBox = paylaod;
    },
    setDialogConverProductBox(state, paylaod) {
        state.dialogConvertProductBox = paylaod;
    },
    setProductBox(state, paylaod) {
        const { id, nombre, codigo, cantidad_por_caja } = paylaod;
        state.productBox = {
            id,
            nombre,
            codigo,
            cantidad: cantidad_por_caja,
        };
    },
    setEditProductBox(state, paylaod) {
        state.editFormProductBox = paylaod;
    },
    setConvertProductBox(state, paylaod) {
        state.convertProductBox = paylaod;
    },

    clearProductBox(state) {
        state.productBox = {};
        state.editFormProductBox = false;
    },
};
const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
