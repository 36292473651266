var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"1000px"},on:{"click:outside":function($event){return _vm.closeDialog()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeDialog()}},model:{value:(_vm.dialogShowOrder),callback:function ($$v) {_vm.dialogShowOrder=$$v},expression:"dialogShowOrder"}},[_c('v-card',[_c('v-row',{staticClass:"mx-1",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto","md":"auto"}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5 pb-2"},[_c('v-icon',[_vm._v(" mdi-account ")]),_vm._v("\n                            "+_vm._s(this.order.client?.nombre_y_apellido)+"\n                        ")],1),_vm._v(" "),_c('v-list-item-title',{staticClass:"text-h7 pb-2"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-domain")]),_vm._v("\n                            "+_vm._s(this.order.client?.ruc)+"\n                        ")],1),_vm._v(" "),_c('v-list-item-title',{staticClass:"text-h7"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-calendar")]),_vm._v("\n                            "+_vm._s(_vm.returnDate)+"\n                        ")],1)],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"auto","md":"auto","align-self":"end"}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 text-end mb-2"},[_vm._v("\n                            Total:\n                            "+_vm._s(_vm.convertMoney(
                                    _vm.returnTotalPrices,
                                    _vm.order.order_detail[0]?.codigo
                                ))+"\n                        ")])],1)],1)],1)],1),_vm._v(" "),_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"md":"10"}},[_c('v-divider',{staticClass:"info"})],1)],1),_vm._v(" "),_c('v-row',{staticClass:"p-0 m-0"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"mx-1",attrs:{"hide-default-footer":"","headers":_vm.headerOrderShow,"items":_vm.order?.order_detail,"items-per-page":-1},scopedSlots:_vm._u([{key:`item.precio_unitario`,fn:function(props){return [_vm._v("\n                        "+_vm._s(_vm.convertMoney(
                                props.item?.precio_unitario,
                                _vm.order.order_detail[0]?.codigo
                            ))+"\n                    ")]}},{key:`item.subtotal`,fn:function(props){return [_vm._v("\n                        "+_vm._s(_vm.convertMoney(
                                props.item?.subtotal,
                                _vm.order.order_detail[0]?.codigo
                            ))+"\n                    ")]}}],null,true)})],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"ma-3",attrs:{"color":"red lighten-2","text":"","outlined":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("\n                Cerrar\n            ")]),_vm._v(" "),_c('v-btn',{staticClass:"ma-3",attrs:{"disabled":_vm.order.estado === 'Pendiente' ? false : true,"color":"primary lighten-2","text":"","outlined":""},on:{"click":function($event){return _vm.pedidoVenta()}}},[_vm._v("\n                Vender\n            ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }