<template>
    <v-dialog
        v-model="dialogReportBySeller"
        width="800"
        @click:outside="cerrarDialog()"
        @keydown.esc="cerrarDialog()"
    >
        <v-card>
            <v-card style="overflow-x: hidden; overflow-y: hidden">
                <v-card-title>Informe por usuario</v-card-title>
            </v-card>
            <template>
                <v-row class="ma-0 pa-0">
                    <v-col md="6">
                        <v-card-subtitle class="text-center"
                            >Lista de vendedores</v-card-subtitle
                        >
                        <v-item-group
                            v-model="selectedSeller"
                            class="item-group-scroll"
                        >
                            <v-row
                                v-for="(details, i) in sellers"
                                :key="i"
                                class="mt-2 mx-4"
                            >
                                <v-col>
                                    <v-item v-slot="{ active, toggle }">
                                        <v-card
                                            :color="active ? 'primary' : ''"
                                            class="d-flex align-center text-center"
                                            height="50"
                                            @click="toggle"
                                        >
                                            <v-card-text
                                                :class="
                                                    active ? 'white--text' : ''
                                                "
                                            >
                                                <h6>{{ details.username }}</h6>
                                            </v-card-text>
                                        </v-card>
                                    </v-item>
                                </v-col>
                            </v-row>
                        </v-item-group>
                    </v-col>
                    <v-divider vertical class="mt-2"></v-divider>
                    <v-col md="6">
                        <v-card-subtitle class="text-center"
                            >Rango de fecha</v-card-subtitle
                        >
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-menu
                                    v-model="abrirDatePickerDesde"
                                    :close-on-content-click="true"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            label="Desde"
                                            prepend-icon="event"
                                            readonly
                                            :value="desdeDateDisp"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        locale="es-es"
                                        v-model="fechaDesde"
                                        no-title
                                        :min="minDate"
                                        :max="new Date().toJSON().slice(0, 10)"
                                        @input="abrirDatePickerHasta = true"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-menu
                                    v-model="abrirDatePickerHasta"
                                    :close-on-content-click="true"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            label="Hasta"
                                            prepend-icon="event"
                                            readonly
                                            :value="hastaDateDisp"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        locale="es-es"
                                        v-model="fechaHasta"
                                        no-title
                                        :min="minDate"
                                        :max="getTomorrow"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </template>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="cerrarDialog()"> Cancelar </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="generar()"
                    :disabled="
                        fechaDesde == null ||
                        fechaHasta == null ||
                        selectedSeller == undefined
                    "
                >
                    Generar informe
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
    data: () => ({
        selectedSeller: undefined,
        abrirDatePickerDesde: false,
        abrirDatePickerHasta: false,
        fechaDesde: null,
        fechaHasta: null,
        minDate: "2022-01-01",
    }),

    mounted() {
        this.getSellers();
    },

    computed: {
        ...mapState("report", ["sellerUsers", "dialogReportBySeller"]),
        ...mapState("seller", ["sellers"]),
        ...mapGetters("report", ["getTomorrow"]),
        desdeDateDisp() {
            return this.returnDate(this.fechaDesde);
        },
        hastaDateDisp() {
            return this.returnDate(this.fechaHasta);
        },
    },
    methods: {
        ...mapMutations("report", ["setDialogReportBySeller"]),
        ...mapActions("report", ["getReportBySellerUser"]),
        ...mapActions("seller", ["getSellers"]),
        generar() {
            const data = {
                fechaDesde: this.fechaDesde,
                fechaHasta: this.fechaHasta,
                userId: this.sellers[this.selectedSeller].user_id,
                userName: this.sellers[this.selectedSeller].username,
            };

            this.getReportBySellerUser(data);
            this.cerrarDialog();
        },
        cerrarDialog() {
            this.selectedSeller = undefined;
            this.abrirDatePickerDesde = false;
            this.abrirDatePickerHasta = false;
            this.fechaDesde = null;
            this.fechaHasta = null;
            this.setDialogReportBySeller(false);
        },
        returnDate(date) {
            if (date) {
                return date.split("-").reverse().join("-");
            }
        },
    },
};
</script>
<style scoped>
.item-group-scroll {
    height: 300px;
    overflow-y: scroll;
}
</style>
