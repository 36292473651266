<template>
    <div>
        <v-sheet class="d-flex flex-wrap">
            <div v-for="(field, index) in valuesColors" :key="index">
                <v-card width="300">
                    <!-- <v-card-title>
                        <h6>Color Remera</h6>
                    </v-card-title> -->
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <h6>Color Nombre</h6>
                                <v-text-field
                                    v-model="field.color"
                                    dense
                                    outlined
                                    placeholder="NEGRO"
                                />
                            </v-col>
                            <v-col>
                                <h6>Color Codigo</h6>
                                <v-text-field
                                    v-model="field.codigo"
                                    dense
                                    outlined
                                    placeholder="N"
                                />
                            </v-col>
                        </v-row>
                        <h6>Tamaño</h6>

                        <v-text-field
                            v-model="field.size"
                            dense
                            outlined
                            placeholder=""
                            append-icon="add"
                            @click:append="
                                addExtensionSize({
                                    colorIdx: index,
                                    size: field.size,
                                }),
                                    (field.size = '')
                            "
                        />
                        <v-chip-group active-class="primary--text" column>
                            <v-chip
                                v-for="(tag, indexSize) in field.sizes"
                                :key="indexSize"
                                close
                                close-icon="mdi-delete"
                                @click:close="
                                    removeExtensionSize({
                                        colorIdx: index,
                                        sizeIdx: indexSize,
                                    })
                                "
                            >
                                {{ tag }}
                            </v-chip>
                        </v-chip-group>
                    </v-card-text>
                </v-card>
            </div>
        </v-sheet>
        <v-btn
            v-if="!edit"
            class="d-flex justify-center mt-3"
            color="primary"
            rounded
            @click="addExtensionColor()"
        >
            <v-icon>mdi-plus</v-icon>
        </v-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: [
        "valuesColors",
        "addExtensionColor",
        "addExtensionSize",
        "removeExtensionSize",
    ],
    data: () => ({}),
    computed: {
        ...mapState("product", ["edit"]),
    },
};
</script>

<style></style>
