<template v-slot:top>
    <div>
        <v-card class="my-10">
            <v-list-item class="">
                <v-card
                    class="mt-n7"
                    color="primary"
                    elevation="10"
                    min-height="80"
                    min-width="80"
                >
                    <v-icon class="ma-5" large dark
                        >mdi-file-document-edit-outline
                    </v-icon>
                </v-card>

                <v-list-item-title>
                    <div class="overline mx-10 mt-5">
                        <h4>Lista Presupuesto</h4>
                    </div>
                </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-card-text>
                        <v-card-actions> </v-card-actions>
                    </v-card-text>
                </v-list-item-content>
            </v-list-item>

            <ListBudgets />

            <ShowBudgetComponent v-if="showDialogBudget" />
            <template v-if="budgetDialog">
                <DownloadBudgetComponent />
            </template>
        </v-card>
    </div>
</template>
<script>
import ListBudgets from "./ListBudgets.Component.vue";
import FormBudget from "./FormBudget.Component.vue";

import ShowBudgetComponent from "./ShowBudget.Component.vue";
import DownloadBudgetComponent from "./DownloadBudget.Component.vue";
import { mapState } from "vuex";

export default {
    data: () => ({}),

    components: {
        ListBudgets,
        FormBudget,
        ShowBudgetComponent,
        DownloadBudgetComponent,
    },

    computed: {
        ...mapState("sale", ["budgetDialog"]),
        ...mapState("budget", ["showDialogBudget"]),
    },
    methods: {},
};
</script>
