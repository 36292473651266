import axios from "axios";

const state = {
    boxes: [],
    moneyList: [],
    box: [],
    isApertura: true,
    dialogMoneyIn: false,
    dialogMoneyOut: false,
    isEditar: false,
    moneyIn: {
        id: 0,
        monto: 0,
        motivo: "",
    },
    moneyOut: {
        id: 0,
        monto: 0,
        motivo: "",
    },
    paymentReceipt: [
        {
            id: null,
            recibo_numero: null,
            created_at: null,
            sale_header: [
                {
                    id: null,
                    factura_numero: null,
                    credito: null,
                    plazo: null,
                    pagado: null,
                    anulado: null,
                    client: {
                        id: null,
                        nombre_y_apellido: null,
                        ruc: null,
                        telefono: null,
                        direccion: null,
                        email: null,
                    },
                    sale_detail: [
                        {
                            sale_header_id: null,
                            id: null,
                            total: 0,
                        },
                    ],
                },
            ],
            payment_receipt_detail: [
                {
                    id: 0,
                    codigo: "0",
                    monto: 0,
                    metodo_pago: "efectivo",
                },
            ],
        },
    ],
    rutaPayment: "",
};

const actions = {
    getBoxes({ commit }) {
        axios.get("boxes").then((response) => {
            commit("SET_BOXES", response.data);
        });
    },
    async createBox({ dispatch }, payload) {
        this.commit("app/setIsLoading", true);
        await axios
            .post("/boxes", payload)
            .then(async (response) => {
                await dispatch("comprobarApertura");
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                dispatch("getBoxes");
            })
            .catch((error) => {
                if (error.response) {
                    // Request made and server responded
                    this.commit(
                        "app/setSnackbarAlert",
                        `${error.response.data.message}`
                    );
                }
            });
        this.commit("app/setIsLoading", false);
    },
    getOneBox({ commit }, payload) {
        axios
            .get("/boxes/" + payload)
            .then((response) => {
                commit("SET_ONE_BOX", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
                console.log(error.response.data);
            });
    },
    getOneMoneyIn({ commit }, payload) {
        axios
            .get("/money_ins/" + payload)
            .then((response) => {
                commit("SET_ONE_MONEY_IN", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
                console.log(error.response.data);
            });
    },
    getOneMoneyOut({ commit }, payload) {
        axios
            .get("/money_outs/" + payload)
            .then((response) => {
                commit("SET_ONE_MONEY_OUT", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
                console.log(error.response.data);
            });
    },
    getPaymentReceipt({ commit }) {
        axios
            .get(state.rutaPayment)
            .then((response) => {
                commit("SET_PAYMENT_RECEIPT", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
                console.log(error.response.data);
            });
    },
    comprobarApertura({ commit }) {
        axios.get("/check_apertura").then((response) => {
            commit("SET_APERTURA", response.data.isApertura);
        });
    },
    addMoneyBox({ state, commit }, payload) {
        axios
            .post("/money_ins", payload)
            .then((response) => {
                commit("closeMoneyInDialog");
                this.commit(
                    "app/setSnackbarSuccess",
                    "Se registró correctamente el ingreso."
                );
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
                console.log(error.response.data);
            });
    },
    editMoneyIn({ state, commit, dispatch }) {
        axios
            .put("/money_ins/" + state.moneyIn.id, state.moneyIn)
            .then((response) => {
                dispatch("getMoneyList", response.data.box_id);
                commit("closeMoneyInDialog");
                this.commit(
                    "app/setSnackbarSuccess",
                    "Se modificó correctamente el ingreso."
                );
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
                console.log(error.response.data);
            });
    },
    subsMoneyBox({ commit, state }, payload) {
        axios
            .post("/money_outs", payload)
            .then(() => {
                commit("closeMoneyOutDialog");
                this.commit(
                    "app/setSnackbarSuccess",
                    "Se registró correctamente el egreso."
                );
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
                console.log(error.response.data);
            });
    },
    editMoneyOut({ state, commit, dispatch }) {
        axios
            .put("/money_outs/" + state.moneyOut.id, state.moneyOut)
            .then((response) => {
                dispatch("getMoneyList", response.data.box_id);
                commit("closeMoneyOutDialog");
                this.commit(
                    "app/setSnackbarSuccess",
                    "Se modificó correctamente el ingreso."
                );
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
                console.log(error.response.data);
            });
    },
    getMoneyList({ commit }, payload) {
        axios
            .get("/boxes/" + payload.box_id + "/money_list/currency/" + payload.currency_id)
            .then((response) => {
                commit("SET_MONEY_LIST", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
                console.log(error.response.data);
            });
    },
    deleteMoney({}, payload) {
        axios
            .delete(payload)
            .then(() => {
                this.commit("app/setSnackbarAlert", "Registro eliminado.");
                window.location.reload();
            })
            .catch((error) => {
                console.log(error.response.data);
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
};

const mutations = {
    SET_BOXES(state, boxes) {
        state.boxes = boxes;
    },
    CREATE_BOX(state, item) {
        state.boxes.push(item);
    },
    SET_APERTURA(state, payload) {
        state.isApertura = payload;
    },
    SET_ONE_BOX(state, payload) {
        state.box = payload;
    },
    CLEAR_ARQUEO(state) {
        state.arqueo = {
            cienmil: "0",
            cincuentamil: "0",
            veintemil: "0",
            diezmil: "0",
            cincomil: "0",
            dosmil: "0",
            mil: "0",
            quinientos: "0",
            cien: "0",
            cincuenta: "0",
            box_id: "0",
        };
    },
    clearBox(state) {
        state.box = [];
    },
    setDialogMoneyIn(state, payload) {
        state.dialogMoneyIn = payload;
    },
    setDialogMoneyOut(state, payload) {
        state.dialogMoneyOut = payload;
    },
    SET_MONEY_LIST(state, payload) {
        state.moneyList = payload;
    },
    SET_ONE_MONEY_IN(state, payload) {
        state.moneyIn = payload;
    },
    SET_ONE_MONEY_OUT(state, payload) {
        state.moneyOut = payload;
    },
    setEditarMoneyIn(state, payload) {
        state.isEditar = payload;
    },
    SET_PAYMENT_RECEIPT(state, payload) {
        state.paymentReceipt = payload;
    },
    closeMoneyInDialog(state) {
        state.dialogMoneyIn = false;
        state.moneyIn = {
            id: 0,
            monto: 0,
            motivo: "",
        };
        state.isEditar = false;
    },
    closeMoneyOutDialog(state) {
        state.dialogMoneyOut = false;
        state.moneyOut = {
            id: 0,
            monto: 0,
            motivo: "",
        };
        state.isEditar = false;
    },
    setRutaPayment(state, payload) {
        state.rutaPayment = payload;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
