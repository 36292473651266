import axios from "axios";
import router from "../../routes/routes";
const state = {
    sales: [],
    sale: {},
    devolutions: [],
    devolution: {},
    saleDevolution: {},
    saleDevolutionDetail: [],
    dialogFormDevolution: false,
    loadingFormDevolution: false,
    dialogShowDevolution: false,
    loadingShowDevolution: false,
    dialogDeleteDevolution: false,
    loadingDeleteDevolution: false,
    dialogFormExchange: false,
    loadingFormExchange: false,
    loadingListSale: false,
    dialogFormDevProd: false,
    search: {},
    page: {
        current: 1,
        total: 0,
        perPage: 0,
        lastPage: 0,
        firstPage: 0,
    },
    header: [
        {
            text: "Nombre",
            align: "start",
            value: "product.nombre",
        },
        {
            text: "Codigo",
            align: "start",
            value: "product.codigo",
        },
        { text: "cantidad", value: "cantidad" },
        { text: "Precio Unit.", value: "precio_unitario" },
        { text: "Subtotal", value: "subtotal" },
        { text: "Estado", value: "estado" },
        { text: "Opciones", value: "actions" },
    ],
    headerDevProd: [
        {
            text: "Nombre",
            align: "start",
            value: "nombre",
        },
        {
            text: "Codigo",
            align: "start",
            value: "codigo",
        },
        { text: "cantidad", value: "cantidad" },
        { text: "Precio Unit.", value: "precio_unitario" },
        { text: "Subtotal", value: "subtotal" },
        { text: "Observacion", value: "observacion" },
        { text: "Opciones", value: "actions" },
    ],

    headerExchange: [
        {
            text: "Nombre",
            align: "start",
            value: "product.nombre",
        },
        {
            text: "Codigo",
            align: "start",
            value: "product.codigo",
        },
        { text: "cantidad", value: "cantidad" },
        { text: "Precio Unit.", value: "precio_unitario" },
        { text: "Subtotal", value: "subtotal" },
        { text: "Estado", value: "estado", align: "center" },
        { text: "Opciones", value: "actions" },
    ],
    headerDevolution: [
        {
            text: "Número",
            align: "center",
            value: "numero",
        },
        { text: "Nª Nota Crédito", value: "nc_numero" },
        { text: "Nº venta", value: "numero_venta" },
        { text: "Cliente", value: "client" },
        { text: "Total", value: "total" },
        { text: "Moneda", value: "moneda" },
        { text: "Fecha", value: "fecha" },
        { text: "Usuario", value: "name" },
        { text: "Opciones", value: "actions", align: "center", width: "150px" },
    ],
    showHeaderDevolution: [
        {
            text: "Cantidad",
            align: "start",
            value: "cantidad",
        },
        {
            text: "Producto",
            align: "start",
            value: "nombre",
        },
        { text: "Precio Unit.", value: "precio_unitario" },
        { text: "Total", value: "total" },

        { text: "", value: "data-table-expand" },
    ],
    showHeaderExchange: [
        {
            text: "Intercambio",
            align: "start",
            value: "productToChange.nombre",
        },
        {
            text: "Producto Nuevo",
            align: "start",
            value: "product.nombre",
        },
        { text: "Cantidad", value: "cantidad" },
        { text: "Precio Unit.", value: "precio_unitario" },
        { text: "Subtotal", value: "subtotal" },
        { text: "Opciones", value: "actions" },
    ],
};

const actions = {
    async searchSales({ state, commit }, payload) {
        state.loadingListSale = true;
        await axios
            .post(`/search_sale?page=${state.page.current}`, payload)
            .then((res) => {
                commit("SET_SALES", res.data);
            })
            .catch((err) => {
                console.log(err);

                this.commit("app/setSnackbarAlert", err.response.data.message);
            });
    },
    getAllDevolutions({ commit }) {
        axios
            .get("/devolutions")
            .then((res) => {
                commit("SET_ALL_DEVOLUTION", res.data);
            })
            .catch((err) => {
                console.log(err);

                this.commit("app/setSnackbarAlert", err.response.data.message);
            });
    },
    getOneDevolution({ commit, state }, payload) {
        state.loadingShowDevolution = true;
        axios
            .get(`/devolutions/${payload}`)
            .then((res) => {
                commit("SET_ONE_DEVOLUTION", res.data);
            })
            .catch((err) => {
                console.log(err);

                this.commit("app/setSnackbarAlert", err.response.data.message);
            });
    },
    getOneSaleDevolution({ commit, state }, payload) {
        state.loadingFormDevolution = true;
        state.loadingFormExchange = true;
        axios
            .post(`/devolutions/${payload}/sale`)
            .then((res) => {
                commit("SET_ONE_SALE_DEVOLUTION", res.data);
            })
            .catch((err) => {
                console.log(err);

                this.commit("app/setSnackbarAlert", err.response.data.message);
            });
    },
    createDevolution({ commit }, payload) {
        state.loadingFormDevolution = true;
        axios
            .post("/devolutions", payload)
            .then((res) => {
                commit("CREATE_DEVOLUTION", res.data);
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                router.push("/devolution");
            })
            .catch((err) => {
                console.log(err);

                this.commit("app/setSnackbarAlert", err.response.data.message);
            });
    },
    deleteDevolution({ commit, dispatch }, payload) {
        axios
            .delete(`/devolutions/${payload.id}`)
            .then(async (res) => {
                commit("DELETE_DEVOLUTION", res.data);
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                await dispatch("getAllDevolutions");
            })
            .catch((err) => {
                console.log(err);

                this.commit("app/setSnackbarAlert", err.response.data.message);
            });
    },
    cancelDevolution({ commit, dispatch }, payload) {
        axios
            .post(`/devolutions/${payload}/anular`)
            .then(async (res) => {
                commit("CANCEL_DEVOLUTION", res.data);
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                await dispatch("getAllDevolutions");
            })
            .catch((err) => {
                console.log(err);

                this.commit("app/setSnackbarAlert", err.response.data.message);
            });
    },
    exchangeDevolution({ commit, dispatch }, payload) {
        state.loadingFormDevolution = true;
        axios
            .post(``, payload)
            .then((res) => {
                commit("EXCHANGE_DEVOLUTION", res.data);
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
            })
            .then(async () => {
                await dispatch("getAllDevolutions");
                router.push("/devolution");
            })
            .catch((err) => {
                console.log(err);
                this.commit("app/setSnackbarAlert", err.response.data.message);
            });
    },
};

const mutations = {
    SET_SALES(state, payload) {
        state.loadingListSale = false;
        state.sales = [...state.sales, ...payload.data];
        state.page = {
            total: payload.total,
            perPage: payload.per_page,
            current: payload.current_page,
            lastPage: payload.last_page,
            firstPage: payload.first_page,
        };
    },
    SET_ONE_DEVOLUTION(state, payload) {
        state.devolution = payload;
        state.loadingShowDevolution = false;
    },
    SET_ONE_SALE_DEVOLUTION(state, payload) {
        state.loadingFormDevolution = false;
        state.loadingFormExchange = false;
        state.saleDevolution = { ...payload };
        state.saleDevolutionDetail = [...payload.sale_detail];
    },
    CREATE_DEVOLUTION(sate, payload) {
        state.loadingFormDevolution = false;
    },
    SET_ALL_DEVOLUTION(state, payload) {
        state.devolutions = payload;
    },
    DELETE_DEVOLUTION(state, payload) {
        state.devolution = {};
    },
    CANCEL_DEVOLUTION(state, payload) {
        state.devolution = {};
    },
    EXCHANGE_DEVOLUTION(state, payload) {
        state.loadingFormDevolution = false;
    },
    setDialogFormDevolution(state, payload) {
        state.dialogFormDevolution = payload;
    },
    setDialogShowDevolution(state, payload) {
        state.dialogShowDevolution = payload;
    },
    setDialogDeleteDevolution(state, payload) {
        state.dialogDeleteDevolution = payload;
    },
    setDialogFormExchange(state, payload) {
        state.dialogFormExchange = payload;
    },
    setDevolution(state, payload) {
        state.devolution = payload;
    },
    setDialogFormDevProd(state, payload) {
        state.dialogFormDevProd = payload;
    },
    setSearch(state, paylaod) {
        state.page.current = 1;
        state.sales = [];
        state.search = paylaod;
    },
};

const getters = {
    getSalesData: (state) => {
        return state.sales;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
