const { default: axios } = require("axios");

const state = {
    dialogShowQuote: false,
    dialogFormQuote: false,
    dialogDeleteQuote: false,
    quotes: [
        {
            currency_id: 1,
            quotation_id: 1,
            nombre: "Guaranies",
            simbolo: "Gs.",
            codigo: "PYG",
            cotizacion: 1,
        },
    ],
    quote: {},
    loading: false,
    pageQuote: {},
    isEditQuote: false,
    quotesPage: {
        current: 0,
        total: 0,
        perPage: 0,
        lastPage: 0,
        firstPage: 0,
    },
    headers: [
        { text: "Moneda", value: "nombre" },
        { text: "Compra", value: "cotizacion" },
        { text: "Venta", value: ""},
        { text: "Última actualización", value: "updated_at" },
        { text: "Actualizado por", value: "username", align: "center" },
        { text: "Opciones", value: "actions", align: "center" },
    ],
};
const actions = {
    getQuotes({ state, commit }) {
        state.loading = true;
        const { perPage, current } = state.quotesPage;
        axios
            .get(`/quotations?page=${current}`)
            .then((res) => {
                commit("GET_QUOTES", res.data);
            })
            .catch((err) => {
                this.commit("app/setSnackbarAlert", err.message);
            })
            .finally((res) => {
                state.loading = false;
            });
    },
    createQuote({ commit, dispatch }, payload) {
        payload.tasa = payload.cotizacion;
        const data = Object.assign({}, payload);
        axios
            .post("/quotations", payload)
            .then(() => {
                commit("CREATE_QUOTE");
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                dispatch("getQuoteCurrency");
            })
            .catch((err) => {
                this.commit("app/setSnackbarAlert", err.message);
            });
    },
    editQuote({ commit, dispatch }, payload) {
        axios
            .put(`/quotations/${payload.id}`, payload)
            .then((res) => {
                commit("EDIT_QUOTE", res.data);
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                dispatch("getQuotes");
            })
            .catch((err) => {
                this.commit("app/setSnackbarAlert", err.message);
            });
    },
    deleteQuote({ commit, dispatch }, payload) {
        axios
            .delete(`/quotations/${payload.id}`)
            .then(() => {
                commit("DELETE_QUOTE");
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                dispatch("getQuotes");
            })
            .catch((err) => {
                this.commit("app/setSnackbarAlert", err.message);
            });
    },
    getQuoteCurrency({ commit }) {
        axios
            .get("quotation_currencies")
            .then((res) => {
                commit("GET_QUOTE_CURRENCY", res.data);
            })
            .catch((err) => {
                this.commit("app/setSnackbarAlert", err.message);
            });
    },
};
const mutations = {
    GET_QUOTES(state, payload) {
        state.quotes = payload.data;
        state.quotesPage = {
            current: payload.current_page,
            perPage: payload.per_page,
            total: payload.total,
            lastPage: payload.last_page,
        };
    },
    CREATE_QUOTE(state) {
        state.quote = {};
        state.dialogFormQuote = false;
    },
    EDIT_QUOTE(state, payload) {
        state.quote = {};
        state.dialogFormQuote = false;
        state.isEditQuote = false;
    },
    DELETE_QUOTE(state, payload) {
        state.quote = {};
    },
    GET_QUOTE_CURRENCY(state, payload) {
        state.quotes = payload;
    },
    setDialogShowQuote(state, payload) {
        state.dialogShowQuote = payload;
    },
    setDialogFormQuote(state, payload) {
        state.dialogFormQuote = payload;
    },
    setDialogDeleteQuote(state, paylaod) {
        state.dialogDeleteQuote = paylaod;
    },
    setIsEditQuote(state, payload) {
        state.isEditQuote = payload;
    },
    setQuote(state, payload) {
        state.quote = Object.assign({}, payload);
    },
    resetQuote(state) {
        state.quote = {};
    },
};
const getters = {
    getQuote(state) {
        state.quote = JSON.parse(localStorage.getItem("quote"));
        return state.quote;
    },
    getListQuotes: (state) => {
        let list = [];
        state.quotes.forEach((quote) => {
            list.push({
                quotation_id: quote.quotation_id,
                monto: 0,
            });
        });
        return list;
    },
    getQuotes(state) {
        return state.quotes;
    },
};

module.exports = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
