<template>
    <v-dialog
        v-model="dialogTransfer"
        @click:outside="setDialogTransfer(false)"
        @keydown.esc="setDialogTransfer(false)"
    >
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-card>
                <v-card-title>Trasnferencia de producto</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-select
                                v-model="selectedStoreOut"
                                :items="stores"
                                label="Deposito Origen"
                                item-text="nombre"
                                item-value="id"
                                :rules="storeOutRules"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="selectedStoreIn"
                                :items="storeSelectIn"
                                label="Deposito Destino"
                                item-text="nombre"
                                item-value="id"
                                :rules="storeInRules"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-autocomplete
                                v-model="selectedProduct"
                                :items="products"
                                :search-input.sync="searchProduct"
                                label="Elija un producto"
                                return-object
                                no-filter
                                clearable
                            >
                                <!-- En caso que no encuentra -->
                                <template v-slot:no-data>
                                    <div
                                        class="d-flex flex-column align-items-center"
                                    >
                                        <h6>Producto inexistente</h6>
                                    </div>
                                </template>
                                <template slot="selection" slot-scope="data">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{
                                                `${data.item.producto} - ${data.item.codigo}`
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Stock: {{ data.item.stock }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    {{ agregarDatoProducto(data.item) }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{
                                                `${data.item.producto} - ${data.item.codigo}`
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Stock: {{ data.item.stock }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                v-model="cantidad"
                                type="Number"
                                label="cantidad"
                            />
                        </v-col>
                        <v-col class="d-flex" align-self="center">
                            <v-btn
                                :color="btnAddEditColor"
                                outlined
                                @click="agregarProducto"
                                >{{ btnAddEditName }}</v-btn
                            >
                        </v-col>
                    </v-row>

                    <v-data-table
                        :headers="headersTransfer"
                        :items="transferProducts"
                        hide-default-footer
                    >
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon color="green" @click="editarProducto(item)"
                                >mdi-pencil</v-icon
                            >
                            <v-icon
                                v-if="!editar"
                                color="red"
                                @click="deleteTransferProducts(item)"
                                >mdi-cancel</v-icon
                            >
                        </template>
                    </v-data-table>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="red"
                        outlined
                        @click="
                            setDialogTransfer(false);
                            clearTransfer();
                        "
                        >Cerrar</v-btn
                    >
                    <v-btn
                        color="primary"
                        :disabled="!valid"
                        outlined
                        @click="guardarIngreso()"
                        >Guardar</v-btn
                    >
                </v-card-actions>
            </v-card>
            <template>
                <v-dialog v-model="dialogChangeStore" max-width="400px">
                    <v-card>
                        <v-card-title class="text-h5">
                            Deseas cambiar el deposito?
                        </v-card-title>

                        <v-card-text>
                            Si cambias el deposito eliminaran los productos
                            agregados
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn
                                color="blue darken-1"
                                text
                                @click="changeStore"
                            >
                                Cambiar
                            </v-btn>

                            <v-btn
                                color="green darken-1"
                                text
                                @click="noChangeStore"
                            >
                                Cancelar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
        </v-form>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
    data: () => ({
        valid: true,
        searchProduct: "",
        selectedStoreIn: 0,
        selectedStoreOut: 0,
        localProduct: {},
        selectedProduct: {},
        editar: false,
        cantidad: 0,
        oldProductId: 0,
        dialogChangeStore: false,
        changeStoreObj: null,
        storeOutRules: [
            (v) => !!v || "Deposito origen es obligatorio",
            (v) => v !== 0 || "Deposito origen es obligatorio",
        ],
        storeInRules: [
            (v) => !!v || "Deposito destino es obligatorio",
            (v) => v !== 0 || "Deposito origen es obligatorio",
        ],
    }),
    watch: {
        selectedStoreOut(val, old) {
            this.getProductsStores({ dep: val });
            if (this.changeStoreObj !== val) {
                this.changeStoreObj = old;
                this.dialogChangeStore = true;
            }
        },
        searchProduct(val) {
            this.buscarProductos({
                buscador: val,
                dep: this.selectedStoreOut,
            });
        },
        dialogTransfer(val) {
            if (val) {
                this.selectedStoreOut = parseInt(this.getLocaleStorageStore);
                // this.getProductsStores({ dep: this.selectedStoreOut });
                this.changeStoreObj = this.selectedStoreOut;
                this.buscarProductos({
                    buscador: "",
                    dep: this.selectedStoreOut,
                });
            }
        },
    },
    computed: {
        ...mapState("transfer", [
            "dialogTransfer",
            "transfer",
            "transferProducts",
            "headersTransfer",
        ]),
        ...mapState("deposito", ["stores"]),
        ...mapState("product", ["products"]),
        ...mapGetters("deposito", ["getLocaleStorageStore"]),

        btnAddEditName() {
            return this.editar ? "Editar" : "Agregar";
        },
        btnAddEditColor() {
            return this.editar ? "green" : "primary";
        },
        storeSelectIn() {
            return this.stores.filter((p) => p?.id !== this.selectedStoreOut);
        },
    },
    methods: {
        ...mapMutations("transfer", [
            "setDialogTransfer",
            "addTransferProducts",
            "editTransferProducts",
            "deleteTransferProducts",
            "clearTransferProducts",
            "clearTransfer",
        ]),
        ...mapMutations("deposito", ["setLocaleStorageStore"]),
        ...mapMutations("app", ["setSnackbar"]),

        ...mapActions("deposito", ["getAllStores", "getProductsStores"]),
        ...mapActions("product", ["getProducts", "buscarProductos"]),
        ...mapActions("transfer", ["createTransferStore"]),

        agregarProducto() {
            this.cantidad ??= 0;
            if (this.cantidad <= 0) {
                this.setSnackbar({
                    text: "Cantidad no puede ser menor o igual a 0",
                    type: "info",
                    timeout: 1500,
                    show: true,
                    icon: "mdi-alert-circle-outline",
                });
                return;
            }
            let stockProduct;

            this.products.forEach((product) => {
                if (product.product_id === this.localProduct.product_id) {
                    stockProduct = product;
                    return;
                }
            });
            if (stockProduct.stock < this.cantidad) {
                this.setSnackbar({
                    text: "Cantidad no puede ser menor o igual a 0",
                    type: "info",
                    timeout: 1500,
                    show: true,
                    icon: "mdi-alert-circle-outline",
                });
                return;
            }

            this.localProduct.cantidad = parseInt(this.cantidad);
            if (this.editar) {
                //editar
                this.editTransferProducts(this.localProduct);
                this.editar = false;
            } else {
                //agregar
                this.addTransferProducts(this.localProduct);
            }

            this.localProduct = {};
            this.selectedProduct = {};
            this.cantidad = 0;
        },

        editarProducto(item) {
            this.editar = true;
            this.oldProductId = parseInt(item.product_id);
            this.selectedProduct = item.product_id;
            this.cantidad = item.cantidad;
        },
        agregarDatoProducto(item) {
            if (this.selectedProduct) {
                this.localProduct.product_id = item.product_id;
                this.localProduct.nombre = item.producto;
            }
        },

        guardarIngreso() {
            this.$refs.form.validate();
            if (!this.transferProducts.length) {
                this.setSnackbar({
                    text: "No puedes hacer transferencia sin productos",
                    type: "info",
                    timeout: 1500,
                    show: true,
                    icon: "mdi-alert-circle-outline",
                });
                return;
            }

            const newTransfer = {
                store_in_id: this.selectedStoreIn,
                store_out_id: this.selectedStoreOut,
                details: this.transferProducts,
            };
            this.createTransferStore(newTransfer);
            this.clearData();
        },
        changeStore() {
            this.clearTransferProducts();
            this.changeStoreObj = null;
            this.dialogChangeStore = false;
        },
        noChangeStore() {
            this.selectedStoreOut = this.changeStoreObj;
            this.dialogChangeStore = false;
        },
        clearData() {
            this.selectedStoreIn = 0;
        },

        // filterObjectProduct(item, queryText, itemText) {
        //     return (
        //         (item.producto &&
        //             item.producto
        //                 .toLocaleLowerCase()
        //                 .indexOf(queryText.toLocaleLowerCase()) > -1) ||
        //         (item.codigo &&
        //             item.codigo
        //                 .toLocaleLowerCase()
        //                 .indexOf(queryText.toLocaleLowerCase()) > -1) ||
        //         (item.descripcion &&
        //             item.descripcion
        //                 .toLocaleLowerCase()
        //                 .indexOf(queryText.toLocaleLowerCase()) > -1) ||
        //         (item.marca &&
        //             item.marca
        //                 .toLocaleLowerCase()
        //                 .indexOf(queryText.toLocaleLowerCase()) > -1) ||
        //         (item.categoria &&
        //             item.categoria
        //                 .toLocaleLowerCase()
        //                 .indexOf(queryText.toLocaleLowerCase()) > -1)
        //     );
        // },
    },
    mounted() {
        this.selectedStoreOut = parseInt(this.getLocaleStorageStore);
        // this.getProductsStores({ dep: this.selectedStoreOut });
        this.changeStoreObj = this.selectedStoreOut;
    },
};
</script>

<style></style>
