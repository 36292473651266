<template v-slot:activator="{ on, attrs }">
    <v-form ref="form" v-model="valid">
        <v-row justify="center">
            <!-- Dialog Editar Venta -->
            <v-dialog persistent v-model="dialog" max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Editar venta</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row class="p-0 m-0">
                                <v-col md="2"> 
                                    <v-checkbox
                                        v-model="sale.factura"
                                        label="Factura"
                                    >
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field
                                        :disabled="!sale.factura"
                                        label="Número de factura"
                                        v-model="sale.factura_numero"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-autocomplete
                                        v-model="sale.client"
                                        :filter="filterObjectClient"
                                        :items="clients"
                                        :search-input.sync="buscarCliente"
                                        item-text="nombre_y_apellido"
                                        return-object
                                        clearable
                                        @click:clear="borraClient()"
                                    >
                                        <!-- En caso que no encuentra -->
                                        <template v-slot:no-data>
                                            <v-sheet
                                                class="d-flex justify-center ma-2"
                                            >
                                                <p>Cliente no registrado</p>
                                            </v-sheet>
                                            <v-sheet
                                                class="d-flex justify-center"
                                            >
                                                <v-btn
                                                    @click="
                                                        setDialogClient();
                                                        setIsClientForOther();
                                                    "
                                                >
                                                    Nuevo Cliente
                                                </v-btn>
                                            </v-sheet>
                                        </template>

                                        <template slot="ite m" slot-scope="data">
                                            {{ data.item.nombre_y_apellido }} -
                                            {{ data.item.ruc }}
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-checkbox
                                        v-model="sale.credito"
                                        label="Crédito"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-text-field
                                        v-if="sale.credito"
                                        type="number"
                                        label="Días"
                                        v-model="sale.plazo"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="p-0 m-0">
                                <v-col md="6">
                                    <v-select
                                        v-model="sale.currency_id"
                                        :items="quotes"
                                        item-text="nombre"
                                        item-value="quotation_id"
                                        label="Moneda"
                                    />
                                </v-col>
                            </v-row>
                            <!-- Tabla Editar Venta -->
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-data-table
                                        :headers="headersProduct"
                                        :items="sale.sale_detail"
                                        hide-default-footer
                                        disable-pagination
                                    >
                                        <template v-slot:top>
                                            <!-- Dialog Editar Item de la Venta -->
                                            <v-dialog
                                                v-model="dialogEditarItem"
                                                max-width="500px"
                                                @click:outside="
                                                    setCancelarEditItem(false)
                                                "
                                                @keydown.esc="
                                                    setCancelarEditItem(false)
                                                "
                                            >
                                                <v-card>
                                                    <v-card-title>
                                                        <span class="text-h5"
                                                            >{{
                                                                tituloEditar
                                                            }}
                                                            Item</span
                                                        >
                                                    </v-card-title>

                                                    <v-card-text>
                                                        <v-container>
                                                            <v-row
                                                                class="d-flex justify-center"
                                                            >
                                                                <v-col
                                                                    cols="12"
                                                                    md="8"
                                                                >
                                                                    <v-autocomplete
                                                                        v-model="
                                                                            selectedProduct
                                                                        "
                                                                        :items="
                                                                            products
                                                                        "
                                                                        label="Seleccionar Producto"
                                                                        return-object
                                                                        :search-input.sync="
                                                                            searchProduct
                                                                        "
                                                                        hide-no-data
                                                                        hide-details
                                                                        :filter="
                                                                            filterObjectProduct
                                                                        "
                                                                        ref="buscarProducto"
                                                                        clearable
                                                                    >
                                                                        <template
                                                                            slot="item"
                                                                            slot-scope="data"
                                                                        >
                                                                            <v-list-item-content>
                                                                                <v-list-item-title
                                                                                    >{{
                                                                                        data
                                                                                            .item
                                                                                            .producto
                                                                                    }}
                                                                                    -
                                                                                    {{
                                                                                        data
                                                                                            .item
                                                                                            .codigo
                                                                                    }}
                                                                                </v-list-item-title>

                                                                                <v-list-item-subtitle
                                                                                    >Disponible:
                                                                                    {{
                                                                                        data
                                                                                            .item
                                                                                            .stock
                                                                                    }}</v-list-item-subtitle
                                                                                >
                                                                            </v-list-item-content>
                                                                        </template>
                                                                        <template
                                                                            slot="selection"
                                                                            slot-scope="data"
                                                                        >
                                                                            <v-list-item-content>
                                                                                <v-list-item-title
                                                                                    >{{
                                                                                        data
                                                                                            .item
                                                                                            .producto
                                                                                    }}
                                                                                    -
                                                                                    {{
                                                                                        data
                                                                                            .item
                                                                                            .codigo
                                                                                    }}
                                                                                </v-list-item-title>

                                                                                <v-list-item-subtitle
                                                                                    >Disponible:
                                                                                    {{
                                                                                        data
                                                                                            .item
                                                                                            .stock
                                                                                    }}</v-list-item-subtitle
                                                                                >
                                                                            </v-list-item-content>
                                                                        </template>
                                                                    </v-autocomplete>
                                                                </v-col>
                                                                <v-col md="4">
                                                                    <v-text-field
                                                                        type="number"
                                                                        v-model="
                                                                            itemEditar.cantidad
                                                                        "
                                                                        label="Cantidad"
                                                                        ref="cantidad"
                                                                        required
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col>
                                                                    <v-select
                                                                        class="mt-n2"
                                                                        v-model="
                                                                            itemEditar.store_id
                                                                        "
                                                                        label="Deposito"
                                                                        :items="
                                                                            stores
                                                                        "
                                                                        placeholder="Seleccionar Deposito"
                                                                        item-text="nombre"
                                                                        item-value="id"
                                                                    ></v-select>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card-text>

                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            color="blue darken-1"
                                                            text
                                                            @click="
                                                                setCancelarEditItem();
                                                                selectedProduct =
                                                                    null;
                                                            "
                                                        >
                                                            Cancelar
                                                        </v-btn>
                                                        <v-btn
                                                            v-if="isEditarItem"
                                                            color="blue darken-1"
                                                            text
                                                            @click="
                                                                editarItem();
                                                                selectedProduct =
                                                                    null;
                                                            "
                                                        >
                                                            Editar
                                                        </v-btn>
                                                        <v-btn
                                                            v-else
                                                            color="blue darken-1"
                                                            text
                                                            @click="
                                                                agregarItem();
                                                                selectedProduct =
                                                                    null;
                                                            "
                                                        >
                                                            Agregar
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                            <v-row
                                                justify="space-between"
                                                class="mb-1"
                                            >
                                                <v-col md="3">
                                                    <h5>
                                                        Total:
                                                        {{
                                                            (
                                                                convertMoney(totalVenta, getCurrencyCode)
                                                            )
                                                        }}
                                                    </h5>
                                                </v-col>
                                                <v-col md="3">
                                                    <v-btn
                                                        color="primary"
                                                        @click="setNuevoItem()"
                                                    >
                                                        <v-icon>
                                                            mdi-plus
                                                        </v-icon>
                                                        Agregar
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <!--Dialog eliminar item-->
                                            <v-dialog
                                                v-model="dialogEliminarItem"
                                                max-width="500px"
                                                @click:outside="
                                                    setDialogEliminarItem(false)
                                                "
                                                @keydown.esc="
                                                    setDialogEliminarItem(false)
                                                "
                                            >
                                                <v-card>
                                                    <v-card-title
                                                        class="text-h5"
                                                        >Quieres retirar este
                                                        item?</v-card-title
                                                    >
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            color="blue darken-1"
                                                            text
                                                            @click="
                                                                setDialogEliminarItem(
                                                                    false
                                                                )
                                                            "
                                                            >Cancelar</v-btn
                                                        >
                                                        <v-btn
                                                            color="blue darken-1"
                                                            text
                                                            @click="
                                                                eliminarItem(
                                                                    index
                                                                )
                                                            "
                                                            >Eliminar</v-btn
                                                        >
                                                        <v-spacer></v-spacer>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </template>

                                        <!-- Mostrar precio subTotal -->
                                        <template
                                            v-slot:[`item.subtotal`]="{ item }"
                                        >
                                            {{ convertMoney(item.subtotal, getCurrencyCode) }}
                                        </template>

                                        <!-- Mostrar precio costo -->
                                        <template
                                            v-slot:[`item.precio_unitario`]="{
                                                item,
                                            }"
                                        >
                                            {{ convertMoney(item.precio_unitario, getCurrencyCode) }}
                                        </template>

                                        <!-- Iconos Editar Y Eliminar producto  -->
                                        <template
                                            v-slot:[`item.actions`]="{
                                                item,
                                                index,
                                            }"
                                        >
                                            <v-icon
                                                small
                                                class="mr-2"
                                                color="blue"
                                                @click="
                                                    setEditarItem(item, index)
                                                "
                                            >
                                                mdi-pencil
                                            </v-icon>
                                            <v-icon
                                                small
                                                class="mr-2"
                                                color="red"
                                                v-if="
                                                    sale.sale_detail.length > 1
                                                "
                                                @click="setEliminarItem(index)"
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="red lighten-2"
                            text
                            class="ma-3"
                            @click="cancelarEditar()"
                        >
                            Cerrar
                        </v-btn>

                        <v-btn
                            :disabled="!valid"
                            class="ma-3"
                            color="primary"
                            elevation="2"
                            @click="editSale()"
                        >
                            Editar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-form>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import { convertMoney } from "../../formatos";

export default {
    data: () => ({
        valid: true,
        buscarCliente: "",
        check: false,
        searchProduct: "",
        //selectedProduct: "",
        index: 0,
        isEditarItem: false,
        tituloEditar: "",
        selectStorage: null,
        selectedProduct: null,
    }),
    watch: {
        searchProduct(val) {
            this.buscarProductos({ buscardo: val, dep: this.selectStorage });
        },
        selectedProduct(val) {
            if (val != null) {
                let payload = {
                    payload: val,
                    isFromAutocomplete: true,
                };
                this.setItemEditar(payload);
            }
        },
        buscarCliente(val) {
            this.searchClient(val);
        },
        "sale.client"(val) {
            if(val != null){
                this.searchClient(val.ruc);
                this.sale.client_id = val.id;
            }
        },
        "sale.factura"(val){
            if(val) if(this.sale.factura_numero == null) this.getInvoiceNumber();
        },
    },
    computed: {
        //llamada de variables del store
        ...mapState("sale", [
            "dialog",
            "edit",
            "sale",
            "headersSale",
            "headersProduct",
            "dialogEditarItem",
            "cantidad",
            "itemEditar",
            "totalVenta",
            "dialogEliminarItem",
            "products",
        ]),
        ...mapState("deposito", ["stores"]),
        ...mapState("client", ["clients"]),
        ...mapState("app", ["snackbar"]),
        ...mapState("quote", ["quotes"]),

        ...mapGetters("deposito", ["getLocaleStorageStore"]),

        //Dependiendo del edit (boolean) cambia el titulo del form
        formTitle() {
            return this.edit === false ? "Nueva Venta" : "Editar Venta";
        },
        formTitleEditProduct() {
            return this.isEditProduct === false ? "AGREGAR" : "EDITAR";
        },
        formColorEditProduct() {
            return this.isEditProduct === false ? "primary" : "green";
        },
        methodPlazo() {
            if (this.sale.credito) {
                const rule = (v) => !!v || "Plazo es obligatorio";
                this.plazoRules.push(rule);
            } else {
                this.plazoRules = [];
            }
            return this.plazoRules;
        },
        getCurrencyCode() {
            return this.quotes.find((co) => co.currency_id === this.sale?.currency_id || 1).codigo;
        },
    },

    mounted() {
        this.getAllStores();
        this.selectStorage = parseInt(this.getLocaleStorageStore);
        this.buscarProductos({ buscardo: "", dep: this.selectStorage });
    },

    methods: {
        ...mapActions("client", ["searchClient"]),
        ...mapActions("deposito", ["getAllStores"]),
        ...mapActions("sale", [
            "editSale",
            "buscarProductos",
            "agregarItem",
            "setItemEditar",
            "editarItem",
            "eliminarItem",
            "getOneSale",
            "getInvoiceNumber",
        ]),

        ...mapMutations("sale", [
            //"setEdit",
            "setIsEditProduct",
            "setDialogEditarItem",
            "setTotalVentaEditar",
            "cancelarEditar",
            "setDialogEliminarItem",
            "setCancelarEditItem",
        ]),
        ...mapMutations("app", ["setSnackbarInfo"]),

        //validar campo requerido
        //Dependiendo del edit (boolean), crea o edita la marca
        //resetea validacion, obtiene las listas marca, borra los campos nombre url
        validateSubmit(edit, sale) {
            sale.pagado = false;

            if (!sale.credito) {
                sale.credito = false;
                sale.pagado = true;
                sale.plazo = 0;
            }

            sale.products = this.saleProducts;

            if (!this.getSaleProductCount) {
                this.setSnackbarInfo(
                    "No hay productos agregados, agregue al menos uno"
                );
                return;
            }

            if (this.$refs.form.validate()) {
                if (edit) {
                    this.editSale(sale);
                } else {
                    this.createSale(sale);
                }

                this.resetValidate();
            }

            this.localShow = true;
        },

        setEditarItem(item, index) {
            this.tituloEditar = "Editar";
            this.isEditarItem = true;

            let temp = {
                /**se copian los valores a una variable temporal porque o sino se vuelve reactivo en la tabla y en caso de cancelar se muestra los valores cargados */
                id: item.id,
                cantidad: item.cantidad,
                precio_unitario: item.precio_unitario,
                sale_header_id: item.sale_header_id,
                product_id: item.product_id,
                subtotal: item.subtotal,
                product: {
                    id: item.product.id,
                    nombre: item.product.nombre,
                    codigo: item.product.codigo,
                    img_url: item.product.img_url,
                },
                stock: item.stock,
            };

            let payload = {
                payload: temp,
                isFromAutocomplete: false,
                index: index,
            };

            this.setItemEditar(payload);
            this.setDialogEditarItem(true);
        },

        setEliminarItem(index) {
            this.setDialogEliminarItem(true);
            this.index = index;
        },

        setNuevoItem() {
            this.tituloEditar = "Agregar";
            this.isEditarItem = false;
            this.setDialogEditarItem(true);
        },
        //resetea las validaciones activadas
        resetValidate() {
            this.$refs.form.resetValidation();
        },
        validate() {
            this.$refs.form.validate();
        },

        filterObjectClient(item, queryText, itemText) {
            return (
                item.nombre_y_apellido
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.ruc
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },
        filterObjectProduct(item, queryText, itemText) {
            return (
                (item.producto &&
                    item.producto
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.codigo &&
                    item.codigo
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.descripcion &&
                    item.descripcion
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.marca &&
                    item.marca
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.categoria &&
                    item.categoria
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1)
            );
        },
        borraClient() {
            this.sale.client_id = null;
        },
        convertMoney
    },
};
</script>

<style scoped>
.table {
    background-color: #f7f7f8;
}
.v-data-table-header {
    background-color: lightblue;
}
</style>
