var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},on:{"click:outside":function($event){return _vm.cerrarDialogProduct()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cerrarDialogProduct()}},model:{value:(_vm.dialogFamily),callback:function ($$v) {_vm.dialogFamily=$$v},expression:"dialogFamily"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_vm._v(" "),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{ref:"nombre_familia_producto",attrs:{"label":"Nombre","rules":[_vm.required('nombre')],"required":""},model:{value:(_vm.product.nombre),callback:function ($$v) {_vm.$set(_vm.product, "nombre", $$v)},expression:"product.nombre"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Código"},model:{value:(_vm.product.codigo),callback:function ($$v) {_vm.$set(_vm.product, "codigo", $$v)},expression:"product.codigo"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"label":"Código de referencia","rules":[_vm.required('referencia')],"error":_vm.errorReferencia,"error-messages":_vm.errorReferencia ? 'Código de referencia repetido' : '',"required":""},model:{value:(_vm.product.referencia),callback:function ($$v) {_vm.$set(_vm.product, "referencia", $$v)},expression:"product.referencia"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-autocomplete',{attrs:{"menu-props":{
                                        nudgeBottom: 15 + 'px',
                                    },"items":_vm.brands,"search-input":_vm.searchBrand,"label":"Elija una marca","item-text":"nombre","item-value":"id","cache-items":"","clearable":"","loading":_vm.loading},on:{"update:searchInput":function($event){_vm.searchBrand=$event},"update:search-input":function($event){_vm.searchBrand=$event},"click:clear":function($event){_vm.product.brand_id = null}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-sheet',{staticClass:"d-flex justify-center ma-2"},[_c('h6',[_vm._v("Marca inexistente")])]),_vm._v(" "),_c('v-sheet',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.setDialogBrand();
                                                    _vm.setIsBrandForOther();}}},[_vm._v("Crear Marca")])],1)]},proxy:true},{key:"item",fn:function({ item }){return [_c('v-card',{staticClass:"mr-2 rounded",attrs:{"outlined":"","tile":""}},[(!!item.img_url)?_c('v-img',{attrs:{"max-height":"50","max-width":"50","src":item.img_url}}):_vm._e()],1),_vm._v("\n                                        "+_vm._s(item.nombre)+"\n                                    ")]}},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.endIntersectBrand),expression:"endIntersectBrand"}]})]},proxy:true}]),model:{value:(_vm.product.brand_id),callback:function ($$v) {_vm.$set(_vm.product, "brand_id", $$v)},expression:"product.brand_id"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.categories,"search-input":_vm.searchCategory,"label":"Seleccione una categoria","item-text":"nombre","item-value":"id","multiple":"","cache-items":"","clearable":""},on:{"update:searchInput":function($event){_vm.searchCategory=$event},"update:search-input":function($event){_vm.searchCategory=$event},"click:clear":function($event){_vm.product.category = null}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-sheet',{staticClass:"d-flex justify-center ma-2"},[_c('h6',[_vm._v("Categoria inexistente")])]),_vm._v(" "),_c('v-sheet',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"primary",on:{"click":function($event){_vm.setDialogCategory();
                                                    _vm.setIsCategoryForOther();}}},[_vm._v("Crear Categoria")])],1)]},proxy:true},{key:"item",fn:function({ item }){return [_c('v-card',{staticClass:"mr-2 rounded",attrs:{"outlined":"","tile":""}},[(!!item.img_url)?_c('v-img',{attrs:{"max-height":"50","max-width":"50","src":item.img_url}}):_vm._e()],1),_vm._v("\n                                        "+_vm._s(item.nombre)+"\n                                    ")]}},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(
                                                _vm.endIntersectCategory
                                            ),expression:"\n                                                endIntersectCategory\n                                            "}]})]},proxy:true}]),model:{value:(_vm.product.category),callback:function ($$v) {_vm.$set(_vm.product, "category", $$v)},expression:"product.category"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"d-flex align-end",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-file-input',{attrs:{"accept":"image/*","label":"Selccionar Imagen","rules":_vm.sizeRules,"show-size":"","counter":"","clearable":""},on:{"change":_vm.onFileChange},model:{value:(_vm.filename),callback:function ($$v) {_vm.filename=$$v},expression:"filename"}})],1),_vm._v(" "),_c('v-col',{staticClass:"d-flex align-end mb-5",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-sheet',{staticClass:"rounded",attrs:{"elevation":"2"}},[(_vm.showImg)?_c('v-img',{staticClass:"rounded text-center align-end pa-1",attrs:{"src":_vm.showImg,"height":"150","width":"120","aspect-ratio":"2","contain":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                    on,
                                                    attrs,
                                                }){return [_c('v-fab-transition',[_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","dark":"","bottom":"","color":"red"},on:{"click":_vm.resetImage}},'v-btn',attrs,false),on),[_vm._v("Eliminar\n                                                    ")])],1)]}}],null,false,3542831796)},[_vm._v(" "),_c('span',[_vm._v("Eliminar Imagen")])])],1):_vm._e()],1)],1),_vm._v(" "),_c('v-col',{attrs:{"md":"4"}},[_c('v-select',{attrs:{"items":_vm.opcionesIva,"label":"Iva"},model:{value:(_vm.product.iva),callback:function ($$v) {_vm.$set(_vm.product, "iva", $$v)},expression:"product.iva"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Descripcion","rows":"2"},model:{value:(_vm.product.descripcion),callback:function ($$v) {_vm.$set(_vm.product, "descripcion", $$v)},expression:"product.descripcion"}})],1)],1)],1),_vm._v(" "),_c('small',[_vm._v("*Campos obligatorios")]),_vm._v(" "),_c('v-divider')],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":function($event){return _vm.cerrarDialogProduct()}}},[_vm._v("\n                        Cerrar\n                    ")]),_vm._v(" "),(_vm.edit)?_c('v-btn',{attrs:{"disabled":!_vm.valid,"color":"blue darken-1","outlined":""},on:{"click":function($event){return _vm.validateSubmit(_vm.edit, _vm.product)}}},[_vm._v("\n                        Editar\n                    ")]):_c('v-btn',{attrs:{"disabled":!_vm.valid || _vm.isLoading,"loading":_vm.isLoading,"color":"blue darken-1","outlined":""},on:{"click":function($event){return _vm.validateSubmit(_vm.edit, _vm.product)}}},[_vm._v("\n                        Guardar\n                    ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }