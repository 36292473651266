import axios from "axios";
//Utilizado en remision
const state = {
    transDatas: [],
    dialogFormTransData: false,
    dialogDeleteTransData: false,
    isEditTransData: false,
    transData: {},
    headers: [
        {
            text: "Motivo",
            align: "start",
            value: "motivo",
        },
        {
            text: "Responsable",
            align: "start",
            value: "responsable",
        },
        {
            text: "Recorrido Estimado",
            align: "start",
            value: "recorrido_estimativo",
        },
        {
            text: "Direc. Recogida",
            align: "start",
            value: "direccion_recogida",
        },
        {
            text: "Direc. Entrega",
            align: "start",
            value: "direccion_entrega",
        },
        {
            text: "Fecha Inicio",
            align: "start",
            value: "fecha_inicio",
        },
        {
            text: "Fecha Fin",
            align: "start",
            value: "fecha_fin",
        },
        { text: "Opciones", value: "actions", sortable: false, width: "150px" },
    ],
};
const actions = {
    getTransDatas({ commit }) {
        axios
            .get("transfer_datas")
            .then((res) => {
                commit("GET_TRANS_DATAS", res.data);
            })
            .catch((err) => {
                throw err;
            });
    },
    createTransData({ commit, dispatch }, payload) {
        axios
            .post("transfer_datas", payload)
            .then((res) => {
                commit("CLEAR_TRANSFER_DATA");
                commit("CREATE_TRANSFER_DATA");
                dispatch("getTransDatas");
            })
            .catch((err) => {
                throw err;
            });
    },
    editTransData({ commit, dispatch }, payload) {
        axios
            .put(`transfer_datas/${payload.id}`, payload)
            .then((res) => {
                commit("CLEAR_TRANSFER_DATA");
                commit("EDIT_TRANSFER_DATA");
                dispatch("getTransDatas");
            })
            .catch((err) => {
                throw err;
            });
    },
    deleteTransData({ commit, dispatch }, payload) {
        axios
            .delete(`transfer_datas/${payload.id}`)
            .then((res) => {
                dispatch("getTransDatas");
            })
            .catch((err) => {
                throw err;
            });
    },
};
const mutations = {
    setDialogFormTransData(state, payload) {
        state.dialogFormTransData = payload;
    },
    setDialogDeleteTransData(state, payload) {
        state.dialogDeleteTransData = payload;
    },
    setTransData(state, payload) {
        state.transData = payload;
    },
    handlerEditTransData(state, transData) {
        state.isEditTransData = true;
        state.dialogFormTransData = true;
        state.transData = Object.assign({}, transData);
    },
    handlerClose(state) {
        state.transData = {
            motivo: "",
            responsable: "",
            recorrido_estimativo: "",
            direccion_recogida: "",
            direccion_entrega: "",
            fecha_inicio: "",
            fecha_fin: "",
        };
        state.dialogFormTransData = false;
        state.isEditTransData = false;
    },
    GET_TRANS_DATAS(state, payload) {
        state.transDatas = payload;
    },
    CREATE_TRANSFER_DATA(state) {
        state.isEditTransData = false;
        state.dialogFormTransData = false;
    },
    EDIT_TRANSFER_DATA(state) {
        state.isEditTransData = false;
        state.dialogFormTransData = false;
    },
    CLEAR_TRANSFER_DATA(state) {
        state.transData = {
            motivo: "",
            responsable: "",
            recorrido_estimativo: "",
            direccion_recogida: "",
            direccion_entrega: "",
            fecha_inicio: "",
            fecha_fin: "",
        };
    },
};
const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
