<template>
    <v-card class="mx-4 my-8">
        <v-toolbar flat color="white" class="my-4">
            <v-toolbar-title>
                <h4>Lista Productos</h4>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                class="mr-2"
                solo
                color="primary"
                @click="setDialogTransfer(true)"
            >
                Transferencia
            </v-btn>

            <v-btn
                class="mr-2"
                solo
                color="primary"
                @click="setDialogRemission(true)"
            >
                <v-icon left> mdi-archive-arrow-up-outline </v-icon>
                Remision
            </v-btn>

            <v-btn
                class="mr-2"
                solo
                color="primary"
                @click="setDialogIncome(true)"
            >
                <v-icon left> mdi-plus </v-icon>
                Ingreso
            </v-btn>
            <!-- <v-btn icon color="green" @click="handlePrintStockProducts()">
                <v-icon> mdi-file-pdf-box </v-icon>
            </v-btn> -->
        </v-toolbar>
        <v-data-table
            :items="productsStore"
            :headers="getHeaderAdmin"
            show-expand
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            :loading="loading"
            :options="options"
            no-filter
            hide-default-footer
            class="mx-4"
        >
            <template v-slot:top>
                <!-- Encabezado Lista Tabla -->
                <!-- Buscar y Button Agregar -->

                <v-row align="center" justify="center">
                    <v-col sm="12" md="6" lg="4">
                        <v-text-field
                            v-model="searchProduct.filtro"
                            dense
                            outlined
                            label="Buscar Producto"
                            append-icon="mdi-magnify"
                            :clearable="true"
                            @input="handleInputSearch()"
                        ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" lg="3">
                        <AutocompleteBrandComponent v-model="localBrand" />
                    </v-col>
                    <v-col sm="12" md="6" lg="3">
                        <AutocompleteCategoryComponent
                            v-model="localCategory"
                        />
                    </v-col>

                    <v-col sm="12" md="6" lg="2">
                        <v-select
                            v-model="currency"
                            :items="quotes"
                            dense
                            outlined
                            item-text="nombre"
                            item-value="quotation_id"
                            label="Moneda"
                        />
                    </v-col>
                </v-row>
            </template>

            <template v-slot:progress class="my-4">
                <div class="text-center">
                    Cargando...
                    <v-progress-linear
                        indeterminate
                        color="primary"
                        height="10"
                    />
                </div>
            </template>

            <template v-slot:[`item.precio_venta`]="{ item }">
                {{ item.precio_venta.toLocaleString("es-ES") }}
            </template>

            <template
                v-slot:[`item.latest_purchase_detail.precio_unitario`]="{
                    item,
                }"
            >
                {{
                    convertMoney(
                        item.latest_purchase_detail?.precio_unitario || 0,
                        getCurrencyCode
                    )
                }}
            </template>

            <template
                v-slot:[`item.latest_purchase_detail.created_at`]="{ item }"
            >
                {{
                    item.latest_purchase_detail
                        ? convertDate(item.latest_purchase_detail.created_at)
                        : "--"
                }}
            </template>

            <template v-slot:[`item.precio_mayorista`]="{ item }">
                {{ item.precio_mayorista.toLocaleString("es-ES") }}
            </template>

            <template v-slot:[`item.precio_descuento`]="{ item }">
                {{ item.precio_descuento.toLocaleString("es-ES") }}
            </template>

            <!-- Columnas de precios -->
            <!-- <template v-slot:item.precio_compra_promedio="{ item }"> -->
            <template v-slot:[`item.precio_compra_promedio`]="{ item }">
                {{ convertMoney(item.precio_compra_promedio, getCurrencyCode) }}
            </template>

            <template v-slot:item.precio_venta="{ item }">
                {{ convertMoney(item.precio_venta, getCurrencyCode) }}
            </template>

            <template v-slot:item.precio_mayorista="{ item }">
                {{ convertMoney(item.precio_mayorista, getCurrencyCode) }}
            </template>

            <template v-slot:item.precio_descuento="{ item }">
                {{ convertMoney(item.precio_descuento, getCurrencyCode) }}
            </template>

            <template
                v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }"
            >
                <v-icon
                    v-if="!isExpanded && returnIsProductPerBox(item.product)"
                    class="mr-2"
                    :color="!isExpanded ? 'primary' : 'red'"
                    dense
                    @click="handleClickConvert(), expand(!isExpanded)"
                >
                    {{ isExpanded ? "mdi-close" : icons.mdiArchiveSyncOutline }}
                </v-icon>
                <v-icon
                    v-if="!isExpanded"
                    :color="!isExpanded ? 'green' : 'red'"
                    dense
                    @click="leerPrecioProducto(item), expand(!isExpanded)"
                    >{{ isExpanded ? "mdi-close" : "mdi-pencil" }}</v-icon
                >

                <v-icon
                    v-if="!isExpanded"
                    :color="!isExpanded ? 'red darken-3' : 'red'"
                    dense
                    :disabled="!permisos.includes('edit_purchase_price')"
                    @click="leerPrecioFinalProducto(item), expand(!isExpanded)"
                    >{{ isExpanded ? "mdi-close" : icons.mdiCashSync }}</v-icon
                >
                <v-icon
                    v-else
                    color="red"
                    @click="
                        expand(!isExpanded);
                        changeFinalPrice = false;
                        convertProd = false;
                    "
                    dense
                    >mdi-close</v-icon
                >
            </template>

            <template v-slot:expanded-item="{ headers, item }">
                <!-- Editar precio de productos -->
                <td
                    v-if="changeFinalPrice"
                    :colspan="headers.length"
                    class="ma-8 pa-5 mb-2"
                >
                    <v-row align="center">
                        <v-col cols="2">
                            <h5>Editar precio</h5>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                dense
                                outlined
                                label="Ultima compra"
                                type="number"
                                hide-details
                                v-model="localProduct.precio_unitario"
                            />
                        </v-col>
                        <v-col cols="4">
                            <p class="text-h6 red--text">
                                Esta operacion no es recomendada...
                            </p>
                        </v-col>
                        <v-spacer />
                        <v-col cols="2" class="text-center">
                            <v-btn
                                color="blue"
                                outlined
                                dense
                                @click="editarPrecioFinalProducto(item)"
                            >
                                Editar
                            </v-btn>
                        </v-col>
                    </v-row>
                </td>

                <td
                    v-else-if="!convertProd && !changeFinalPrice"
                    :colspan="headers.length"
                    class="ma-8 pa-5 mb-2"
                >
                    <v-row align="center">
                        <v-col cols="2">
                            <h5>Editar precio</h5>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                dense
                                outlined
                                label="Precio venta"
                                type="number"
                                hide-details
                                v-model="localProduct.precio_venta"
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                dense
                                outlined
                                label="Precio Mayorista"
                                type="number"
                                hide-details
                                v-model="localProduct.precio_mayorista"
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                dense
                                outlined
                                label="Precio Descuento"
                                type="number"
                                hide-details
                                v-model="localProduct.precio_descuento"
                            />
                        </v-col>
                        <v-spacer />
                        <v-col cols="2" class="text-center">
                            <v-btn
                                color="blue"
                                outlined
                                dense
                                @click="editarPrecioProducto(item)"
                            >
                                Editar
                            </v-btn>
                        </v-col>
                    </v-row>
                </td>

                <!-- Convertir producto a paquete -->
                <td v-else :colspan="headers.length" class="pa-0 pb-1">
                    <v-card class="pa-2" tile elevation="8">
                        <v-row align="center">
                            <v-col cols="3">
                                <v-card elevation="1" class="pa-2">
                                    <div class="text-overline ml-4">
                                        {{
                                            !!item.product.caja_product_id
                                                ? "Caja"
                                                : "Producto"
                                        }}
                                    </div>
                                    <v-card-text>
                                        {{ item.product.nombre }}
                                    </v-card-text>
                                    <v-card-text>
                                        {{ item.product.codigo }}
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col
                                cols="3"
                                class="d-flex justify-center flex-column align-items-center"
                            >
                                <v-icon
                                    class="mb-2"
                                    size="36"
                                    color="primary"
                                    >{{
                                        icons.mdiArrowRightBoldBoxOutline
                                    }}</v-icon
                                >
                                <v-text-field
                                    type="number"
                                    solo
                                    placeholder="Cantidad"
                                    v-model="localProductBox.cantidad"
                                />
                            </v-col>

                            <v-col cols="3">
                                <v-card elevation="1" class="pa-2">
                                    <div class="text-overline ml-4">
                                        {{
                                            !item.product.caja_product_id
                                                ? "Caja"
                                                : "Producto"
                                        }}
                                    </div>

                                    <v-card-text>
                                        {{
                                            item.product?.can_convert_to_product
                                                ?.nombre
                                                ? item.product
                                                      ?.can_convert_to_product
                                                      ?.nombre
                                                : item.product
                                                      ?.can_convert_to_box
                                                      ?.nombre
                                        }}
                                    </v-card-text>
                                    <v-card-text>
                                        {{
                                            item.product?.can_convert_to_product
                                                ?.codigo
                                                ? item.product
                                                      ?.can_convert_to_product
                                                      ?.codigo
                                                : item.product
                                                      ?.can_convert_to_box
                                                      ?.codigo
                                        }}
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-spacer />
                            <v-col cols="2" class="text-center">
                                <v-btn
                                    color="blue"
                                    outlined
                                    @click="convertirProducto(item)"
                                >
                                    Convertir
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </td>
            </template>
        </v-data-table>
        <v-container>
            <!--  <v-row justify="end" align="center">
                <v-col cols="2" align-self="center">
                    <p class="text-subtitle-2">Por Pagina:</p>
                </v-col>
                <v-col cols="2">
                    <v-select
                        class="selected-tag mt-6"
                        dense
                        outlined
                        v-model="depositosPage.perPage"
                        :items="[5, 10, 25, 50]"
                    />
                </v-col>
                <v-col cols="8">
                    <v-container class="max-width"> -->
            <v-pagination
                v-model="depositosPage.current"
                :length="depositosPage.lastPage"
                @input="inputPage(depositosPage)"
            />
            <!-- </v-container>
                </v-col>
            </v-row>-->
        </v-container>
    </v-card>
</template>
<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import { convertMoney, convertDate } from "../../formatos";
import {
    mdiArchiveSyncOutline,
    mdiArrowRightBoldBoxOutline,
    mdiCashSync,
} from "@mdi/js";
import AutocompleteBrandComponent from "../AutocompleteBrand.Component.vue";
import AutocompleteCategoryComponent from "../AutocompleteCategory.Component.vue";
import LoadingComponentVue from "../Loading.Component.vue";

export default {
    data: () => ({
        store: null,
        singleExpand: true,
        expanded: [],
        searchProduct: {
            store_id: 0,
            category_id: null,
            brand_id: null,
            filtro: "",
        },
        localProduct: {
            precio_venta: null,
            precio_descuento: null,
            precio_mayorista: null,
        },
        localProductBox: {
            cantidad: 0,
        },
        currency: 1,
        convertProd: false,
        changeFinalPrice: false,
        icons: {
            mdiArchiveSyncOutline,
            mdiArrowRightBoldBoxOutline,
            mdiCashSync,
        },
        localBrand: {},
        localCategory: {},
    }),
    components: {
        AutocompleteBrandComponent,
        AutocompleteCategoryComponent,
        LoadingComponentVue,
    },
    async mounted() {
        await this.getQuoteCurrency();
        this.currency = this.getQuote.quotation_id;
        this.searchProduct.store_id = this.store = parseInt(
            this.getLocaleStorageStore
        )
            ? parseInt(this.getLocaleStorageStore)
            : this.stores[0];
        this.searchStoreProducts(this.searchProduct);
    },

    computed: {
        ...mapState("deposito", [
            "productsStore",
            "headers",
            "headersAdmin",
            "stores",
            "dialogFormPriceEdit",
            "loading",
            "depositosPage",
        ]),
        ...mapState("app", ["permisos"]),
        ...mapState("income", ["dialogIncome"]),
        ...mapGetters("deposito", ["getLocaleStorageStore", "getDepositoPage"]),
        ...mapState("quote", ["quotes"]),
        ...mapGetters("quote", ["getQuote"]),
        getCurrencyCode() {
            return this.quotes.find((co) => co.currency_id === this.currency)
                .codigo;
        },

        getHeaderAdmin() {
            return this.findInArray(this.permisos, "stock_edit")
                ? this.headers
                : this.headersAdmin;
        },
        options: {
            get() {
                return this.getDepositoPage;
            },
            set(val) {
                return this.setDepositoPage(val);
            },
        },
    },

    watch: {
        currency(newVal, oldVal) {
            this.setLoading(true);
            this.restoreCurrencyConversion(oldVal); // se llama para revertir la conversion. y volver a la moneda principal
            this.currencyConversion(newVal); // se llama para hacer la conversion a la moneda seleccionada
        },
        localBrand(val, old) {
            this.depositosPage.current = 1;
            this.searchProduct.store_id = this.store;
            this.searchProduct.brand_id = val?.id;
            this.searchStoreProducts(this.searchProduct);
        },
        localCategory(val, old) {
            this.depositosPage.current = 1;
            this.searchProduct.store_id = this.store;
            this.searchProduct.category_id = val?.id;
            this.searchStoreProducts(this.searchProduct);
        },
    },

    methods: {
        ...mapMutations("prod_box", [
            "setDialogConverProductBox",
            "setConvertProductBox",
        ]),
        ...mapActions("quote", ["getQuoteCurrency"]),
        ...mapActions("prod_box", ["convertProductPerBox"]),
        ...mapActions("deposito", [
            "getAllStores",
            "getProductsStores",
            "getProductPrice",
            "editProductPrice",
            "editProductPurchasePrice",
            "searchStoreProducts",
            "printStockProducts",
        ]),
        ...mapMutations("deposito", [
            "setDialogFormPriceEdit",
            "setDialogFormStore",
            "setLocaleStorageStore",
            "currencyConversion",
            "restoreCurrencyConversion",
            "setLoading",
            "setDepositoPage",
        ]),
        ...mapMutations("income", ["setDialogIncome"]),
        ...mapMutations("transfer", ["setDialogTransfer"]),
        ...mapMutations("remission", ["setDialogRemission"]),

        editarPrecioProducto(item) {
            if (
                item.precio_mayorista === 0 &&
                this.localProduct.precio_mayorista === 0
            ) {
                this.localProduct.precio_mayorista =
                    this.localProduct.precio_venta;
            }
            if (
                item.precio_descuento === 0 &&
                this.localProduct.precio_descuento === 0
            ) {
                this.localProduct.precio_descuento =
                    this.localProduct.precio_venta;
            }

            this.localProduct.id = item.id;
            this.localProduct.dep = this.store;
            this.editProductPrice(this.localProduct);
            this.expanded = [];
        },

        editarPrecioFinalProducto(item) {
            this.localProduct.id = item.id;
            this.localProduct.dep = this.store;
            this.editProductPurchasePrice(this.localProduct);
            this.expanded = [];
        },

        leerPrecioProducto(item) {
            this.localProduct = {
                id: item.id,
                precio_venta: item.precio_venta.toFixed(2),
                precio_descuento: item.precio_descuento.toFixed(2),
                precio_mayorista: item.precio_mayorista.toFixed(2),
            };

            this.convertProd = false;
            this.changeFinalPrice = false;
        },
        leerPrecioFinalProducto(item) {
            this.localProduct = {
                id: item.id,
                precio_unitario: parseFloat(
                    item.latest_purchase_detail.precio_unitario
                ).toFixed(2),
            };

            this.convertProd = false;
            this.changeFinalPrice = true;
            this.localProduct.id = item.id;
        },
        handleChangeStore(item) {
            this.setLocaleStorageStore(item);
            this.getProductsStores({ dep: item });
        },

        handleClickConvert() {
            this.convertProd = true;
        },

        convertirProducto(item) {
            const obj = {
                stock_id: item.id,
                cantidad: this.localProductBox.cantidad,
                is_box_to_product: !!item.product.caja_product_id
                    ? true
                    : false,
            };

            this.convertProductPerBox(obj);
            this.localProductBox = {
                cantidad: 0,
            };
            this.searchProduct = {
                store_id: 0,
                category_id: 0,
                brand_id: 0,
                filtro: "",
            };
            this.expanded = [];
        },
        returnIsProductPerBox(payload) {
            return (
                _.isObject(payload.can_convert_to_box) ||
                _.isObject(payload.can_convert_to_product)
            );
        },
        findInArray(myArray, item) {
            return !!myArray.find((el) => el == item);
        },
        async inputPage(op) {
            this.searchProduct.store_id = parseInt(this.getLocaleStorageStore)
                ? parseInt(this.getLocaleStorageStore)
                : this.stores[0];
            this.options = op;
            this.searchStoreProducts(this.searchProduct);
        },
        handleInputSearch() {
            this.depositosPage.current = 1;
            this.searchProduct.store_id = parseInt(this.getLocaleStorageStore)
                ? parseInt(this.getLocaleStorageStore)
                : this.stores[0];
            this.searchStoreProducts(this.searchProduct);
        },
        handleInputBrand(payload) {
            this.depositosPage.current = 1;
            this.searchProduct.store_id = parseInt(this.getLocaleStorageStore)
                ? parseInt(this.getLocaleStorageStore)
                : this.stores[0];
            this.searchProduct.brand_id = payload;
            this.searchStoreProducts(this.searchProduct);
        },
        handleInputCategory(payload) {
            this.depositosPage.current = 1;
            this.searchProduct.store_id = parseInt(this.getLocaleStorageStore)
                ? parseInt(this.getLocaleStorageStore)
                : this.stores[0];
            this.searchProduct.category_id = payload;
            this.searchStoreProducts(this.searchProduct);
        },
        handlePrintStockProducts() {
            this.printStockProducts(this.searchProduct);
        },
        convertMoney,
        convertDate,
    },
};
</script>
<style scoped>
.v-progress-linear__content {
    height: 1000px;
    z-index: 1;
}
.select-page {
    max-width: 100px;
}
.v-data-footer {
    display: flex;
    justify-content: center;
}
.selected-tag {
    width: 100px;
}
</style>
