<template>
    <v-form>
        <v-dialog v-model="dialogMoneyIn" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Ingreso de dinero</span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <vuetify-money
                                v-model="moneyIn.monto"
                                label="Monto en guaranies"
                                :options="vmOptions"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                :items="quotes"
                                item-text="nombre"
                                item-value="quotation_id"
                                label="Moneda"
                                v-model="moneyIn.quotation_id"
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="moneyIn.motivo"
                                label="Motivo"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="red"
                            text
                            @click="setDialogMoneyIn(false)"
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="setDialogMoneyIn(false), agregarDinero()"
                        >
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
    data: () => ({
        localItem: {},
    }),
    watch: {
        dialogMoneyIn(val) {
            if (val) {
                this.moneyIn.quotation_id = this.getQuote.quotation_id;
            }
        },
    },
    mounted() {
        this.getQuoteCurrency();
    },
    computed: {
        ...mapState("box", ["dialogMoneyIn", "isEditar", "moneyIn"]),
        ...mapState("app", ["vmOptions"]),
        ...mapState("quote", ["quotes", "quote"]),
        ...mapGetters("quote", ["getQuote"]),
        titulo() {
            return this.isEditar
                ? "Editar ingreso de dinero"
                : "Nuevo ingreso de dinero";
        },
        btnText() {
            return this.isEditar ? "Editar" : "Guardar";
        },
    },
    methods: {
        ...mapMutations("box", ["setDialogMoneyIn"]),
        ...mapActions("box", ["addMoneyBox", "editMoneyIn"]),
        ...mapActions("quote", ["getQuoteCurrency"]),

        agregarDinero() {
            this.isEditar
                ? this.editMoneyIn(this.moneyIn)
                : this.addMoneyBox(this.moneyIn);
        },
    },
};
</script>

<style></style>
