<template>
    <v-dialog
        v-model="dialogIncome"
        @click:outside="setDialogIncome(false)"
        @keydown.esc="setDialogIncome(false)"
        width="1200px"
    >
        <v-form ref="form" v-model="valid">
            <v-card>
                <v-form ref="formProduct" v-model="validProduct">
                    <v-card-title>Ingresar productos </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6">
                                <!-- <v-autocomplete
                                    :loading="loadingSearchProduct"
                                    v-model="selectedProduct"
                                    :items="products"
                                    :search-input.sync="search"
                                    label="Elija un producto"
                                    return-object
                                    item-value="id"
                                    clearable
                                    no-filter
                                    @click="searchOnlyProduct('')"
                                    required
                                    :disabled="this.editar"
                                    :rules="[(v) => !!v || 'Campo Obligatorio']"
                                >
                                    <template v-slot:no-data>
                                        <div
                                            class="d-flex flex-column align-items-center"
                                        >
                                            <h6>Producto inexistente</h6>
                                            <v-btn
                                                class="ma-2"
                                                outlined
                                                small
                                                color="primary"
                                                @click="
                                                    setDialog();
                                                    searchProduct = '';
                                                "
                                            >
                                                <v-icon dark>mdi-plus</v-icon>
                                                Agregar
                                            </v-btn>
                                        </div>
                                    </template>
                                    <template
                                        slot="selection"
                                        slot-scope="data"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{
                                                    `${data.item.nombre} - ${data.item.codigo}`
                                                }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{
                                                    `${data.item.nombre} - ${data.item.codigo}`
                                                }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete> -->

                                <AutocompleteOnlyProductComponent
                                    v-model="selectedProduct"
                                />
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    v-model="cantidad"
                                    type="Number"
                                    label="cantidad"
                                />
                            </v-col>
                            <v-col cols="2">
                                <v-select
                                    v-model="selectedStore"
                                    :items="stores"
                                    label="Deposito"
                                    item-text="nombre"
                                    item-value="id"
                                    :rules="storeRules"
                                    @change="
                                        (item) => setLocaleStorageStore(item)
                                    "
                                ></v-select>
                            </v-col>
                            <v-col class="d-flex" align-self="center">
                                <v-btn
                                    :color="btnAddEditColor"
                                    outlined
                                    @click="agregarProducto"
                                    >{{ btnAddEditName }}</v-btn
                                >
                            </v-col>
                        </v-row>

                        <v-data-table
                            :headers="headersIncome"
                            :items="incomeProducts"
                            hide-default-footer
                        >
                            <template v-slot:[`item.nombre`]="{ item }">
                                <span>{{ item.nombre }}</span>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon
                                    color="green"
                                    @click="editarProducto(item)"
                                    >mdi-pencil</v-icon
                                >
                                <v-icon
                                    v-if="!editar"
                                    color="red"
                                    @click="deleteIncomeProducts(item)"
                                    >mdi-cancel</v-icon
                                >
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-form>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="red"
                        outlined
                        @click="
                            setDialogIncome(false);
                            clearIncome();
                        "
                        >Cerrar</v-btn
                    >
                    <v-btn color="primary" outlined @click="guardarIngreso"
                        >Guardar</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { requiredObject, requiredSelection } from "../../../utils/validate";
import AutocompleteOnlyProductComponent from "../../AutocompleteOnlyProduct.Component.vue";

export default {
    data: () => ({
        valid: true,
        validProduct: true,
        queryTerm: "",
        searchProduct: "",
        selectedStore: null,
        localProduct: {},
        selectedProduct: null,
        editar: false,
        cantidad: 0,
        storeRules: [
            (v) => !!v || "Es necesario seleccionar deposito",
            (v) => v !== 0 || "Deposito origen es obligatorio",
        ],
        requiredObject,
        requiredSelection,
    }),
    components: {
        AutocompleteOnlyProductComponent,
    },
    watch: {
        searchProduct(val) {
            this.searchOnlyProduct(val);
        },
        selectedStore(val) {
            this.selectedStore = val;
        },
        selectedProduct(val) {
            if (val) {
                this.localProduct.product_id = val.id;
                this.localProduct.nombre = val.nombre;
            }
        },
        dialogIncome(val) {
            if (val) {
                this.searchOnlyProduct("");
            }
        },
    },
    computed: {
        ...mapState("income", [
            "dialogIncome",
            "income",
            "incomeProducts",
            "headersIncome",
        ]),
        ...mapState("deposito", ["stores"]),
        ...mapState("product", ["products", "loadingSearchProduct"]),
        ...mapGetters("deposito", ["getLocaleStorageStore"]),

        btnAddEditName() {
            return this.editar ? "Editar" : "Agregar";
        },
        btnAddEditColor() {
            return this.editar ? "green" : "primary";
        },
        search: {
            get() {
                return this.queryTerm;
            },

            set(searchInput) {
                if (this.queryTerm !== searchInput) {
                    this.queryTerm = searchInput;
                    this.searchOnlyProduct(searchInput);
                }
            },
        },
    },
    methods: {
        ...mapMutations("income", [
            "setDialogIncome",
            "addIncomeProducts",
            "editIncomeProducts",
            "deleteIncomeProducts",
            "clearIncome",
        ]),
        ...mapMutations("deposito", ["setLocaleStorageStore"]),
        ...mapMutations("app", ["setSnackbar", "setSnackbarInfo"]),
        ...mapMutations("product", ["setDialog"]),

        ...mapActions("deposito", ["getAllStores"]),
        ...mapActions("product", ["searchOnlyProduct"]),
        ...mapActions("income", ["createIncomeStore"]),

        agregarProducto() {
            if (!this.$refs.formProduct.validate()) {
                this.setSnackbarInfo("Campos obligatorios");
                return;
            }

            this.cantidad ??= 0;
            if (this.cantidad <= 0) {
                this.setSnackbar({
                    text: "Cantidad no puede ser menor o igual a 0",
                    type: "info",
                    timeout: 1500,
                    show: true,
                    icon: "mdi-alert-circle-outline",
                });
                return;
            }

            this.localProduct.cantidad = parseInt(this.cantidad);

            if (this.editar) {
                this.selectedProduct.cantidad = this.cantidad;
                this.editIncomeProducts(this.selectedProduct);
                this.editar = false;
            } else {
                this.addIncomeProducts(this.localProduct);
            }

            this.localProduct = {};
            this.selectedProduct = null;
            this.cantidad = 0;

            this.$refs.formProduct.resetValidation();
        },

        editarProducto(item) {
            this.editar = true;
            this.selectedProduct = {
                product_id: item.product_id,
                cantidad: item.cantidad,
                nombre: item.nombre,
            };
            this.cantidad = item.cantidad;
        },

        guardarIngreso() {
            this.$refs.form.validate();
            if (this.valid) {
                if (!this.incomeProducts.length) {
                    this.setSnackbar({
                        text: "No puedes crear ingreso sin productos",
                        type: "info",
                        timeout: 1500,
                        show: true,
                        icon: "mdi-alert-circle-outline",
                    });
                    return;
                }
                const newIncome = {
                    store_id: this.selectedStore,
                    details: this.incomeProducts,
                };
                this.createIncomeStore(newIncome);
            } else {
                this.setSnackbar({
                    text: "Algun campo no esta completa",
                    type: "info",
                    timeout: 1500,
                    show: true,
                    icon: "mdi-alert-circle-outline",
                });
            }
        },
    },
    mounted() {
        this.selectedStore = parseInt(this.getLocaleStorageStore);
    },
};
</script>

<style></style>
