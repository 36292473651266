<template>
    <div class="mx-10">
        <v-data-table
            :headers="headersSale"
            :items="sales"
            :options.sync="options"
            :loading="loading"
            hide-default-footer
        >
            <template v-slot:footer>
                <v-pagination
                    v-model="salesPage.current"
                    class="py-4"
                    :length="salesPage.lastPage"
                    @input="inputPage(salesPage)"
                />
            </template>

            <template v-slot:top>
                <!-- Encabezado Lista Tabla -->
                <!-- Buscar y Button Agregar -->
                <v-row dense justify="space-between">
                    <v-col cols="12" md="6">
                        <v-text-field
                            :disabled="!isNinguna"
                            v-model="search"
                            label="Buscar"
                            append-icon="mdi-magnify"
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col md="4" sm="4">
                        <v-select
                            v-model="selectedFiltro"
                            :items="filtros"
                            label="Filtro"
                            outlined
                            dense
                        ></v-select>
                    </v-col>
                </v-row>
            </template>

            <!-- Columna Cliente -->
            <template v-slot:[`item.ruc`]="{ item }">
                <v-sheet
                    class="pa-2 ma-md-2 d-flex flex-column rounded"
                    v-if="item.ruc"
                >
                    <div class="subtitle-2">
                        {{ item.nombre_y_apellido }}
                    </div>
                    <div class="body-2">
                        {{ item.ruc }}
                    </div>
                </v-sheet>
            </template>

            <!-- Columna credito -->
            <template v-slot:[`item.credito`]="{ item }">
                <span>
                    {{
                        getCreditText(
                            item.credito,
                            item.pagado,
                            item.plazo,
                            item.created_at
                        )
                    }}
                </span>
                <!-- <v-chip
                    class="ma-2"
                    label
                    :color="
                        item.credito == 0
                            ? 'primary'
                            : item.pagado == 1
                            ? 'green lighten-1'
                            : 'deep-orange darken-1'
                    "
                    text-color="white
                    "
                >
                    <v-icon left>{{
                        item.credito == 1
                            ? "mdi-credit-card-multiple"
                            : "mdi-cash-multiple"
                    }}</v-icon>

                    {{ isCredito(item.credito) }}
                </v-chip>-->
            </template>

            <!-- Columna Plazo
            <template v-slot:[`item.plazo`]="{ item }">
                {{
                    item.plazo === null || item.plazo === 0
                        ? "Sin Plazo"
                        : item.plazo
                }}
            </template> -->

            <!-- Mostrar anulado -->
            <template v-slot:[`item.factura_numero`]="{ item }">
                {{
                    item.factura_numero === null
                        ? "Sin Factura"
                        : item.factura_numero
                }}
                <span v-if="item.anulado" class="text-danger">anulado</span>
            </template>

            <!-- columna precio total de productos -->
            <template v-slot:[`item.total`]="{ item }">
                {{ convertMoney(item.total, item?.codigo || "PYG") }}
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
                <span>{{ item.fecha }}</span>
            </template>

            <!-- Iconos Editar Y Eliminar  -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    color="blue"
                    @click="imprimirVenta(item.id)"
                >
                    mdi-printer
                </v-icon>
                <v-icon
                    small
                    class="mr-2"
                    color="green"
                    @click="showSaleDetail(item.id)"
                >
                    mdi-eye
                </v-icon>
                <v-icon
                    :disabled="isCanCancel && !!item.anulado"
                    small
                    class="mr-2"
                    color="red"
                    @click="setAnularVenta(item.id)"
                >
                    mdi-cancel
                </v-icon>

                <v-menu
                    bottom
                    v-if="
                        permisos.includes('sale_edit') ||
                        permisos.includes('sale_delete')
                    "
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon icon v-bind="attrs" v-on="on"
                            >mdi-dots-vertical</v-icon
                        >
                    </template>

                    <v-list dense>
                        <v-list-item
                            v-if="permisos.includes('sale_edit')"
                            class="font-weight-medium"
                            @click="
                                setDialog();
                                setEdit();
                                getEditSale(item);
                            "
                        >
                            <v-icon small class="mr-2" color="blue">
                                mdi-pencil
                            </v-icon>
                            Editar
                        </v-list-item>
                        <v-list-item
                            v-if="permisos.includes('sale_delete')"
                            class="font-weight-medium"
                            @click="
                                localSale = item;
                                setDeleteDialog();
                            "
                        >
                            <v-icon small class="mr-2" color="red">
                                mdi-delete Eliminar
                            </v-icon>
                            Eliminar
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>

        <!-- Paginacion para sale -->
        <!-- <v-pagination
            v-model="salesPage.current"
            class="py-4"
            :length="salesPage.lastPage"
        /> -->

        <DeleteDialog
            :dialog="deleteDialog"
            :setDialog="setDeleteDialog"
            v-on:deleted="setDeleteSale(localSale)"
        />
        <AvisoAperturaDialog />
        <v-dialog
            v-model="dialogAnular"
            max-width="500"
            class="ma-0 pa-0"
            content-class="elevation-0"
        >
            <v-alert
                class="bg-white"
                outlined
                color="deep-orange"
                type="warning"
            >
                ¿Desea anular esta venta?
                <v-card-actions class="mt-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mr-3"
                        outlined
                        text
                        @click="dialogAnular = false"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        outlined
                        text
                        color="deep-orange"
                        @click="
                            anularFactura(idAnular);
                            dialogAnular = false;
                        "
                        >Anular</v-btn
                    >
                </v-card-actions>
            </v-alert>
        </v-dialog>
        <DialogShowSale />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";

import DeleteDialog from "../DeleteDialog.Component.vue";
import DialogShowSale from "./DialogShowSale.Component.vue";
import AvisoAperturaDialog from "../AvisoAperturaDialog.vue";
import { convertMoney } from "../../formatos";

export default {
    data: () => ({
        search: "",
        searchDetailProduct: "",
        localSale: {},
        dialogAnular: false,
        idAnular: 0,
        filtros: ["Producto", "Cliente", "Numero", "Ninguna"],
        selectedFiltro: "Ninguna",
        isNinguna: false,
        localSearch: {},
    }),
    watch: {
        //! TODO agregar paginacion
        // "salesPage.current"(newVal, oldVal) {
        //     if (newVal !== oldVal) {
        //         this.getSales();
        //     }
        // },
        search(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.salesPage.current = 1;
            }
            if (newVal === "") {
                this.getSales();
            } else {
                if (this.selectedFiltro === "Producto") {
                    this.localSearch.product = newVal;
                    this.searchSales(this.localSearch);
                }
                if (this.selectedFiltro === "Cliente") {
                    this.localSearch.client = newVal;
                    this.searchSales(this.localSearch);
                }
                if (this.selectedFiltro === "Numero") {
                    this.localSearch.number = newVal;
                    this.searchSales(this.localSearch);
                }
            }
        },
        selectedFiltro(val) {
            if (val === "Ninguna") {
                this.isNinguna = false;
                this.getSales();
            } else {
                this.isNinguna = true;
            }
        },
    },
    components: {
        DeleteDialog,
        DialogShowSale,
        AvisoAperturaDialog,
    },
    /*created() {  //Edgar saca esta parte, porque cuando se llama a otra ruta desde otro lugar, se sobrescribe siempre con esta ruta aca
        this.setRuta(`/sales?page=${this.salesPage.current}`);
    },*/
    mounted() {
        this.getSales();
        this.getPermissions(this.$route.path);
        this.canCancel();
    },
    computed: {
        ...mapState("sale", [
            "sales",
            "headersSale",
            "sale",
            "deleteDialog",
            "isCanCancel",
            "loading",
            "salesPage",
        ]),
        ...mapState("app", ["snackbar", "tipoImpresion", "permisos"]),
        ...mapState("app", ["canCreate", "canEdit", "canDelete"]),
        ...mapGetters("sale", ["getSaleProductCount", "getSalesPage"]),
        getCreditColor() {
            return this.sale.pagado == 1
                ? "green lighten-1"
                : "deep-orange darken-1";
        },
        options: {
            get() {
                return this.getSalesPage;
            },
            set(val) {
                return this.setSalesPage(val);
            },
        },
    },
    methods: {
        ...mapActions("sale", [
            "getSales",
            "deleteSale",
            "getEditSale",
            // "getStockProduct",
            "getShowSale",
            "canCancel",
            "anularFactura",
            "printSaleInvoice",
            "searchSales",
        ]),
        ...mapMutations("sale", [
            "setDialog",
            "setEdit",
            "getEdit",
            "setSale",
            "setDeleteDialog",
            "clearSale",
            "setDialogShowSale",
            "setDialogImpresion",
            "setSalesPage",
            //  "setRuta",
        ]),
        ...mapActions("app", ["getPermissions"]),
        ...mapMutations("app", ["setDialogFacturacion", "setOpcionFactura"]),
        ...mapActions("report", ["getInvoiceReport"]),

        //Eliminar Categoria
        setDeleteSale(sale) {
            this.deleteSale(sale);
        },

        isCredito(cred) {
            return cred == 1 ? "Credito" : "Contado";
        },
        showSaleDetail(id) {
            this.getShowSale(id);
            this.setDialogShowSale(true);
        },
        redirectSaleDetail(id) {
            this.$router.push(`sale/${id}`);
        },
        setAnularVenta(id) {
            this.dialogAnular = true;
            this.idAnular = id;
        },

        imprimirVenta(payload) {
            // this.setOpcionFactura({ id: payload });
            // this.setDialogFacturacion(true);

            // this.printSaleInvoice(payload);
            this.getInvoiceReport(payload);
        },
        getCreditText(credito, pagado, plazo, fecha) {
            let text = "";
            if (credito) {
                if (pagado) {
                    text = "Pagado";
                } else {
                    let fechaActual = new Date();
                    const dia = 1000 * 60 * 60 * 24;

                    let fechaActualUTC = new Date(fechaActual.toISOString());

                    let fechaDesdeBaseDatos = new Date(fecha);
                    fechaDesdeBaseDatos.setDate(
                        fechaDesdeBaseDatos.getDate() + plazo
                    );

                    if (fechaDesdeBaseDatos < fechaActualUTC) {
                        let diferenciaMilisegundos =
                            fechaActualUTC - fechaDesdeBaseDatos;
                        let diferenciaDias =
                            Math.floor(diferenciaMilisegundos / dia) + 1;

                        text = `-${diferenciaDias} / ${plazo} días`;
                    } else {
                        let diferencia = fechaDesdeBaseDatos - fechaActualUTC;
                        diferencia = Math.floor(diferencia / dia);
                        diferencia = plazo - diferencia + 1;

                        text = `${diferencia}/${plazo} días`;
                    }
                }
            } else {
                text = "Contado";
            }
            return text;
        },
        async inputPage(op) {
            this.options = op;
            if (this.search !== "") {
                await this.searchSales(this.localSearch);
            } else {
                await this.getSales();
            }
        },

        convertMoney,
    },
};
</script>
