import jsPDF from "jspdf";
import { numToText } from "numero-a-texto";
import autoTable from "jspdf-autotable";
import { convertMoney } from "../../formatos";
import printJS from "print-js";

const DATOS_FACTURA = [
    "Encarnación,",
    "de",
    "Cond. de Venta:",
    "Señor(es):",
    "C.I. o RUC:",
    "Dirección:",
    "Tel:",
    "Nota de remision N°:",
];

const DATOS_CABECERA = [
    [
        "Cant",
        "CLASE DE MERCADERIAS Y/O SERVICIOS",
        "PRECIO UNITARIO",
        "EXENTAS",
        "5%",
        "10%",
    ],
];

const DATOS_PAGOS = [
    "VALOR PARCIAL:",
    "TOTAL A PAGAR:",
    "LIQUIDACIÓN DEL IVA: (5%):",
    "(10%):",
    "TOTAL IVA:",
];

const state = {};
const actions = {};
const mutations = {
    printFactura(state, payload) {
        var pdf = new jsPDF("p", "mm");
        pdf.setFontSize(9);

        const { params, productos } = payload;

        let count = 0;
        let index;
        productos.invoice_header.forEach((arraySale) => {
            let doble = 0;
            // Configuracion de la hoja //
            // ------------------------------- //

            // Posicionar datos
            // ------------------

            if (count !== 0) {
                pdf.addPage("p");
            }
            for (index = 0; index < 2; index++) {
                pdf.setDrawColor(0);
                pdf.setFillColor(255, 255, 255);
                pdf.roundedRect(5, 42 + doble, 202, 15, 0, 0, "FD");

                pdf.text(6, 45 + doble, DATOS_FACTURA[0], "left");
                pdf.text(40, 45 + doble, DATOS_FACTURA[1], "left");
                pdf.text(150, 45 + doble, DATOS_FACTURA[2], "left");
                pdf.text(6, 50 + doble, DATOS_FACTURA[3], "left");
                pdf.text(150, 50 + doble, DATOS_FACTURA[4], "left");
                pdf.text(6, 55 + doble, DATOS_FACTURA[5], "left");
                pdf.text(120, 55 + doble, DATOS_FACTURA[6], "left");
                pdf.text(165, 55 + doble, DATOS_FACTURA[7], "left");

                pdf.setDrawColor(0);
                pdf.setFillColor(255, 255, 255);
                pdf.roundedRect(5, 115 + doble, 202, 18, 0, 0, "FD");
                pdf.text(6, 120 + doble, DATOS_PAGOS[1], "left");

                pdf.line(155, 115 + doble, 155, 133 + doble);
                pdf.line(35, 121 + doble, 153, 121 + doble);
                pdf.line(7, 127 + doble, 153, 127 + doble);

                pdf.setDrawColor(0);
                pdf.setFillColor(255, 255, 255);
                pdf.roundedRect(5, 129 + doble, 202, 5, 0, 0, "FD");
                pdf.text(6, 133 + doble, DATOS_PAGOS[2], "left");
                pdf.text(90, 133 + doble, DATOS_PAGOS[3], "left");
                pdf.text(150, 133 + doble, DATOS_PAGOS[4], "left");

                const date = new Date(productos.created_at) /// se agrega created_at para que se genere con la fecha de la venta
                    .toLocaleDateString("es-PY", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                    })
                    .split("de");
                pdf.text(date[0], params[0].ancho, params[0].alto + doble);
                pdf.text(date[1], params[0].ancho + 30, params[0].alto + doble);
                pdf.text(date[2], params[0].ancho + 50, params[0].alto + doble);
                pdf.text(
                    productos.client?.ruc,
                    params[1].ancho,
                    params[1].alto + doble
                );
                pdf.text(
                    productos.client?.nombre_y_apellido,
                    params[2].ancho,
                    params[2].alto + doble
                );
                if (productos.credito) {
                    pdf.text(
                        "Credito",
                        params[4].ancho,
                        params[4].alto + doble
                    );
                } else {
                    pdf.text(
                        "Contado",
                        params[3].ancho,
                        params[3].alto + doble
                    );
                }

                if (productos.client?.direccion != null) {
                    pdf.text(
                        productos.client?.direccion,
                        params[5].ancho,
                        params[5].alto + doble
                    );
                }
                let i = 0;
                let iva5 = 0;
                let iva10 = 0;
                let ivaEx = 0;
                arraySale.invoice_detail.forEach((item) => {
                    if (productos.client.nacionalidad === "Paraguay") {
                        if (item.iva === "5%") {
                            iva5 += item.subtotal;
                        }
                        if (item.iva === "10%") {
                            iva10 += item.subtotal;
                        }
                    }
                    if (item.iva === "exenta" || item.newIva === "exenta") {
                        ivaEx += item.subtotal;
                    }
                    i += 5;
                });

                autoTable(pdf, {
                    startY: 60 + doble,
                    theme: "grid",
                    head: DATOS_CABECERA,
                    margin: { left: 5, right: 4 },
                    bodyStyles: {
                        fontStyle: "normal",
                        fontSize: 10,
                    },
                    headStyles: {
                        fontStyle: "normal",
                        fontSize: 9,
                        fillColor: false,
                        textColor: [0, 0, 0],
                        lineWidth: 0.1,
                        halign: "center",
                    },
                    body: arraySale.arrayForPrint,
                    columnStyles: {
                        0: { cellWidth: 10, fontSize: 8, halign: "center" },
                        1: {
                            cellWidth: 112,
                            fontSize: 8,
                            overflow: "ellipsize",
                        },
                        2: { cellWidth: 20, fontSize: 8, halign: "right" },
                        3: { cellWidth: 20, fontSize: 8, halign: "right" },
                        4: { cellWidth: 20, fontSize: 8, halign: "right" },
                        5: { cellWidth: 20, fontSize: 8, halign: "right" },
                    },
                    styles: {
                        cellPadding: 0.5,
                    },
                });

                pdf.text(
                    numToText.translate(arraySale.total).toUpperCase(),
                    params[13].ancho,
                    params[13].alto + doble,
                    {
                        maxWidth: params[13].largo,
                        align: "justify",
                    }
                );
                pdf.text(
                    arraySale.total.toLocaleString("es-Es", {
                        maximumFractionDigits: 2,
                    }),
                    params[14].ancho,
                    params[14].alto + doble
                );

                //iva
                let ivaTotal = iva5 / 21 + iva10 / 11;
                pdf.text(
                    Math.round(iva5 / 21).toLocaleString("es-Es", {
                        maximumFractionDigits: 2,
                    }),
                    params[15].ancho,
                    params[15].alto + doble
                );
                pdf.text(
                    Math.round(iva10 / 11).toLocaleString("es-Es", {
                        maximumFractionDigits: 2,
                    }),
                    params[16].ancho,
                    params[16].alto + doble
                );
                pdf.text(
                    Math.round(ivaTotal).toLocaleString("es-Es", {
                        maximumFractionDigits: 2,
                    }),
                    params[17].ancho,
                    params[17].alto + doble
                );
                doble += 143;
            }

            count++;
        });

        // let datos = [[1, "LENTE PROTECTOR TRANSPARENTE", "10.000", "10.000"]];

        // autoTable(pdf, {
        //     startY: 60,
        //     theme: "grid",
        //     head: DATOS_CABECERA,
        //     margin: { left: 5, right: 4 },
        //     bodyStyles: {
        //         fontStyle: "normal",
        //         fontSize: 10,
        //     },
        //     headStyles: {
        //         fontStyle: "normal",
        //         fontSize: 9,
        //         fillColor: false,
        //         textColor: [0, 0, 0],
        //         lineWidth: 0.1,
        //         halign: "center",
        //     },
        //     body: datos,
        //     columnStyles: {
        //         0: { cellWidth: 10, fontSize: 8, halign: "center" },
        //         1: {
        //             cellWidth: 112,
        //             fontSize: 8,
        //             overflow: "ellipsize",
        //         },
        //         2: { cellWidth: 20, fontSize: 8, halign: "right" },
        //         3: { cellWidth: 20, fontSize: 8, halign: "right" },
        //         4: { cellWidth: 20, fontSize: 8, halign: "right" },
        //         5: { cellWidth: 20, fontSize: 8, halign: "right" },
        //     },
        //     styles: {
        //         cellPadding: 0.5,
        //     },
        // });

        const data = pdf.output("blob");
        const blobUrl = URL.createObjectURL(data);
        printJS(blobUrl);
    },

    printOneFactura(state, payload) {
        // ---------------- //
        // params devuelve toda la posicion de impresion
        // productos devuelve los array de 10 item de compra
        const { params, productos } = payload;

        var pdf = new jsPDF("p", "mm");
        pdf.setFontSize(9);

        let doble = 0;
        for (let index = 0; index < 2; index++) {
            pdf.setDrawColor(0);
            pdf.setFillColor(255, 255, 255);
            pdf.roundedRect(5, 42 + doble, 202, 15, 0, 0, "FD");

            pdf.text(6, 45 + doble, DATOS_FACTURA[0], "left");
            pdf.text(40, 45 + doble, DATOS_FACTURA[1], "left");
            pdf.text(150, 45 + doble, DATOS_FACTURA[2], "left");
            pdf.text(6, 50 + doble, DATOS_FACTURA[3], "left");
            pdf.text(150, 50 + doble, DATOS_FACTURA[4], "left");
            pdf.text(6, 55 + doble, DATOS_FACTURA[5], "left");
            pdf.text(120, 55 + doble, DATOS_FACTURA[6], "left");
            pdf.text(165, 55 + doble, DATOS_FACTURA[7], "left");

            pdf.setDrawColor(0);
            pdf.setFillColor(255, 255, 255);
            pdf.roundedRect(5, 115 + doble, 202, 18, 0, 0, "FD");
            pdf.text(6, 120 + doble, DATOS_PAGOS[1], "left");

            pdf.line(155, 115 + doble, 155, 133 + doble);
            pdf.line(35, 121 + doble, 153, 121 + doble);
            pdf.line(7, 127 + doble, 153, 127 + doble);

            pdf.setDrawColor(0);
            pdf.setFillColor(255, 255, 255);
            pdf.roundedRect(5, 129 + doble, 202, 5, 0, 0, "FD");
            pdf.text(6, 133 + doble, DATOS_PAGOS[2], "left");
            pdf.text(90, 133 + doble, DATOS_PAGOS[3], "left");
            pdf.text(150, 133 + doble, DATOS_PAGOS[4], "left");

            const date = new Date(productos.created_at) /// se agrega created_at para que se genere con la fecha de la venta
                .toLocaleDateString("es-PY", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                })
                .split("de");
            pdf.text(date[0], params[0].ancho, params[0].alto + doble);
            pdf.text(date[1], params[0].ancho + 30, params[0].alto + doble);
            pdf.text(date[2], params[0].ancho + 50, params[0].alto + doble);
            pdf.text(
                productos.client?.ruc,
                params[1].ancho,
                params[1].alto + doble
            );
            pdf.text(
                productos.client?.nombre_y_apellido,
                params[2].ancho,
                params[2].alto + doble
            );
            if (productos.credito) {
                pdf.text("Credito", params[4].ancho, params[4].alto + doble);
            } else {
                pdf.text("Contado", params[3].ancho, params[3].alto + doble);
            }

            if (productos.client?.direccion != null) {
                pdf.text(
                    productos.client?.direccion,
                    params[5].ancho,
                    params[5].alto + doble
                );
            }
            let i = 0;
            let iva5 = 0;
            let iva10 = 0;
            let ivaEx = 0;
            let total = 0;
            productos.invoice_detail.forEach((item) => {
                if (productos.client.nacionalidad === "Paraguay") {
                    if (item.iva === "5%") {
                        iva5 += item.subtotal;
                    }
                    if (item.iva === "10%") {
                        iva10 += item.subtotal;
                    }
                }
                if (item.iva === "exenta" || item.newIva === "exenta") {
                    ivaEx += item.subtotal;
                }
                i += 5;
                total += item.subtotal;
            });

            autoTable(pdf, {
                startY: 60 + doble,
                theme: "grid",
                head: DATOS_CABECERA,
                margin: { left: 5, right: 4 },
                bodyStyles: {
                    fontStyle: "normal",
                    fontSize: 10,
                },
                headStyles: {
                    fontStyle: "normal",
                    fontSize: 9,
                    fillColor: false,
                    textColor: [0, 0, 0],
                    lineWidth: 0.1,
                    halign: "center",
                },
                body: productos.arrayForPrint,
                columnStyles: {
                    0: { cellWidth: 10, fontSize: 8, halign: "center" },
                    1: {
                        cellWidth: 112,
                        fontSize: 8,
                        overflow: "ellipsize",
                    },
                    2: { cellWidth: 20, fontSize: 8, halign: "right" },
                    3: { cellWidth: 20, fontSize: 8, halign: "right" },
                    4: { cellWidth: 20, fontSize: 8, halign: "right" },
                    5: { cellWidth: 20, fontSize: 8, halign: "right" },
                },
                styles: {
                    cellPadding: 0.5,
                },
            });

            pdf.text(
                numToText.translate(total).toUpperCase(),
                params[13].ancho,
                params[13].alto + doble,
                {
                    maxWidth: params[13].largo,
                    align: "justify",
                }
            );
            pdf.text(
                total.toLocaleString("es-Es", {
                    maximumFractionDigits: 2,
                }),
                params[14].ancho,
                params[14].alto + doble
            );

            //iva
            let ivaTotal = iva5 / 21 + iva10 / 11;
            pdf.text(
                Math.round(iva5 / 21).toLocaleString("es-Es", {
                    maximumFractionDigits: 2,
                }),
                params[15].ancho,
                params[15].alto + doble
            );
            pdf.text(
                Math.round(iva10 / 11).toLocaleString("es-Es", {
                    maximumFractionDigits: 2,
                }),
                params[16].ancho,
                params[16].alto + doble
            );
            pdf.text(
                Math.round(ivaTotal).toLocaleString("es-Es", {
                    maximumFractionDigits: 2,
                }),
                params[17].ancho,
                params[17].alto + doble
            );
            doble += 143;
        }

        // -------------------- //
        //Inicio de alineacion para factura

        // ------------------------- //
        //Impresion de la factura
        const data = pdf.output("blob");
        const blobUrl = URL.createObjectURL(data);
        printJS(blobUrl);
    },

    impresionPedido(state, payload) {
        const empresa = JSON.parse(localStorage.getItem("empresa"));
        const hoja = JSON.parse(localStorage.getItem("hoja_ticket"));
        const mensaje = JSON.parse(localStorage.getItem("mensaje"));

        var pdf = new jsPDF("p", "mm", [hoja.alto, hoja.ancho]);

        const date = new Date();
        const day = date.getDate();
        const month = date.getMonth() + 1; // The month index starts from 0
        const year = date.getFullYear();

        const hour = date.getHours();
        const min = date.getMinutes();

        //Nombre empresa
        pdf.setFontSize(10);
        pdf.text(7, 5, empresa.nombre);
        pdf.setFontSize(10);

        //Cliente
        pdf.text(
            7,
            15,
            `Cliente: ${
                payload.client != null
                    ? payload.client.nombre_y_apellido
                    : "-- --"
            }`
        );
        //Ruc
        pdf.text(
            7,
            20,
            `Ruc: ${payload.client != null ? payload.client.ruc : "--"}`
        );
        //fecha emision

        pdf.text(7, 25, `Fecha emision: ${day}/${month}/${year}`);
        pdf.text(7, 30, `Hora emision: ${hour}:${min} `);
        pdf.text(7, 35, `N° Pedido: ${payload.numero}`);

        pdf.text(7, 40, `Vendedor: ${payload.seller.username}`);

        let alturaDetalle = 0;
        let arrayForPrint = [];

        payload.order_detail.forEach((element) => {
            const prod = [
                element.cantidad,
                element.product.nombre,
                element.precio_unitario,
                element.subtotal,
            ];
            arrayForPrint.push(prod);
        });

        // todo: Devolver arrayforprint diferente cuando es ticket
        autoTable(pdf, {
            startY: 45,
            margin: {
                left: 7,
            },
            head: [["Cant.", "Producto", "P. Unit.", "Sub. T."]],
            body: arrayForPrint,
            didDrawPage: function (data) {
                alturaDetalle = data.cursor.y;
            },
            styles: { fontSize: 10, cellPadding: 0.5, halign: "right" },

            theme: "plain",
        });

        pdf.text(
            7,
            alturaDetalle + 5,
            `TOTAL: ${convertMoney(payload.total, payload.codigo)}`
        );
        pdf.text(7, alturaDetalle + 15, "\t\t----------------------------");
        // ------------------------- //
        //Impresion de la factura
        const data = pdf.output("blob");
        const blobUrl = URL.createObjectURL(data);
        printJS(blobUrl);
    },
    impresionPresupuesto(state, payload) {
        const empresa = JSON.parse(localStorage.getItem("empresa"));
        const hoja = JSON.parse(localStorage.getItem("hoja_ticket"));
        const mensaje = JSON.parse(localStorage.getItem("mensaje"));

        var pdf = new jsPDF("p", "mm", [hoja.alto, hoja.ancho]);

        const date = new Date();
        const day = date.getDate();
        const month = date.getMonth() + 1; // The month index starts from 0
        const year = date.getFullYear();

        const hour = date.getHours();
        const min = date.getMinutes();

        //Nombre empresa
        pdf.setFontSize(10);
        pdf.text(7, 5, empresa.nombre);
        pdf.setFontSize(10);

        //Cliente
        pdf.text(
            7,
            15,
            `Cliente: ${
                payload.client != null
                    ? payload.client.nombre_y_apellido
                    : "-- --"
            }`
        );
        //Ruc
        pdf.text(
            7,
            20,
            `Ruc: ${payload.client != null ? payload.client.ruc : "--"}`
        );
        //fecha emision

        pdf.text(7, 25, `Fecha emision: ${day}/${month}/${year}`);
        pdf.text(7, 30, `Hora emision: ${hour}:${min} `);
        pdf.text(7, 35, `N° Presupuesto: ${payload.numero}`);

        pdf.text(7, 40, `Vendedor: ${payload.seller.username}`);

        let alturaDetalle = 0;
        let arrayForPrint = [];

        payload.budget_detail.forEach((element) => {
            const prod = [
                element.cantidad,
                element.product.nombre,
                element.precio_unitario,
                element.subtotal,
            ];
            arrayForPrint.push(prod);
        });

        // todo: Devolver arrayforprint diferente cuando es ticket
        autoTable(pdf, {
            startY: 45,
            margin: {
                left: 7,
            },
            head: [["Cant.", "Producto", "P. Unit.", "Sub. T."]],
            body: arrayForPrint,
            didDrawPage: function (data) {
                alturaDetalle = data.cursor.y;
            },
            styles: { fontSize: 10, cellPadding: 0.5, halign: "right" },

            theme: "plain",
        });

        pdf.text(
            7,
            alturaDetalle + 5,
            `TOTAL: ${convertMoney(payload.total, payload.currency.codigo)}`
        );
        pdf.text(7, alturaDetalle + 15, "\t\t----------------------------");

        // ------------------------- //
        //Impresion de la factura
        const data = pdf.output("blob");
        const blobUrl = URL.createObjectURL(data);
        printJS(blobUrl);
    },
    printTicket(state, payload) {
        //Todo configurable taman para papel
        //Todo configurable el tamanho de fuente

        state.loadingFactura = false;
        const empresa = JSON.parse(localStorage.getItem("empresa"));
        const hoja = JSON.parse(localStorage.getItem("hoja_ticket"));
        const mensaje = JSON.parse(localStorage.getItem("mensaje"));

        var pdf = new jsPDF("p", "mm", [hoja.alto || 78, hoja.ancho || 100]);
        const { params, productos } = payload;

        const date = new Date();
        const day = date.getDate();
        const month = date.getMonth() + 1; // The month index starts from 0
        const year = date.getFullYear();

        const hour = date.getHours();
        const min = date.getMinutes();
        //Nombre empresa
        pdf.setFontSize(12);
        pdf.text(2, 5, empresa?.nombre || "");
        pdf.setFontSize(8);
        pdf.text(2, 10, empresa?.cell || "");

        //Cliente
        pdf.text(
            2,
            15,
            `Cliente: ${
                productos.client != null
                    ? productos.client.nombre_y_apellido
                    : "Venta Publico"
            }`
        );
        //Ruc
        pdf.text(
            2,
            20,
            `Ruc: ${productos.client != null ? productos.client.ruc : "--"}`
        );
        //fecha emision

        pdf.text(2, 25, `Fecha emision: ${day}/${month}/${year}`);
        pdf.text(2, 30, `Hora emision: ${hour}:${min} `);
        pdf.text(2, 35, `N. Doc: ${productos.numero}`);

        pdf.text(2, 40, `Vendedor: ${productos.seller.username}`);

        let alturaDetalle = 0;

        // todo: Devolver arrayforprint diferente cuando es ticket
        autoTable(pdf, {
            startY: 45,
            margin: {
                left: 0,
            },
            tableWidth: hoja.ancho,
            head: [["Cant.", "Producto", "P. Unit.", "Sub. T."]],
            body: productos.arrayForPrint,
            didDrawPage: function (data) {
                alturaDetalle = data.cursor.y;
            },
            styles: { fontSize: 8, cellPadding: 0.5, halign: "right" },

            theme: "plain",
        });

        const price = new Intl.NumberFormat("es-PY").format(productos.total);

        pdf.text(
            2,
            alturaDetalle + 5,
            `TOTAL: ${price} ${productos.currency.simbolo}`
        );

        pdf.text(2, alturaDetalle + 10, mensaje?.datos || "");

        // ------------------------- //
        //Impresion de la factura
        const data = pdf.output("blob", {
            margin: [0, 10, 0, 10],
        });
        const blobUrl = URL.createObjectURL(data);
        printJS(blobUrl);
    },
};
const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
