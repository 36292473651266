import jsPDF from "jspdf";
import { convertMoney } from "../../formatos";
import autoTable from "jspdf-autotable";
const state = {
    stock: [],
    search: "",
    headers: [
        { text: "Cantidad", value: "cantidad" },
        { text: "Producto", value: "product.nombre" },
        { text: "Código", value: "product.codigo" },
        { text: "Costo promedio", value: "precio_compra_promedio" },
        { text: "Precio Descuento", value: "precio_descuento" },
        { text: "Precio Mayorista", value: "precio_mayorista" },
        { text: "Precio Venta", value: "precio_venta" },
    ],
    totalRow: [],
    loading: false,
};
const actions = {
    getStock({ commit }) {
        axios.get("/get_stock", { dep: 1 }).then((response) => {
            commit("SET_STOCK", response.data);
        });
    },
    generarInformeStock({ state, commit }, payload) {
        state.loading = true;
        axios.post("/informe_stock", payload).then((response) => {
            const report = response.data;
             /**SE GENERA EL INFORME GENERAL */
            let header = [ "Código", "Producto", "Existencia", "Reserva", "Disponible", "Costo promedio", "Venta", "Diferencia"];
            const doc = new jsPDF('l', 'mm', [297, 210]);
            doc.text("Informe de stock "+report.dep.nombre, 100, 10);
            doc.text("Informe General", 15, 20);
            /* convertir a formato moneda y sumar totales*/
            let sumaCompra = 0;
            let sumaVenta = 0;
            report.informe.forEach((e) => {
                sumaCompra += (e[5] * e[2]);
                sumaVenta += (e[6] * e[2]);
                e[5] = convertMoney(e[5], report.currency.codigo);
                e[6] = convertMoney(e[6], report.currency.codigo);
                e[7] = convertMoney(e[7], report.currency.codigo);
            });

            sumaCompra = convertMoney(sumaCompra, report.currency.codigo);
            sumaVenta = convertMoney(sumaVenta, report.currency.codigo);
            commit("SET_TOTAL_OBJECT", {sumaCompra: sumaCompra, sumaVenta: sumaVenta});

            /* agregando objeto para crear la ultima fila con los totales */
            var body = [ ...report.informe , state.totalRow];

            autoTable(doc, {
                startY: 30,
                head: [header],
                body: body,
                columnStyles: {
                    0: { cellWidth: 30 },
                    3: { halign: "center" },
                    4: { halign: "center" },
                    5: { halign: "center" },
                },
            });

            // SE AGREGA INFORME POR CATEGORIA
            doc.addPage();
            doc.text("Informe por categorías", 100, 20);
            let alturaTexto = 30;
            let alturaTabla = 35;
            // for que recorre categoria por categoria
            report.categories.forEach( (categorias, index ) => {
                sumaCompra = 0;
                sumaVenta = 0;
                // for que recorre para sumar los totales y convertir a formato moneda
                categorias.report.forEach((e)=>{
                    sumaCompra += (e[5] * e[2]);
                    sumaVenta += (e[6] * e[2]);
                    e[5] = convertMoney(e[5], report.currency.codigo);
                    e[6] = convertMoney(e[6], report.currency.codigo);
                    e[7] = convertMoney(e[7], report.currency.codigo);
                });
                sumaCompra = convertMoney(sumaCompra, report.currency.codigo);
                sumaVenta = convertMoney(sumaVenta, report.currency.codigo);

                /** SE GENERA LA ULTIMA FILA CON LOS TOTALES */
                commit("SET_TOTAL_OBJECT", {sumaCompra: sumaCompra, sumaVenta: sumaVenta});
                var body = [ ...categorias.report, state.totalRow];
                doc.text(categorias.categoria, 15, alturaTexto); // titulo de la categoria
                // tabla lista de productos de la categoria
                autoTable(doc, {
                    startY: alturaTabla,
                    head: [header],
                    body: body,
                    columnStyles: { 
                        0: { cellWidth: 30 },
                        3: { halign: 'center'},
                        4: { halign: 'center'},
                        5: { halign: 'center'},
                    },
                    didDrawPage: (d) => alturaTexto = d.cursor.y + 10,
                });
                /** SE CALCULA LA ALUTRA DE LA TABLA PARA SALTAR LA HOJA O COTINUAR EN LA MISMA */
                if( alturaTexto >= 175 && index != (report.categories.length - 1) ) {
                    doc.addPage();
                    alturaTexto = 20;
                    alturaTabla = alturaTexto + 5;
                }
                alturaTabla = alturaTexto + 5;
            });

            //INFORME POR MARCAS
            doc.addPage();
            doc.text("Informe por marcas", 100, 20);
            alturaTexto = 30;
            alturaTabla = 35;
            // for que recorre categoria por categoria
            report.brands.forEach((marca, index) => {
                sumaCompra = 0;
                sumaVenta = 0;
                // for que recorre para sumar los totales y convertir a formato moneda
                marca.report.forEach((e)=>{
                    sumaCompra += (e[5] * e[2]);
                    sumaVenta += (e[6] * e[2]);
                    e[5] = convertMoney(e[5], report.currency.codigo);
                    e[6] = convertMoney(e[6], report.currency.codigo);
                    e[7] = convertMoney(e[7], report.currency.codigo);
                });
                sumaCompra = convertMoney(sumaCompra, report.currency.codigo);
                sumaVenta = convertMoney(sumaVenta, report.currency.codigo);

                /** SE GENERA LA ULTIMA FILA CON LOS TOTALES */
                commit("SET_TOTAL_OBJECT", {sumaCompra: sumaCompra, sumaVenta: sumaVenta});
                var body = [ ...marca.report, state.totalRow];
                doc.text(marca.marca, 15, alturaTexto); // titulo de la categoria
                // tabla lista de productos de la categoria
                autoTable(doc, {
                    startY: alturaTabla,
                    head: [header],
                    body: body,
                    columnStyles: { 
                        0: { cellWidth: 30 },
                        3: { halign: 'center'},
                        4: { halign: 'center'},
                        5: { halign: 'center'},
                    },
                    didDrawPage: (d) => alturaTexto = d.cursor.y + 10,
                });
                /** SE CALCULA LA ALUTRA DE LA TABLA PARA SALTAR LA HOJA O COTINUAR EN LA MISMA */
                if( alturaTexto >= 175 && index != (report.categories.length - 1) ) {
                    doc.addPage();
                    alturaTexto = 20;
                    alturaTabla = alturaTexto + 5;
                }
                alturaTabla = alturaTexto + 5;
            });

            doc.save("informe_stock_"+report.dep.nombre+".pdf");
            state.loading = false;
            });
        },
};
const mutations = {
    SET_STOCK(state, payload) {
        state.stock = payload;
    },
    SET_TOTAL_OBJECT(state, payload){
        state.totalRow = 
        [
            {content: '', 
                styles:{
                    fontStyle: 'bold',
                    textColor: 'white',
                    fillColor: [41, 128, 186]
                } 
            }, 
            {content: '', 
                styles:{
                    fontStyle: 'bold',
                    textColor: 'white',
                    fillColor: [41, 128, 186]
                } 
            }, 
            {content: '', 
                styles:{
                    fontStyle: 'bold',
                    textColor: 'white',
                    fillColor: [41, 128, 186]
                } 
            }, 
            {content: '', 
                styles:{
                    fontStyle: 'bold',
                    textColor: 'white',
                    fillColor: [41, 128, 186]
                } 
            }, 
            {
                content: 'Total', 
                styles:{
                    fontStyle: 'bold',
                    textColor: 'white',
                    fillColor: [41, 128, 186]
                } 
            }, 
            {
                content: payload.sumaCompra, 
                styles:{
                    fontStyle: 'bold',
                    textColor: 'white',
                    fillColor: [41, 128, 186]
                } 
            }, 
            {
                content: payload.sumaVenta, 
                styles:{
                    fontStyle: 'bold',
                    textColor: 'white',
                    fillColor: [41, 128, 186]
                } 
            },
            {
                content: '', 
                styles:{
                    fontStyle: 'bold',
                    textColor: 'white',
                    fillColor: [41, 128, 186]
                } 
            }, 
        ]
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
