import axios from "axios";
import { mdiInvoiceListOutline } from "@mdi/js";

const state = {
    drawer: true,
    mini: false,
    hasAccess: false,
    isLoadingPermissions: false,
    isLoading: false,
    dialogFacturacion: false,
    opcionFactura: {},
    user: {},
    snackbar: {
        timeout: 1000,
        type: "info",
        icon: "mdi-alert",
        show: false,
        text: "Esto es snackbar",
    },
    items: [],
    permisos: [],
    gradient: "rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)",
    canCreate: false,
    canEdit: false,
    canDelete: false,
    boxCanDetail: false,
    userStatistics: {
        id: 1,
        name: "IDev",
        ventas: 3001,
        compras: 702,
        clientes: 2000,
    },
    projectVersion: "1",
    vmOptions: {
        locale: "es-PY",
        prefix: "",
        suffix: "Gs",
        precision: 0,
    },
};

const actions = {
    async getItems({ state, commit }) {
        let tempItems = [
            {
                title: "Punto de Venta",
                groups: [
                    {
                        text: "Punto de Venta",
                        icon: "point_of_sale",
                        to: "/pos",
                    },
                ],
            },
            {
                title: "Producto y Servicios",
                groups: [
                    {
                        text: "Familia de Productos",
                        icon: "mdi-tools",
                        to: "/product_family",
                    },
                    {
                        text: "Productos",
                        icon: "inventory",
                        to: "/product",
                    },
                    {
                        text: "Producto Caja",
                        icon: "inventory",
                        to: "/product_per_box",
                    },
                    { text: "Servicios", icon: "mdi-tools", to: "/service" },
                    { text: "Marcas", icon: "local_offer", to: "/brand" },
                    { text: "Categorias", icon: "category", to: "/category" },
                ],
            },
            {
                title: "Clientes y Proveedores",
                groups: [
                    { text: "Proveedor", icon: "groups", to: "/supplier" },
                    {
                        text: "Cliente",
                        icon: "person",
                        to: "/client",
                    },
                ],
            },
            {
                title: "Compra y Venta",
                groups: [
                    { text: "Compras", icon: "shopping_cart", to: "/purchase" },
                    { text: "Ventas", icon: "payments", to: "/sale" },
                    {
                        text: "Factura",
                        icon: mdiInvoiceListOutline,
                        to: "/invoice",
                    },
                    {
                        text: "Presupuestos",
                        icon: "mdi-file-document-edit-outline",
                        to: "/budget",
                    },
                    {
                        text: "Pedidos",
                        icon: "mdi-book",
                        to: "/order",
                    },
                    {
                        text: "Devoluciones",
                        icon: "mdi-book",
                        to: "/devolution",
                    },
                ],
            },
            {
                title: "Informes",
                groups: [
                    { text: "Deposito", icon: "archive", to: "/store" },
                    { text: "Caja", icon: "mdi-cash-register", to: "/box" },
                    {
                        text: "Nota Credito",
                        icon: "mdi-cash-register",
                        to: "/credit_note",
                    },
                    {
                        text: "Informes",
                        icon: "mdi-chart-bar",
                        to: "/report",
                    },
                ],
            },
            {
                title: "Deudores y Acreedores",
                groups: [
                    { text: "Deudores", icon: "mdi-cash-minus", to: "/debt" },
                    {
                        text: "Acreedores",
                        icon: "mdi-cash-plus",
                        to: "/creditor",
                    },
                ],
            },
            {
                title: "Monedas y cotizaciones",
                groups: [
                    {
                        text: "Monedas",
                        icon: "mdi-wallet-outline",
                        to: "/money",
                    },
                    {
                        text: "Cotizaciones",
                        icon: "mdi-currency-usd",
                        to: "/quote",
                    },
                    { text: "Billetes", icon: "mdi-cash", to: "/bill" },
                ],
            },
            {
                title: "Permisos y Roles",
                groups: [
                    {
                        text: "Parametros",
                        icon: "mdi-settings",
                        to: "/parameter",
                    },
                    { text: "Usuarios", icon: "manage_accounts", to: "/user" },
                    {
                        text: "Permisos",
                        icon: "admin_panel_settings",
                        to: "/permission",
                    },
                    { text: "Roles", icon: "task", to: "/role" },
                ],
            },
        ];
        try {
            await axios.get(`/index_permissions`).then((response) => {
                state.permisos = response.data;
                let itemIndex = tempItems.length - 1;
                while (itemIndex >= 0) {
                    let e = tempItems[itemIndex];
                    let groupIndex = e.groups.length - 1;

                    while (groupIndex >= 0) {
                        let group = e.groups[groupIndex];
                        let permissionName = group.to.substring(1) + "_index";
                        let permitidos = response.data.find((e) => {
                            return e === permissionName;
                        });
                        if (permitidos == null) {
                            e.groups.splice(groupIndex, 1);
                            if (e.groups.length == 0) {
                                tempItems.splice(itemIndex, 1);
                            }
                        }
                        groupIndex -= 1;
                    }
                    itemIndex -= 1;
                }
            });
        } catch (e) {
            console.log(e);
        }
        commit("setItems", tempItems);
        commit("isPermission", {
            permiso: "box_detail",
            variable: "boxCanDetail",
        });
        commit("setIsLoadingPermissions", true);
    },
    async getPermissions({ state, commit }, path) {
        if (state.permisos.length > 0) {
            commit("setPermissions", path);
        } else {
            axios.get(`/index_permissions`).then((response) => {
                state.permisos = response.data;
                commit("setPermissions", path);
            });
        }
    },
    getUser({ commit }) {
        axios.get("/get_current_user").then((response) => {
            commit("setUser", response.data);
        });
    },
    logout() {
        axios.post("logout").then(() => {
            window.location.href = "/";
        });
    },
    getUserStatistics({ commit }) {
        axios.post("/get_user_statistics").then((response) => {
            commit("SET_USER_STATISTICS", response.data);
        });
    },
    checkSession() {
        axios.get("/api/check-session").catch((error) => {
            if (error.response) {
                if (error.response.status == 401)
                    window.location.href = "/login";
            }
        });
    },
    getProjectVersion({ commit }) {
        axios
            .get("/project_version")
            .then((response) => {
                commit("SET_PROJECT_VERSION", response.data);
            })
            .catch((error) => {
                if (error.response) {
                    this.commit(
                        "app/setSnackbarAlert",
                        `${error.response.data.message}`
                    );
                }
            });
    },
};

const mutations = {
    setDrawer(state) {
        state.drawer = !state.drawer;
    },
    setMini(state) {
        state.mini = !state.mini;
    },
    setSnackbar(state, payload) {
        state.snackbar.text = payload.text;
        state.snackbar.type = payload.type;
        state.snackbar.timeout = payload.timeout;
        state.snackbar.show = payload.show;
        state.snackbar.icon = payload.icon;
    },
    setItems(state, items) {
        state.items = items;
    },
    setUser(state, user) {
        state.user = user;
    },
    setSnackbarAlert(state, text) {
        state.snackbar.text = text;
        state.snackbar.type = "error";
        state.snackbar.timeout = -1;
        state.snackbar.show = true;
        state.snackbar.icon = "mdi-alert-circle-outline";
    },
    setSnackbarInfo(state, text) {
        state.snackbar.text = text;
        state.snackbar.type = "info";
        state.snackbar.timeout = 3000;
        state.snackbar.show = true;
        state.snackbar.icon = "mdi-alert-rhombus-outline";
    },
    setSnackbarWarn(state, text) {
        state.snackbar.text = text;
        state.snackbar.type = "warning";
        state.snackbar.timeout = 2000;
        state.snackbar.show = true;
        state.snackbar.icon = "mdi-alert-octagon-outline";
    },
    setSnackbarSuccess(state, text) {
        state.snackbar.text = text;
        state.snackbar.type = "success";
        state.snackbar.timeout = 3000;
        state.snackbar.show = true;
        state.snackbar.icon = "mdi-check-circle-outline";
    },
    setUser(state, user) {
        state.user = user;
    },
    setcreate(state, access) {
        state.canCreate = access;
    },
    setedit(state, access) {
        state.canEdit = access;
    },
    setdelete(state, access) {
        state.canDelete = access;
    },
    setIsLoadingPermissions(state, isLoadingPermissions) {
        state.isLoadingPermissions = isLoadingPermissions;
    },
    setPermissions(state, path) {
        let permission = ["create", "edit", "delete"]; //lista de permisos
        let index = permission.length - 1;
        while (index >= 0) {
            let permiso = path + "_" + permission[index];
            permiso = permiso.slice(1);
            let IsPermiso = state.permisos.find((e) => {
                return e === permiso;
            });
            let hasAccess = Boolean(IsPermiso);
            state[
                "can" +
                    permission[index].charAt(0).toUpperCase() +
                    permission[index].slice(1)
            ] = hasAccess;
            index -= 1;
        }
    },
    SET_USER_STATISTICS(state, payload) {
        state.userStatistics = payload;
    },
    // este metodo no se está usando pero puede ser util para solicitar permisos inviduales
    /**El problema es que la variable se crea de forma dinanmica  */
    isPermission(state, payload) {
        state[payload.variable] = false;
        let isPermiso = state.permisos.find((e) => {
            return e === payload.permiso;
        });
        let hasAccess = Boolean(isPermiso);
        state[payload.variable] = hasAccess;
    },
    setIsLoading(state, payload) {
        state.isLoading = payload;
    },
    SET_PROJECT_VERSION(state, payload) {
        state.projectVersion = payload;
        const version = localStorage.getItem("projectVersion");
        if (version == null) {
            localStorage.setItem("projectVersion", payload);
        } else if (version != payload) {
            localStorage.setItem("projectVersion", payload);
            location.reload(true);
        }
    },
    /* SE CREAN SETTER PARA AJUSTAR PARAMETROS DEL VUETIFY MONEY */
    setVmLocale(state, payload) {
        state.vmOptions.locale = payload;
    },
    setVmPrefix(state, payload) {
        state.vmOptions.prefix = payload;
    },
    setVmSuffix(state, payload) {
        state.vmOptions.suffix = payload;
    },
    setVmPrecision(state, payload) {
        state.vmOptions.precision = payload;
    },
    setDialogFacturacion(state, payload) {
        state.dialogFacturacion = payload;
    },
    setOpcionFactura(state, payload) {
        state.opcionFactura = payload;
    },
};

const getters = {
    getDrawer: (state) => state.drawer,
    getMini: (state) => state.mini,
    getItemsIcon: (state) => state.items,
    checkPermiso: (state) => (permiso) => {
        return state.permisos.includes(permiso);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
