<template>
    <v-autocomplete
        v-model="localSelectedProduct"
        :items="returnAllProducts"
        label="Buscar producto"
        return-object
        :loading="loadingSearchProduct"
        :search-input.sync="searchProduct"
        no-filter
        :filter="filterObjectProduct"
        @change="handleInput()"
        @keydown.enter="agregar()"
        @focus="reset()"
        clearable
    >
        <!-- No Encuentra el producto -->
        <template v-slot:no-data>
            <div class="d-flex flex-column align-items-center pt-4">
                <h6>Producto inexistente</h6>
            </div>
        </template>

        <!--   Lista categoria de productos -->
        <template slot="item" slot-scope="data">
            <v-list-item-avatar tile>
                <img :src="data.item.img_url" />
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>{{ data.item.nombre }}</v-list-item-title>
                <v-list-item-subtitle
                    >{{ data.item.codigo }}
                    -
                    {{ data.item.descripcion }}</v-list-item-subtitle
                >
            </v-list-item-content>
        </template>

        <!-- Producto seleccionado  -->
        <template slot="selection" slot-scope="data">
            <v-list-item-content>
                <v-list-item-title>
                    {{ `${data.item.producto} - ${data.item.codigo}` }}
                </v-list-item-title>
            </v-list-item-content>
        </template>

        <template v-slot:append-item>
            <div v-intersect="endIntersectProduct" />
        </template>
    </v-autocomplete>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
export default {
    props: ["deposito", "select", "agregar"],
    model: {
        prop: "select",
        event: "blur",
    },
    data: () => ({
        searchProduct: "",
        localSelectedProduct: {},
    }),
    mounted() {
        this.localSelectedProduct = JSON.parse(JSON.stringify(this.select));
    },
    watch: {
        searchProduct(val) {
            this.productsPage.current = 1;
            this.searchOnlyProduct(val);
        },
        localSelectedProduct(val) {
            if (val) {
                this.handleInput(val);
            }
        },
        select(val) {
            if (!val) {
                this.localSelectedProduct = null;
            } else {
                this.localSelectedProduct = this.select;
            }
        },
        deposito(val) {
            this.searchOnlyProduct("");
        },
    },
    computed: {
        ...mapState("product", [
            "products",
            "loadingSearchProduct",
            "productsPage",
        ]),
        ...mapGetters("product", ["returnAllProducts"]),
    },

    methods: {
        ...mapMutations("product", [
            "setLazy",
            "setProducts",
            "clearProductsPage",
        ]),
        ...mapActions("product", ["getProducts", "searchOnlyProduct"]),

        handleInput() {
            this.$emit("blur", this.localSelectedProduct);
        },

        filterObjectProduct(item, queryText, itemText) {
            return (
                (item.producto &&
                    item.producto
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.codigo &&
                    item.codigo
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.descripcion &&
                    item.descripcion
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.marca &&
                    item.marca
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.categoria &&
                    item.categoria
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1)
            );
        },

        async endIntersectProduct(entries, observer, isIntersecting) {
            if (isIntersecting) {
                if (this.productsPage.lastPage > this.productsPage.current) {
                    this.setLazy(true);
                    this.productsPage.current += 1;
                    await this.searchOnlyProduct("");
                }
            }
        },
        async reset() {
            this.setProducts([]);
            this.clearProductsPage();
            await this.searchOnlyProduct("");
        },
    },
};
</script>

<style scoped></style>
