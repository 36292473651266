<template>
    <div>
        <v-autocomplete
            v-model="localSelectedClient"
            :filter="filterObjectClient"
            :items="clients"
            :search-input.sync="buscarCliente"
            item-text="nombre_y_apellido"
            class="mr-7"
            return-object
            cache-items
            label="Cliente"
            ref="cliente"
            required
            clearable
        >
            <!-- En caso que no encuentra -->
            <template v-slot:no-data>
                <v-sheet class="d-flex justify-center ma-2">
                    <p>Cliente no registrado</p>
                </v-sheet>
                <v-sheet class="d-flex justify-center">
                    <v-btn
                        outlined
                        @click="
                            setDialogClient();
                            setIsClientForOther();
                        "
                    >
                        Nuevo Cliente
                    </v-btn>
                </v-sheet>
            </template>

            <template slot="item" slot-scope="data">
                <v-list-item-content>
                    <v-list-item-title>
                        {{ data.item.nombre_y_apellido }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{ data.item.ruc }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </v-autocomplete>
        <FormClient />
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import FormClient from "./Clients/FormClient.Component.vue";

export default {
    props: ["client"],
    model: {
        prop: "client",
        event: "blur",
    },
    data: () => ({
        buscarCliente: "",
        localSelectedClient: {},
    }),
    components: {
        FormClient,
    },
    watch: {
        buscarCliente(val) {
            this.searchClient(val);
        },
        localSelectedClient(val) {
            if (val) {
                this.handleInput(val);
            }
        },
        client(val) {
            if (!val) {
                this.localSelectedClient = null;
            } else {
                this.localSelectedClient = this.client;
            }
        },
        clientForOther() {
            if (this.isClientForOther) {
                this.localSelectedClient = this.clientForOther;
                this.clearClientForOther();
            }
        },
    },
    computed: {
        ...mapState("client", [
            "clients",
            "clientForOther",
            "isClientForOther",
        ]),
    },
    methods: {
        ...mapActions("client", ["searchClient"]),
        ...mapMutations({
            setDialogClient: "client/setDialog",
            setIsClientForOther: "client/setIsClientForOther",
            clearClientForOther: "client/clearClientForOther",
        }),
        handleInput(value) {
            this.$emit("blur", value);
        },

        filterObjectClient(item, queryText, itemText) {
            return (
                item.nombre_y_apellido
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.ruc
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },
    },
};
</script>

<style></style>
