<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <article>
            <v-autocomplete
                v-model="remission.transfer_data_id"
                :filter="filterObject"
                :items="transDatas"
                :search-input.sync="searchTransData"
                label="Elija datos de transferencia"
                item-value="id"
                cache-items
                clearable
                :rules="transferDataRules"
            >
                <!-- En caso que no encuentra -->
                <template v-slot:no-data>
                    <div class="d-flex flex-column justify-center align-center">
                        <h6>Conductor inexistente</h6>
                        <v-btn outlined @click="setDialogFormTransData(true)"
                            >Agregar</v-btn
                        >
                    </div>
                </template>
                <template slot="item" slot-scope="data">
                    <v-list-item-content>
                        <v-list-item-title
                            >Responsable: {{ data.item.responsable }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                            >Recorrido:
                            {{
                                data.item.recorrido_estimativo
                            }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle
                            >Motivo:
                            {{ data.item.motivo }}</v-list-item-subtitle
                        >
                    </v-list-item-content>
                </template>
                <template slot="selection" slot-scope="data">
                    <v-list-item-content>
                        <v-list-item-title
                            >Responsable: {{ data.item.responsable }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                            >Recorrido:
                            {{
                                data.item.recorrido_estimativo
                            }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle
                            >Motivo:
                            {{ data.item.motivo }}</v-list-item-subtitle
                        >
                    </v-list-item-content>
                </template>
            </v-autocomplete>
        </article>
    </v-form>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
    data: () => ({
        valid: false,
        searchTransData: "",
        transferDataRules: [(v) => !!v || "Datos de Transferencia Obligatoria"],
    }),
    computed: {
        ...mapState("transfer_data", ["transDatas"]),
        ...mapState("remission", ["remission"]),
    },
    methods: {
        ...mapMutations("transfer_data", ["setDialogFormTransData"]),

        filterObject(item, queryText, itemText) {
            return (
                item.responsable
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.motivo
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },
    },
};
</script>

<style></style>
