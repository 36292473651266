import axios from "axios";

const state = {
    stores: [],
    productsStore: [],
    productsIncome: [],
    productEditPrice: {
        nombre: "",
    },
    dialogRemision: false,
    dialogIngreso: false,
    dialogFormStore: false,
    dialogFormPriceEdit: false,
    loading: false,
    currencyStore: null,
    depositosPage: {
        current: 1,
        total: 0,
        perPage: 0,
        lastPage: 0,
        firstPage: 0,
    },
    headers: [
        {
            text: "Producto",
            align: "start",
            value: "product.nombre",
        },
        {
            text: "Codigo",
            align: "start",
            value: "product.codigo",
        },
        {
            text: "Último precio compra",
            value: "latest_purchase_detail.precio_unitario",
        },
        {
            text: "P. compra prom.",
            align: "start",
            value: "precio_compra_promedio",
        },
        {
            text: "P. Venta",
            align: "end",
            value: "precio_venta",
        },
        {
            text: "P. Mayorista",
            align: "end",
            value: "precio_mayorista",
        },
        {
            text: "P. Descuento",
            align: "end",
            value: "precio_descuento",
        },
        {
            text: "Reserva",
            align: "center",
            value: "reserva",
        },
        {
            text: "Existencia",
            align: "center",
            value: "cantidad",
        },
        {
            text: "Disponible",
            align: "center",
            value: "disponible",
        },
        {
            text: "",
            align: "center",
            value: "data-table-expand",
            width: "100px",
        },
    ],
    headersAdmin: [
        {
            text: "Producto",
            align: "start",
            value: "product.nombre",
        },
        {
            text: "Codigo",
            align: "start",
            value: "product.codigo",
        },
        {
            text: "último precio compra",
            value: "latest_purchase_detail.precio_unitario",
            width: "200px",
        },
        {
            text: "P. Venta",
            align: "end",
            value: "precio_venta",
        },
        {
            text: "P. Mayorista",
            align: "end",
            value: "precio_mayorista",
        },
        {
            text: "P. Descuento",
            align: "end",
            value: "precio_descuento",
        },
        {
            text: "Reserva",
            align: "center",
            value: "reserva",
        },
        {
            text: "Existencia",
            align: "center",
            value: "cantidad",
        },
        {
            text: "Disponible",
            align: "center",
            value: "disponible",
        },
        {
            text: "",
            align: "center",
            value: "data-table-expand",
        },
    ],
    headersIncome: [
        {
            text: "Ingresado por",
            align: "center",
            value: "user.name",
        },
        {
            text: "Deposito",
            align: "center",
            value: "store.nombre",
        },
        {
            text: "Cantidad Prod.",
            align: "center",
            value: "store_input_detail",
        },
        {
            text: "Fecha",
            align: "center",
            value: "fecha",
        },
        { text: "", value: "data-table-expand" },
    ],
};
const actions = {
    async getAllStores({ commit }) {
        await axios
            .get("/stores")
            .then((response) => {
                commit("GET_ALL_STORES", response.data);
            })
            .catch((error) => {
                console.log(error.response.data);
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    async createStore({ commit, dispatch }, payload) {
        await axios
            .post("/stores", payload)
            .then((response) => {
                dispatch("getAllStores");
            })
            .catch((error) => {
                console.log(error.response.data);
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    async getProductsStores({ commit, rootGetters }, payload) {
        commit("setLoading", true);
        await axios
            .post("/get_stock", payload)
            .then((response) => {
                commit("GET_PRODUCTS_STORE", response.data);
                commit(
                    "currencyConversion",
                    rootGetters["quote/getQuote"].quotation_id
                );
            })
            .catch((error) => {
                console.log(error);
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },

    async getProductPrice({ commit, dispatch }, payload) {
        await axios
            .get(`/stocks/${payload.id}/edit`)
            .then((response) => {
                response.data.nombre = payload.product.nombre;
                commit("GET_PRODUCT_PRICE", response.data);
            })
            .catch((error) => {
                console.log(error.response.data);
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },

    async editProductPrice({ commit, dispatch, getters }, payload) {
        await axios
            .put(`update_stock_price`, payload)
            .then((response) => {
                commit("EDIT_PRODUCT_PRICE", response.data);

                dispatch("getProductsStores", {
                    dep: payload.dep,
                });
                this.commit(
                    "app/setSnackbarSuccess",
                    "Precio Editado Correctamente"
                );
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    async editProductPurchasePrice({ commit, dispatch, getters }, payload) {
        await axios
            .post(`edit_purchase_price`, payload)
            .then((response) => {
                commit("EDIT_PRODUCT_PURCHASE_PRICE", response.data);

                dispatch("getProductsStores", {
                    dep: payload.dep,
                });
                this.commit(
                    "app/setSnackbarSuccess",
                    "Precio Editado Correctamente"
                );
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },

    getIncomeProducts({ commit, dispatch }) {
        axios
            .get("list_income")
            .then((res) => {
                commit("GET_INCOME", res.data);
            })
            .catch((err) => {
                this.commit("app/setSnackbarError", err.response.data.message);
            });
    },

    searchStoreProducts({ commit, dispatch }, payload) {
        state.loading = true;
        if (state.setTimeoutBuscador) clearTimeout(state.setTimeoutBuscador);
        state.setTimeoutBuscador = setTimeout(async function () {
            axios
                .post(
                    `stock_search_product?page=${state.depositosPage.current}`,
                    payload
                )
                .then((res) => {
                    commit("GET_STORE_FILTER", res.data);
                })
                .catch((err) => {
                    this.commit(
                        "app/setSnackbarError",
                        err.response.data.message
                    );
                });
        }, 250);
    },

    printStockProducts({ commit, dispatch }, payload) {
        axios
            .post("print_stock", payload)
            .then((res) => {
                console.log(res.data);
                commit("PRINT_STOCK_PRODUCTS", res.data);
            })
            .catch((err) => {
                this.commit("app/setSnackbarError", err.response.data.message);
            });
    },
};
const mutations = {
    GET_ALL_STORES(state, payload) {
        state.stores = payload;
    },
    GET_PRODUCTS_STORE(state, payload) {
        state.productsStore = payload;
    },
    GET_PRODUCT_PRICE(state, payload) {
        state.productEditPrice = payload;
    },
    EDIT_PRODUCT_PRICE(state, payload) {},
    setDialogRemision(state, payload) {
        state.dialogRemision = payload;
    },
    EDIT_PRODUCT_PURCHASE_PRICE(state, payload) {},
    GET_INCOME(state, payload) {
        state.productsIncome = payload;
        state.productsIncome.forEach((value, index, array) => {
            const d = new Date(value.created_at).toLocaleDateString("es-ES", {
                year: "numeric",
                month: "short",
                day: "numeric",
            });

            value.fecha = d;
        });
    },
    GET_STORE_FILTER(state, payload) {
        state.loading = false;
        state.productsStore = payload.data;
        state.depositosPage = {
            current: payload.current_page,
            from: payload.from,
            to: payload.to,
            total: payload.total,
            perPage: payload.per_page,
            lastPage: payload.last_page,
        };
    },
    PRINT_STOCK_PRODUCTS(state, paylaod) {},
    setDialogIngreso(state, payload) {
        state.dialogIngreso = payload;
    },
    setDialogFormStore(state, payload) {
        state.dialogFormStore = payload;
    },
    setDialogFormPriceEdit(state, payload) {
        state.dialogFormPriceEdit = payload;
    },
    setLocaleStorageStore(state, payload) {
        localStorage.setItem("deposito", payload);
    },
    setLoading(state, paylaod) {
        state.loading = paylaod;
    },
    currencyConversion(state, payload) {
        //metodo llamado desde el watch en listStore, convierte a la moneda seleccionada, desde la moneda principal
        if (payload != 1) {
            const cotizaciones = this.getters["quote/getQuotes"];
            const currency = cotizaciones.find(
                (co) => co.currency_id === payload
            );
            state.productsStore.forEach((e) => {
                e.precio_compra_promedio /= currency.cotizacion;
                e.precio_venta /= currency.cotizacion;
                e.precio_mayorista /= currency.cotizacion;
                e.precio_descuento /= currency.cotizacion;
            });
        }
        state.loading = false;
    },
    restoreCurrencyConversion(state, payload) {
        //metodo llamado desde el watch en listStore, revierte la conversion de cualquier moneda a la moneda prinvcipal
        if (payload != 1) {
            const cotizaciones = this.getters["quote/getQuotes"];
            const currency = cotizaciones.find(
                (co) => co.currency_id === payload
            );
            state.productsStore.forEach((e) => {
                e.precio_compra_promedio *= currency.cotizacion;
                e.precio_venta *= currency.cotizacion;
                e.precio_mayorista *= currency.cotizacion;
                e.precio_descuento *= currency.cotizacion;
            });
        }
    },
    setCurrencyStore(state, payload) {
        state.currencyStore = payload;
    },
    setDepositoPage(state, payload) {
        state.depositosPage = payload;
    },
};
const getters = {
    returnNameStore: (state) => (id) => {
        let store = {};
        store = state.stores.find((store) => store.id === id);
        return store?.nombre;
    },
    getLocaleStorageStore(state) {
        return localStorage.getItem("deposito");
    },
    getDepositoPage(state) {
        return state.depositosPage;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
