const state = {
    dialog: false,
    edit: false,
    deleteDialog: false,
    isDeleted: false,
    isFromService: false,
    products: [],
    loadingSearchProduct: false,
    loadingSearchProductFamilly: false,
    loadingCompareProductCode: false,
    editProductCode: "",
    setTimeoutBuscador: false,
    errorCodigo: true,
    lazy: false,
    product: {
        iva: "10%",
    },
    productDetails: [],
    productsPage: {
        current: 0,
        total: 0,
        perPage: 0,
        lastPage: 0,
        firstPage: 0,
    },
    productsFamillyPage: {
        current: 1,
        total: 0,
        perPage: 0,
        lastPage: 0,
        firstPage: 0,
    },
    loading: false,
    img: null,
    headers: [], // se define en la mutacion setheaders
    headersFamily: [
        { text: "Nombre", align: "start", value: "nombre" },
        { text: "Referencia", align: "start", value: "referencia" },
        { text: "Codigo", value: "codigo" },
        { text: "Marca", value: "brand.nombre", align: "center" },
        {
            text: "Categoria",
            value: "category",
            align: "center",
        },
        { text: "Descripcion", value: "descripcion" },
        {
            text: "Imagen",
            value: "img_url",
            sortable: false,
            align: "center",
        },
        {
            text: "Opciones",
            value: "actions",
            sortable: false,
            width: "150px",
        },
    ],
    setTimeoutBuscador: "",
    selectedProduct: {
        nombre: "Producto",
        descripcion: "Descripción",
        img_url: "",
    },
    errorReferencia: false,
    productFamilies: [],
    isSalir: true,
    dialogFamily: false,
    imgFile: null,
};

const actions = {
    getProducts({ commit, state }) {
        state.loading = true;
        let url = "";
        state.products = [];
        url = state.isFromService ? "/services" : "/products";
        url += `?page=${state.productsPage.current}`;
        axios
            .get(url)
            .then((response) => {
                commit("GET_PRODUCTS", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            })
            .finally(() => {
                state.loading = false;
            });
    },

    getProductFamilies({ commit, state }) {
        state.loading = true;
        let url = "/product_families";
        state.productFamilies = [];
        url += `?page=${state.productsFamillyPage.current}`;
        axios
            .get(url)
            .then((response) => {
                commit("GET_PRODUCTS_FAMILLY", response.data);
            })
            .catch((error) => {
                console.log(error);
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            })
            .finally(() => {
                state.loading = false;
            });
    },

    searchReferencia({ commit, state }, referencia) {
        state.loadingSearchProductFamilly = true;
        if (state.setTimeoutBuscador) clearTimeout(state.setTimeoutBuscador);
        state.setTimeoutBuscador = setTimeout(async function () {
            axios
                .post(
                    `search_referencia?page=${state.productsFamillyPage.current}`,
                    {
                        referencia,
                    }
                )
                .then((res) => {
                    commit("setProductFamilies", res.data);
                })
                .catch((error) => {
                    console.log(error);
                    this.commit(
                        "app/setSnackbarAlert",
                        error.response.data.message
                    );
                    state.loadingSearchProductFamilly = false;
                });
        }, 500);
    },

    async createProductFamily({ commit, state }, params) {
        state.loading = true;
        if (params.img_url) {
            await this.dispatch("image/createImage", params.img_url).then(
                (res) => {
                    params.img_url = res.data;
                }
            );
        }
        axios
            .post("/product_families", state.product)
            .then((response) => {
                commit("GET_PRODUCTS", response.data);
                commit("clearProduct");
                commit("setDialogFamily");
                this.commit(
                    "app/setSnackbarSuccess",
                    "Familia de producto creada"
                );
            })
            .catch((e) => {
                console.log(e);
                this.commit("app/setSnackbarAlert", e.response.data.message);
            });
        state.loading = false;
    },

    async createProductDetails({ commit, dispatch, state }) {
        this.commit("app/setIsLoading", true);
        //si existe imagen crea esa imagen sino pasa linea

        let img;
        if (state.imgFile) {
            const base64Archivo = await convertirArchivoABase64(state.imgFile);
            // params.imgFile = base64Archivo;
            img = base64Archivo;
        }

        let i = 0;
        await Promise.all(
            state.productDetails.map(async (el) => {
                el.imgFile = img;
                await axios
                    .post("/products", el)
                    .then((response) => {})
                    .catch((error) => {
                        this.commit(
                            "app/setSnackbarAlert",
                            error.response.data.message
                        );
                        return false;
                    });

                i++;
            })
        );

        this.commit(
            "app/setSnackbarSuccess",
            i + " Productos creardo correctamente"
        );
        commit("CREATE_DETAILS_PRODUCT");
        dispatch("getProducts");
        commit("clearProduct");
        this.commit("app/setIsLoading", false);
        return true;
    },

    async createProduct({ commit, dispatch, state }, params) {
        this.commit("app/setIsLoading", true);
        //si existe imagen crea esa imagen sino pasa linea
        if (state.imgFile) {
            const base64Archivo = await convertirArchivoABase64(state.imgFile);
            params.imgFile = base64Archivo;
        }

        let url = "";
        url = state.isFromService ? "/services" : "/products";

        axios
            .post(url, params)
            .then((response) => {
                this.commit(
                    "app/setSnackbarSuccess",
                    "Producto creardo correctamente"
                );
                dispatch("getProducts");
                commit("clearProduct");
                this.commit("app/setIsLoading", false);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
                this.commit("app/setIsLoading", false);
            });
        //this.commit("app/setIsLoading", false);
    },

    async editProduct({ commit, dispatch, state }, params) {
        //si existe imagen crea esa imagen sino pasa linea
        this.commit("app/setIsLoading", true);
        if (state.imgFile) {
            const base64Archivo = await convertirArchivoABase64(state.imgFile);
            params.imgFile = base64Archivo;
        }

        axios
            .put(`/products/${params.id}`, params)
            .then(() => {
                commit("EDIT_PRODUCT", params);
            })
            .then(() => {
                this.commit(
                    "app/setSnackbarSuccess",
                    "Producto editado correctamente"
                );
                dispatch("getProducts");
                commit("clearProduct");
                this.commit("app/setIsLoading", false);
            })
            .catch((error) => {
                console.log(error);
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
                this.commit("app/setIsLoading", false);
            });
    },

    async editProductFamily({ commit, dispatch, getters }, params) {
        let item = getters.getProductById(params.id);
        if (params.img_url !== item.img_url) {
            await this.dispatch("image/createImage", params.img_url).then(
                async (res) => {
                    params.img_url = res.data;
                    await this.dispatch("image/editImage", item);
                }
            );
        }

        axios
            .put(`/product_families/${params.id}`, params)
            .then(() => {
                commit("EDIT_PRODUCT", params);
            })
            .then(() => {
                this.commit(
                    "app/setSnackbarSuccess",
                    "Familia de producto editada correctamente"
                );
                dispatch("getProductFamilies");
                commit("clearProduct");
                commit("setDialogFamily");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },

    deleteProduct({ commit, dispatch }, params) {
        axios
            .delete(`/products/${params.id}`)
            .then((response) => {
                commit("DELETE_PRODUCT", params);
                dispatch("getProducts");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },

    deleteProductFamily({ commit }, params) {
        axios
            .delete(`/product_families/${params.id}`)
            .then((response) => {
                commit("DELETE_PRODUCT", params);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },

    discontProduct({ dispatch }, params) {
        axios
            .post(`products/${params.id}/discontinuar`)
            .then((response) => {
                dispatch("getProducts");
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },

    getOneProduct({ commit }, params) {
        axios
            .get(`/products/${params.id}`)
            .then((response) => {
                this.commit("prod_box/setProductBox", response.data);
                commit("GET_ONE_PRODUCT", params);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    /**SE AGREGA LA OPCION DE BUSCAR PRODUCTOS DE LA DB */
    buscarProductos({ state, commit }, payload) {
        if (state.setTimeoutBuscador) clearTimeout(state.setTimeoutBuscador);
        state.setTimeoutBuscador = setTimeout(function () {
            state.loadingSearchProduct = true;
            axios
                .post("/search_products", payload)
                .then((res) => {
                    commit("GET_PRODUCTS_SEARCH", res.data);
                })
                .catch((error) => {
                    console.log("hay error: " + error);
                })
                .finally(() => {
                    state.loadingSearchProduct = false;
                });
        }, 500);
    },
    /**SE BUSCA POR CODIGO Y SE INGRESA AL PRESIONAR ENTER (PARA LECTORES DE CODIGO DE BARRA) */
    searchProductsByCode({ commit, state }, codigo) {
        axios
            .get("/search_products_by_code", {
                params: {
                    codigo: codigo,
                },
            })
            .then((res) => {
                let product = {};
                product.cantidad = 1;
                product.product_id = res.data.id;
                product.product = {
                    nombre: res.data.nombre,
                    codigo: res.data.codigo,
                };
                product.precio_unitario = res.data.stock.precio_venta;
                commit("sale/addSaleProduct", product, { root: true }); //llama al otro archivo de vuex para pasar producto seleccionado
                state.selectedProduct = res.data;
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },

    async getProductsStores({ commit, dispatch }, payload) {
        await axios
            .post("/get_stock", payload)
            .then((response) => {
                commit("GET_PRODUCTS_STORE", response.data);
            })
            .catch((error) => {
                console.log(error.response.data);
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },

    async searchOnlyProduct({ commit, state }, payload) {
        state.loadingSearchProduct = true;
        const oldProd = [...state.products];

        if (state.setTimeoutBuscador) clearTimeout(state.setTimeoutBuscador);
        state.setTimeoutBuscador = setTimeout(async function () {
            await axios
                .post(
                    `/search_only_product?page=${state.productsPage.current}`,
                    {
                        buscador: payload,
                    }
                )
                .then((res) => {
                    commit("GET_ONLY_PRODUCT", { newProd: res.data, oldProd });
                    state.loadingSearchProduct = false;
                })
                .catch((error) => {
                    this.commit(
                        "app/setSnackbarInfo",
                        error.response.data.message
                    );
                    state.loadingSearchProduct = false;
                });
        }, 250);
    },

    async searchRepeatingProduct({ commit, state }, codigo) {
        if (state.editProductCode !== codigo) {
            state.loadingCompareProductCode = true;
            if (state.setTimeoutBuscador)
                clearTimeout(state.setTimeoutBuscador);
            state.setTimeoutBuscador = setTimeout(async function () {
                await axios
                    .post("/check_product_by_code", {
                        codigo,
                    })
                    .then((res) => {
                        !!res.data
                            ? commit("CODIGO_OK", res.data)
                            : commit("CODIGO_ERROR", res.data);
                    })
                    .catch((error) => {
                        this.commit(
                            "app/setSnackbarInfo",
                            error.response.data.message
                        );
                    });
            }, 500);
        }
    },

    async searchFilterProduct({ commit, state }, search) {
        state.loading = true;
        if (state.setTimeoutBuscador) clearTimeout(state.setTimeoutBuscador);
        state.setTimeoutBuscador = setTimeout(async function () {
            await axios
                .post(
                    `search_product_filter?page=${state.productsPage.current}`,
                    search
                )
                .then((res) => {
                    commit("GET_SEARCH_FILTER_PRODUCT", res.data);
                })
                .catch((error) => {
                    console.log(error.response.data);
                    this.commit(
                        "app/setSnackbarAlert",
                        error.response.data.message
                    );
                })
                .finally(() => {
                    state.loading = false;
                });
        }, 500);
    },
};

const mutations = {
    setDialog(state) {
        if (state.isSalir) state.dialog = !state.dialog;
    },
    setDialogFamily(state) {
        state.dialogFamily = !state.dialogFamily;
    },
    setEdit(state, paylaod) {
        state.edit = paylaod;
    },
    setDeleteDialog(state) {
        state.deleteDialog = !state.deleteDialog;
    },
    setProduct(state, item) {
        let categorias = [];
        item.category.forEach((e) => {
            categorias.push(e.id);
        });
        if (state.edit) {
            state.editProductCode = item.codigo;
        }
        state.product = { ...item, category: [...categorias] };
    },
    clearProduct(state) {
        state.product = {
            iva: "10%",
            img_url: null,
        };
        state.imgFile = null;
        // if(state.isSalir){
        //     state.dialog = false;
        // }
        state.img = null;
    },
    GET_PRODUCTS(state, products) {
        state.products = products.data;
        state.productsPage = {
            total: products.total,
            perPage: products.per_page,
            current: products.current_page,
            lastPage: products.last_page,
            firstPage: products.first_page,
        };
    },
    GET_PRODUCTS_FAMILLY(state, products) {
        state.productFamilies = products.data;
        state.productsFamillyPage = {
            total: products.total,
            perPage: products.per_page,
            current: products.current_page,
            lastPage: products.last_page,
            firstPage: products.first_page,
        };
    },
    GET_PRODUCTS_SEARCH(state, products) {
        state.products = products;
    },
    GET_ONE_PRODUCT(state, product) {
        state.product = product;
    },
    EDIT_PRODUCT(state) {
        state.dialog = false;
        state.edit = false;
        state.img = null;
        state.editProductCode = "";
    },
    DELETE_PRODUCT(state) {
        state.delete = false;
    },
    SEARCH_PRODUCTS(state, paylaod) {
        state.products = paylaod;
    },
    GET_PRODUCTS_STORE(state, paylaod) {
        state.products = paylaod;
    },

    GET_ONLY_PRODUCT(state, products) {
        state.productsPage = {
            total: products.newProd.total,
            perPage: products.newProd.per_page,
            current: products.newProd.current_page,
            lastPage: products.newProd.last_page,
            firstPage: products.newProd.first_page,
        };
        if (state.lazy) {
            state.products = [...products.oldProd, ...products.newProd.data];
            state.lazy = false;
        } else {
            state.products = products.newProd.data;
        }
    },
    CREATE_DETAILS_PRODUCT(state) {
        state.productDetails = [];
        state.img = null;
    },

    CODIGO_OK(state) {
        state.loadingCompareProductCode = false;
        state.errorCodigo = false;
    },
    CODIGO_ERROR(state) {
        state.loadingCompareProductCode = false;
        state.errorCodigo = true;
    },
    SET_ERROR_REFERENCIA(state, paylaod) {
        state.errorReferencia = paylaod.exist;
    },
    GET_SEARCH_FILTER_PRODUCT(state, products) {
        state.productsPage = {
            total: products.total,
            perPage: products.per_page,
            current: products.current_page,
            lastPage: products.last_page,
            firstPage: products.first_page,
        };
        state.products = products.data;
    },
    setImage(state, item) {
        state.img = item;
    },
    setSelectedProduct(state, payload) {
        state.selectedProduct = payload;
    },
    setIsFromService(state, payload) {
        state.isFromService = payload;
    },
    setHeaders(state) {
        if (state.isFromService) {
            state.headers = [
                { text: "Nombre", align: "start", value: "nombre" },
                { text: "Codigo", value: "codigo", width: "10%" },
                { text: "Descripcion", value: "descripcion" },
                { text: "Precio", value: "precio" },
                { text: "Opciones", value: "actions", sortable: false },
            ];
        } else {
            state.headers = [
                { text: "Nombre", align: "start", value: "nombre" },
                { text: "Codigo", value: "codigo" },
                { text: "Marca", value: "brand.nombre", align: "center" },
                {
                    text: "Categoria",
                    value: "category",
                    align: "center",
                },
                { text: "Descripcion", value: "descripcion" },
                {
                    text: "Imagen",
                    value: "img_url",
                    sortable: false,
                    align: "center",
                },
                {
                    text: "Opciones",
                    value: "actions",
                    sortable: false,
                    width: "150px",
                },
            ];
        }
    },
    setProducts(state, payload) {
        state.products = payload;
    },
    setLazy(state, payload) {
        state.lazy = payload;
    },
    setDetailsProduct(state, payload) {
        payload.forEach((color) => {
            if (color.sizes.length > 0) {
                color.sizes.forEach((size) => {
                    let productTemp = Object.assign({}, state.product);

                    productTemp.nombre += "-" + color.color + "-" + size;
                    productTemp.codigo += "-" + color.codigo + "-" + size;

                    productTemp.nombre.trim();
                    productTemp.codigo.trim();

                    state.productDetails.push(productTemp);
                });
            } else {
                let productTemp = Object.assign({}, state.product);

                productTemp.nombre += "-" + color.color;
                productTemp.codigo += "-" + color.codigo;

                productTemp.nombre.trim();
                productTemp.codigo.trim();

                state.productDetails.push(productTemp);
            }
        });
    },
    setProductPage(state, payload) {
        state.productsPage = payload;
    },
    addListProducts(state, payload) {
        state.products = [...payload.oldProd, ...payload.newProd];
    },
    setProductFamilies(state, paylaod) {
        state.productsFamillyPage = {
            total: paylaod.total,
            perPage: paylaod.per_page,
            current: paylaod.current_page,
            lastPage: paylaod.last_page,
            firstPage: paylaod.first_page,
        };
        if (state.lazy) {
            state.productFamilies = [...state.productFamilies, ...paylaod.data];
            state.lazy = false;
        } else {
            state.productFamilies = paylaod.data;
        }
        state.loadingSearchProductFamilly = false;
    },
    setProductFromReferencia(state, payload) {
        if (state.edit) {
            state.product.brand_id = state.product.brand_id ?? payload.brand_id;
            state.product.category = state.product.category ?? payload.category;
            state.product.descripcion =
                state.product.descripcion ?? payload.descripcion;
            state.product.img_url = state.product.img_url ?? payload.img_url;
            state.product.iva = payload.iva;
        } else {
            state.product = payload;
            state.product.product_family_id = payload.id;
            state.product.id = null;
        }
    },
    setIsSalir(state, payload) {
        state.isSalir = payload;
    },
    setImgFile(state, file) {
        state.imgFile = file;
    },
    setImgUrl(state, url) {
        state.product.img_url = url;
    },
    clearProductsPage() {
        state.productsPage = {
            current: 1,
        };
    },
};

const getters = {
    getProductById: (state) => (id) => {
        return state.products.find((obj) => obj.id === id);
    },
    getSelectedProduct: (state) => {
        let selected = state.selectedProduct;
        return selected;
    },
    returnAllProducts: (state) => {
        return state.products;
    },
};

// Función para convertir el archivo a base64
async function convertirArchivoABase64(file) {
    return await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
