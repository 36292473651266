<!-- Dialog General para eliminar -->
<template>
    <v-dialog
        persistent
        v-model="dialog"
        max-width="500"
        content-class="elevation-0"
    >
        <v-alert border="bottom" color="red" colored-border elevation="2">
            <v-row align="center" justify="center">
                <v-col cols="2">
                    <v-icon x-large color="red">mdi-alert-outline</v-icon>
                </v-col>
                <v-col cols="10">
                    <h3 class="text-h5">¿Estás seguro de eliminar?</h3>
                    <div>Los datos eliminados ya no se pueden restablecer.</div>
                </v-col>
            </v-row>
            <v-divider class="my-4 info" style="opacity: 0.22" />
            <slot></slot>
            <v-card-actions>
                <v-spacer />
                <v-btn class="mr-3" outlined text @click="setDialog(false)">
                    Cancelar
                </v-btn>

                <v-btn
                    outlined
                    text
                    color="red"
                    @click="deleteComponent(), setDialog(false)"
                >
                    Eliminar
                </v-btn>
            </v-card-actions>
        </v-alert>
    </v-dialog>
</template>

<script>
export default {
    props: ["dialog", "setDialog"],
    methods: {
        //Si preciona el boton Eliminar activa la funcion donde se llamo
        deleteComponent() {
            this.$emit("deleted");
        },
    },
};
</script>
