<template>
    <v-footer class="blue--text">
        <v-col class="text-center">
            <strong>IDevPY</strong> -- {{ projectVersion }}
        </v-col>
    </v-footer>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    data: () => ({
        version: '',
    }),
    mounted(){
        this.getProjectVersion();
       /* console.log(this.projectVersion);
        this.version = localStorage.getItem("projectVersion");
        if ( this.version == null ){
            localStorage.setItem("projectVersion", this.projectVersion);
        }else if( this.version != this.projectVersion ){
            localStorage.setItem("projectVersion", this.projectVersion);
            location.reload(true);
        }*/
    },
    computed:{
        ...mapState("app", ["projectVersion"]),
    },
    methods:{
        ...mapActions("app", ["getProjectVersion"]),
    }
}
</script>
