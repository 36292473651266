<template>
    <tbody>
        <div v-for="(valor, index) in parameter.valor" :key="index">
            <v-list-item-title>{{ valor.nombre }}</v-list-item-title>
            <v-row>
                <v-col>
                    <v-text-field
                        v-model.number="valor.alto"
                        dense
                        outlined
                        label="Alto"
                        suffix="mm"
                        type="number"
                    />
                </v-col>

                <template v-if="valor.nombre !== 'Tabla'">
                    <v-col>
                        <v-text-field
                            v-model.number="valor.ancho"
                            dense
                            outlined
                            label="Ancho"
                            suffix="mm"
                            type="number"
                        />
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model.number="valor.largo"
                            dense
                            outlined
                            label="Largo"
                            suffix="mm"
                            type="number"
                        />
                    </v-col>
                </template>

                <template v-else>
                    <v-col>
                        <v-text-field
                            v-model.number="valor.derecha"
                            dense
                            outlined
                            label="M. Derecha"
                            suffix="mm"
                            type="number"
                        />
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model.number="valor.izquierda"
                            dense
                            outlined
                            label="M. Izquierda"
                            suffix="mm"
                            type="number"
                        />
                    </v-col>
                </template>
            </v-row>
        </div>
        <v-card-actions class="auto">
            <v-btn
                outlined
                color="primary"
                @click="guardarConfiguracionImpresion()"
                >Guardar</v-btn
            >
        </v-card-actions>
    </tbody>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapState("config", ["parameter"]),
    },

    methods: {
        ...mapActions("config", [
            "editParameters",
            "getOneParameterObj",
            "createParameters",
            "createPrintParams",
        ]),

        async guardarConfiguracionImpresion() {
            await this.editParameters(this.parameter);
            await this.getOneParameterObj("print");
        },
    },
    async created() {
        await this.getOneParameterObj("print");
    },
};
</script>

<style></style>
