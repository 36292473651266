<template v-slot:top>
    <div>
        <v-card class="my-5 px-3">
            <v-card-title> Bienvenido {{ userStatistics.name }} </v-card-title>
            <v-card-subtitle> Hoy es {{ hoy }} </v-card-subtitle>
            <v-row class="pb-5">
                <v-col lg="4" cols="sm" class="pb-2">
                    <v-hover v-slot="{ hover }">
                        <v-card
                            :elevation="hover ? 5 : 2"
                            style="text-decoration: none; color: inherit"
                            to="sale"
                            v-if="permiso('/sale')"
                        >
                            <v-row class="no-gutters">
                                <div class="col-auto">
                                    <div class="cyan fill-height">&nbsp;</div>
                                </div>
                                <div class="col pa-3 py-4 cyan--text">
                                    <h5 class="text-truncate text-uppercase">
                                        Ventas
                                    </h5>
                                    <h1>{{ userStatistics.ventas }}</h1>
                                </div>
                            </v-row>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col lg="4" cols="sm" class="pb-2">
                    <v-hover v-slot="{ hover }">
                        <v-card
                            :elevation="hover ? 5 : 2"
                            style="text-decoration: none; color: inherit"
                            to="store"
                            v-if="permiso('/store')"
                        >
                            <v-row class="no-gutters">
                                <div class="col-auto">
                                    <div class="primary fill-height">
                                        &nbsp;
                                    </div>
                                </div>
                                <div class="col pa-3 py-4 primary--text">
                                    <h5 class="text-truncate text-uppercase">
                                        Productos
                                    </h5>
                                    <h1>{{ userStatistics.stock }}</h1>
                                </div>
                            </v-row>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col lg="4" cols="sm" class="pb-2">
                    <v-hover v-slot="{ hover }">
                        <v-card
                            :elevation="hover ? 5 : 2"
                            style="text-decoration: none; color: inherit"
                            to="client"
                            v-if="permiso('/client')"
                        >
                            <v-row class="no-gutters">
                                <div class="col-auto">
                                    <div class="success fill-height">
                                        &nbsp;
                                    </div>
                                </div>
                                <div class="col pa-3 py-4 success--text">
                                    <h5 class="text-truncate text-uppercase">
                                        Clientes
                                    </h5>
                                    <h1>{{ userStatistics.clientes }}</h1>
                                </div>
                            </v-row>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-layout class="pa-3" justify-center align-center>
                <v-card-title> Módulos </v-card-title>
            </v-layout>
            <v-row>
                <v-col v-for="(item, i) in modulos" :key="i" md="3">
                    <v-hover v-slot="{ hover }">
                        <v-card
                            :to="item.to"
                            outlined
                            class="mx-auto d-flex text-center"
                            style="text-decoration: none; color: inherit"
                            :elevation="hover ? 5 : 2"
                        >
                            <v-flex
                                class="mx-auto my-auto flex-column p-3"
                                align-center
                            >
                                <v-avatar
                                    size="65"
                                    class="mb-3"
                                    color="light-blue lighten-5"
                                >
                                    <v-icon size="40" color="light-blue">{{
                                        item.icon
                                    }}</v-icon>
                                </v-avatar>
                                <div class="text-h7">{{ item.text }}</div>
                            </v-flex>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
    mounted() {
        this.getItems();
        this.getUserStatistics();
        this.permiso("/stock");
    },

    computed: {
        ...mapState("app", ["items", "userStatistics"]),
        modulos() {
            let elementos = [];
            this.items.forEach((item) => {
                item.groups.forEach((element) => {
                    elementos.push(element);
                });
            });
            return elementos;
        },
        hoy() {
            const daysOfWeek = [
                "Domingo",
                "Lunes",
                "Martes",
                "Miércoles",
                "Jueves",
                "Viernes",
                "Sábado",
            ];
            const monthsOfYear = [
                "enero",
                "febrero",
                "marzo",
                "abril",
                "mayo",
                "junio",
                "julio",
                "agosto",
                "septiembre",
                "octubre",
                "noviembre",
                "diciembre",
            ];

            const today = new Date();
            const dayOfWeek = daysOfWeek[today.getDay()];
            const dayOfMonth = today.getDate();
            const monthOfYear = monthsOfYear[today.getMonth()];
            const year = today.getFullYear();

            const formattedDate = `${dayOfWeek}, ${dayOfMonth} de ${monthOfYear} de ${year}`;
            return formattedDate;
        },
    },
    methods: {
        ...mapActions("app", ["getItems", "getUserStatistics"]),

        permiso(p) {
            return (
                this.modulos.find((e) => {
                    return e.to === p;
                }) != undefined
            );
        },
    },
};
</script>
