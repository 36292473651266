<template>
    <div>
        <v-autocomplete
            v-model="localCategory"
            :items="categories"
            :search-input.sync="buscarCategoria"
            :loading="loading"
            no-filter
            outlined
            dense
            label="Categoria"
            ref="category"
            clearable
            @click:clear="(e) => handleInput(e)"
            @change="(e) => handleInput(e)"
        >
            <!-- En caso que no encuentra -->
            <template v-slot:no-data>
                <v-sheet class="d-flex justify-center ma-2">
                    <p>Categoria no registrado</p>
                </v-sheet>
                <v-sheet class="d-flex justify-center">
                    <v-btn
                        outlined
                        @click="
                            setDialogCategory();
                            setIsCategoryForOther();
                        "
                    >
                        Nuevo Cliente
                    </v-btn>
                </v-sheet>
            </template>

            <template slot="item" slot-scope="data">
                <v-list-item-content>
                    <v-list-item-title>
                        {{ data.item.nombre }}
                    </v-list-item-title>
                </v-list-item-content>
            </template>

            <template slot="selection" slot-scope="data">
                <v-list-item-content>
                    <v-list-item-title>
                        {{ data.item.nombre }}
                    </v-list-item-title>
                </v-list-item-content>
            </template>

            <template v-slot:append-item>
                <div v-intersect="endIntersectCategory" />
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import FormCategoryComponentVue from "./Category/FormCategory.Component.vue";

export default {
    props: ["category"],
    model: {
        prop: "category",
        event: "blur",
    },
    data: () => ({
        localCategory: null,
        buscarCategoria: "",
    }),
    components: {
        FormCategoryComponentVue,
    },
    mounted() {
        this.categoryPage.current = 1;
        this.searchCategories("");
    },
    watch: {
        async buscarCategoria(val, old) {
            if (val !== old) {
                this.categoryPage.current = 1;
                await this.searchCategories(val);
            }
        },
        categoryForOther() {
            if (this.isCategoryForOther) {
                this.localCategory = this.categoryForOther;
                this.clearCategoryForOther();
            }
        },
        localCategory(val) {
            if (val != null) {
                this.handleInput(val);
            }
        },
    },
    computed: {
        ...mapState("category", [
            "categories",
            "categoryForOther",
            "isCategoryForOther",
            "categoryPage",
            "loading",
        ]),
        ...mapState("category", ["returnCategories"]),
    },
    methods: {
        ...mapActions("category", ["searchCategories", "getCategories"]),
        ...mapMutations({
            setDialogCategory: "category/setDialog",
            setIsCategoryForOther: "category/setIsCategoryForOther",
            clearCategoryForOther: "category/clearCategoryForOther",
            setLazy: "category/setLazy",
        }),
        handleInput(value) {
            this.$emit("blur", value);
        },
        async endIntersectCategory(entries, observer, isIntersecting) {
            if (isIntersecting) {
                if (this.categoryPage.lastPage > this.categoryPage.current) {
                    this.categoryPage.current += 1;
                    this.setLazy(true);
                    if (this.buscarCategoria) {
                        await this.searchCategories(this.buscarCategoria);
                    } else {
                        await this.searchCategories("");
                    }
                }
            }
        },
    },
};
</script>

<style></style>
