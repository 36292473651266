<template>
    
    <v-card class="pa-5">
        <v-row>
            <v-col cols="12" md="4">

                <v-card class="pa-5">
                    <v-icon class="text-info" @click="goBack">mdi-arrow-left</v-icon>
                    <v-card-title>{{ creditorDetail.nombre }}</v-card-title>
                    <v-divider class="mx-2 info"></v-divider>
                    <v-card-text>RUC</v-card-text>
                    <div class="ml-4 mt-n2 text-subtitle-1">
                        {{ creditorDetail.ruc }}
                    </div>
                    <v-card-text>Celular</v-card-text>
                    <div class="ml-4 mt-n2 text-subtitle-1">
                        {{creditorDetail.phone?telefono : ' ' }}
                    </div>
                    <v-card-text>Email</v-card-text>
                    <div class="ml-4 mt-n2 text-subtitle-1">
                        {{creditorDetail.mail?correo : ' '}}
                    </div>
                    <v-card-text>Dirección</v-card-text>
                    <div class="ml-4 mt-n2 text-subtitle-1">
                        {{creditorDetail.address?direccion : ' '}}
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" md="8">
                <v-row>
                    <v-col cols="12" md="8">
                        <v-card-title>Facturas Debidas </v-card-title>
                    </v-col>
                    <v-col
                        cols="12"
                        md="4"
                        class="d-flex flex-column justify-end"
                    >
                        <v-card-title class="justify-end"> {{ convertMoney(creditorDetail.total, creditorDetail.codigo) }} </v-card-title>

                        <v-card-subtitle class="text-end">Monto Total</v-card-subtitle
                        >
                    </v-col>
                </v-row>

                <v-divider class="mx-2"></v-divider>
                <v-card
                    v-for="(details, i) in creditorDetail.purchase_header"
                    :key="i"
                    class="mt-2"
                    elevation="3"
                    :style="'border: 1px solid '+ details.vencido"
                >
                    <v-list-item class="grow">
                        <v-card-text>
                            <div class="subtitle">Factura</div>
                            <h6>
                                {{ details.factura_numero }}
                            </h6>
                        </v-card-text>
                        <v-card-text>
                            <div class="subtitle">Monto</div>
                            <h6>
                                {{  convertMoney(details.total, details.codigo) }}
                            </h6>
                        </v-card-text>
                        <v-card-text>
                            <div class="subtitle">Fecha</div>
                            <h6>
                                {{ details.created_at }}
                            </h6>
                        </v-card-text>
                        <v-card-text>
                            <div class="subtitle">Plazo</div>
                            <h6>
                                {{ details.plazo }} días
                            </h6>
                        </v-card-text>

                        <v-card-actions>
                            <v-icon
                                small
                                class="mr-2"
                                color="green"
                                @click="mostrarDetalles(details.id)"
                            >
                                mdi-eye
                            </v-icon>

                            
                            <v-icon
                                class="mr-1 mdi mdi-instant-deposit"
                                color="blue"
                                @click=" showPaymentDialog(details.id) "
                            >
                                mdi-cash-multiple
                            </v-icon>                   
                    
                        </v-card-actions>
                    </v-list-item>
                </v-card>
            </v-col>    
        </v-row>
        
        <DialogShowPurchase/>
        <!--DIALOG PARA CONFIRMAR PAGAR AL ACREEDOR-->
        <v-dialog v-model="dialogPurchaseReceipt" max-width="300" class="ma-0 pa-0">
            <v-alert
                class="bg-white ma-0"
                
                outlined
                color="info"
                type="info"
            >
                ¿Desea pagar esta factura?
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        class=""
                        @click="pagar()"
                        >pagar</v-btn
                    >
                </v-card-actions>
            </v-alert>
        </v-dialog>
    <SnackBarDialog></SnackBarDialog>
    </v-card>
</template>


<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import DialogShowPurchase from "../Purchases/DialogShowPurchase.Component.vue";
import SnackBarDialog from "../SnackBarDialog.Component";
import { convertMoney } from '../../formatos';

export default {
    data: () => ({
        dialogPurchaseReceipt: false,
        purhchaseId: 0,
    }),
    components:{
        DialogShowPurchase,
        SnackBarDialog
    },
    mounted(){
        const params = {
            id: this.$route.params.id,
            currency_id: this.$route.params.currency_id,
        }
        this.getCreditorDetail(params);
    },
    computed: {
        ...mapState("purchase", ["creditorDetail",]),

    },
    methods: {
        ...mapActions("purchase", ["getOnePurchase", "pagarFacturaCredito", "getCreditorDetail"]),
        ...mapMutations("purchase", ["setDialogCreditorDetail"]),
        showPaymentDialog(id){
            this.dialogPurchaseReceipt = true;
            this.purhchaseId = id;
        },
        mostrarDetalles(id){
            this.getOnePurchase({id: id});
            this.setDialogCreditorDetail(true);
        },
        pagar(){
            if (this.creditorDetail.purchase_header.length == 1) this.goBack();
            this.pagarFacturaCredito(this.purhchaseId); 
            this.dialogPurchaseReceipt = false
        },
        goBack(){
            history.go(-1); 
            return false;
        },
        convertMoney
    },
};
</script>
