<template v-slot:top>
    <div>
        <v-card class="my-10">
            <v-list-item class="">
                <v-card
                    class="mt-n7"
                    color="primary"
                    elevation="10"
                    min-height="80"
                    min-width="80"
                >
                    <v-icon class="ma-5" large dark>mdi-account</v-icon>
                </v-card>

                <v-list-item-title>
                    <div class="overline mx-10 mt-5">
                        <h4>Lista Deudores</h4>
                    </div>
                </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-card-text>
                        <v-card-actions> </v-card-actions>
                    </v-card-text>
                </v-list-item-content>
            </v-list-item>

            <!-- Lista de Marcas -->
            <ListDebt />
        </v-card>

        <!-- Para Dialogo Editar y Nuevo  -->
        <template>
            <FormDebt />
        </template>
        <!-- Dialogo Para Form Marca llamado de Form Product -->
    </div>
</template>
<script>
import FormDebt from "./FormDebt.Component.vue";
import ListDebt from "./ListDebt.Component.vue";
import DetailsDebt from "./DetailsDebt.Component.vue";

import { mapState, mapActions } from "vuex";

export default {
    components: {
        FormDebt,
        ListDebt,
        DetailsDebt,
    },
    computed: {},
    methods: {},
};
</script>
