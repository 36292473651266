<template>
    <div>
        <h1>Permisos</h1>

        <!--Formulario para crear o editar -->
        <template>
            <v-form v-on:submit.prevent="guardar">
                <v-container>
                    <v-row>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Nombre"
                                v-model="permission.name"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-btn color="success" float-right type="submit">
                            guardar
                        </v-btn>
                    </v-row>
                </v-container>
            </v-form>
        </template>

        <template>
            <ul v-for="(item, index) in permissions">
                <li >
                    <span>{{item.name}} </span> 
                    <v-icon small class="mr-2" @click="selectItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon @click="eliminar(item)">
                        remove_circle_outline
                    </v-icon>
                </li>
            </ul>
        </template>
        
    </div>
</template>

<script>
export default {
    data() {
        return {
            permissions: [],
            permission:{
                id:'',
                name:'',
            }

        };
    },
    mounted() {
        axios.get("/permissions").then(response => {
            this.permissions = response.data;
        });
    },
    methods: {
        guardar() {
            if (this.permission.id) {
                axios
                    .put(`permissions/${this.permission.id}`, this.permission)
                    .then(response => {
                        const index = this.permissions.indexOf(this.permission);
                        this.permissions[index]=response.data;
                        this.permission={
                            id:'',
                            name:'',
                        }
                    });
            } else {
                axios.post("permissions", this.permission).then(response => {
                    this.permissions.push(response.data);
                    this.permission={
                        id:'',
                        name:'',
                    }              
                });
            }
        },
        eliminar(item) {
            axios.delete(`/permissions/${item.id}`).then(response => {
                const index = this.permissions.indexOf(item);
			    if (index >= 0) this.permissions.splice(index, 1)
            });
        },
        selectItem(item){
            this.permission= item;
        }
    }
};
</script>
