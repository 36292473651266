<template>
    <div>
        <v-row>
            <v-col cols="12" md="4">
                
                <v-card class="pa-5">
                    <v-icon @click="goBack">mdi-arrow-left</v-icon>
                    <v-card-title>{{ client.nombre_y_apellido }}</v-card-title>
                    <v-divider class="mx-2"></v-divider>
                    <v-card-text>RUC</v-card-text>
                    <div class="ml-4 mt-n2 text-subtitle-1">
                        {{ client.ruc }}
                    </div>
                    <v-card-text>Celular</v-card-text>
                    <div class="ml-4 mt-n2 text-subtitle-1">
                        {{ client.telefono }}
                    </div>
                    <v-card-text>Email</v-card-text>
                    <div class="ml-4 mt-n2 text-subtitle-1">
                        {{ client.email }}
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" md="8">
                <v-card class="pa-5">
                    <v-row>
                        <v-col cols="12" md="8">
                            <v-card-title>Lista de ventas</v-card-title>
                        </v-col>
                        <v-col
                            cols="12"
                            md="4"
                            class="d-flex flex-column justify-end"
                        >
                            <v-card-title class="justify-end" > {{ convertMoney(debtorData.total, debtorData.codigo) }} </v-card-title>
                            <v-card-subtitle class="text-end" >Monto Total</v-card-subtitle>
                        </v-col>
                    </v-row>

                    <v-divider class="mx-2"></v-divider>
                    <ListSaleDetail />
                </v-card>
            </v-col>
        </v-row>
        <template>
            <DialogShowSale />
        </template>
    </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";

import ListSaleDetail from "./ListSaleDetail.Component.vue";
import DialogShowSale from "../Sales/DialogShowSale.Component.vue";
import { convertMoney } from "../../formatos.js";

export default {
    data: () => ({
        search: "",
    }),
    components: {
        ListSaleDetail,
        DialogShowSale,
    },
    mounted() {
        this.getPermissions(this.$route.path);
        const params = {
            id: this.$route.params.id,
            currency_id: this.$route.params.currency_id
        }
        this.getOneDebtor(params);
        this.findClient(this.$route.params);
    },
    computed: {
        ...mapState("app", ["canCreate", "canEdit", "canDelete"]),
        ...mapState("client", ["client"]),
        ...mapState("debtor", [
            "debtors",
            "headers",
            "debtorDetails",
            "debtorData",
        ]),
    },
    methods: {
        ...mapMutations("debtor", ["setDialog", "setDeleteDialog"]),
        ...mapActions("debtor", ["getDebtors", "getOneDebtor"]),
        ...mapActions("app", ["getPermissions"]),
        ...mapActions("client", ["findClient"]),

        goDetailsDebt(id) {
            this.$router.push(`/debt/${id}`);
        },
        goBack(){
            history.go(-1); 
            return false;
        },
        convertMoney,
    },
};
</script>

<style>
.container {
    height: 100%;
}
</style>
