<template>
    <v-card class="pa-5">
        <v-icon class="text-info" @click="goBack">mdi-arrow-left</v-icon>
        
        <v-row justify="center">
            <v-col md="3" class="text-center">
                <v-card-subtitle class="h5 ma-0 pa-0">
                    Detalle de ventas
                </v-card-subtitle>
            </v-col>
            
        </v-row>
       <ListSalesComponentVue></ListSalesComponentVue>
    </v-card>
</template>


<script>
import ListSalesComponentVue from '../../Sales/ListSales.Component.vue';
export default {
    components:{
       ListSalesComponentVue
    },
    methods: {
        goBack(){
            history.go(-1); 
            return false;
        },
    },
};
</script>
