const state = {
    dialogTransfer: false,
    transfer: {
        store_in_id: 0,
        store_out_id: 0,
        details: [],
    },
    transferProducts: [],
    headersTransfer: [
        { text: "Producto", value: "nombre" },
        { text: "Cantidad", value: "cantidad" },
        { text: "Opciones", value: "actions", align: "center" },
    ],
};
const actions = {
    createTransferStore({ commit, dispatch }, payload) {
        axios
            .post("/transfer_stock", payload)
            .then(() => {
                commit("CREATE_TRANSFER");
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
            })
            .then(() => {
                dispatch(
                    "deposito/getProductsStores",
                    { dep: 1 },
                    { root: true }
                );
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
};
const mutations = {
    setDialogTransfer(state, payload) {
        state.dialogTransfer = payload;
    },
    addTransferProducts(state, product) {
        const index = state.transferProducts.find(
            (p) => p.product_id === product.product_id
        );
        if (!index) {
            state.transferProducts.push(product);
        } else {
            this.commit(
                "app/setSnackbarInfo",
                "Se ha repetido el producto, verifique la tabla"
            );
        }
    },
    editTransferProducts(state, product) {
        state.transferProducts.splice(
            state.transferProducts.indexOf(
                (p) => p.product_id === product.product_id
            ),
            1,
            product
        );
    },

    deleteTransferProducts(state, product) {
        const { product_id } = product;
        const index = state.transferProducts.findIndex(
            (p) => product_id === product_id
        );
        state.transferProducts.splice(index, index >= 0 ? 1 : 0);
    },
    clearTransferProducts(state) {
        state.transferProducts = [];
    },
    clearTransfer(state) {
        state.transfer = {
            store_in_id: 0,
            store_out_id: 0,
            details: [],
        };
        state.transferProducts = [];
    },
    CREATE_TRANSFER(state) {
        state.transfer = {
            store_in_id: 0,
            store_out_id: 0,
            details: [],
        };
        state.transferProducts = [];
        state.dialogTransfer = false;
    },
};
const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
