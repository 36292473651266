<template v-slot:activator="{ on, attrs }">
    <v-form ref="form" v-model="valid">
        <v-row justify="center">
            <!-- Dialog (Modal Form Client) -->
            <v-dialog
                v-model="dialogFamily"
                persistent
                max-width="800px"
                @click:outside="cerrarDialogProduct()"
                @keydown.esc="cerrarDialogProduct()"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        label="Nombre"
                                        v-model="product.nombre"
                                        ref="nombre_familia_producto"
                                        :rules="[required('nombre')]"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        label="Código"
                                        v-model="product.codigo"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                        label="Código de referencia"
                                        v-model="product.referencia"
                                        :rules="[required('referencia')]"
                                        :error="errorReferencia"
                                        :error-messages="errorReferencia ? 'Código de referencia repetido' : ''"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <!-- Seccion Marca -->
                                <v-col cols="12" sm="4" md="4">
                                    <!-- seleccion y busqueda de marca -->
                                    <v-autocomplete
                                        :menu-props="{
                                            nudgeBottom: 15 + 'px',
                                        }"
                                        v-model="product.brand_id"
                                        :items="brands"
                                        :search-input.sync="searchBrand"
                                        label="Elija una marca"
                                        item-text="nombre"
                                        item-value="id"
                                        cache-items
                                        clearable
                                        :loading="loading"
                                        @click:clear="product.brand_id = null"
                                    >
                                        <!-- En caso que no encuentra -->
                                        <!-- Opcion para crear marca -->
                                        <template v-slot:no-data>
                                            <v-sheet
                                                class="d-flex justify-center ma-2"
                                            >
                                                <h6>Marca inexistente</h6>
                                            </v-sheet>
                                            <v-sheet
                                                class="d-flex justify-center"
                                            >
                                                <v-btn
                                                    color="primary"
                                                    @click="
                                                        setDialogBrand();
                                                        setIsBrandForOther();
                                                    "
                                                    >Crear Marca</v-btn
                                                >
                                            </v-sheet>
                                        </template>

                                        <template v-slot:item="{ item }">
                                            <v-card
                                                outlined
                                                tile
                                                class="mr-2 rounded"
                                            >
                                                <v-img
                                                    v-if="!!item.img_url"
                                                    max-height="50"
                                                    max-width="50"
                                                    :src="item.img_url"
                                                />
                                            </v-card>
                                            {{ item.nombre }}
                                        </template>
                                        <template v-slot:append-item>
                                            <div
                                                v-intersect="endIntersectBrand"
                                            />
                                        </template>
                                    </v-autocomplete>
                                </v-col>

                                <!-- Seccion Categoria -->
                                <v-col cols="12" sm="4" md="4">
                                    <!-- seleccion y busqueda de categoria -->
                                    <v-autocomplete
                                        v-model="product.category"
                                        :items="categories"
                                        :search-input.sync="searchCategory"
                                        label="Seleccione una categoria"
                                        item-text="nombre"
                                        item-value="id"
                                        multiple
                                        cache-items
                                        clearable
                                        @click:clear="
                                            product.category = null
                                        "
                                    >
                                        <!-- En caso que no encuentra -->
                                        <!-- Opcion para crear categoria -->
                                        <template v-slot:no-data>
                                            <v-sheet
                                                class="d-flex justify-center ma-2"
                                            >
                                                <h6>Categoria inexistente</h6>
                                            </v-sheet>
                                            <v-sheet
                                                class="d-flex justify-center"
                                            >
                                                <v-btn
                                                    class="primary"
                                                    @click="
                                                        setDialogCategory();
                                                        setIsCategoryForOther();
                                                    "
                                                    >Crear Categoria</v-btn
                                                >
                                            </v-sheet>
                                        </template>

                                        <template v-slot:item="{ item }">
                                            <v-card
                                                outlined
                                                tile
                                                class="mr-2 rounded"
                                            >
                                                <v-img
                                                    v-if="!!item.img_url"
                                                    max-height="50"
                                                    max-width="50"
                                                    :src="item.img_url"
                                                />
                                            </v-card>
                                            {{ item.nombre }}
                                        </template>
                                        <template v-slot:append-item>
                                            <div
                                                v-intersect="
                                                    endIntersectCategory
                                                "
                                            />
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    class="d-flex align-end"
                                    cols="12"
                                    sm="4"
                                    md="4"
                                >
                                    <v-file-input
                                        v-model="filename"
                                        accept="image/*"
                                        label="Selccionar Imagen"
                                        :rules="sizeRules"
                                        show-size
                                        counter
                                        clearable
                                        @change="onFileChange"
                                    >
                                    </v-file-input>
                                </v-col>
                                <v-col
                                    class="d-flex align-end mb-5"
                                    cols="12"
                                    sm="4"
                                    md="4"
                                >
                                    <v-sheet elevation="2" class="rounded">
                                        <v-img
                                            v-if="showImg"
                                            v-bind:src="showImg"
                                            height="150"
                                            width="120"
                                            class="rounded text-center align-end pa-1"
                                            aspect-ratio="2"
                                            contain
                                        >
                                            <v-tooltip bottom>
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-fab-transition>
                                                        <v-btn
                                                            x-small
                                                            dark
                                                            bottom
                                                            color="red"
                                                            v-on="on"
                                                            v-bind="attrs"
                                                            @click="resetImage"
                                                            >Eliminar
                                                        </v-btn>
                                                    </v-fab-transition>
                                                </template>
                                                <span>Eliminar Imagen</span>
                                            </v-tooltip>
                                        </v-img>
                                    </v-sheet>
                                </v-col>

                                <v-col md="4">
                                    <v-select
                                        v-model="product.iva"
                                        :items="opcionesIva"
                                        label="Iva"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea
                                        label="Descripcion"
                                        v-model="product.descripcion"
                                        rows="2"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                        <small>*Campos obligatorios</small>

                        <v-divider></v-divider>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="red darken-1"
                            outlined
                            @click="cerrarDialogProduct()"
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            v-if="edit"
                            :disabled="!valid"
                            color="blue darken-1"
                            outlined
                            @click="validateSubmit(edit, product)"
                        >
                            Editar
                        </v-btn>
                        <v-btn
                            v-else
                            :disabled="!valid || isLoading"
                            :loading="isLoading"
                            color="blue darken-1"
                            outlined
                            @click="validateSubmit(edit, product)"
                        >
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-form>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import { required} from "../../utils/validate.js";
export default {
    components: {
    },
    data: () => ({
        valid: true,
        required,
        negativeRules: [(v) => v >= 0 || "El precio no puede ser negativo"],
        sizeRules: [
            (v) =>
                !v ||
                v.size < 2000000 ||
                "No subir archivo superando los 2 MB!",
        ],
        // marcaRules: [(v) => !!v || "Marca del Producto es obligatorio"],
        localItem: {},
        searchBrand: "",
        searchCategory: "",
        filename: null,
        showImg: null,
        iva: "10%",
        opcionesIva: ["5%", "10%", "Exenta"],
        quoteRules: [
            (v) => !!v || "Es necesario seleccionar moneda",
            (v) => v !== 0 || "Es necesario seleccionar moneda",
        ],
        valuesColors: [],
    }),
    mounted() {
        this.getBrands();
        this.getCategories();
        this.getQuoteCurrency();
    },
    computed: {
        //llamada de variables del store
        ...mapState("product", [
            "dialogFamily",
            "edit",
            "product",
            "img",
            "errorReferencia"
        ]),
        ...mapState("brand", [
            "brands",
            "isBrandForOther",
            "brandForOther",
            "brandsPage",
            "loading",
        ]),
        ...mapState("category", [
            "categories",
            "isCategoryForOther",
            "categoryForOther",
            "categoryPage",
        ]),
        ...mapState("app", ["isLoading"]),
        ...mapState("quote", ["quotes"]),
        //Dependiendo del edit (boolean) cambia el titulo del form
        formTitle() {
            return this.edit === false
                ? "Nueva Familia de Productos"
                : "Editar Familia de Productos";
        },
    },
    watch: {
        //actualiza al agregar nueva Marca desde el Form Producto
        //agrega el id Marca creado al campo de seleccion Marca
        brandForOther(val) {
            if (this.isBrandForOther) {
                this.product.marca_id = this.brandForOther.id;
                this.clearBarndForOther;
            }
        },
        categoryForOther(val) {
            if (this.isCategoryForOther) {
                this.product.category.push(this.categoryForOther.id);
                this.clearCatecogyForOther;
            }
        },
        dialogFamily: function () {
            if (this.product.img_url !== null) {
                this.showImg = this.product.img_url;
                this.setImage(this.product.img_url);
                setTimeout(() =>{
                    this.$refs.nombre_familia_producto.$refs.input.focus();
                })
            } else {
                this.showImg = null;
                this.setImage(null);
            }
        },
        searchBrand(val) {
            this.searchBrands(val);
        },
        searchCategory(val) {
            this.searchCategories(val);
        },
        "product.referencia"(val){
            this.checkReferencia(val)
        }
    },
    methods: {
        //llamada de metodos del store
        ...mapMutations("product", [
            "setDialogFamily",
            "setEdit",
            "clearProduct",
            "setImage",
        ]),
        ...mapMutations("brand", { setDialogBrand: "setDialog" }),
        ...mapMutations("brand", [
            "setIsBrandForOther",
            "clearBrandForOther",
            "addListBrands",
        ]),
        ...mapMutations("category", { setDialogCategory: "setDialog" }),
        ...mapMutations("category", [
            "setIsCategoryForOther",
            "clearCategoryForOther",
            "addListCategories",
        ]),
        ...mapActions("quote", ["getQuoteCurrency"]),
        //llamada de peticiones del store
        ...mapActions("product", [
            "editProductFamily",
            "getCategory",
            "createProductFamily",
            "checkReferencia"
        ]),
        ...mapActions("brand", ["getBrands", "searchBrands"]),
        ...mapActions("category", ["getCategories", "searchCategories"]),

        //validar campo requerido
        //Dependiendo del edit (boolean), crea o edita la marca
        //resetea validacion, obtiene las listas marca, borra los campos nombre url
        validateSubmit(edit, item) {
            this.product.img_url = this.img;

            if (this.$refs.form.validate()) {
                edit ? this.editProductFamily(item) : this.createProductFamily(item);
            }
            this.resetValidate();
        },
        resetValidate() {
            this.$refs.form.resetValidation();
        },

        resetImage() {
            this.showImg = null;
            this.setImage(null);
            this.filename = null;
        },
        onFileChange(payload) {
            const file = payload; //obtener datos de la variable
            if (file) {
                this.showImg = URL.createObjectURL(file); //guardar solo la ruta guardada
                URL.revokeObjectURL(file); // liberar memoria
                this.saveImage(file); //guardar imagen
            } else {
                this.setImage(null);
            }
        },
        saveImage(payload) {
            let data = new FormData();
            data.append("image", payload);
            this.setImage(data);
        },
        endIntersectBrand(entries, observer, isIntersecting) {
            if (isIntersecting) {
                if (this.brandsPage.lastPage > this.brandsPage.current) {
                    this.brandsPage.current += 1;
                    this.getBrands();
                    this.addListBrands(this.brands);
                }
            }
        },
        endIntersectCategory(entries, observer, isIntersecting) {
            if (isIntersecting) {
                if (this.categoryPage.lastPage > this.categoryPage.current) {
                    this.categoryPage.current += 1;
                    this.getCategories();
                    this.addListCategories(this.categories);
                }
            }
        },
        addExtensionColor() {
            this.valuesColors.push({
                color: "",
                codigo: "",
                size: "",
                sizes: [],
            });
        },
        addExtensionSize(payload) {
            this.valuesColors[payload.colorIdx].sizes.push(payload.size);
        },
        removeExtensionSize(payload) {
            this.valuesColors[payload.colorIdx].sizes.splice(
                payload.sizeIdx,
                1
            );
        },
        cerrarDialogProduct() {
            this.setDialogFamily();
            this.clearProduct();
            this.setEdit(false);
            this.resetValidate();
            this.clearBrandForOther();
            this.valuesColors = [];
        },
    },
};
</script>
