var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px","retain-focus":false},on:{"click:outside":function($event){_vm.setDialog();
                _vm.clearClient();
                _vm.resetValidate();},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.setDialog();
                _vm.clearClient();
                _vm.resetValidate();}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_vm._v(" "),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Nombre Apellido","rules":[_vm.required('nombre')],"required":""},model:{value:(_vm.client.nombre_y_apellido),callback:function ($$v) {_vm.$set(_vm.client, "nombre_y_apellido", $$v)},expression:"client.nombre_y_apellido"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Ruc","rules":[_vm.required('ruc')],"required":""},model:{value:(_vm.client.ruc),callback:function ($$v) {_vm.$set(_vm.client, "ruc", $$v)},expression:"client.ruc"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"email","rules":[
                                        _vm.required('email'),
                                        _vm.validEmail(),
                                    ]},model:{value:(_vm.client.email),callback:function ($$v) {_vm.$set(_vm.client, "email", $$v)},expression:"client.email"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-row',{attrs:{"no-gutters":"","align-end":""}},[(!_vm.edit)?_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-select',{attrs:{"items":_vm.countries,"item-text":"name","item-value":"code"},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_vm._v("\n                                                "+_vm._s(item.code)+"\n                                            ")]}}],null,false,3404179129),model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"sm":"8","md":"8"}},[_c('v-text-field',{attrs:{"label":"Número de teléfono","hint":"Ingresa tu número de teléfono sin el 0 al inicio","type":"tel"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return undefined},proxy:true}]),model:{value:(_vm.client.telefono),callback:function ($$v) {_vm.$set(_vm.client, "telefono", $$v)},expression:"client.telefono"}})],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"label":"Nacionalidad","items":_vm.nationality,"rows":"2"},model:{value:(_vm.client.nacionalidad),callback:function ($$v) {_vm.$set(_vm.client, "nacionalidad", $$v)},expression:"client.nacionalidad"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Direccion","rows":"2"},model:{value:(_vm.client.direccion),callback:function ($$v) {_vm.$set(_vm.client, "direccion", $$v)},expression:"client.direccion"}})],1)],1)],1),_vm._v(" "),_c('small',[_vm._v("*indicates required field")])],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),(_vm.edit)?_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":function($event){_vm.setDialog();
                            _vm.setEdit();
                            _vm.clearClient();
                            _vm.resetValidate();}}},[_vm._v("\n                        Cerrar\n                    ")]):_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":function($event){_vm.setDialog();
                            _vm.clearClient();
                            _vm.resetValidate();}}},[_vm._v("\n                        Cerrar\n                    ")]),_vm._v(" "),(_vm.edit)?_c('v-btn',{attrs:{"disabled":!_vm.valid,"color":"blue darken-1","outlined":""},on:{"click":function($event){return _vm.validateSubmit(_vm.edit, _vm.client)}}},[_vm._v("\n                        Editar\n                    ")]):_c('v-btn',{attrs:{"disabled":!_vm.valid || _vm.isLoading,"loading":_vm.isLoading,"color":"blue darken-1","outlined":""},on:{"click":function($event){return _vm.validateSubmit(_vm.edit, _vm.client)}}},[_vm._v("\n                        Guardar\n                    ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }