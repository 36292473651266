import axios from "axios";

const state = {
    sellers: [],
};
const actions = {
    getSellers({ commit }) {
        axios
            .get("/get_sellers")
            .then((res) => {
                commit("GET_SELLERS", res.data);
            })
            .catch((err) => {
                this.commit("app/setSnackbarAlert", err.response.data.message);
            });
    },
};
const mutations = {
    GET_SELLERS(state, payload) {
        state.sellers = payload;
    },
};
const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
