<template>
    <v-row justify="center">
        <v-dialog v-model="dialogFormRecipient" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5"
                        >{{ returnTitleForm }} Destinatario</span
                    >
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="recipient.nombre"
                                        label="Destinatario"
                                        :rules="nombreRules"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="recipient.ruc"
                                        label="Ruc"
                                        placeholder="aaaa-111"
                                        :rules="rucRules"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-textarea
                                        v-model="recipient.direccion"
                                        label="Direccion"
                                        auto-grow
                                        rows="2"
                                        :rules="direccionRules"
                                        required
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red " text @click="handlerClose()">
                        Cerrar
                    </v-btn>
                    <v-btn
                        :color="returnColorBtn"
                        text
                        @click="crearRecipient()"
                    >
                        {{ returnBtnForm }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
    data: () => ({
        valid: true,
        dialog: false,
        nombreRules: [(v) => !!v || "Nombre es obligatorio"],
        rucRules: [(v) => !!v || "Ruc es obligatorio"],
        direccionRules: [(v) => !!v || "Direccion es obligatorio"],
    }),
    computed: {
        ...mapState("recipient", [
            "recipient",
            "dialogFormRecipient",
            "isEditRecipient",
        ]),

        returnTitleForm() {
            return this.isEditRecipient ? "Editar" : "Agregar";
        },
        returnBtnForm() {
            return this.isEditRecipient ? "Editar" : "Guardar";
        },
        returnColorBtn() {
            return this.isEditRecipient ? "green darken-1" : "blue darken-1";
        },
    },
    methods: {
        ...mapMutations("recipient", [
            "setDialogFormRecipient",
            "handlerClose",
        ]),
        ...mapActions("recipient", [
            "createRecipient",
            "getRecipients",
            "editRecipient",
        ]),

        crearRecipient() {
            if (this.$refs.form.validate()) {
                if (this.isEditRecipient) {
                    this.editRecipient(this.recipient);
                } else {
                    this.createRecipient(this.recipient);
                }
            }
        },
    },
    mounted() {},
};
</script>

<style></style>
