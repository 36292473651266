var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"1000px"},on:{"click:outside":function($event){return _vm.setDialogCreditorDetail(false)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.setDialogCreditorDetail(false)}},model:{value:(_vm.dialogCreditorDetail),callback:function ($$v) {_vm.dialogCreditorDetail=$$v},expression:"dialogCreditorDetail"}},[_c('v-card',[_c('v-row',{staticClass:"mx-1",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"font-weight-normal"},[_vm._v("Proveedor:\n                            "+_vm._s(_vm.purchase.supplier.nombre === null
                                    ? ""
                                    : _vm.purchase.supplier.nombre))]),_vm._v(" "),_c('v-list-item-subtitle',{staticClass:"font-weight-normal"},[_vm._v("Ruc:\n                            "+_vm._s(_vm.purchase.supplier.ruc))]),_vm._v(" "),_c('v-list-item-subtitle',{staticClass:"font-weight-normal"},[_vm._v("Fecha:\n                            "+_vm._s(_vm.purchase.fecha))]),_vm._v(" "),_c('v-list-item-subtitle',{staticClass:"font-weight-normal"},[_vm._v("Plazo:\n                            "+_vm._s(_vm.purchase.plazo))])],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h7 text-end mb-2"},[_vm._v("\n                            Factura: "+_vm._s(_vm.purchase.factura_numero)+"\n                        ")]),_vm._v(" "),_c('v-list-item-title',{staticClass:"text-h6 text-end mb-2"},[_vm._v("\n                            Total:\n                            "+_vm._s(_vm.convertMoney(
                                    _vm.purchase.total,
                                    _vm.purchase.moneda?.codigo || "PYG"
                                ))+"\n                        ")])],1)],1)],1)],1),_vm._v(" "),_c('v-row',{staticClass:"p-0 m-0"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headersProductCreditorDetail,"items":_vm.purchase.purchase_detail,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.store_id`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.returnNameStore(item.store_id)))])]}},{key:`item.precio_unitario`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.convertMoney(
                                item.precio_unitario,
                                _vm.purchase.moneda?.codigo
                            )))])]}},{key:`item.precio_descuento`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.convertMoney(
                                item.precio_descuento,
                                _vm.purchase.moneda?.codigo
                            )))])]}},{key:`item.precio_venta`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.convertMoney(
                                item.precio_venta,
                                _vm.purchase.moneda?.codigo
                            )))])]}},{key:`item.precio_mayorista`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.convertMoney(
                                item.precio_mayorista,
                                _vm.purchase.moneda?.codigo
                            )))])]}},{key:`item.subtotal`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.convertMoney(
                                item.subtotal,
                                _vm.purchase.moneda?.codigo
                            )))])]}}],null,true)})],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"ma-3",attrs:{"color":"red lighten-2","text":""},on:{"click":function($event){return _vm.setDialogCreditorDetail(false)}}},[_vm._v("\n                Cerrar\n            ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }