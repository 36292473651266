import axios from "axios";
const state = {
    vehicles: [],
    dialogFormVehicle: false,
    dialogDeleteVehicle: false,
    isEditVehicle: false,
    vehicle: {
        marca_vehiculo: "",
        rua: "",
    },
    headers: [
        {
            text: "Marca",
            align: "start",
            value: "marca_vehiculo",
        },
        { text: "Chapa", value: "rua", sortable: false },
        { text: "Opciones", value: "actions", sortable: false, width: "150px" },
    ],
};
const actions = {
    getVehicles({ commit }) {
        axios
            .get("vehicles")
            .then((res) => {
                commit("GET_VEHICLES", res.data);
            })
            .catch((error) => {
                throw error;
            });
    },
    createVehicle({ commit, dispatch }, payload) {
        axios
            .post("vehicles", payload)
            .then((res) => {
                commit("CLEAR_VEHICLE");
                commit("EDIT_VEHICLE");
                dispatch("getVehicles");
            })
            .catch((err) => {
                throw err;
            });
    },
    editVehicle({ commit, dispatch }, payload) {
        axios
            .put(`vehicles/${payload.id}`, payload)
            .then((res) => {
                commit("CLEAR_VEHICLE");
                commit("EDIT_VEHICLE");
                dispatch("getVehicles");
            })
            .catch((err) => {
                throw err;
            });
    },
    deleteVehicle({ commit, dispatch }, payload) {
        axios
            .delete(`vehicles/${payload.id}`)
            .then((res) => {
                commit("CLEAR_VEHICLE");
                dispatch("getVehicles");
            })
            .catch((err) => {
                throw err;
            });
    },
};
const mutations = {
    setDialogFormVehicle(state, payload) {
        state.dialogFormVehicle = payload;
    },
    setDialogDeleteVehicle(state, payload) {
        state.dialogDeleteVehicle = payload;
    },
    setVehicle(state, payload) {
        state.vehicle = payload;
    },
    handlerEditVehicle(state, vehicle) {
        state.isEditVehicle = true;
        state.dialogFormVehicle = true;
        state.vehicle = Object.assign({}, vehicle);
    },
    handlerClose(state) {
        state.vehicle = {
            marca_vehiculo: "",
            rau: "",
        };
        state.dialogFormVehicle = false;
        state.isEditVehicle = false;
    },
    GET_VEHICLES(state, payload) {
        state.vehicles = payload;
    },
    CREATE_VEHICLE(state) {
        state.isEditVehicle = false;
        state.dialogFormVehicle = false;
    },
    EDIT_VEHICLE(state) {
        state.isEditVehicle = false;
        state.dialogFormVehicle = false;
    },
    CLEAR_VEHICLE(state) {
        state.vehicle = {
            marca_vehiculo: "",
            rau: "",
        };
    },
};
const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
