<template>
    <div>
        <v-card
            v-for="(details, i) in debtorDetails"
            :key="i"
            class="mt-2"
            elevation="8"
            :style="'border: 1px solid; border-color: blue'"

        >
            <v-list-item class="grow">
                <v-card-text>
                    <div class="subtitle">Nº Venta</div>
                    <h6>
                        {{ details.numero }}
                    </h6>
                </v-card-text>
                <v-card-text>
                    <div class="subtitle">Factura</div>
                    <h6>
                        {{ details.factura_numero?? '--' }}
                    </h6>
                </v-card-text>
                <v-card-text>
                    <div class="subtitle">Monto</div>
                    <h6>
                        {{
                            convertMoney(details.sale_detail[0].monto_factura, details.codigo)
                        }}
                        Gs
                    </h6>
                </v-card-text>
                <v-card-text>
                    <div class="subtitle">Fecha</div>
                    <h6>
                        {{
                            new Date(details.created_at).toLocaleDateString(
                                "es-PY"
                            )
                        }}
                    </h6>
                </v-card-text>

                <v-card-actions>
                    <v-btn
                        color="white"
                        rounded
                        small
                        @click="
                            getShowSale(details.id);
                            setDialogShowSale(true);
                        "
                    >
                        <v-icon class="mr-1" color="green">
                            mdi-file-document-box-search-outline
                        </v-icon>
                    </v-btn>
                <!--    <v-btn rounded small>
                        <v-icon
                            class="mr-1 mdi mdi-instant-deposit"
                            color="blue"
                        >
                            mdi-cash-multiple
                        </v-icon>
                    </v-btn> -->
                </v-card-actions>
            </v-list-item>
        </v-card>
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { convertMoney } from "../../formatos";

export default {
    computed: {
        ...mapState("debtor", ["debtors", "debtorDetails", "debtorData"]),
    },
    methods: {
        ...mapActions("sale", ["getShowSale"]),
        ...mapMutations("sale", ["setDialogShowSale"]),
        convertMoney
    },
};
</script>
