<template v-slot:top>
    <div>
        <v-card class="my-10">
            <v-list-item class="">
                <v-card
                    class="mt-n7"
                    color="primary"
                    elevation="10"
                    min-height="80"
                    min-width="80"
                >
                    <v-icon class="ma-5" large dark>shopping_cart</v-icon>
                </v-card>

                <v-list-item-title>
                    <div class="overline mx-10 mt-5">
                        <h4>Lista Compras</h4>
                    </div>
                </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-card-text>
                        <v-card-actions> </v-card-actions>
                    </v-card-text>
                </v-list-item-content>
            </v-list-item>

            <!-- Lista de Marcas -->
            <ListPurchases></ListPurchases>
        </v-card>

        <!-- Para Dialogo Editar y Nuevo  -->
        <template v-if="dialog">
            <FormPurchases></FormPurchases>
        </template>
    </div>
</template>
<script>
import ListPurchases from "./ListPurchases.Component.vue";
import FormPurchases from "./FormPurchases.Component.vue";

import { mapState, mapMutations } from "vuex";

export default {
    data: () => ({}),

    components: {
        ListPurchases,
        FormPurchases,
    },

    computed: {
        ...mapState("app", ["snackbar"]),
        ...mapState("purchase", ["dialog"]),
    },
    methods: {
        ...mapMutations("purchase", ["setRutaCompra"]),
    },
    created() {
        this.setRutaCompra("/purchases");
    },
};
</script>
