var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.getSalesData.length > 0 || _vm.loadingListSale)?_c('v-card',{attrs:{"elevation":"0","loading":_vm.loadingListSale,"min-height":"200"}},[_c('template',{slot:"progress"},[_c('LoadingComponent')],1),_vm._v(" "),_c('v-list',{staticClass:"d-flex flex-wrap"},[_vm._l((_vm.getSalesData),function(sale,i){return _c('v-list-item',{key:i},[_c('v-card',{staticClass:"mt-2 d-flex",style:('border: 0.25px solid #5accff; width: 100%'),attrs:{"elevation":"4"}},[_c('v-card-text',[_c('div',{staticClass:"subtitle"},[_vm._v("Venta")]),_vm._v(" "),_c('h6',[_vm._v("\n                            "+_vm._s(sale.numero)+"\n                        ")])]),_vm._v(" "),_c('v-card-text',[_c('div',{staticClass:"subtitle"},[_vm._v("Factura")]),_vm._v(" "),_c('h6',[_vm._v("\n                            "+_vm._s(sale.factura_numero || "--")+"\n                        ")])]),_vm._v(" "),_c('v-card-text',[_c('div',{staticClass:"subtitle"},[_vm._v("Cliente")]),_vm._v(" "),_c('h6',[_vm._v("\n                            "+_vm._s(sale.nombre_y_apellido || "--")+"\n                        ")]),_vm._v(" "),_c('h6',[_vm._v("\n                            "+_vm._s(sale.ruc || "--")+"\n                        ")])]),_vm._v(" "),_c('v-card-text',[_c('div',{staticClass:"subtitle"},[_vm._v("Monto")]),_vm._v(" "),_c('h6',[_vm._v("\n                            "+_vm._s(_vm.convertMoney(
                                    sale.total,
                                    sale.codigo || "PYG"
                                ))+"\n                        ")])]),_vm._v(" "),_c('v-card-text',[_c('div',{staticClass:"subtitle"},[_vm._v("Fecha")]),_vm._v(" "),_c('h6',[_vm._v("\n                            "+_vm._s(new Date(
                                    sale.created_at
                                ).toLocaleDateString("es-es", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                }))+"\n                        ")])]),_vm._v(" "),_c('v-card-actions',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"small":"","icon":"","color":"white"},on:{"click":function($event){_vm.dialog = true;
                                _vm.getOneSaleDevolution(sale.id);
                                _vm.saleId = sale.id;}}},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("\n                                "+_vm._s(_vm.icons.mdiInvoiceTextEditOutline)+"\n                            ")])],1),_vm._v(" "),_c('v-btn',{attrs:{"small":"","icon":"","color":"white"},on:{"click":function($event){_vm.getShowSale(sale.id);
                                _vm.setDialogShowSale(true);}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-eye ")])],1)],1)],1)],1)}),_vm._v(" "),_c('v-skeleton-loader',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.endIntersectSale),expression:"endIntersectSale"}]})],2)],2):_c('v-card',{staticClass:"pt-4 d-flex justify-center",attrs:{"elevation":"0"}},[_c('h4',[_vm._v("No hay datos")])]),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"400"},on:{"click:outside":function($event){_vm.dialog = false}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('v-btn',{staticStyle:{"text-decoration":"none"},attrs:{"color":"primary","outlined":"","to":`form/devolution/${_vm.saleId}`}},[_vm._v("Devolucion")])],1),_vm._v(" "),_c('v-col',[_c('v-btn',{staticStyle:{"text-decoration":"none"},attrs:{"color":"primary","outlined":"","to":`form/exchange/${_vm.saleId}`}},[_vm._v("Intercambio")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }