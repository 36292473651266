<template>
    <div class="mx-10">
        <div>
            <DialogListSale/>
            <DialogPaymentSale/>
        </div>
        <!--dialog mostrar opciones para pago-->
        <template>
        <div class="text-center">
            <v-dialog
                v-model="dialogSelectTypePayment"
                width="400"
                @click:outside="setDialogSelectTypePayment"
            >
                <v-card>
                    <v-card-actions class="justify-center">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary lighten-2"
                                text
                                v-bind="attrs"
                                v-on="on"
                                class="mx-5"
                                @click="setDialogListSale"
                            >
                                Pagar x ventas
                            </v-btn>
                            </template>
                            <span>Esta opción le permite seleccionar las facturas que desee pagar</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary lighten-2"
                                text
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                    setIsPayAmount();
                                    setDialogPayment();
                                "
                            >
                                Pagar monto
                            </v-btn>
                            </template>
                            <span>Esta opción le permite ingresar un monto y seleccionar las facturas que puede pagar por dicho monto, tambien permite pago parcial de factura</span>
                        </v-tooltip>
                    </v-card-actions>

                </v-card>
            </v-dialog>
        </div>
        </template>
        <!--aca termina dialog mostrar opciones para pago-->

        <v-data-table :headers="headers" :items="debtors">

                <template v-slot:[`item.actions`]="{ item}">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            color="green"
                            v-bind="attrs"
                            v-on="on"
                            @click="
                                getDeb(item);
                                goDetailsDebt(item);
                            "
                        >
                            mdi-eye
                        </v-icon>
                        </template>
                        <span>Ver facturas</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                    class="mr-1 mdi mdi-instant-deposit"
                                    color="blue"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="
                                        setDialogSelectTypePayment();
                                        getDeb(item);
                                    "
                            >
                                mdi-cash-multiple
                            </v-icon>
                        </template>
                        <span>Pagar</span>
                    </v-tooltip>
                </template>
                
                <template v-slot:[`item.total`]="{item}">
                    {{ convertMoney( item.total, item.codigo ) }}
                </template>
        </v-data-table>
        <AvisoAperturaDialog/>
    </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { convertMoney } from "../../formatos";
import DialogListSale from "./DialogListSale.vue";
import DialogPaymentSale from "./DialogPaymentSale.Component.vue";
import AvisoAperturaDialog from "../AvisoAperturaDialog.vue";

export default {
    data: () => ({
        search: "",
        dialogPago: false,
    }),
    components: {
        DialogListSale,
        DialogPaymentSale,
        AvisoAperturaDialog,
    },
    mounted() {
        this.getPermissions(this.$route.path);
        this.getDebtors();
    },
    computed: {
        ...mapState("app", ["canCreate", "canEdit", "canDelete"]),
        ...mapState("debtor", ["debtors", "headers", "debtorData"]),
        ...mapState("payment",["dialogSelectTypePayment", "isPayAmount"])
    },
    methods: {
        ...mapMutations("debtor", ["setDialog", "setDeleteDialog"]),
        //...mapMutations("sale",[]),
        ...mapMutations("payment",["setDialogSelectTypePayment", "setDialogListSale", "setDialogPayment", "setIsPayAmount"]),
		...mapMutations("app", ["setVmSuffix"]),
        ...mapActions("debtor", ["getDebtors", "getOneDebtor"]),
        ...mapActions("app", ["getPermissions"]),

        getDeb(payload) { /** este metodo se llama al presionar ver o pagar facturas y solicita los detalles del cliente y sus facturas a pagar */
            this.getOneDebtor({
                id: payload.client_id,
                currency_id: payload.currency_id
            });
            
            this.setVmSuffix(this.debtorData.simbolo);
        },
        goDetailsDebt(payload) {
            this.$router.push(`/debt/${payload.client_id}/currency/${payload.currency_id}`);
        },
        convertMoney
    },
};
</script>
