import axios from "axios";

const state = {
    drivers: [],
    dialogFormDriver: false,
    dialogDeleteDriver: false,
    isEditDriver: false,
    driver: {
        nombre: "",
        ci: "",
    },
    headers: [
        {
            text: "Nombre",
            align: "start",
            value: "nombre",
        },
        { text: "Cedula", value: "ci" },
        { text: "Opciones", value: "actions", sortable: false, width: "150px" },
    ],
};
const actions = {
    getDrivers({ commit }) {
        axios
            .get("drivers")
            .then((res) => {
                commit("GET_DRIVERS", res.data);
            })
            .catch((err) => {
                throw err;
            });
    },
    createDriver({ commit, dispatch }, payload) {
        axios
            .post("drivers", payload)
            .then((res) => {
                commit("CLEAR_DRIVER");
                commit("CREATE_DRIVER");
                dispatch("getDrivers");
            })
            .catch((err) => {
                throw err;
            });
    },
    editDriver({ commit, dispatch }, payload) {
        axios
            .put(`drivers/${payload.id}`, payload)
            .then((res) => {
                commit("CLEAR_DRIVER");
                commit("EDIT_DRIVER");
                dispatch("getDrivers");
            })
            .catch((err) => {
                throw err;
            });
    },
    deleteDriver({ commit, dispatch }, payload) {
        axios
            .delete(`drivers/${payload.id}`)
            .then((res) => {
                dispatch("getDrivers");
            })
            .catch((err) => {
                throw err;
            });
    },
};
const mutations = {
    setDialogFormDriver(state, payload) {
        state.dialogFormDriver = payload;
    },
    setDialogDeleteDriver(state, payload) {
        state.dialogDeleteDriver = payload;
    },
    setDriver(state, payload) {
        state.driver = payload;
    },
    handlerEditDriver(state, driver) {
        state.isEditDriver = true;
        state.dialogFormDriver = true;
        state.driver = Object.assign({}, driver);
    },
    handlerClose(state) {
        state.driver = {
            nombre: "",
            ruc: "",
            direccion: "",
        };
        state.dialogFormDriver = false;
        state.isEditDriver = false;
    },
    GET_DRIVERS(state, payload) {
        state.drivers = payload;
    },
    CREATE_DRIVER(state) {
        state.isEditDriver = false;
        state.dialogFormDriver = false;
    },
    EDIT_DRIVER(state) {
        state.isEditDriver = false;
        state.dialogFormDriver = false;
    },
    CLEAR_DRIVER(state) {
        state.driver = {
            nombre: "",
            ruc: "",
            direccion: "",
        };
    },
};
const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
