const { default: axios } = require("axios");

const state = {
    dialogShowMoney: false,
    dialogFormMoney: false,
    dialogDeleteMoney: false,
    moneys: [],
    money: {},
    loading: false,
    moneysPage: {
        current: 0,
        total: 0,
        perPage: 0,
        lastPage: 0,
        firstPage: 0,
    },
    isEditMoney: false,
    headers: [
        { text: "Moneda", value: "nombre" },
        { text: "Simbolo", value: "simbolo" },
        { text: "Codigo", value: "codigo" },
        { text: "Decimales", value: "decimales" },
        { text: "Opciones", value: "actions", align: "center" },
    ],
};
const actions = {
    getMoneys({ state, commit }) {
        state.loading = true;
        const { current, perPage } = state.moneysPage;
        axios
            .get(`/currencies?page=${current}&limit=${perPage}`)
            .then((res) => {
                commit("GET_MONEYS", res.data);
            })
            .catch((err) => {
                this.commit("app/setSnackbarAlert", err.message);
            })
            .finally(() => {
                state.loading = false;
            });
    },
    createMoney({ commit, dispatch }, payload) {
        axios
            .post("/currencies", payload)
            .then(() => {
                commit("CREATE_MONEY");
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                dispatch("getMoneys");
            })
            .catch((err) => {
                this.commit("app/setSnackbarAlert", err.message);
            });
    },
    editMoney({ commit, dispatch }, payload) {
        axios
            .put(`/currencies/${payload.id}`, payload)
            .then((res) => {
                commit("EDIT_MONEY", res.data);
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                dispatch("getMoneys");
            })
            .catch((err) => {
                this.commit("app/setSnackbarAlert", err.message);
            });
    },
    deleteMoney({ commit, dispatch }, payload) {
        axios
            .delete(`/currencies/${payload.id}`)
            .then(() => {
                commit("DELETE_MONEY");
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                dispatch("getMoneys");
            })
            .catch((err) => {
                this.commit("app/setSnackbarAlert", err.message);
            });
    },
};
const mutations = {
    GET_MONEYS(state, payload) {
        state.moneys = payload.data;
        state.moneysPage = {
            current: payload.current_page,
            perPage: payload.per_page,
            total: payload.total,
            lastPage: payload.last_page,
        };
    },
    CREATE_MONEY(state) {
        state.money = {};
        state.dialogFormMoney = false;
    },
    EDIT_MONEY(state, payload) {
        state.money = {};
        state.dialogFormMoney = false;
        state.isEditMoney = false;
    },
    DELETE_MONEY(state, payload) {
        state.money = {};
    },
    setDialogShowMoney(state, payload) {
        state.dialogShowMoney = payload;
    },
    setDialogFormMoney(state, payload) {
        state.dialogFormMoney = payload;
    },
    setDialogDeleteMoney(state, paylaod) {
        state.dialogDeleteMoney = paylaod;
    },
    setIsEditMoney(state, payload) {
        state.isEditMoney = payload;
    },
    setMoney(state, payload) {
        state.money = payload;
    },
    resetMoney(state) {
        state.money = {};
    },
};
const getters = {};

module.exports = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
