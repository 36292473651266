<template>
    <v-main app id="container">
        <!-- Provides the application the proper gutter -->
        <v-container id="contenedor">
            <!-- If using vue-router -->

            <router-view></router-view>
            <SnackBarDialogComponent />
        </v-container>
    </v-main>
</template>
<script>
import SnackBarDialogComponent from "../components/SnackBarDialog.Component.vue";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
    components: {
        SnackBarDialogComponent,
    },
    mounted() {},
    computed: {
        ...mapState("app", ["dialogFacturacion"]),
    },
    methods: {},
};
</script>
<style scoped>
#container {
    background-color: lightgray;
}

#contenedor {
    max-width: 1300px;
}
</style>
