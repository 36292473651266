<template>
    <v-sheet>
        <v-form>
            <v-container>
                <v-card-title>Arqueo Caja</v-card-title>

                <!-- Listar todas las monedas registradas -->
                <v-card-text v-for="(bill, i) in bills" :key="i">
                    <v-sheet elevation="1" class="pa-2">
                        <v-card-text class="text-subtitle-2">{{
                            bill?.nombre
                        }}</v-card-text>

                        <!-- Listar los billetes que tine la moneda  -->
                        <!-- inputBills envia los valores cantidad a recibir -->
                        <ListBillArqueoComponent
                            :bills="bill.bill"
                            :inputBills="valueCurrecies[i].bill"
                        />
                    </v-sheet>
                </v-card-text>

                <v-card-actions class="justify-end my-2">
                    <v-btn
                        outlined
                        color="error"
                        @click="setDialogArqueo(false)"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        outlined
                        color="primary"
                        @click="handleSubmitSaveBills()"
                    >
                        guardar
                    </v-btn>
                </v-card-actions>
            </v-container>
        </v-form>
    </v-sheet>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ListBillArqueoComponent from "./ListBillArqueo.Component.vue";
export default {
    props: ["setDialogArqueo", "boxId"],
    data: () => ({
        valueCurrecies: [],
    }),
    components: {
        ListBillArqueoComponent,
    },
    watch: {},
    beforeMount() {
        // this.bills?.forEach((el) => {
        //     this.valueCurrecies.push({
        //         id: el.id,
        //         bill: [],
        //     });
        // });
        // this.bills?.forEach((element, index) => {
        //     const copyEle = element;
        //     copyEle.bill.forEach((el1, i) => {
        //         const { id, valor } = el1;
        //         let obj = {
        //             id,
        //             cantidad: 0,
        //             valor,
        //         };
        //         this.valueCurrecies[index].bill[i] = obj;
        //     });
        // });
        this.startBills();
    },

    computed: {
        ...mapState("cash_count", ["bills"]),
    },
    methods: {
        ...mapActions("cash_count", ["getBillsCashCount", "createCashCount"]),

        handleSubmitSaveBills() {
            //Crea estructura para enviar arqueo
            const arqueo = {
                box_id: this.boxId,
                currencies: this.valueCurrecies,
            };

            //enviar los datos para crear arqueo
            this.createCashCount(arqueo);

            //reset de valores

            this.setDialogArqueo(false);
            this.startBills();
        },
        startBills() {
            this.bills?.forEach((el) => {
                this.valueCurrecies.push({
                    id: el.id,
                    bill: [],
                });
            });

            this.bills?.forEach((element, index) => {
                const copyEle = element;
                copyEle.bill.forEach((el1, i) => {
                    const { id, valor } = el1;
                    let obj = {
                        id,
                        cantidad: 0,
                        valor,
                    };
                    this.valueCurrecies[index].bill[i] = obj;
                });
            });
        },
    },
};
</script>

<style scoped></style>
