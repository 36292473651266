import axios from "axios";

const state = {
    loadingGetCreditNote: false,
    loadingShowCreditNote: false,
    creditNote: {},
    creditNotes: [],
    headerCreditNote: [
        { text: "Factura", value: "numero", sortable: false },
        { text: "Cliente", value: "invoice_header.client", sortable: false },
        {
            text: "Fecha",
            value: "updated_at",
            sortable: false,
            align: "center",
        },
        {
            text: "Encargado",
            value: "user.username",
            sortable: false,
            align: "center",
        },
        {
            text: "Opciones",
            value: "actions",
            sortable: false,
            align: "center",
            width: "150px",
        },
    ],
};
const actions = {
    getCreditNotes({ commit, state }) {
        state.loadingGetCreditNote = true;
        axios
            .get("get_credit_notes")
            .then((response) => {
                commit("GET_CREDIT_NOTES", response.data);
            })
            .catch((err) => {
                console.log(err);
                this.commit("app/setSnackbarAlert", err.response.data.message);
            });
    },
    getOneCreditNotes({ commit, state }) {
        state.loadingShowCreditNote = true;
        axios
            .get()
            .then(() => {
                commit("GET_ONE_CREDIT_NOTES", response.data);
            })
            .catch((err) => {
                console.log(err);
                this.commit("app/setSnackbarAlert", err.response.data.message);
            });
    },
};
const mutations = {
    GET_CREDIT_NOTES(state, payload) {
        state.creditNotes = payload;
        state.loadingGetCreditNote = false;
    },
    GET_ONE_CREDIT_NOTES(state, payload) {
        state.creditNote = payload;
        state.loadingShowCreditNote = false;
    },
};
const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
