import axios from "axios";
//Variables Brand
const state = {
    dialog: false,
    edit: false,
    deleteDialog: false,
    isDeleted: false,
    isEditProduct: false,
    dialogCreditorDetail: false,
    purchases: [],
    creditors: [],
    purchaseProducts: [],
    loadingSearch: false,
    loadingGetPurchase: false,
    loadingCreatePurchase: false,
    loadingEditPurchase: false,
    purchase: {
        id: "",
        factura_numero: "",
        credito: false,
        plazo: 0,
        total: "",
        nombre: "",
        ruc: "",
        moneda: {
            codigo: "PYG",
        },
        purchase_detail: [],
        supplier: {
            nombre: "",
        },
        address: {
            direccion: "",
        },
        mail: {
            correo: "",
        },
        phone: {
            telefono: "",
        },
        quotation_id: 1,
    },
    creditorDetail: {
        phone: { telefono: "" },
        address: { direccion: "" },
        mail: { correo: "" },
    },
    showPurchase: {
        id: "",
        factura_numero: "",
        credito: "",
        plazo: "",
        supplier_id: "",
        purchase_detail_sum_precio_unitario: "",
        supplier: {
            id: "",
            nombre: "",
            ruc: "",
        },
        purchase_detail: [
            {
                id: "",
                cantidad: 0,
                precio_unitario: 0,
                precio_mayorista: 0,
                precio_descuento: 0,
                purchase_header_id: "",
                product_id: "",
                product: {
                    id: "",
                    nombre: "",
                    codigo: "",
                    img_url: "",
                },
            },
        ],
    },

    headersDialog: [
        { text: "Codigo", value: "product.codigo" },
        { text: "Producto", value: "product.nombre" },
        { text: "Cantidad", value: "cantidad" },
        { text: "Precio Costo", value: "precio_unitario" },
        { text: "Precio Venta", value: "precio_venta" },
        { text: "Precio Descuento", value: "precio_descuento" },
        { text: "Precio Mayorista", value: "precio_mayorista" },
        { text: "Subtotal", value: "subtotal" },
        { text: "Deposito", value: "dep" },
    ],
    item_detail: [
        {
            //         codigo_detail: showPurchase.purchase_detail.product.codigo,
        },
    ],
    headersPurchase: [
        { text: "Número", value: "numero" },
        { text: "Factura número", value: "factura_numero" },
        { text: "Proveedor", value: "supplier.nombre" },
        { text: "Credito", value: "credito" },
        { text: "Plazo", value: "plazo" },
        {
            text: "Total",
            value: "purchase_detail.total",
            width: "20%",
        },
        { text: "Fecha Creacion", value: "created_at" },
        {
            text: "Opciones",
            value: "actions",
            sortable: false,
            width: "150px",
            align: "center",
        },
    ],
    creditorHeaders: [
        { text: "RUC", value: "ruc" },
        { text: "Proveedor", value: "nombre" },
        { text: "Total", value: "total" },
        { text: "Opciones", value: "actions", align: "center" },
    ],
    headersProduct: [
        { text: "Codigo", value: "product.codigo" },
        { text: "Producto", value: "product.nombre" },
        { text: "Cantidad", value: "cantidad" },
        { text: "Precio Compra", value: "precio_unitario" },
        { text: "Precio Venta", value: "precio_venta" },
        { text: "Precio Descuento", value: "precio_descuento" },
        { text: "Precio Mayorista", value: "precio_mayorista" },
        { text: "SubTotal", value: "sub_total" },
        { text: "Deposito", value: "store_id" },
        { text: "Opciones", value: "actions" },
    ],
    headersProductAdd: [
        { text: "Codigo", value: "product.codigo" },
        { text: "Producto", value: "product.nombre" },
        { text: "Cantidad", value: "cantidad" },
        { text: "Precio Compra", value: "precio_unitario" },
        { text: "Precio Venta", value: "precio_venta" },
        { text: "Precio Descuento", value: "precio_descuento" },
        { text: "Precio Mayorista", value: "precio_mayorista" },
        { text: "SubTotal", value: "sub_total" },
        { text: "Deposito", value: "dep" },
        { text: "Opciones", value: "actions" },
    ],
    headersProductCreditorDetail: [
        { text: "Codigo", value: "product.codigo" },
        { text: "Producto", value: "product.nombre" },
        { text: "Cantidad", value: "cantidad" },
        { text: "Precio Compra", value: "precio_unitario" },
        { text: "Precio Venta", value: "precio_venta" },
        { text: "Precio Descuento", value: "precio_descuento" },
        { text: "Precio Mayorista", value: "precio_mayorista" },
        { text: "SubTotal", value: "subtotal" },
        { text: "Deposito", value: "store_id" },
    ],
    rutaCompra: "",
};

//Acciones Brand
const actions = {
    getPurchases({ commit, state }) {
        state.loadingGetPurchase = true;
        axios.get(state.rutaCompra).then((response) => {
            commit("GET_PURCHASES", response.data);
        });
    },
    createPurchase({ commit, dispatch }, payload) {
        (state.loadingCreatePurchase = true),
            this.commit("app/setIsLoading", true);
        axios
            .post("/purchases", payload)
            .then((response) => {
                commit("CREATE_PURCHASE", response.data);
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
            })
            .then(() => {
                commit("clearPurchase");
            })
            .catch((error) => {
                state.loadingCreatePurchase = false;
                if (error.response) {
                    this.commit(
                        "app/setSnackbarAlert",
                        `${error.response.data.message}`
                    );
                }
                state.loadingCreatePurchase = false;
            });
        this.commit("app/setIsLoading", false);
        dispatch("getPurchases");
    },
    editPurchase({ commit, dispatch }, payload) {
        state.loadingEditPurchase = true;
        axios
            .put(`/purchases/${payload.id}/`, payload)
            .then((response) => {
                commit("EDIT_PURCHASE", response.data);
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
            })
            .then(() => {
                commit("clearPurchase");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    `${error.response.data.message}`
                );
            });

        dispatch("getPurchases");
    },
    deletePurchase({ commit }, payload) {
        axios
            .delete("/purchases/" + payload.id)
            .then((response) => {
                commit("DELETE_PURCHASE", response.data);
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    `${error.response.data.message}`
                );
            });
    },
    async getOnePurchase({ state, commit }, payload) {
        let url = `/purchases/${payload.id}`;
        if (state.edit) {
            url = `/purchases/${payload.id}/edit`;
        }
        await axios
            .get(url)
            .then((response) => {
                commit("GET_ONE_PURCHASE", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    `${error.response.data.message}`
                );
            });
    },
    getShowPurchase({ commit }, id) {
        axios
            .get(`/purchases/${id}/`)
            .then((response) => {
                commit("setShowPurchase", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    `${error.response.data.message}`
                );
            });
    },
    getCreditors({ commit }) {
        axios
            .get("/credit_purchases")
            .then((response) => {
                commit("SET_CREDITORS", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    getCreditorDetail({ commit }, payload) {
        axios
            .get(
                "/credit_purchases/" +
                    payload.id +
                    "/currency/" +
                    payload.currency_id
            )
            .then((response) => {
                commit("SET_CREDITOR_DETAIL", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    pagarFacturaCredito({ commit, dispatch, state }, id) {
        axios
            .post("/pay_creditor", { id: id })
            .then(() => {
                const snackbar = {
                    color: "Success",
                    msg: "Se ha pagado esta factura",
                };
                dispatch("getCreditorDetail", state.creditorDetail.id);
                commit("SHOW_SNACKBAR", snackbar);
            })
            .catch((error) => {
                const snackbar = {
                    color: "Alert",
                    msg: error.response.data.message,
                };
                commit("SHOW_SNACKBAR", snackbar);
            });
    },
};

//Metodos Brand
const mutations = {
    setDialog(state) {
        state.dialog = !state.dialog;
    },
    setEdit(state) {
        state.edit = !state.edit;
    },
    setIsEditProduct(state, payload) {
        state.isEditProduct = payload;
    },
    setDeleteDialog(state) {
        state.deleteDialog = !state.deleteDialog;
    },
    setDialogCreditorDetail(state, payload) {
        state.dialogCreditorDetail = payload;
    },
    setPurchase(state, purchase) {
        state.purchase = purchase;
    },
    setShowPurchase(state, showPurchase) {
        state.showPurchase = showPurchase;
    },
    clearPurchase(state) {
        state.purchase = {
            id: "",
            factura_numero: "",
            credito: "",
            plazo: "",
            total: "",
            nombre: "",
            ruc: "",
            purchase_detail: [],
            moneda: {
                codigo: "PYG",
            },
            supplier: {
                nombre: "",
            },
            address: {
                direccion: "",
            },
            mail: {
                correo: "",
            },
            phone: {
                telefono: "",
            },
            quotation_id: 1,
        };
        state.purchaseProducts = [];
    },
    getEdit(state, item) {
        state.purchase.factura_numero = item.factura_numero;
        state.purchase.supplier_id = item.supplier_id;
        state.purchase.credito = item.credito;
        state.purchase.plazo = item.plazo;

        //traer la llamada de detalles de producto
    },
    addPurchaseProduct(state, payload) {
        let exist = state.purchaseProducts.find((item) => {
            return item.product_id === payload.product_id;
        });

        if (exist) {
            this.commit(
                "app/setSnackbarInfo",
                "Se ha repetido el producto, verifique la tabla"
            );
        } else {
            state.purchaseProducts.push(payload);
        }
    },
    editPurchaseProduct(state, item) {
        let index = state.purchaseProducts.findIndex(
            (obj) => obj.product_id == item.product_id
        );

        state.purchaseProducts[index].cantidad = item.cantidad;
        state.purchaseProducts[index].precio_unitario = item.precio_unitario;
        state.purchaseProducts[index].sub_total = item.sub_total;
    },
    deletePurchaseProduct(state, item) {
        let index = state.purchaseProducts.findIndex(
            (obj) => obj.product_id == item.product_id
        );

        state.purchaseProducts.splice(index, 1);
    },
    GET_PURCHASES(state, purchases) {
        state.loadingGetPurchase = false;
        state.purchases = purchases;
    },
    CREATE_PURCHASE(state) {
        (state.loadingCreatePurchase = false), (state.dialog = false);
        state.purchase = {
            factura_numero: "",
            credito: false,
            plazo: 0,
            total: "",
            nombre: "",
            ruc: "",
            purchase_detail: [],
            supplier: {
                nombre: "",
                ruc: "",
            },
            address: {
                direccion: "",
            },
            mail: {
                correo: "",
            },
            phone: {
                telefono: "",
            },
        };
        state.purchaseProducts = [];
    },
    EDIT_PURCHASE(state) {
        state.loadingEditPurchase = false;
        state.dialog = false;
        state.edit = false;
        state.purchaseProducts = [];
    },
    DELETE_PURCHASE(state) {
        state.delete = false;
        state.isDeleted = false;
    },
    GET_ONE_PURCHASE(state, purchase) {
        state.purchase = {};
        state.purchaseProducts = [];
        state.purchase = purchase;
        state.purchaseProducts = purchase.purchase_detail;
    },
    SET_CREDITORS(state, payload) {
        state.creditors = payload;
    },
    SET_CREDITOR_DETAIL(state, payload) {
        state.creditorDetail = payload;
    },
    SHOW_SNACKBAR(state, payload) {
        this.commit("app/setSnackbar" + payload.color, payload.msg);
    },
    setRutaCompra(state, payload) {
        state.rutaCompra = payload;
    },
};

const getters = {
    getPurchaseById: (state) => (id) => {
        return state.purchases.find((obj) => obj.id === id);
    },
    getPurchaseProductCount: (state) => {
        let count = 0;
        if (!state.purchaseProducts) {
            return count;
        }
        state.purchaseProducts.forEach(() => {
            count += 1;
        });

        return count;
    },
    getTotalPrice: (state, getters) => {
        let total = 0;

        if (getters.getPurchaseProductCount > 0) {
            state.purchaseProducts.forEach((item) => {
                let sub = item.cantidad * item.precio_unitario;
                total += sub;
            });
        }

        return total;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
