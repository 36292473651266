<template v-slot:top>
    <div>
        <v-card class="my-10">
            <v-list-item class="">
                <v-card
                    class="mt-n7"
                    color="primary"
                    elevation="10"
                    min-height="80"
                    min-width="80"
                >
                    <v-icon class="ma-5" large dark>mdi-account</v-icon>
                </v-card>

                <v-list-item-title>
                    <div class="overline mx-10 mt-5">
                        <h4>Lista de acreedores</h4>
                    </div>
                </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-card-text>
                        <v-card-actions> </v-card-actions>
                    </v-card-text>
                </v-list-item-content>
            </v-list-item>

            <!-- Lista de Acreedores -->
            <ListCreditor />
        </v-card>
    </div>
</template>
<script>

import ListCreditor from "./ListCreditor.Component.vue";

export default {
    components: {
        ListCreditor,
    },
   
};
</script>
