<template>
    <div class="mx-10">
        <v-data-table
            :headers="headers"
            :items="categories"
            sort-by="nombre"
            :loading="loading"
            hide-default-footer
        >
            <template v-slot:top>
                <!-- Encabezado Lista Tabla -->
                <!-- Buscar y Button Agregar -->

                <v-row align="center" justify="space-between">
                    <v-col cols="12" lg="6" md="6" sm="6">
                        <v-text-field
                            v-model="search"
                            label="Buscar"
                            append-icon="mdi-magnify"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="auto"
                        md="auto"
                        sm="auto"
                        v-if="canCreate"
                    >
                        <v-btn color="primary" @click="setDialog">
                            <v-icon> mdi-plus </v-icon>
                            Agregar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>

            <!-- columna imagen -->
            <template v-slot:[`item.img_url`]="{ item }">
                <span>
                    <v-img
                        class="ma-1 rounded"
                        max-height="80"
                        max-width="80"
                        :src="item.img_url"
                        contain
                    ></v-img
                ></span>
            </template>

            <!-- Iconos Editar Y Eliminar  -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    v-if="canEdit"
                    small
                    class="mr-2"
                    color="blue"
                    @click="
                        setDialog();
                        setEdit();
                        getEdit(item);
                    "
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    v-if="canDelete"
                    small
                    color="red"
                    @click="
                        localCategory = item;
                        setDeleteDialog();
                    "
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
        <v-pagination
            v-model="categoryPage.current"
            class="py-4"
            :length="categoryPage.lastPage"
        ></v-pagination>
        <DeleteDialog
            :dialog="deleteDialog"
            :setDialog="setDeleteDialog"
            v-on:deleted="setDeleteCategory(localCategory)"
        />
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import DeleteDialog from "../DeleteDialog.Component.vue";
export default {
    data: () => ({
        search: "",
        localCategory: {},
    }),
    components: {
        DeleteDialog,
    },
    mounted() {
        this.categoryPage.current = 1;
        this.getCategories();
        this.getPermissions(this.$route.path);
    },
    watch: {
        "categoryPage.current"(newVal, oldVal) {
            if (this.search) {
                this.categoryPage.current = newVal;
                this.searchCategories(this.search);
                return;
            }
            if (newVal !== oldVal) {
                this.categoryPage.current = newVal;
                this.getCategories();
            }
        },
        search(val) {
            this.categoryPage.current = 1;
            this.searchCategories(val);
        },
    },
    computed: {
        ...mapState("category", [
            "categories",
            "dialog",
            "headers",
            "edit",
            "category",
            "deleteDialog",
            "categoryPage",
            "loading",
        ]),
        ...mapState("app", ["canCreate", "canEdit", "canDelete"]),
    },
    methods: {
        ...mapActions("category", [
            "getCategories",
            "deleteCategory",
            "searchCategories",
        ]),
        ...mapActions("app", ["getPermissions"]),
        ...mapMutations("category", [
            "setDialog",
            "setEdit",
            "getEdit",
            "setCategory",
            "setDeleteDialog",
            "clearCategory",
            "setImage",
        ]),

        //Eliminar Categoria
        setDeleteCategory(category) {
            this.deleteCategory(category);
            this.getCategories();
        },
    },
};
</script>
