import axios from "axios";

const state = {
    loadingGetInvoice: false,
    loadingShowInvoice: false,
    loadingCancelInvoice: false,
    invoice: {},
    invoices: [],
    invoicesPage: {
        current: 1,
        total: 0,
        perPage: 0,
        lastPage: 0,
        firstPage: 0,
    },
    dialogShowInvoice: false,
    dialogCancelInvoice: false,
    invoiceHeader: [
        { text: "Factura", value: "numero", align: "start", width: "10%" },
        {
            text: "Venta",
            value: "sale_header.numero",
            align: "start",
            width: "10%",
        },
        {
            text: "Cliente",
            align: "start",
            value: "client",
        },
        { text: "Fecha emitida", value: "fecha" },
        { text: "Total", value: "total" },
        { text: "Estado", value: "anulado" },
        { text: "Opciones", value: "actions", sortable: false, width: "150px" },
    ],
    showInvoiceHeader: [
        { text: "Codigo", value: "sale_detail.product.codigo" },
        { text: "Producto", value: "nombre" },
        { text: "Cantidad", value: "cantidad" },
        { text: "Precio Unit.", value: "precio_unitario" },
        { text: "Subtotal", value: "subtotal" },
        { text: "Dep.", value: "sale_detail.store.nombre" },
    ],
};
const actions = {
    getInvoices({ state, commit }) {
        state.loadingGetInvoice = true;
        axios
            .get(`/invoices?page=${state.invoicesPage.current}`)
            .then((response) => {
                commit("GET_INVOICES", response.data);
            })
            .catch((err) => {
                console.log(err);
                this.commit("app/setSnackbarAlert", err.response.data.message);
            });
    },
    getInvoiceDetail({ state, commit }, id) {
        state.loadingShowInvoice = true;
        axios
            .get(`/invoices/${id}`)
            .then((response) => {
                commit("GET_INVOICE_DETAIL", response.data);
            })
            .catch((err) => {
                console.log(err);
                this.commit("app/setSnackbarAlert", err.response.data.message);
            });
    },
    cancelInvoice({ state, commit, dispatch }, payload) {
        state.loadingCancelInvoice = true;
        axios
            .post("/invoices/anular", payload)
            .then((res) => {
                console.log(res);
                commit("CANCEL_INVOICE");
            })
            .then(async () => {
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                await dispatch("getInvoices");
            })
            .catch((err) => {
                console.log(err);
                this.commit("app/setSnackbarAlert", err.response.data.message);
            });
    },
};
const mutations = {
    GET_INVOICES(state, payload) {
        state.loadingGetInvoice = false;
        state.invoices = payload.data;
        state.invoicesPage = {
            current: payload.current_page,
            total: payload.total,
            perPage: payload.per_page,
            lastPage: payload.last_page,
            firstPage: payload.first_page,
        };
    },
    GET_INVOICE_DETAIL(state, payload) {
        state.loadingShowInvoice = false;
        state.invoice = payload;
    },
    CANCEL_INVOICE(state) {
        state.loadingCancelInvoice = false;
    },
    setDialogShowInvoice(state, payload) {
        state.dialogShowInvoice = payload;
    },
    setDialogCancelInvoice(state, payload) {
        state.dialogCancelInvoice = payload;
    },
    setInvoicesPage(state, payload) {
        state.invoicesPage = payload;
    },
};
const getters = {
    getInvoicesPage: (state) => {
        return state.invoicesPage;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
