<template v-slot:activator="{ on, attrs }">
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-row justify="center">
            <!-- Dialog (Modal Form Client) -->
            <v-dialog v-model="dialog" persistent max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-select
                            v-model="localStore"
                            :items="stores"
                            placeholder="Seleccionar Deposito"
                            item-text="nombre"
                            item-value="id"
                        />

                        <v-autocomplete
                            :filter="filterObject"
                            :items="products"
                            :search-input.sync="searchProduct"
                            :rules="isEmptyProduct"
                            label="Elija un producto"
                            :loading="loadingChangeStore"
                            clearable
                            @change="(e) => agregarDatoProducto(e)"
                        >
                            <!-- En caso que no encuentra -->
                            <template v-slot:no-data>
                                <v-sheet class="d-flex justify-left ma-2">
                                    <h5>Producto inexistente</h5>
                                </v-sheet>
                            </template>

                            <!--   Lista categoria de productos -->

                            <template slot="item" slot-scope="data">
                                <v-row>
                                    <v-col cols="12" sm="4">
                                        <h6>
                                            {{ data.item.producto }}
                                        </h6>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <h6>
                                            {{ data.item.codigo }}
                                        </h6>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <h6>
                                            stock:
                                            {{ data.item.stock }}
                                        </h6>
                                    </v-col>
                                </v-row>
                            </template>

                            <template v-slot:selection="data">
                                <v-chip label color="primary">
                                    {{ data.item.producto }}
                                    -
                                    {{ data.item.codigo }}
                                    - stock: {{ data.item.stock }}
                                </v-chip>
                            </template>
                        </v-autocomplete>

                        <v-text-field
                            v-model.number="dropProduct.cantidad"
                            label="Cantidad"
                            required
                            type="number"
                            :rules="ruleCantidad"
                        ></v-text-field>

                        <v-textarea
                            v-model="dropProduct.observaciones"
                            :rules="isEmptyObservacion"
                            label="Observaciones"
                            placeholder="Descripcion para la baja"
                            required
                            rows="4"
                        >
                        </v-textarea>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn color="blue darken-1" text @click="clearParams">
                            Borrar
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="edit"
                            color="blue darken-1"
                            text
                            @click="
                                setDialog();
                                setEdit();
                            "
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            v-else
                            color="blue darken-1"
                            text
                            @click="setDialog()"
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            v-if="edit"
                            :disabled="!valid"
                            color="blue darken-1"
                            text
                            @click="validateSubmit(edit, dropProduct)"
                        >
                            Editar
                        </v-btn>
                        <v-btn
                            v-else
                            :disabled="!valid || isLoading"
                            :loading="isLoading"
                            color="blue darken-1"
                            text
                            @click="validateSubmit(edit, dropProduct)"
                        >
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-form>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
export default {
    data: () => ({
        valid: false,
        searchProduct: "",
        isEmptyProduct: [(v) => !!v || "Debes seleccionar un producto"],
        ruleCantidad: [
            (v) => !!v || "Debes ingresar cantidad de baja",
            (v) => (!isNaN(parseFloat(v)) && v > 0) || "Debe ser mayor a 0",
        ],
        isEmptyObservacion: [(v) => !!v || "El campo es obligatorio"],
        localStore: null,
    }),
    mounted() {
        this.getPermissions(this.$route.path);
        this.localStore = this.currencyStore;
        this.getProducts(this.localStore);
    },
    watch: {
        localStore(val) {
            this.getProducts(val);
        },
    },
    computed: {
        ...mapState("app", ["canCreate", "canEdit", "canDelete", "isLoading"]),
        ...mapState("drop_product", [
            "dropProduct",
            "dialog",
            "edit",
            "cantProducts",
            "products",
            "loadingChangeStore",
        ]),
        //...mapState("product", ["products"]),
        ...mapState("deposito", ["currencyStore", "stores"]),

        ...mapGetters("deposito", ["getLocaleStorageStore"]),

        //...mapGetters("drop_product", ["getCountProductById"]),
        formTitle() {
            return this.edit === false ? "Crear Baja" : "Editar Baja";
        },
    },
    methods: {
        ...mapMutations("drop_product", [
            "setDialog",
            "setDeleteDialog",
            "setEdit",
        ]),
        ...mapMutations("app", ["setSnackbarInfo"]),
        ...mapActions("app", ["getPermissions", ""]),
        //...mapActions("product", ["getProducts"]),
        ...mapActions("drop_product", [
            "getDropProduct",
            "createDropProduct",
            "editDropProduct",
            "getCantProducts",
            "getProducts",
        ]),

        validateSubmit(edit, dropProduct) {
            dropProduct.store_id = this.localStore;

            if (dropProduct.store_id == null) {
                this.setSnackbarInfo("Debe seleccionar el deposito");
            } else {
                if (this.$refs.form.validate()) {
                    if (edit) {
                        this.editDropProduct(dropProduct);
                    } else {
                        this.createDropProduct(dropProduct);
                    }
                    this.$refs.form.resetValidation();
                }
            }
        },

        filterObject(item, queryText, itemText) {
            return (
                item.producto
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.codigo
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },

        agregarDatoProducto(item) {
            if (item != null) {
                this.dropProduct.product_id = item.product_id;
            }
        },
        clearParams() {
            this.$refs.form.reset();
            // this.dropProduct.product_id = 0;
            //this.dropProduct.cantidad = 0;
            //this.dropProduct.observaciones = "";
        },
    },
};
</script>
