<template v-slot:top>
    <div>
        <v-card class="my-10">
            <v-list-item class="">
                <v-card
                    class="mt-n7"
                    color="primary"
                    elevation="10"
                    min-height="80"
                    min-width="80"
                >
                    <v-icon class="ma-5" large dark>local_offer</v-icon>
                </v-card>

                <v-list-item-title>
                    <div class="overline mx-10 mt-5">
                        <h4>Lista Marcas</h4>
                    </div>
                </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-card-text>
                        <v-card-actions> </v-card-actions>
                    </v-card-text>
                </v-list-item-content>
            </v-list-item>

            <!-- Lista de Marcas -->
            <ListaBrand></ListaBrand>
        </v-card>

        <!-- Para Dialogo Editar y Nuevo  -->
        <template>
            <FormBrand></FormBrand>
        </template>
    </div>
</template>
<script>
import ListaBrand from "./ListBrand.Component.vue";
import FormBrand from "./FormBrand.Component.vue";
import SnackBarDialog from "../SnackBarDialog.Component";

import { mapState, mapActions } from "vuex";

export default {
    data: () => ({}),

    components: {
        ListaBrand,
        FormBrand,
        SnackBarDialog,
    },
    computed: {
        ...mapState("brand", ["brands", "brand"]),
    },
    methods: {
        ...mapActions("brand", ["getBrands"]),
    },
};
</script>
