<template>
    <div class="mx-10">
        <v-data-table :items="vehicles" :headers="headers" :search="search">
            <template v-slot:top>
                <!-- Encabezado Lista Tabla -->
                <!-- Buscar y Button Agregar -->

                <v-row align="center" justify="space-between">
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="search"
                            label="Buscar"
                            append-icon="mdi-magnify"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            color="primary"
                            @click="setDialogFormVehicle(true)"
                        >
                            <v-icon> mdi-plus </v-icon>
                            Agregar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!-- Iconos Editar Y Eliminar  -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon @click="handlerEditVehicle(item)">
                    <v-icon small color="blue"> mdi-pencil </v-icon>
                </v-btn>
                <v-btn icon @click="eliminarVehiculo(item)">
                    <v-icon small color="red"> mdi-delete </v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <DeleteDialog
            :dialog="dialogDeleteVehicle"
            :setDialog="setDialogDeleteVehicle"
            v-on:deleted="deleteVehicle(vehicle)"
        />
    </div>
</template>

<script>
import DeleteDialog from "../DeleteDialog.Component.vue";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
    data: () => ({
        search: "",
    }),
    components: {
        DeleteDialog,
    },
    computed: {
        ...mapState("vehicle", [
            "vehicles",
            "vehicle",
            "headers",
            "dialogDeleteVehicle",
        ]),
    },
    methods: {
        ...mapMutations("vehicle", [
            "setDialogFormVehicle",
            "setDialogDeleteVehicle",
            "setVehicle",
            "handlerEditVehicle",
        ]),
        ...mapActions("vehicle", ["getVehicles", "deleteVehicle"]),

        eliminarVehiculo(payload) {
            this.setVehicle(payload);
            this.setDialogDeleteVehicle(true);
        },
    },
    mounted() {
        this.getVehicles();
    },
};
</script>

<style></style>
