<template>
    <v-row justify="center">
        <v-dialog v-model="dialogFormTransData" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5"
                        >{{ returnTitleForm }} Transferencia Datos</span
                    >
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="transData.responsable"
                                        label="Responsable"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="transData.recorrido_estimativo"
                                        label="Recorrido Estimado"
                                        type="number"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="transData.direccion_recogida"
                                        label="Direccion Recogida"
                                        :rules="marcaRules"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="transData.direccion_entrega"
                                        label="Direccion Entrega"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <!-- <v-date-picker
                                        v-model="transData.fecha_inicio"
                                        label="Fecha Inicio"
                                        required
                                    ></v-date-picker> -->
                                    <v-menu
                                        v-model="dialogFechaInicio"
                                        :close-on-content-click="true"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                :value="transData.fecha_inicio"
                                                label="Fecha Inicio"
                                                prepend-icon="event"
                                                readonly
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="transData.fecha_inicio"
                                            locale="es-es"
                                            no-title
                                            @input="abrirDatePickerHasta = true"
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="
                                                    dialogFechaInicio = false
                                                "
                                            >
                                                Cancel
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-menu
                                        v-model="dialogFechaFin"
                                        :close-on-content-click="true"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                :value="transData.fecha_fin"
                                                label="Fecha Fin"
                                                prepend-icon="event"
                                                readonly
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="transData.fecha_fin"
                                            locale="es-es"
                                            no-title
                                            @input="abrirDatePickerHasta = true"
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="dialogFechaFin = false"
                                            >
                                                Cancel
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea
                                        rows="3"
                                        v-model="transData.motivo"
                                        label="Motivo"
                                        required
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="handlerClose()">
                        Cerrar
                    </v-btn>
                    <v-btn
                        :color="returnColorBtn"
                        text
                        @click="crearVehiculo()"
                    >
                        {{ returnBtnForm }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
    data: () => ({
        valid: true,
        dialog: false,
        dialogFechaInicio: false,
        dialogFechaFin: false,
        marcaRules: [(v) => !!v || "Marca es obligatorio"],
        ruaRules: [(v) => !!v || "Chapa es obligatorio"],
    }),
    computed: {
        ...mapState("transfer_data", [
            "transData",
            "isEditTransData",
            "dialogFormTransData",
        ]),

        returnTitleForm() {
            return this.isEditTransData ? "Editar" : "Agregar";
        },
        returnBtnForm() {
            return this.isEditTransData ? "Editar" : "Guardar";
        },
        returnColorBtn() {
            return this.isEditTransData ? "green darken-1" : "blue darken-1";
        },
    },
    methods: {
        ...mapMutations("transfer_data", [
            "setDialogFormTransData",
            "handlerClose",
        ]),
        ...mapActions("transfer_data", ["createTransData", "editTransData"]),

        crearVehiculo() {
            if (this.$refs.form.validate()) {
                if (this.isEditTransData) {
                    this.editTransData(this.transData);
                } else {
                    this.createTransData(this.transData);
                }
            }
        },
    },
    mounted() {},
};
</script>

<style></style>
