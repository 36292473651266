<template>
    <!-- <v-dialog v-model="dialogImpresion"> -->
    <v-card v-if="dialogImpresion" class="dialog">
        <div id="seleccion">
            <div class="parte" v-for="n in 3" :key="n">
                <div class="fecha">
                    <span id="day">{{ date[0] }}</span>
                    <span id="month">{{ date[1] }}, </span>
                    <span id="year">{{ date[2] }}</span>
                </div>
                <div class="datos">
                    <span class="nombre">
                        {{ sale.client.nombre_y_apellido }}
                    </span>
                    <span class="ruc">{{ sale.client.ruc }}</span>
                </div>
                <div class="direcciones">
                    <span class="direc" v-html="returnDireccionValue" />
                    <span class="cel" v-html="returnCelularValue" />
                </div>

                <div class="tabla">
                    <div
                        class="pro"
                        v-for="product in sale.products"
                        :key="product.product_id"
                    >
                        <span class="cantidad">{{ product.cantidad }}</span>
                        <span class="codigo">{{ product.producto }}</span>
                        <span class="precio">{{
                            product.precio_unitario
                        }}</span>
                        <span class="subTotalExenta">
                            {{
                                product.iva === "exenta" ? product.subtotal : ""
                            }}
                        </span>
                        <span class="subTotalIva5">
                            {{ product.iva === "5%" ? product.subtotal : "" }}
                        </span>
                        <span class="subTotalIva10">
                            {{ product.iva === "10%" ? product.subtotal : "" }}
                        </span>
                    </div>
                </div>
                <div class="total">{{ totalVenta }}</div>
                <div class="iva">
                    <span class="iva5">{{ returnIva5 }}</span>
                    <span class="iva10">{{ returnIva10 }}</span>
                    <span class="ivaTotal">{{ returnIva10 + returnIva5 }}</span>
                </div>
            </div>
        </div>
    </v-card>
    <!-- </v-dialog> -->
</template>

<script>
import printJS from "print-js";
import { mapState, mapMutations } from "vuex";
export default {
    data: () => ({
        options: {
            year: "numeric",
            month: "long",
            day: "numeric",
        },
        date: new Date()
            .toLocaleDateString("es-PY", {
                year: "numeric",
                month: "long",
                day: "numeric",
            })
            .split("de"),
    }),
    mounted() {
        printJS({
            printable: "seleccion",
            type: "html",
            targetStyles: ["*"],
            font_size: "10px",
            onPrintDialogClose: this.afterPrint,
        });
    },
    computed: {
        ...mapState("sale", [
            "sale",
            "saleProducts",
            "totalVenta",
            "dialogImpresion",
        ]),
        returnDireccionValue() {
            return this.sale.client.direccion === null ||
                this.sale.client.direccion === undefined
                ? "<br />"
                : `<span>${this.sale.client.direccion}</span>`;
        },
        returnCelularValue() {
            return this.sale.client.telefono === null ||
                this.sale.client.telefono === undefined
                ? "<br />"
                : `<span>${this.sale.client.telefono}</span>`;
        },
        returnIva10() {
            let sumaIva = 0;
            this.sale.products.forEach((product) => {
                if (product.iva === "10%") {
                    sumaIva += Math.round(
                        (product.precio_unitario * product.cantidad) / 11
                    );
                }
            });
            return sumaIva === 0 ? "" : sumaIva;
        },
        returnIva5() {
            let sumaIva = 0;
            this.sale.products.forEach((product) => {
                if (product.iva === "5%") {
                    sumaIva += Math.round(
                        (product.precio_unitario * product.cantidad) / 21
                    );
                }
            });
            return sumaIva === 0 ? "" : sumaIva;
        },
    },
    methods: {
        ...mapMutations("sale", ["setDialogImpresion"]),
        afterPrint() {
            this.setDialogImpresion(false);
        },
    },
};
</script>
<style scoped>
#seleccion {
    font-size: 12px;
    width: 216mm;
}
@media print {
    html,
    body {
        width: 216mm;
        height: 330mm;
    }
    @page {
        size: 216mm 330mm;
    }
}
.dialog {
    display: none;
}
.parte {
    padding: 2.6cm 0 1.5cm 0;
    min-height: 6.1cm;
}

#day {
    margin-left: 4.5cm;
}
#month {
    margin-left: 1.5cm;
}
#year {
    margin-left: 1cm;
}
.datos {
    margin-left: 4cm;
    margin-top: -5px;
}
.nombre {
    display: inline-block;
    width: 11cm;
}
.ruc {
    margin-left: 1.5cm;
    max-width: 5cm;
}
.direccion {
    margin-bottom: 10px;
    margin-top: -5px;
}
.direc {
    display: inline-block;
    width: 8.5cm;
    margin-left: 4cm;
}
.cel {
    width: 2.5cm;
}

.tabla {
    height: 3.3cm;
    margin-top: 0.8cm;
}
.pro {
    width: 216mm;
    margin-top: -10px;
}
.cantidad {
    display: inline-block;
    width: 3cm;
    min-width: 3cm;
    margin-left: 1cm;
}
.codigo {
    display: inline-block;
    width: 7.5cm;
    min-width: 7.5cm;
}
.precio {
    display: inline-block;
    width: 2cm;
    min-width: 2cm;
}
.subTotalExenta {
    display: inline-block;
    width: 2cm;
    min-width: 2cm;
    text-align: center;
}

.subTotalIva5 {
    display: inline-block;
    width: 2cm;
    min-width: 2cm;
    text-align: center;
}

.subTotalIva10 {
    display: inline-block;
    width: 2cm;
    min-width: 2cm;
    text-align: center;
}

.total {
    text-align: right;
    margin-right: 4.1cm;
    margin-top: 0.5cm;
}
.iva {
    display: inline-block;
    width: 216mm;
    margin-top: -5px;
}
.iva5 {
    display: inline-block;
    margin-left: 7.2cm;
    min-width: 3cm;
}
.iva10 {
    display: inline-block;
    margin-left: 1.4cm;
    min-width: 2.5cm;
}
.ivaTotal {
    display: inline-block;
    margin-left: 2.3cm;
    min-width: 4cm;
}
</style>
