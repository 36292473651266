<template>
    <v-card class="pa-5">
        <v-icon class="text-info" @click="goBack">mdi-arrow-left</v-icon>
        
        <v-row justify="center">
            <v-col md="3" class="text-center">
                <v-card-subtitle class="h5 ma-0 pa-0">
                    Arqueo de caja
                </v-card-subtitle>
            </v-col>
            
        </v-row>

        <template>
            <v-row justify="center">
                <v-col md="5" class="text-center">
                <template>
                    <v-container fluid>
                        <v-data-table
                            :headers="headers"
                            :items="cashCount"
                            hide-default-footer
                            hide-default-border
                        >
                            <template v-slot:item.subtotal="{ item }">
                                {{ convertMoney( item.subtotal, item.codigo ) }}
                            </template>
                            <template v-slot:footer>
                                <H5 class="text-end p-3 mr-3">
                                    Total: {{ convertMoney(total, cashCount[0]?.codigo || 'PYG') }}
                                </H5>
                            </template>
                        </v-data-table>
                    </v-container>
                </template>
                </v-col>
            </v-row>
        </template>
    </v-card>
</template>


<script>
import { mapActions, mapState } from 'vuex/dist/vuex.common.js';
import { convertMoney } from '../../../formatos';

export default {
    data() {
        return {
            headers:[
                { text: 'Billete', value: 'nombre' },
                { text: 'Cantidad', value: 'cantidad' },
                { text: 'Subtotal', value: 'subtotal' },	
            ],
        };
    },
    computed:{
        ...mapState('cash_count', ['cashCount']),
        total(){
            return this.cashCount.reduce((total, item) => total + item.subtotal, 0);
        }
    },
    mounted(){
        this.getCashCount(this.$route.params);
    },
    methods: {
        ...mapActions('cash_count', ["getCashCount"]),

        goBack(){
            history.go(-1); 
            return false;
        },
        convertMoney
    },
};
</script>
