<template>
    <div>
        <v-card class="my-10">
            <v-row>
                <v-col>
                    <v-card
                        class="mx-auto"
                        max-width="200"
                        outlined
                        elevation="11"
                        @click="informeDeposito()"
                        :loading="loading"
                    >
                        <v-list-item three-line>
                            <v-list-item-title class="text-h7 text-center mb-1">
                                Informe de Stock
                            </v-list-item-title>
                        </v-list-item>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card
                        class="mx-auto"
                        max-width="200"
                        outlined
                        elevation="11"
                        @click="dialogTipoInformeVenta = true"
                        :loading="loadingVentas"
                    >
                        <v-list-item three-line>
                            <v-list-item-title class="text-h7 text-center mb-1">
                                Informe de Ventas
                            </v-list-item-title>
                        </v-list-item>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card
                        class="mx-auto"
                        max-width="200"
                        outlined
                        elevation="11"
                        @click="showDialogPurchaseReport"
                        :loading="loadingInformeCompras"
                    >
                        <v-list-item three-line>
                            <v-list-item-title class="text-h7 text-center mb-1">
                                Informe de Compras
                            </v-list-item-title>
                        </v-list-item>
                    </v-card>
                </v-col>
                <!--<v-col>
                    <v-card
                        class="mx-auto"
                        max-width="200"
                        outlined
                        elevation="11"
                        @click="proximamente()"
                    >
                        <v-list-item three-line>
                            <v-list-item-title class="text-h7 text-center mb-1">
                                Informe de Caja
                            </v-list-item-title>
                        </v-list-item>
                    </v-card>
                </v-col>-->
            </v-row>
        </v-card>
        <template>
            <!--DIALOG PARA ELEGIR EL TIPO DE INFORM DE VENTA -->
            <v-dialog
                v-model="dialogTipoInformeVenta"
                width="600"
            >
                <v-card>
                    <v-card-actions class="justify-center">
                        <v-btn
                            color="primary lighten-2"
                            text
                            @click="showDialogReportBySeller()"
                        >
                            Informe por usuario
                        </v-btn>
                        
                        <v-btn
                            color="primary lighten-2"
                            text
                            @click="informeGeneralVentas() "
                        >
                            Informe general
                        </v-btn>

                        <v-btn
                            color="primary lighten-2"
                            text
                            @click="informeFacturasEmitidas() "
                        >
                            Facturas emitidas
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!--ACÁ TERMINA DIALOG PARA ELEGIR EL TIPO DE INFORM DE VENTA -->

        <DialogReportBySeller></DialogReportBySeller>
        <DialogReportAllSeller></DialogReportAllSeller>
        <DialogPurchaseReport></DialogPurchaseReport>
        <DialogReportByDep></DialogReportByDep>
        </template>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import DialogReportBySeller from "./DialogReportBySeller.vue";
import DialogReportAllSeller from "./DialogReportAllSeller.vue";
import DialogPurchaseReport from "./DialogPurchaseReport.vue";
import DialogReportByDep from "./DialogReportByDep.vue";

export default {
    data: () => ({
        dialogTipoInformeVenta: false,
        dialogTipoInformeCompra: false,
    }),
    components:{
        DialogReportBySeller,
        DialogReportAllSeller,
        DialogPurchaseReport,
        DialogReportByDep,
    },  
    mounted() {
        
    },
    computed: {
        ...mapState("stock", ["loading"]),
        ...mapState("report", ["loadingVentas", "loadingInformeCompras"]),
    },
    methods: {
        ...mapActions("report", ["getSellerUsers"]),
        ...mapMutations("report", ["setDialogReportBySeller", "setDialogReportAllSeller", "setDialogPurchaseReport", "setIsFacturaInforme", "setDialogReportByDep"]),
        showDialogReportBySeller(){
            this.setDialogReportBySeller(true);
            this.getSellerUsers();
            this.dialogTipoInformeVenta = false;
        },
        showDialogPurchaseReport(){
            this.setDialogPurchaseReport(true);
        },
        informeGeneralVentas(){
            this.setIsFacturaInforme(false);
            this.setDialogReportAllSeller(true); 
            this.dialogTipoInformeVenta = false;
        },
        informeFacturasEmitidas(){
            this.setIsFacturaInforme(true);
            this.setDialogReportAllSeller(true); 
            this.dialogTipoInformeVenta = false;
        },
        informeDeposito(){
            this.setDialogReportByDep(true);
        },  
        proximamente(){
            alert('Módulo en construcción');
        }
    },

};
</script>
