<template v-slot:activator="{ on, attrs }">
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-row justify="center">
            <!-- Dialog (Modal Form Client) -->
            <v-dialog
                v-model="dialog"
                persistent
                max-width="600px"
                :retain-focus="false"
                @click:outside="
                    setDialog();
                    clearClient();
                    resetValidate();
                "
                @keydown.esc="
                    setDialog();
                    clearClient();
                    resetValidate();
                "
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        label="Nombre Apellido"
                                        v-model="client.nombre_y_apellido"
                                        :rules="[required('nombre')]"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        label="Ruc"
                                        v-model="client.ruc"
                                        :rules="[required('ruc')]"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        label="email"
                                        v-model="client.email"
                                        :rules="[
                                            required('email'),
                                            validEmail(),
                                        ]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-row no-gutters align-end>
                                        <v-col
                                            cols="12"
                                            sm="4"
                                            md="4"
                                            v-if="!edit"
                                        >
                                            <v-select
                                                v-model="selectedCountry"
                                                :items="countries"
                                                item-text="name"
                                                item-value="code"
                                            >
                                                <template
                                                    v-slot:selection="{ item }"
                                                >
                                                    {{ item.code }}
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col sm="8" md="8">
                                            <v-text-field
                                                v-model="client.telefono"
                                                label="Número de teléfono"
                                                hint="Ingresa tu número de teléfono sin el 0 al inicio"
                                                type="tel"
                                            >
                                                <template v-slot:prepend>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col cols="12" sm="6" md="6">
                                    <v-select
                                        label="Nacionalidad"
                                        v-model="client.nacionalidad"
                                        :items="nationality"
                                        rows="2"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea
                                        label="Direccion"
                                        v-model="client.direccion"
                                        rows="2"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                        <small>*indicates required field</small>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="edit"
                            color="red darken-1"
                            outlined
                            @click="
                                setDialog();
                                setEdit();
                                clearClient();
                                resetValidate();
                            "
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            v-else
                            color="red darken-1"
                            outlined
                            @click="
                                setDialog();
                                clearClient();
                                resetValidate();
                            "
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            v-if="edit"
                            :disabled="!valid"
                            color="blue darken-1"
                            outlined
                            @click="validateSubmit(edit, client)"
                        >
                            Editar
                        </v-btn>
                        <v-btn
                            :disabled="!valid || isLoading"
                            :loading="isLoading"
                            v-else
                            color="blue darken-1"
                            outlined
                            @click="validateSubmit(edit, client)"
                        >
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-form>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import { required, validEmail } from "../../utils/validate";
export default {
    data: () => ({
        valid: true,
        phoneNumber: "",
        selectedCountry: "+595",
        countries: [
            { name: "Paraguay (+595)", code: "+595" },
            { name: "Argentina (+54)", code: "+54" },
            { name: "Brasil (+55)", code: "+55" },
            { name: "Bolivia (+591)", code: "+591" },
            { name: "España (+34)", code: "+34" },
        ],
        required,
        validEmail,
    }),
    computed: {
        //llamada de variables del store
        ...mapState("client", ["dialog", "edit", "client", "nationality"]),
        ...mapState("app", ["isLoading"]),
        //Dependiendo del edit (boolean) cambia el titulo del form
        formTitle() {
            return this.edit === false ? "Nuevo Cliente" : "Editar Cliente";
        },
    },

    methods: {
        //llamada de metodos del store
        ...mapMutations("client", [
            "setDialog",
            "setEdit",
            "clearClient",
            "clearClientForOther",
        ]),
        //llamada de peticiones del store
        ...mapActions("client", ["createClient", "editClient", "getClients"]),

        //validar campo requerido
        //Dependiendo del edit (boolean), crea o edita la marca
        //resetea validacion, obtiene las listas marca, borra los campos nombre url
        validateSubmit(edit, client) {
            if (this.client.telefono) this.formattedPhoneNumber();
            if (this.$refs.form.validate()) {
                if (edit) {
                    this.editClient(client);
                } else {
                    this.createClient(client);
                }

                this.getClients();
                this.clearClient();
                this.resetValidate();
            }
        },
        //resetea las validaciones activadas
        resetValidate() {
            this.$refs.form.resetValidation();
        },
        formattedPhoneNumber() {
            this.client.telefono = this.client.telefono.trim();
            if (this.client.telefono.charAt(0) === "0") {
                this.client.telefono = this.client.telefono.substring(1);
            }
            this.client.telefono = this.selectedCountry + this.client.telefono;
        },
    },
};
</script>
