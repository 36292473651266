<template>
    <v-dialog
        v-model="dialogQuickPay"
        width="500"
        @click:outside="setDialogQuickPay(false)"
        @keydown.esc="setDialogQuickPay(false)"
    >
        <v-card>
            <v-card-title class="text-h5 primary text-white justify-center">
                Cobro en efectivo
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row justify="center">
                        <v-col md="8">
                            <v-autocomplete
                                @click:clear="sale.cliente = ''"
                                v-model="selectedClient"
                                :filter="filterObjectClient"
                                :items="clients"
                                :search-input.sync="buscarCliente"
                                :loading="loadingClient"
                                return-object
                                item-value="id"
                                clearable
                                label="Cliente"
                                ref="cliente"
                                @change="(e) => chengeClient(e)"
                            >
                                <!-- En caso que no encuentra -->
                                <template v-slot:no-data>
                                    <v-sheet class="d-flex justify-center ma-2">
                                        <h6>Cliente no registrado</h6>
                                    </v-sheet>
                                    <v-sheet class="d-flex justify-center">
                                        <v-btn
                                            small
                                            class="primary"
                                            @click="
                                                setDialog();
                                                setIsClientForOther();
                                            "
                                        >
                                            Nuevo Cliente
                                        </v-btn>
                                    </v-sheet>
                                </template>

                                <template slot="item" slot-scope="data">
                                    {{ data.item.nombre_y_apellido }} -
                                    {{ data.item.ruc }}
                                </template>
                                <template v-slot:selection="data"
                                    >{{ data.item.nombre_y_apellido }} -
                                    {{ data.item.ruc }}</template
                                >
                            </v-autocomplete>
                        </v-col>
                        <v-col md="4">
                            <v-checkbox
                                v-model="sale.factura"
                                :disabled="habilitarFactura"
                                label="Factura"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center align-center">
                        <v-col
                            class="d-flex justify-center align-center"
                            md="8"
                        >
                            <vuetify-money
                                label="Monto cobrado"
                                ref="montoCobrado"
                                v-model="montoCobrado"
                                :color="
                                    montoCobrado >= totalVenta ? 'green' : 'red'
                                "
                                required
                                :options="vmOptions"
                            />
                        </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center align-center" md="8">
                        <h4>
                            Total
                            {{
                                convertMoney(totalVenta, selectCurrency.codigo)
                            }}
                        </h4>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="d-flex justify-center align-center" md="8">
                        <h4>
                            Vueltos:
                            {{ convertMoney(vuelto, selectCurrency.codigo) }}
                        </h4>
                    </v-row>
                </v-container>
            </v-card-text>

            <FormClientComponent />

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    :disabled="montoCobrado < totalVenta || isPagando"
                    :loading="isPagando"
                    outlined
                    @click="btnPagoRapido()"
                >
                    pagar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import FormClientComponent from "../Clients/FormClient.Component.vue";

import PrintDialog from "./PrintDialog.Component";
import { convertMoney } from "../../formatos";
export default {
    components: {
        FormClientComponent,
        PrintDialog,
    },
    data: () => ({
        buscarCliente: "",
        selectedClient: null,
        montoCobrado: "",
        vuelto: 0,
        ticket: null,
        // disabledFactura: false,
    }),
    mounted() {
        this.ticket = JSON.parse(localStorage.getItem("ticket"));
        if (this.ticket == null) {
            this.ticket = true;
            localStorage.setItem("ticket", true);
        }
        if (!!this.sale?.client) {
            this.selectedClient = this.sale.client;
            this.searchClient(this.sale.client.ruc);
        }
    },
    computed: {
        ...mapState("sale", [
            "dialogQuickPay",
            "sale",
            "totalVenta",
            "isPagando",
            "dialogImpresion",
            "printDialog",
            "isPrint",
            "selectCurrency",
            "saleProducts",
        ]),
        ...mapState("client", [
            "client",
            "clients",
            "clientForOther",
            "isClientForOther",
            "loadingClient",
        ]),
        ...mapState("app", ["vmOptions"]),
        ...mapGetters("quote", ["getQuote"]),
        ...mapGetters("sale", ["getSelectCurrency"]),
        ...mapGetters("app", ["checkPermiso"]),
        selectCurrency: {
            get() {
                return this.getSelectCurrency;
            },
            set(newVal) {
                this.setSelectCurrency(newVal);
            },
        },
        habilitarFactura() {
            return this.selectedClient === null;
        },
        facturaChecked() {},
    },
    watch: {
        buscarCliente(val) {
            if (val) {
                this.searchClient(val);
                if (this.isClientForOther) {
                    this.client.nombre_y_apellido = val;
                }
            }
        },
        "sale.client"(val) {
            if (val != null) {
                this.selectedClient = val;
                this.sale.client_id = val.id;
            }
        },
        clientForOther(val) {
            if (this.isClientForOther) {
                this.selectedClient = this.clientForOther;
                this.clearClientForOther();
            }
        },
        montoCobrado(val) {
            val >= this.totalVenta
                ? (this.vuelto = val - this.totalVenta)
                : (this.vuelto = 0);
        },
        /*dialogQuickPay(val) {
            if (!!this.sale?.client) {
                this.selectedClient = this.sale.client;
            }
        },*/
        dialogQuickPay: {
            handler: function (val) {
                if (val) {
                    setTimeout(() => {
                        this.$refs.cliente.$refs.input.focus();
                    });
                } else {
                    // this.disabledFactura = false;
                    this.buscarCliente = "";
                    this.selectedClient = null;
                    this.montoCobrado = "";
                    this.vuelto = 0;
                    this.sale.client = null;
                    this.sale.client_id = null;
                    this.sale.cliente = null;
                    this.sale.factura = null;
                }
            },
            deep: true,
        },
    },
    methods: {
        convertMoney,
        ...mapActions("client", ["searchClient"]),
        ...mapActions("sale", ["pagoRapido", "printSale"]),
        ...mapActions("quote", ["getQuoteCurrency"]),
        ...mapMutations("sale", [
            "setDialogQuickPay",
            "setPaymentReceiptDetail",
            "setIsPagando",
            "setDialogImpresion",
            "setPrintDialog",
            "setSelectCurrency",
            "setPriceForeign",
            "revertPriceForeign",
            "updateTotal",
        ]),
        ...mapMutations("client", [
            "setDialog",
            "setIsClientForOther",
            "clearClientForOther",
        ]),

        btnPagoRapido() {
            this.setIsPagando(true);
            this.setPaymentReceiptDetail({
                codigo: "0",
                monto: this.totalVenta,
                metodo_pago: "efectivo",
                entidad_metodo_pago: "",
            });

            // if (this.ticket && this.isPrint) {
            //     this.sale.factura
            //         ? this.printSale()
            //         : this.setPrintDialog(true);
            // } else {
            //     if (this.sale.factura) this.setDialogImpresion(true);
            // }
            this.pagoRapido();
        },
        filterObjectClient(item, queryText, itemText) {
            return (
                item.nombre_y_apellido
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.ruc
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },
        chengeClient(val) {
            this.sale.factura = false;
            this.disableFactura = false;
            this.sale.client = null;
            this.sale.client_id = null;
            this.sale.cliente = null;
            if (val != null) {
                this.sale.client = { ...val };
                this.sale.client_id = val.id;
                this.sale.cliente = val.nombre_y_apellido;
                this.sale.factura = true;
                this.disableFactura = true;
                if (
                    val.nacionalidad !== "Paraguay" &&
                    this.checkPermiso("export_create")
                ) {
                    this.setPriceForeign();
                } else {
                    this.revertPriceForeign();
                }

                this.updateTotal();
            }
        },
    },
};
</script>
