<template>
    <v-form ref="form" v-model="valid">
        <v-dialog
            v-model="dialogFormStore"
            @click:outside="setDialogFormStore(false), reset()"
            @keydown.esc="setDialogFormStore(false), reset()"
            width="60%"
        >
            <v-card>
                <v-card-title>Nuevo Deposito</v-card-title>
                <v-text-field
                    v-model="nombre"
                    label="Nombre Deposito"
                    class="pa-6"
                    :rules="nameRules"
                    aria-required=""
                ></v-text-field>

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        class="ma-4"
                        color="red darken-1"
                        text
                        outlined
                        @click="setDialogFormStore(false), reset()"
                    >
                        Cerrar
                    </v-btn>
                    <v-btn
                        :disabled="!valid"
                        class="ma-4"
                        color="blue darken-1"
                        text
                        outlined
                        @click="
                            crearDeposito(nombre), setDialogFormStore(false)
                        "
                    >
                        Crear
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
    data: () => ({
        nombre: "",
        valid: true,
        nameRules: [(v) => !!v || "El nombre es obligatorio"],
    }),
    computed: {
        ...mapState("deposito", ["dialogFormStore"]),
    },
    methods: {
        ...mapMutations("deposito", ["setDialogFormStore"]),
        ...mapActions("deposito", ["createStore"]),
        crearDeposito(item) {
            const localItem = { nombre: item };
            this.createStore(localItem);
        },
        reset() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>

<style></style>
