<template>
    <div class="mx-10">
        <v-data-table
            :headers="headersRemissionList"
            :items="remissions"
            :search="search"
            sort-by="nombre"
        >
            <template v-slot:top>
                <!-- Encabezado Lista Tabla -->
                <!-- Buscar y Button Agregar -->

                <v-row align="center">
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="search"
                            label="Buscar"
                            append-icon="mdi-magnify"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </template>
            <!-- Iconos Editar Y Eliminar  -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    color="blue"
                    @click="editarRemision(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon small color="red" @click="eliminarRemision(item)">
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
    data: () => ({
        search: "",
    }),
    mounted() {
        this.getRemissions();
    },
    computed: {
        ...mapState("remission", ["remissions", "headersRemissionList"]),
    },
    methods: {
        ...mapActions("remission", ["getRemissions", "getOneRemission"]),
        ...mapMutations("remission", [
            "setDialogRemission",
            "setIsEdit",
            "setDialogDeleteRemission",
        ]),

        editarRemision(payload) {
            this.setIsEdit(true);
            this.setDialogRemission(true);
            this.getOneRemission(payload);
        },
        eliminarRemision(payload) {
            this.getOneRemission(payload);
            this.setDialogDeleteRemission(true);
        },
    },
};
</script>
