<template>
    <div class="dialog" v-if="dialogImpresion">
        <div id="seleccion">
            <div
                v-for="(arrayProducts, index) in getTenProductsArray"
                :key="index"
            >
                <div class="parte">
                    <div class="fecha">
                        <p class="fecha-content">
                            {{ date[0] }}
                            {{ date[1] }},
                            {{ date[2] }}
                        </p>
                        <span class="ruc">{{ sale?.client?.ruc }}</span>
                    </div>
                    <div class="datos">
                        <span class="nombre">
                            {{ sale.client?.nombre_y_apellido }}
                        </span>
                        <span v-if="sale?.credito" class="credito">X</span>
                        <span v-else class="contado">X</span>
                    </div>
                    <div class="direcciones">
                        <span class="direc" v-html="returnDireccionValue" />
                        <!-- <span class="cel" v-html="returnCelularValue" /> -->
                    </div>

                    <div class="tabla">
                        <div
                            class="pro"
                            v-for="product in arrayProducts"
                            :key="product.product_id"
                        >
                            <span class="cantidad">{{ product.cantidad }}</span>
                            <span class="codigo">{{ product.producto }}</span>
                            <span class="precio">{{
                                product.precio_unitario
                            }}</span>
                            <span class="subTotalExenta">
                                {{
                                    product.iva === "exenta"
                                        ? product.subtotal
                                        : ""
                                }}
                            </span>
                            <span class="subTotalIva5">
                                {{
                                    product.iva === "5%" ? product.subtotal : ""
                                }}
                            </span>
                            <span class="subTotalIva10">
                                {{
                                    product.iva === "10%"
                                        ? product.subtotal
                                        : ""
                                }}
                            </span>
                        </div>
                    </div>
                    <div class="detalle-precio">
                        <div class="precio-text">
                            <p class="precio-text-p">
                                {{
                                    returnTextTotal(
                                        returnTotalVenta(arrayProducts)
                                    )
                                }}
                            </p>
                        </div>
                        <div class="total">
                            {{ returnTotalVenta(arrayProducts) }}
                        </div>
                    </div>
                    <div class="iva">
                        <span class="iva5">{{
                            returnIva5(arrayProducts)
                        }}</span>
                        <span class="iva10">{{
                            returnIva10(arrayProducts)
                        }}</span>
                        <span class="ivaTotal">{{
                            returnIva10(arrayProducts) +
                            returnIva5(arrayProducts)
                        }}</span>
                    </div>

                    <!-- ---------------Segunda Parte---------- -->
                    <!-- -------------------------------------- -->
                    <!-- -------------------------------------- -->
                    <!-- -------------------------------------- -->
                    <div class="fecha2">
                        <p class="fecha-content2">
                            {{ date[0] }}
                            {{ date[1] }},
                            {{ date[2] }}
                        </p>
                        <span class="ruc">{{ sale?.client?.ruc }}</span>
                    </div>
                    <div class="datos2">
                        <span class="nombre">
                            {{ sale.client?.nombre_y_apellido }}
                        </span>
                        <span v-if="sale?.credito" class="credito">x</span>
                        <span v-else class="contado">x</span>
                    </div>
                    <div class="direcciones2">
                        <span class="direc" v-html="returnDireccionValue" />
                    </div>

                    <div class="tabla2">
                        <div
                            class="pro2"
                            v-for="product in arrayProducts"
                            :key="product.product_id"
                        >
                            <span class="cantidad">{{ product.cantidad }}</span>
                            <span class="codigo">{{ product.producto }}</span>
                            <span class="precio">{{
                                product.precio_unitario
                            }}</span>
                            <span class="subTotalExenta">
                                {{
                                    product.iva === "exenta"
                                        ? product.subtotal
                                        : ""
                                }}
                            </span>
                            <span class="subTotalIva5">
                                {{
                                    product.iva === "5%" ? product.subtotal : ""
                                }}
                            </span>
                            <span class="subTotalIva10">
                                {{
                                    product.iva === "10%"
                                        ? product.subtotal
                                        : ""
                                }}
                            </span>
                        </div>
                    </div>
                    <div class="detalle-precio2">
                        <div class="precio-text2">
                            <p class="precio-text-p2">
                                {{
                                    returnTextTotal(
                                        returnTotalVenta(arrayProducts)
                                    )
                                }}
                            </p>
                        </div>
                        <div class="total2">
                            {{ returnTotalVenta(arrayProducts) }}
                        </div>
                    </div>
                    <div class="iva2">
                        <span class="iva5">{{
                            returnIva5(arrayProducts)
                        }}</span>
                        <span class="iva10">{{
                            returnIva10(arrayProducts)
                        }}</span>
                        <span class="ivaTotal">{{
                            returnIva10(arrayProducts) +
                            returnIva5(arrayProducts)
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import printJS from "print-js";
import { mapState, mapMutations, mapGetters } from "vuex";
import numeroLetras from "../../utils/NumeroALetra";

export default {
    data: () => ({
        options: {
            year: "numeric",
            month: "long",
            day: "numeric",
        },
        date: new Date()
            .toLocaleDateString("es-PY", {
                year: "numeric",
                month: "long",
                day: "numeric",
            })
            .split("de"),
    }),
    mounted() {
        printJS({
            printable: "seleccion",
            type: "html",
            targetStyles: ["*"],
            onPrintDialogClose: this.afterPrint(),
            onError: this.afterPrint(),
        });
    },
    computed: {
        ...mapState("sale", ["sale", "saleProducts", "dialogImpresion"]),
        ...mapGetters("sale", ["getTenProductsArray"]),
        returnDireccionValue() {
            return this.sale.client?.direccion === null ||
                this.sale.client?.direccion === undefined
                ? "<br />"
                : `<span>${this.sale.client?.direccion}</span>`;
        },
        returnCelularValue() {
            return this.sale.client?.telefono === null ||
                this.sale.client?.telefono === undefined
                ? "<br />"
                : `<span>${this.sale.client?.telefono}</span>`;
        },
    },
    methods: {
        ...mapMutations("sale", [
            "setDialogImpresion",
            "clearAfterImpresion",
            "limpiarPOS",
        ]),
        afterPrint() {
            this.setDialogImpresion(false);
            this.clearAfterImpresion();
            this.limpiarPOS();
        },

        returnTextTotal(payload) {
            return numeroLetras(payload);
        },

        returnTotalVenta(payload) {
            let total = 0;
            payload.forEach((element) => {
                total += element.subtotal;
            });
            return total;
        },
        returnIva10(payload) {
            let sumaIva = 0;
            payload.forEach((product) => {
                if (product.iva === "10%") {
                    sumaIva += Math.round(
                        (product.precio_unitario * product.cantidad) / 11
                    );
                }
            });
            return sumaIva === 0 ? "" : sumaIva;
        },
        returnIva5(payload) {
            let sumaIva = 0;
            payload.forEach((product) => {
                if (product.iva === "5%") {
                    sumaIva += Math.round(
                        (product.precio_unitario * product.cantidad) / 21
                    );
                }
            });
            return sumaIva === 0 ? "" : sumaIva;
        },
    },
};
</script>
<style scoped>
template {
    display: none;
    margin: 0;
    padding: 0;
}

html,
body {
    width: 216mm;
    height: 330mm;
    margin: 0;
    padding: 0;
}
@media print {
    @page {
        size: 216mm 330mm;
        orphans: 0;
        widows: 0;
    }
}
#seleccion {
    background: white;
    margin: 0;
}

.dialog {
    padding: 0;
    margin: 0;
    position: relative;
}
.parte {
    margin: 0;
    padding: 0;
    height: 330mm;
    width: 216mm;
    border: 1px solid black;
    position: relative;
}

.fecha {
    position: absolute;
    top: 4.6cm;
    width: 100%;
}
.fecha-content {
    position: absolute;
    left: 5cm;
    width: 10cm;
}
.ruc {
    position: absolute;
    left: 16.5cm;
    width: 4cm;
}

.datos {
    position: absolute;
    top: 5.1cm;
    width: 100%;
}
.nombre {
    position: absolute;
    left: 3.1cm;
    width: 9.5cm;
}
.contado {
    position: absolute;
    left: 16.9cm;
}
.credito {
    position: absolute;
    left: 19.1cm;
}
.direcciones {
    position: absolute;
    top: 5.6cm;
    width: 100%;
}
.direc {
    position: absolute;
    left: 3.5cm;
    width: 10cm;
}

.tabla {
    position: absolute;
    top: 6.3cm;
    height: 5.5cm;
    width: 100%;
}
.pro {
    position: relative;
    padding-top: 0.5cm;
}
.cantidad {
    position: absolute;
    left: 1.3cm;
    width: 1.3cm;
}
.codigo {
    position: absolute;
    left: 3cm;
    width: 8cm;
}
.precio {
    position: absolute;
    left: 11.3cm;
}
.subTotalExenta {
    position: absolute;
    left: 13.3cm;
}

.subTotalIva5 {
    position: absolute;
    left: 15.6cm;
}

.subTotalIva10 {
    position: absolute;
    left: 18cm;
}

.detalle-precio {
    position: absolute;
    top: 12.5cm;
    display: flex;
    width: 100%;
}
.precio-text {
    margin-left: 2cm;
    flex-grow: 5;
}
.precio-text-p {
    margin-left: 2.5cm;
    display: inline;
}
.total {
    margin-right: 1.7cm;
}
.iva {
    position: absolute;
    top: 13.8cm;
}
.iva5 {
    position: absolute;
    left: 5cm;
}
.iva10 {
    position: absolute;
    left: 11.2cm;
}
.ivaTotal {
    position: absolute;
    left: 17.4cm;
}

.fecha2 {
    position: absolute;
    top: 20.4cm;
    width: 100%;
}
.fecha-content2 {
    position: absolute;
    left: 5cm;
    width: 10cm;
}
.ruc2 {
    position: absolute;
    left: 16.5cm;
    width: 4cm;
}

.datos2 {
    position: absolute;
    top: 20.9cm;
    width: 100%;
}
.nombre2 {
    position: absolute;
    left: 3.1cm;
    width: 9.5cm;
}
.direcciones2 {
    position: absolute;
    top: 21.4cm;
    width: 100%;
}

.tabla2 {
    position: absolute;
    top: 22.2cm;
    height: 5cm;
    width: 100%;
}
.pro2 {
    position: relative;
    padding-top: 0.5cm;
}

.detalle-precio2 {
    position: absolute;
    top: 28.4cm;
    display: flex;
    width: 100%;
}
.precio-text2 {
    margin-left: 2cm;
    flex-grow: 5;
}
.precio-text-p2 {
    margin-left: 2.5cm;
    display: inline;
}
.total2 {
    margin-right: 1.7cm;
}

.iva2 {
    position: absolute;
    top: 29.7cm;
}
</style>
