<template>
    <v-form>
        <v-dialog
            v-model="dialogMoneyOut"
            max-width="600px"
            @click:outside="closeMoneyOutDialog()"
            @keydown.esc="closeMoneyOutDialog()"
        >
            <v-card>
                <v-card-title>
                    <span class="thet-h5">{{ titulo }}</span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <vuetify-money
                                v-model="moneyOut.monto"
                                label="Monto a retirar en guaranies"
                                :options="vmOptions"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                :items="quotes"
                                item-text="nombre"
                                item-value="quotation_id"
                                label="Moneda"
                                v-model="moneyOut.quotation_id"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="moneyOut.motivo"
                                label="Motivo"
                            />
                        </v-col>
                    </v-row>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="closeMoneyOutDialog()">
                            Cerrar
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="btnConfirmar()"
                        >
                            {{ btnText }}
                        </v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-form>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
    data: () => ({
        localItem: {},
    }),
    watch: {
        dialogMoneyOut(val) {
            if (val) {
                this.moneyOut.quotation_id = this.getQuote.quotation_id;
            }
        },
    },
    mounted() {
        this.getQuoteCurrency();
    },
    computed: {
        ...mapState("box", ["dialogMoneyOut", "moneyOut", "isEditar"]),
        ...mapState("app", ["vmOptions"]),
        ...mapState("quote", ["quotes"]),
        ...mapGetters("quote", ["getQuote"]),

        titulo() {
            return this.isEditar
                ? "Editar egreso de dinero"
                : "Nuevo egreso de dinero";
        },
        btnText() {
            return this.isEditar ? "Editar" : "Guardar";
        },
    },
    methods: {
        ...mapMutations("box", ["setDialogMoneyOut", "closeMoneyOutDialog"]),
        ...mapActions("box", ["subsMoneyBox", "editMoneyOut"]),
        ...mapActions("quote", ["getQuoteCurrency"]),

        btnConfirmar() {
            this.isEditar
                ? this.editMoneyOut(this.moneyOut)
                : this.subsMoneyBox(this.moneyOut);
        },
    },
};
</script>
