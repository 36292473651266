<template>
    <v-autocomplete
        v-bind="$attrs"
        v-model="localBrand"
        :items="brands"
        :search-input.sync="buscarMarca"
        label="Elija una marca"
        clearable
        no-filter
        outlined
        dense
        :loading="loading"
        :menu-props="{
            nudgeBottom: 15 + 'px',
        }"
        @click:clear="(e) => handleInput(e)"
        @change="(e) => handleInput(e)"
    >
        <!-- En caso que no encuentra -->
        <!-- Opcion para crear marca -->
        <template v-slot:no-data>
            <v-sheet class="d-flex justify-center ma-2">
                <h6>Marca inexistente</h6>
            </v-sheet>
            <v-sheet class="d-flex justify-center">
                <v-btn
                    color="primary"
                    @click="
                        setDialogBrand();
                        setIsBrandForOther();
                    "
                    >Crear Marca</v-btn
                >
            </v-sheet>
        </template>

        <template slot="item" slot-scope="data">
            <v-list-item-content>
                <v-list-item-title>
                    {{ data.item.nombre }}
                </v-list-item-title>
            </v-list-item-content>
        </template>
        <template slot="selection" slot-scope="data">
            <v-list-item-content>
                <v-list-item-title>
                    {{ data.item.nombre }}
                </v-list-item-title>
            </v-list-item-content>
        </template>

        <template v-slot:append-item>
            <div v-intersect="endIntersectBrand" />
        </template>
    </v-autocomplete>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
    inheritAttrs: false,
    props: ["brand"],
    model: {
        prop: "brand",
        event: "blur",
    },
    data: () => ({
        localBrand: null,
        buscarMarca: "",
    }),
    watch: {
        buscarMarca(val, old) {
            if (val !== old) {
                this.brandsPage.current = 1;
                this.searchBrands(val);
            }
        },
        localBrand(val) {
            if (val != null) {
                this.handleInput(val);
            }
        },
    },
    mounted() {
        this.brandsPage.current = 1;
        this.getBrands();
    },
    computed: {
        ...mapState("brand", [
            "brands",
            "brandsPage",
            "loading",
            "brandForOther",
            "isBrandForOther",
        ]),
    },
    methods: {
        ...mapMutations({
            setDialogBrand: "brand/setDialog",
            setIsBrandForOther: "brand/setIsBrandForOther",
            clearBrandForOther: "brand/clearBrandForOther",
            setLazy: "brand/setLazy",
        }),
        ...mapActions("brand", ["getBrands", "searchBrands"]),
        async endIntersectBrand(entries, observer, isIntersecting) {
            if (isIntersecting) {
                if (this.brandsPage.lastPage > this.brandsPage.current) {
                    this.brandsPage.current += 1;
                    this.setLazy(true);
                    if (this.buscarMarca) {
                        await this.searchBrands(this.buscarMarca);
                    } else {
                        await this.searchBrands("");
                    }
                }
            }
        },
        handleInput(value) {
            this.$emit("blur", value);
        },
    },
};
</script>

<style></style>
