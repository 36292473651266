var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-10"},[_c('v-data-table',{attrs:{"loading":_vm.loadingGetPurchase,"headers":_vm.headersPurchase,"items":_vm.purchases,"search":_vm.search,"sort-by":"nombre"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),(_vm.canCreate)?_c('v-col',{attrs:{"cols":"12","lg":"auto","md":"auto","sm":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.nuevo()}}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v("\n                        Agregar\n                    ")],1)],1):_vm._e()],1)]},proxy:true},{key:`item.supplier.nombre`,fn:function({ item }){return [_c('v-chip',{attrs:{"color":"indigo darken-1","label":"","text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-circle-outline ")]),_vm._v("\n                "+_vm._s(item.supplier.nombre))],1)]}},{key:`item.credito`,fn:function({ item }){return [_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","color":item.credito ? 'primary' : 'green lighten-1',"text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.credito === 1
                        ? "mdi-credit-card-multiple"
                        : "mdi-cash-multiple"))]),_vm._v("\n\n                "+_vm._s(_vm.isCredito(item.credito))+"\n            ")],1)]}},{key:`item.plazo`,fn:function({ item }){return [_vm._v("\n            "+_vm._s(item.credito === 0 ? "Sin Plazo" : `${item.plazo} dias`)+"\n        ")]}},{key:`item.purchase_detail.total`,fn:function({ item }){return _vm._l((item.purchase_detail),function(detalle){return _c('div',{key:detalle.id},[_vm._v("\n                "+_vm._s(_vm.convertMoney(
                        detalle.total,
                        detalle.quotation.currency.codigo
                    ))+"\n            ")])})}},{key:`item.created_at`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.returnDate(item.created_at)))])]}},{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.showPurchaseDetail(item)}}},[_vm._v("\n                mdi-eye\n            ")]),_vm._v(" "),(_vm.canEdit)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){_vm.setDialog(true);
                    _vm.setEdit();
                    _vm.getOnePurchase(item);}}},[_vm._v("\n                mdi-pencil\n            ")]):_vm._e(),_vm._v(" "),(_vm.canDelete)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){_vm.localPurchase = item;
                    _vm.setDeleteDialog();}}},[_vm._v("\n                mdi-delete\n            ")]):_vm._e()]}}],null,true)}),_vm._v(" "),_c('DeleteDialog',{attrs:{"dialog":_vm.deleteDialog,"setDialog":_vm.setDeleteDialog},on:{"deleted":function($event){return _vm.setDeletePurchase(_vm.localPurchase)}}}),_vm._v(" "),_c('AvisoAperturaDialog'),_vm._v(" "),_c('DialogShowPurchase')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }