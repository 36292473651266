<template>
    <v-card class="my-10">
        <v-list-item class="">
            <v-card
                class="mt-n7"
                color="primary"
                elevation="10"
                min-height="80"
                min-width="80"
            >
                <v-icon class="ma-5" large dark>local_offer</v-icon>
            </v-card>

            <v-list-item-title>
                <div class="overline mx-10 mt-5">
                    <h4>Lista Remisiones</h4>
                </div>
            </v-list-item-title>
        </v-list-item>
        <v-list-item>
            <v-list-item-content>
                <v-card-text>
                    <v-card-actions> </v-card-actions>
                </v-card-text>
            </v-list-item-content>
        </v-list-item>

        <!-- Lista de Marcas -->
        <RemissionListComponentVue />
        <FormRemissionComponent />
        <DeleteDialogComponentVue
            :dialog="dialogDeleteRemission"
            :setDialog="setDialogDeleteRemission"
            v-on:deleted="deleteRemission(remission)"
        />
    </v-card>
</template>

<script>
import RemissionListComponentVue from "./RemissionList.Component.vue";
import FormRemissionComponent from "../Store/Remission/FormRemission.Component.vue";
import DeleteDialogComponentVue from "../DeleteDialog.Component.vue";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
    data: () => ({}),
    components: {
        RemissionListComponentVue,
        FormRemissionComponent,
        DeleteDialogComponentVue,
    },
    computed: {
        ...mapState("remission", ["remission", "dialogDeleteRemission"]),
    },
    methods: {
        ...mapActions("remission", ["deleteRemission"]),
        ...mapMutations("remission", ["setDialogDeleteRemission"]),
    },
};
</script>

<style></style>
