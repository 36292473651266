<template>
    <div class="mx-10">
        <v-data-table
            :headers="headers"
            :items="suppliers"
            :loading="loading"
            hide-default-footer
        >
            <template v-slot:top>
                <!-- Encabezado Lista Tabla -->
                <!-- Buscar y Button Agregar -->

                <v-row dense justify="space-between">
                    <v-col cols="12" lg="6" md="6" sm="6">
                        <v-text-field
                            v-model="search"
                            dense
                            outlined
                            label="Buscar"
                            append-icon="mdi-magnify"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="auto"
                        md="auto"
                        sm="auto"
                        v-if="canCreate"
                    >
                        <v-btn color="primary" @click="setDialog">
                            <v-icon> mdi-plus </v-icon>
                            Agregar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!-- Iconos Editar Y Eliminar  -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    v-if="canEdit"
                    small
                    class="mr-2"
                    color="blue"
                    @click="
                        setDialog();
                        setEdit();
                        setSupplier(item);
                    "
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    v-if="canDelete"
                    small
                    color="red"
                    @click="
                        localSupplier = item;
                        setDeleteDialog();
                    "
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
        <v-pagination
            v-model="suppliersPage.current"
            class="py-4"
            :length="suppliersPage.lastPage"
        />
        <DeleteDialog
            :dialog="deleteDialog"
            :setDialog="setDeleteDialog"
            v-on:deleted="
                deleteSupplier(localSupplier);
                getSuppliers();
            "
        />
    </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import DeleteDialog from "../DeleteDialog.Component.vue";

export default {
    data: () => ({
        search: "",
        localSupplier: {},
    }),
    watch: {
        "suppliersPage.current"(newVal, oldVal) {
            if (this.search) {
                this.suppliersPage.current = newVal;
                this.searchSupplier(this.search);
                return;
            }
            if (newVal !== oldVal) {
                this.suppliersPage.current = newVal;
                this.getSuppliers();
            }
        },
        search(val) {
            this.suppliersPage.current = 1;
            this.searchSupplier(val);
        },
    },
    components: {
        DeleteDialog,
    },
    mounted() {
        this.suppliersPage.current = 1;
        this.getSuppliers();
        this.getPermissions(this.$route.path);
    },
    computed: {
        ...mapState("supplier", [
            "suppliers",
            "dialog",
            "headers",
            "edit",
            "supplier",
            "deleteDialog",
            "loading",
            "suppliersPage",
        ]),
        ...mapState("app", ["canCreate", "canEdit", "canDelete"]),
    },
    methods: {
        ...mapActions("supplier", [
            "getSuppliers",
            "deleteSupplier",
            "searchSupplier",
        ]),
        ...mapMutations("supplier", [
            "setDialog",
            "setEdit",
            "setSupplier",
            "setDeleteDialog",
        ]),
        ...mapActions("app", ["getPermissions"]),
    },
};
</script>
