<template>
    <div>
        <v-dialog
            v-model="dialogRemission"
            @click:outside="resetAll()"
            @keydown.esc="resetAll()"
            eager
            width="80%"
            class="d-flex"
        >
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1">
                        Elija Vehiculo y Conductor
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="e1 > 2" step="2">
                        Destinatario
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="e1 > 3" step="3">
                        Datos transferencia
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="4"> Detalles Producto</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card
                            class="mb-12 pa-5"
                            color="grey lighten-5"
                            height="500px"
                        >
                            <CardVehicleDriverComponent ref="vehicleDriver" />
                        </v-card>
                        <v-row class="ma-2">
                            <v-btn
                                outlined
                                color="red"
                                text
                                @click="resetAll()"
                            >
                                Cancel
                            </v-btn>
                            <v-spacer />
                            <v-btn
                                outlined
                                :disabled="!$refs.vehicleDriver?.valid"
                                color="primary"
                                @click="handleStep1()"
                            >
                                Continue

                                <v-icon right dark> mdi-arrow-right </v-icon>
                            </v-btn>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card
                            class="mb-12 pa-5"
                            color="grey lighten-5"
                            height="500px"
                        >
                            <CardRecipientComponent ref="recipientRef" />
                        </v-card>

                        <v-row class="ma-2" align="end">
                            <v-btn
                                outlined
                                color="red"
                                text
                                @click="resetAll()"
                            >
                                Cancel
                            </v-btn>
                            <v-spacer />
                            <v-btn
                                outlined
                                class="mr-2"
                                color="primary"
                                @click="e1 = 1"
                                ><v-icon left dark> mdi-arrow-left </v-icon>
                                Prev
                            </v-btn>
                            <v-btn
                                outlined
                                :disabled="!$refs.recipientRef?.valid"
                                color="primary"
                                @click="handleStep2()"
                            >
                                Continue
                                <v-icon right dark> mdi-arrow-right </v-icon>
                            </v-btn>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-card
                            class="mb-12 pa-5"
                            color="grey lighten-5"
                            height="500px"
                        >
                            <CardDatatransferComponentVue ref="transDataRef" />
                        </v-card>

                        <v-row class="ma-2">
                            <v-btn
                                outlined
                                color="red"
                                class="ml-2"
                                text
                                @click="resetAll()"
                            >
                                Cancel
                            </v-btn>
                            <v-spacer />
                            <v-btn
                                outlined
                                class="mr-2"
                                color="primary"
                                @click="e1 = 2"
                                ><v-icon left dark> mdi-arrow-left </v-icon>
                                Prev
                            </v-btn>
                            <v-btn
                                outlined
                                :disabled="!$refs.transDataRef?.valid"
                                color="primary"
                                @click="handleStep3()"
                            >
                                Continue
                                <v-icon right dark> mdi-arrow-right </v-icon>
                            </v-btn>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="4">
                        <v-card
                            class="mb-12 pa-5"
                            color="grey lighten-5"
                            height="500px"
                        >
                            <CardProductsComponentVue />
                        </v-card>

                        <v-row class="ma-2" align="end">
                            <v-btn
                                outlined
                                color="red"
                                class="ml-2"
                                text
                                @click="setDialogRemission(false)"
                            >
                                Cancel
                            </v-btn>
                            <v-spacer />
                            <v-btn
                                outlined
                                class="mr-2"
                                color="primary"
                                @click="e1 = 3"
                                ><v-icon left dark> mdi-arrow-left </v-icon>
                                Prev
                            </v-btn>
                            <v-btn
                                outlined
                                color="primary"
                                @click="handleSave()"
                            >
                                Guardar
                            </v-btn>
                        </v-row>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>
        <template>
            <FormDriverComponentVue v-if="e1 === 1" />
            <FormVehicleComponentVue v-if="e1 === 1" />
            <FormRecipientComponentVue v-if="e1 === 2" />
            <FormTransferDataComponentVue v-if="e1 === 3" />
        </template>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import CardVehicleDriverComponent from "./CardVehicleDriver.Component.vue";
import CardRecipientComponent from "./CardRecipient.Component.vue";
import CardDatatransferComponentVue from "./CardDatatransfer.Component.vue";
import CardProductsComponentVue from "./CardProducts.Component.vue";

import FormDriverComponentVue from "../../Driver/FormDriver.Component.vue";
import FormVehicleComponentVue from "../../Vehicle/FormVehicle.Component.vue";
import FormRecipientComponentVue from "../../Recipient/FormRecipient.Component.vue";
import FormTransferDataComponentVue from "../../TransferData/FormTransferData.Component.vue";

export default {
    data: () => ({
        e1: 1,
        validVehicleDriver: false,
        validRecipient: false,
        validTrasnferData: false,
    }),
    components: {
        CardVehicleDriverComponent,
        CardRecipientComponent,
        CardDatatransferComponentVue,
        CardProductsComponentVue,
        FormDriverComponentVue,
        FormRecipientComponentVue,
        FormVehicleComponentVue,
        FormTransferDataComponentVue,
    },
    computed: {
        ...mapState("remission", [
            "dialogRemission",
            "remission",
            "remissionProducts",
            "isEdit",
        ]),
    },
    watch: {
        dialogRemission(val) {
            if (val) {
                this.getDrivers();
                this.getVehicles();
                this.getRecipients();
                this.getTransDatas();
            }
        },
    },

    methods: {
        ...mapMutations("remission", ["setDialogRemission", "clearRemission"]),
        ...mapMutations("app", ["setSnackbar"]),

        ...mapActions("remission", ["createRemission", "editRemission"]),
        ...mapActions("driver", ["getDrivers"]),
        ...mapActions("vehicle", ["getVehicles"]),
        ...mapActions("recipient", ["getRecipients"]),
        ...mapActions("transfer_data", ["getTransDatas"]),

        resetAll() {
            this.$refs.vehicleDriver?.$refs.form.reset();
            this.$refs.recipientRef?.$refs.form.reset();
            this.$refs.transDataRef?.$refs.form.reset();

            this.setDialogRemission(false);
            this.clearRemission();
            this.e1 = 1;
        },
        handleStep1() {
            this.validVehicleDriver =
                this.$refs.vehicleDriver.$refs.form.validate();
            if (this.validVehicleDriver) {
                this.e1 = 2;
            }
        },
        handleStep2() {
            this.validRecipient = this.$refs.recipientRef.$refs.form.validate();
            if (this.validRecipient) {
                this.e1 = 3;
            }
        },
        handleStep3() {
            this.validTrasnferData =
                this.$refs.transDataRef.$refs.form.validate();
            if (this.validTrasnferData) {
                this.e1 = 4;
            }
        },
        handleSave() {
            if (this.remissionProducts.length === 0) {
                this.setSnackbar({
                    text: "No puede guardar los productos vacios",
                    type: "info",
                    timeout: 1500,
                    show: true,
                    icon: "mdi-alert-circle-outline",
                });
                return;
            }
            this.e1 = 1;
            if (this.isEdit) {
                this.remission.remission_details = this.remissionProducts;
                this.editRemission(this.remission);
                return;
            }

            this.remission.remission_details = this.remissionProducts;
            this.createRemission(this.remission);
        },
    },
};
</script>

<style scoped></style>
