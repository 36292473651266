<template>
    <v-card class="pa-5">
        <v-icon class="text-info" @click="goBack">mdi-arrow-left</v-icon>
        
        <v-row justify="center">
            <v-col md="3" class="text-center">
                <v-card-subtitle class="h5 ma-0 pa-0">
                    Ingreso y egreso de efectivo
                </v-card-subtitle>
            </v-col>
            
        </v-row>
        <template>
            <v-row justify="center">
                <v-col md="8">
                    <v-simple-table class="px-5 mx-5">
                        <thead>
                            <tr>
                                <th>Origen</th>
                                <th>Observación</th>
                                <th>Monto</th>
                                <th>Opciones</th>
                            </tr></thead>
                        <tbody>
                            <tr v-for="m in moneyList">
                                <td> 
                                    <v-icon v-if="m.origen == 'in'" class="text-success">mdi-arrow-right</v-icon> 
                                    <v-icon v-else class="text-danger">mdi-arrow-left</v-icon>
                                </td>
                                <td>{{ m.motivo }}</td>
                                <td>{{ convertMoney(m.monto, m.codigo) }}</td>
                                <td>
                                    <v-icon v-if="iconEdit(m.origen)" small class="text-success" @click="setEditMoney(m)">mdi-pencil</v-icon> 
                                    <v-icon v-if="iconDelete(m.origen)" small class="text-danger ml-2" @click="showDeleteDialog(m)"> mdi-delete</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
                
            </v-row>
            
        </template>
            <div>
            <!-- Diálogo borrar -->
            <v-dialog v-model="deleteDialog" max-width="500px">
                <v-card>
                    <v-card-title>Confirmar eliminación</v-card-title>
                    <v-card-text>
                        ¿Estás seguro de que deseas eliminar este elemento?
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn color="secondary" @click="deleteDialog = false">Cancelar</v-btn>
                        <v-btn color="red text-white" @click="deleteItem">Confirmar</v-btn>
                        </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <SnackBarDialog></SnackBarDialog>
        <AddMoneyComponent />
        <SubMoneyComponent/>
    </v-card>
</template>


<script>
//TODO: PROBAR CON OTRAS MONEDAS LAS COMPRAS

import { mapActions, mapMutations, mapState } from 'vuex';
import {convertMoney} from '../../../formatos.js'
import SnackBarDialog from "../../SnackBarDialog.Component";
import AddMoneyComponent from "../AddMoney.Component.vue";
import SubMoneyComponent from "../SubMoneyComponent.vue";
export default {
    data: () => ({
        deleteDialog: false,
        deleteUrl: 0,
        canDeleteIn: false,
        canDeleteOut: false,
        canEditIn: false,
        canEditOut: false,
    }),
    components:{
        SnackBarDialog,
        AddMoneyComponent,
        SubMoneyComponent,
    },
    mounted(){
        /** parametros de id de caja e id de moneda para mostrar estos detalles */
        const params = {
            box_id: this.$route.params.id,
            currency_id: this.$route.params.currency_id,
        }
        this.getMoneyList(params)
    },
    computed: { 
        ...mapState("box", ["moneyList", "box"]),
        ...mapState("app", ["permisos"]),
    },
    methods: {
        ...mapActions("box", ["getMoneyList", "getOneBox", "deleteMoney", "getOneMoneyIn", "getOneMoneyOut", ]),
        ...mapMutations("box", ["clearBox", "setDialogMoneyIn", "setEditarMoneyIn", "setDialogMoneyOut"]),
        convertMoney,
        // Lógica para eliminar el elemento
        showDeleteDialog(m) {
            this.deleteDialog = true;
            this.deleteUrl = m.origen === 'in' ? '/money_ins/'+ m.id : '/money_outs/' + m.id; //genera la url para eliminiar  
        },
        setEditMoney(m){
            if(m.origen == 'in'){
                this.getOneMoneyIn(m.id);
                this.setEditarMoneyIn(true);
                this.setDialogMoneyIn(true);
            }else{
                this.getOneMoneyOut(m.id);
                this.setEditarMoneyIn(true);
                this.setDialogMoneyOut(true);
            }
            
        },
        deleteItem(){
            this.deleteMoney(this.deleteUrl);
            this.getMoneyList(this.$route.params.id);
            this.deleteDialog = false;
        },
        goBack(){
            this.clearBox();
            history.go(-1); 
            return false;
        },
        iconEdit(origen){
            const can = this.checkPermiso('money_' + origen + '_edit');
            return can;
        },
        iconDelete(origen){
            const can = this.checkPermiso('money_' + origen + '_delete');
            return can;
        },
        checkPermiso(permiso){
            const isPermiso = this.permisos.find((e) => {
                return e === permiso;
            });
            const hasAccess = Boolean(isPermiso);
            return hasAccess;
        },
    },
};
</script>
