<template>
    <v-card class="pa-5">
        <v-icon class="text-info" @click="goBack">mdi-arrow-left</v-icon>
        
        <v-row justify="center">
            <v-col md="3" class="text-center">
                <v-card-subtitle class="h5 ma-0 pa-0">
                    {{ box.fecha_apertura }}
                </v-card-subtitle>
                <v-card-subtitle class="text-success">
                    Apertura
                </v-card-subtitle>
                
            </v-col>
            <v-col md="3" class="text-center">
                <v-card-subtitle :class="box.fecha_cierre ? 'h5 ma-0 pa-0' : 'text-danger h5 ma-0 pa-0'">
                    {{ box.fecha_cierre ? box.fecha_cierre : 'Sin cierre' }}
                </v-card-subtitle>
                <v-card-subtitle class="text-danger">
                    Cierre
                </v-card-subtitle>
               
            </v-col>
        </v-row>
        <template>
            <v-row justify="center">
                <template>
                <v-container fluid>
                    <v-data-iterator
                        :items="box.monedas"
                        hide-default-footer
                    >
                    <template v-slot:default="props">
                        <v-row>
                            <v-col
                                v-for="item in props.items"
                                :key="item.nombre"
                                cols="12"
                                sm="6"
                                md="6"
                                lg="6"
                            >
                                <v-card>
                                    <v-card-title class="subheading font-weight-bold d-flex justify-center">
                                        {{ item.nombre }}
                                    </v-card-title>

                                    <v-divider></v-divider>

                                    <v-list dense>
                                        <v-list-item>
                                            <v-list-item-content>Saldo Inicial:</v-list-item-content>
                                            <v-list-item-content>
                                                {{ convertMoney(item.saldo_inicial, item.codigo)  }}
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>Ingreso directo: </v-list-item-content>
                                            <v-list-item-content>
                                                <p class="ma-0 pa-0"> {{ convertMoney(item.total_ingresos_caja, item.codigo)}}  <v-icon small class="text-info mx-3 mb-1" @click="showDetail('/box/' + id + '/money_list/currency/' + item.currency_id)">mdi-eye</v-icon> </p>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>Ventas contado:</v-list-item-content>
                                            <v-list-item-content>
                                                <p class="ma-0 pa-0"> {{ convertMoney(item.total_ventas_contado, item.codigo) }}  <v-icon small class="text-info mx-3 mb-1" @click="mostrarVentasContado(item.currency_id)">mdi-eye</v-icon></p>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>Ventas a crédito:</v-list-item-content>
                                            <v-list-item-content>
                                                <p class="ma-0 pa-0">{{ convertMoney(item.total_ventas_credito, item.codigo)  }} <v-icon small class="text-info  mx-3 mb-1" @click="mostrarVentasCredito(item.currency_id)">mdi-eye</v-icon> </p>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>Total ventas:</v-list-item-content>
                                            <v-list-item-content>
                                                <p class="ma-0 pa-0">{{ convertMoney(item.total_ventas, item.codigo)  }} <v-icon small class="text-info  mx-3 mb-1" @click="mostrarTotalVentas(item.currency_id)">mdi-eye</v-icon> </p>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>Ingreso efectivo:</v-list-item-content>
                                            <v-list-item-content>
                                                <p class="ma-0 pa-0">{{ convertMoney(item.total_efectivo, item.codigo)  }} <v-icon small class="text-info  mx-3 mb-1" @click="mostrarVentasEfectivo()">mdi-eye</v-icon> </p>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>Métodos de pago:</v-list-item-content>
                                            <v-list-item-content>
                                                <p class="ma-0 pa-0">{{ convertMoney(item.total_metodo, item.codigo)  }} <v-icon small class="text-info  mx-3 mb-1" @click="mostrarVentasMetodo()">mdi-eye</v-icon> </p>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>Cobro a deudores:</v-list-item-content>
                                            <v-list-item-content>
                                                <p class="ma-0 pa-0">{{ convertMoney(item.cobro_deudores, item.codigo)  }} <v-icon small class="text-info  mx-3 mb-1" @click="mostrarCobros()">mdi-eye</v-icon> </p>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>Total gastos:</v-list-item-content>
                                            <v-list-item-content>
                                                <p class="ma-0 pa-0"> {{ convertMoney(item.total_egresos_caja, item.codigo)}}  <v-icon small class="text-info mx-3 mb-1" @click="showDetail('/box/' + id + '/money_list/currency/' + item.currency_id)">mdi-eye</v-icon> </p>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>Pago a acreedores:</v-list-item-content>
                                            <v-list-item-content>
                                                <p class="ma-0 pa-0">{{ convertMoney(item.pago_compras_credito, item.codigo)  }} <v-icon small class="text-info  mx-3 mb-1" @click="mostrarPagoCompras()">mdi-eye</v-icon> </p>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>Devoluciones</v-list-item-content>
                                            <v-list-item-content>
                                                <p class="ma-0 pa-0">{{ convertMoney(item.total_devolutions, item.codigo)  }} <v-icon small class="text-info  mx-3 mb-1" @click="mostrarPagoCompras()" disabled>mdi-eye</v-icon> </p>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>Notas de crédito</v-list-item-content>
                                            <v-list-item-content>
                                                <p class="ma-0 pa-0">{{ convertMoney(item.total_credit_notes, item.codigo)  }} <v-icon disabled small class="text-info  mx-3 mb-1" @click="mostrarPagoCompras()">mdi-eye</v-icon> </p>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>Compras contado:</v-list-item-content>
                                            <v-list-item-content>
                                                <p class="ma-0 pa-0">{{ convertMoney(item.compras_contado, item.codigo)   }} <v-icon small class="text-info  mx-3 mb-1" @click="mostrarComprasContado()">mdi-eye</v-icon> </p>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>Arqueo:</v-list-item-content>
                                            <v-list-item-content>
                                                <p class="ma-0 pa-0">{{ convertMoney(item.cash_count, item.codigo)   }} <v-icon small class="text-info  mx-3 mb-1" @click="mostrarArqueo(item.currency_id)">mdi-eye</v-icon> </p>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content class="font-weight-bold">Efectivo total en caja:</v-list-item-content>
                                            <v-list-item-content>
                                                <p class="ma-0 pa-0 font-weight-bold">{{ convertMoney(item.total_efectivo_caja + item.saldo_inicial, item.codigo) }} </p>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-col>
                        </v-row>
                    </template>
                    </v-data-iterator>
                </v-container>
                </template>
                
            </v-row>
            
        </template>
    </v-card>
</template>


<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { convertMoney } from "../../formatos";

export default {
    data: () => ({
       id: 0,
    }),
    components:{
        
    },
    mounted(){
        this.id = this.$route.params.id;
        this.getOneBox(this.id);
    },
    computed: { 
        ...mapState("box", ["box"]),
    },
    methods: {
        convertMoney,
        ...mapActions("box", ["getOneBox"]),
        ...mapMutations("sale", ["setRuta"]),
        ...mapMutations("purchase", ["setRutaCompra"]),
        ...mapMutations("box", ["clearBox", "setRutaPayment"]),
        showDetail(ruta){
            this.$router.push(ruta);
        },
        mostrarVentasContado( currency_id ){
            this.setRuta("/boxes/" + this.id + "/sale_contado/currency/" + currency_id);
            this.$router.push('/box/' + this.id + '/box_sale');
        },
        mostrarVentasCredito( currency_id ){
            this.setRuta("/boxes/" + this.id + "/sale_credito/currency/" + currency_id);
            this.$router.push('/box/' + this.id + '/box_sale');
        },
        mostrarVentasEfectivo(){
            this.setRutaPayment("/boxes/" + this.id + "/sale_efectivo");
            this.$router.push('/box/' + this.id + '/box_payment');
        },
        mostrarVentasMetodo(){
            this.setRutaPayment("/boxes/" + this.id + "/sale_metodo");
            this.$router.push('/box/' + this.id + '/box_payment');
        },
        mostrarComprasContado(){
            this.setRutaCompra("/boxes/" + this.id + "/purchases");
            this.$router.push('/box/' + this.id + '/box_purchase');
        },
        mostrarCobros(){
            this.setRutaPayment("/boxes/" + this.id + "/cobro_deudores");
            this.$router.push('/box/' + this.id + '/box_payment');
        },
        mostrarPagoCompras(){
            this.setRutaCompra("/boxes/" + this.id + "/pago_acreedores");
            this.$router.push('/box/' + this.id + '/box_purchase');
        },
        mostrarTotalVentas( currency_id ){
            this.setRuta("/boxes/" + this.id + "/total_sales/currency/" + currency_id);
            this.$router.push('/box/' + this.id + '/box_sale');
        },
        mostrarArqueo( currency_id ){
            this.$router.push('/box/' + this.id + '/chash_count/currency/' + currency_id);
        },
        goBack(){
            this.clearBox();
            history.go(-1); 
            return false;
        },
    },
};
</script>
